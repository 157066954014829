import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"


Vue.use(Vuex)

const url = 'https://cbg.teva.ua';
// const url = 'https://cbg.inchkiev.ua';

const itemOnPage = 20;

export default new Vuex.Store({

    state: {
        totalHeight: '0px',
        menu: false,

        rowData: null,

        dataForFilters: null,

        gamePopup: false,

        rowContent: {
            data: null,
            page: 1
        },
        filterNos: null,
        currentProduction: null,
        moreProduction: null,

        loader: true,

        answerData: {
            img: null,
            linkText: null,
        },

        allFilter: true,

        nosFilter: null,

        recipe: {
            isActive: false,
            disable: false,
        },
        nonRecipe: {
            isActive: false,
            disable: false,
        },
        pharm: {
            isActive: false,
            disable: false,
        },

        pageNum: [1],

    },

    getters: {

        getMenu(state) {
            return state.menu
        },

        getTotalHeight(state) {
            return state.totalHeight
        },

        getRowContent(state) {
            return state.rowContent
        },

        getFilterNos(state) {
            return state.filterNos
        },

        getCurrentProduction(state) {
            return state.currentProduction
        },

        getMoreProduction(state) {
            return state.moreProduction
        },

        getAnswerData(state) {
            return state.answerData
        },

        getLoader(state) {
            return state.loader
        },

        getAllFilter(state) {
            return state.allFilter
        },

        getRecipe(state) {
            return state.recipe
        },

        getNonRecipe(state) {
            return state.nonRecipe
        },

        getPharm(state) {
            return state.pharm
        },

        getPageNum(state) {
            return state.pageNum
        },
        getGamePopup(state) {
            return state.gamePopup
        }
    },

    mutations: {
        setHeightContent(state) {

            let headerH = document.getElementById('header-wrapper').offsetHeight
            let footerH = document.getElementById('footer-wrapper').offsetHeight

            state.totalHeight = (headerH + footerH) + 'px'

            document.documentElement.style.setProperty('--height_content', state.totalHeight);
            document.documentElement.style.setProperty('--height_header', headerH + "px");
        },

        setMenu(state) {
            state.menu = !state.menu
        },

        setDataContent(state, data) {
            state.rowContent = data

            state.rowContent.data.sort((a, b) => a.translation.name.localeCompare(b.translation.name))
        },

        setFilterNos(state, data) {
            state.filterNos = data
        },

        setCurrentProduction(state, data) {
            state.currentProduction = data
        },

        setMoreProduction(state, data) {
            state.moreProduction = data
        },

        setAnswerData(state, data) {
            state.answerData = data
        },

        setLoader(state, data) {
            state.loader = data;
        },

        setRowData(state, data) {
            state.rowData = data
        },

        selAllFilters(state, data) {
            state.allFilter = data
        },

        setRecipe(state, data) {
            state.recipe.isActive = data.isActive;
            state.recipe.disable = data.disable;
        },

        setNonRecipe(state, data) {
            state.nonRecipe.isActive = data.isActive;
            state.nonRecipe.disable = data.disable;
        },

        setPharm(state, data) {
            state.pharm.isActive = data.isActive;
            state.pharm.disable = data.disable;
        },

        setDataForFilters(state, data) {
            state.dataForFilters = data
        },

        setPageNumSingle(state, nextPage) {
            state.pageNum = [nextPage]
        },

        setPageNumMore(state, nextPage) {
            state.pageNum.push(nextPage)
        },

        setGamePopup(state) {
            state.gamePopup = !state.gamePopup
        }
    },

    actions: {

        doLoader(context, data) {
            context.commit('setLoader', data);
        },

        doHeightContent(context) {
            context.commit('setHeightContent');
        },

        doMenu(context) {
            context.commit('setMenu');
        },

        doProduction({commit, dispatch, state}, data) {
            let lF = '';
            let sF = '';
            let rF = '';
            let aF = '';
            let nF = '';

            commit('setLoader', true);

            let elementArray = []

            if (Object.keys(state.route.query).length !== 0) {

                if (state.route.query.l !== undefined) {
                    lF = state.route.query.l;
                }

                if (state.route.query.s !== undefined) {
                    sF = state.route.query.s;
                }

                if (state.route.query.r !== undefined) {
                    rF = state.route.query.r;
                    if (rF === '0') {
                        commit('setNonRecipe', {isActive: true, disable: false});
                    } else if (rF === '1') {
                        commit('setRecipe', {isActive: true, disable: false});
                    }
                }

                if (state.route.query.a !== undefined) {
                    aF = state.route.query.a;
                    if (aF === '1') {
                        commit('setPharm', {isActive: true, disable: false});
                    }
                }

                if (state.route.query.n !== undefined) {
                    nF = state.route.query.n;

                    if (nF === '') {
                        elementArray = []
                    } else {
                        nF.split(',').forEach((value) => {
                            elementArray.push(parseInt(value));
                        })
                    }
                }
            }

            axios.get(url + "/api/getDrugs?locale=ukr" + '&l=' + lF + '&s=' + sF + '&n=' + nF + '&r=' + rF + '&a=' + aF).then((res) => {

                let nos = []

                res.data.filters.nosologies.forEach((el) => {

                    el.disabled = false

                    el.active = false

                    el.hide = false;

                    elementArray.forEach((secondEl) => {
                        if (secondEl === el.id) {
                            el.active = true
                        }
                    })

                    nos.push(el)
                })

                commit('setFilterNos', nos);

                commit('setRowData', res.data.data)

                commit('setDataForFilters', res.data.data)

                dispatch('sort', data)

                dispatch("checkDependency", data.value2)

                setTimeout(() => {
                    commit('setLoader', false);
                }, 100)
            })
        },

        doCurrentProduction(context, {id, variation_id}) {
            context.commit('setLoader', true);
            axios.get(url + "/api/getDrug/" + id + "?locale=ukr").then((res) => {


                let baseProduction = res.data;
                let variation = variation_id;
                let variationProduction = res.data.variations.find(z => variation !== undefined && z.id == variation);
                let variationProduction2 = variationProduction ? {...variationProduction} : {...res.data.variations[0]};
                let drugForm = !baseProduction.variations ? [] : baseProduction.variations.filter(z => z.id !== variationProduction.id).map(z => {
                    return {
                        ...z.drug_form,
                        variation_id: z.id,
                        drug_dosage: z.drug_dosage,
                    }
                })

                let fullData = {
                    row: baseProduction,
                    prod: variationProduction2,
                    form: drugForm
                }

                context.commit('setCurrentProduction', fullData);

                axios.get(url + "/api/getRelatedDrugs?nosology=" + baseProduction.nosology.id + "&locale=ukr").then((res) => {
                    let moreProduction = res.data.filter((el, index) => {
                        return index <= 1
                    });

                    context.commit('setMoreProduction', moreProduction);
                })

                document.title = fullData.row.translation.name;

                setTimeout(() => {
                    context.commit('setLoader', false);
                }, 100)
            })
        },

        doAnswerData(context) {
            context.commit('setLoader', true);
            axios.get(url + "/api/mnn?locale=ukr").then((res) => {

                axios.get(url + "/api/mnn?locale=ru").then((secondRes) => {
                    let img = res.data;

                    let data = {
                        img: null,
                        imgMob: null,
                        linkText: null,
                    }

                    data.img = Object.values(img)
                    data.linkText = Object.keys(img)
                    data.imgMob = Object.values(secondRes.data)

                    context.commit('setAnswerData', data);

                    setTimeout(() => {
                        context.commit('setLoader', false);
                    }, 100)
                })

            })
        },

        changeRecipe({commit}, data) {
            commit('setRecipe', {isActive: data, disable: false});
        },

        changeNonRecipe({commit}, data) {
            commit('setNonRecipe', {isActive: data, disable: false});
        },

        changePharm({commit}, data) {
            commit('setPharm', {isActive: data, disable: false});
        },

        checkDependency({commit, dispatch, state}, action) {

            commit('setRecipe', {isActive: state.recipe.isActive, disable: false});
            commit('setNonRecipe', {isActive: state.nonRecipe.isActive, disable: false});
            commit('setPharm', {isActive: state.pharm.isActive, disable: false});

            let array = []

            state.dataForFilters.forEach((el) => {
                if (!el.hide) {
                    array.push(el)
                }
            })

            if (array.filter(e => e.with_recipe).length === 0) {
                commit('setRecipe', {isActive: false, disable: true});
            }

            if (array.filter(e => !e.with_recipe).length === 0) {
                commit('setNonRecipe', {isActive: false, disable: true});
            }

            if (array.filter(e => e.available_medication).length === 0) {
                commit('setPharm', {isActive: false, disable: true});
            }


            state.filterNos.forEach((el) => {
                el.disabled = false;
                if (state.recipe.isActive || state.nonRecipe.isActive || state.pharm.isActive || action !== '') {
                    if (array.filter(e => e.nosology.id === el.id).length === 0) {
                        el.disabled = true
                    }
                }
            })

        },

        totalPage({commit, dispatch, state}) {

            let currentPage = state.rowContent.page

            dispatch('Paginator', {
                items: state.rowData.filter((el) => {
                    return !el.hide
                }), page: currentPage, per_page: itemOnPage
            }).then((res) => {
                commit('setDataContent', res);

            })
        },

        getNewPage({commit, dispatch, state}, goToPage) {
            if (goToPage.type === 'single') {

                commit('setPageNumSingle', goToPage.page)

                dispatch('Paginator', {
                    items: state.rowData.filter((el) => {
                        return !el.hide
                    }), page: goToPage.page, per_page: itemOnPage
                }).then((res) => {

                    commit('setDataContent', res);

                    window.scrollTo(0, 0)

                })
            } else if (goToPage.type === 'more') {

                commit('setPageNumMore', goToPage.page)

                dispatch('Paginator2', {
                    items: state.rowData.filter((el) => {
                        return !el.hide
                    }), page: goToPage.page, per_page: itemOnPage
                }).then((res) => {

                    commit('setDataContent', res);

                })
            }
        },

        sort({commit, dispatch, state}, data) {

            dispatch('sortText', data)
            dispatch('sortDrop', data)

            let dataPage = {page: 1, type: "single"}

            dispatch('getNewPage', dataPage)

        },

        sortDrop({commit, dispatch, state}, data) {

            if (data.value1 === 'За категоріями') {
                state.rowData.sort((x, y) => {
                    return x.nosology.name.localeCompare(y.nosology.name);
                });
            }

            if (data.value1 === 'За популярністю') {
                state.rowData.sort((a, b) => {
                    return b.popularity - a.popularity
                });
            }

            if (data.value1 === 'За алфавітом') {
                state.rowData.sort((x, y) => {
                    return x.translation.name.localeCompare(y.translation.name);
                });
            }

        },

        sortText({commit, dispatch, state}, data) {
            state.rowData.forEach(el => {
                el.hide = false

                if (!el.translation.name.toLowerCase().includes(data.value2.toLowerCase())) {
                    el.hide = true
                }
            })
        },

        Paginator({commit, dispatch}, data) {

            var page = data.page || 1,
                per_page = data.per_page || 10,
                offset = (page - 1) * per_page,
                paginatedItems = data.items.slice(offset).slice(0, per_page),
                total_pages = Math.ceil(data.items.length / per_page);

            return {
                page: page,
                per_page: per_page,
                pre_page: page - 1 ? page - 1 : null,
                next_page: (total_pages > page) ? page + 1 : null,
                total: data.items.length,
                total_pages: total_pages,
                data: paginatedItems,
            };
        },

        Paginator2({commit, dispatch, state}, data) {

            var page = data.page || 1,
                per_page = data.per_page || 10,
                offset = (page - 1) * per_page,
                paginatedItems = data.items.slice(offset).slice(0, per_page),
                total_pages = Math.ceil(data.items.length / per_page);

            let concatArray = state.rowContent.data.concat(paginatedItems)

            return {
                page: page,
                per_page: per_page,
                pre_page: page - 1 ? page - 1 : null,
                next_page: (total_pages > page) ? page + 1 : null,
                total: data.items.length,
                total_pages: total_pages,
                data: concatArray,
            };

            // let dataPre = []
            //
            // let page = '',
            //   per_page = '',
            //   offset = '',
            //   paginatedItems = '',
            //   total_pages = '';
            //
            // state.pageNum.forEach((el) => {
            //
            //   page = el || 1;
            //   per_page = data.per_page || 10;
            //   offset = (page - 1) * per_page
            //   paginatedItems = data.items.slice(offset).slice(0, per_page)
            //   total_pages = Math.ceil(data.items.length / per_page)
            //
            //   dataPre.push(paginatedItems)
            // })
            //
            // return {
            //   page: page,
            //   per_page: per_page,
            //   pre_page: page - 1 ? page - 1 : null,
            //   next_page: (total_pages > page) ? page + 1 : null,
            //   total: data.items.length,
            //   total_pages: total_pages,
            //   data: dataPre,
            // };
        },

        GamePopup(context) {
            context.commit('setGamePopup');
        }
    }
})