<template>
  <div class="footer-wrapper" id="footer-wrapper">
    <div class="footer-item">

      <p class="text1" v-html="textFooter.footer_one"></p>

      <div class="center-block">

        <p class="left-text" v-html="textFooter.footer_two"></p>

        <a :onclick="`gtag('event', 'click', {  'event_category': 'footer: кнопка подписаться',  'event_label': '${$route.name}'})`"
           target="_blank" href="https://consent.teva.ua/" class="sub-btn hover_type1">
          <p>Пiдписатися</p>
        </a>

      </div>

      <p class="text2" v-html="textFooter.footer_third"></p>

    </div>

  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "footer-wrapper",

  data: () => ({

    apiUrl: "",

    currentLang: null,

    textFooter: {},

    activeClass: "active"

  }),
  created() {
    this.getFooterText();
  },
  methods: {
    getFooterText() {
      axios.get(this.$root.globalUrl + "/api/getFooterText?locale=ukr").then((res) => {
        this.textFooter = JSON.parse(res.data)
      })
    }
  }
}
</script>

<style lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.footer-wrapper {
  margin: auto 0;
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-self: flex-end;
  background: $green;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  min-height: 150px;

  sup{
    line-height: 0 !important;
    font-size: 80%;
    vertical-align: revert;
  }

  .footer-item {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 42px 0;
    }

    @media screen and (max-width: 425px) {
      align-items: center;
    }

    .text1 {

      max-width: 345px;
      width: 100%;
      color: $white;
      font-size: 10px;
      font-family: $R;
      @media screen and (max-width: 1000px) {
        order: 3;
        max-width: 1000px;
        margin-bottom: 10px;
      }

      @media screen and (max-width: 425px) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }

    .center-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px;
      @media screen and (max-width: 1000px) {
        margin: 0 0 10px 0;
        order: 1;
      }
      @media screen and (max-width: 425px) {
        margin: 0;
        flex-direction: column;
      }


      .left-text {
        color: $white;
        font-family: $B;
        font-size: 14px;
        margin-right: 22px;
        max-width: 250px;
        width: 100%;
        @media screen and (max-width: 1000px) {
          margin-right: 15px;
        }
        @media screen and (max-width: 425px) {
          font-size: 14px;
          text-align: center;
          max-width: 300px;
          width: 100%;
          margin-bottom: 13px;
          margin-right: 0;
        }
      }

      a {
        text-decoration: none;
      }

      .sub-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 35px;
        width: 190px;
        min-width: 140px;
        height: 50px;

        @media screen and (max-width: 1000px) {
          width: 140px;
          height: 30px;
        }

        @media screen and (max-width: 425px) {
          width: 192px;
          height: 50px;
          margin-bottom: 40px;
        }

        p {
          font-size: 16px;
          color: $green;
          font-family: $B;
          @media screen and (max-width: 1000px) {
            font-size: 14px;
          }

          @media screen and (max-width: 425px) {
            font-size: 14px;
          }
        }
      }
    }

    .text2 {
      max-width: 292px;
      width: 100%;
      color: $white;
      font-size: 12px;
      font-family: $M;

      a {
        color: $white;

        @media screen and (max-width: 425px) {
          display: inline;

        }
      }

      @media screen and (max-width: 1000px) {
        order: 2;
        margin: 0 0 10px 0;
      }

      @media screen and (max-width: 425px) {
        font-size: 12px;
        text-align: center;
        margin-bottom: 20px;

      }
    }
  }


}
</style>
