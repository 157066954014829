import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '../views/Home.vue'
import production from '../views/production.vue'
import answers from '../views/answers.vue'
import budesonid from '../views/games/Budesonid.vue'
import clopidogrel from '../views/games/Clopidogrel.vue'
import acetylcysteine from '../views/games/Acetylcysteine.vue'
import atorvastatin from '../views/games/Atorvastatin.vue'
import desloratadine from '../views/games/Desloratadine.vue'
import omeprazol from '../views/games/Omeprazol.vue'
import ramipril from '../views/games/Ramipril.vue'
import games from '../views/games.vue'
import stoptusin from '../views/games/Stoptusin.vue'
import askTeva from '../views/games/ask-teva.vue'
import valsartan from '../views/games/Valsartan.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/production/:id/:variation?',
    name: 'production',
    component: production
  }, {
    path: '/answers',
    name: 'answers',
    component: answers
  },

  {
    path: '/games/budesonid',
    name: 'budesonid',
    component: budesonid
  },
  {
    path: '/games/clopidogrel',
    name: 'clopidogrel',
    component: clopidogrel
  },
  {
    path: '/games/acetylcysteine',
    name: 'acetylcysteine',
    component: acetylcysteine
  },
  {
    path: '/games/atorvastatin',
    name: 'atorvastatin',
    component: atorvastatin
  },
  {
    path: '/games/desloratadine',
    name: 'desloratadine',
    component: desloratadine
  },
  {
    path: '/games/omeprazol',
    name: 'omeprazol',
    component: omeprazol
  },
  {
    path: '/games/ramipril',
    name: 'ramipril',
    component: ramipril
  },
  {
    path: '/games',
    name: 'games',
    component: games
  },
  {
    path: '/games/stoptusin',
    name: 'stoptusin',
    component: stoptusin
  },
  {
    path: '/games/asa',
    name: 'askTeva',
    component: askTeva
  },
  {
    path: '/games/valsartan',
    name: 'valsartan',
    component: valsartan
  },


  {
    path: '*',
    redirect: "/"
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  },
})

export default router