import { render, staticRenderFns } from "./Ramipril-game.vue?vue&type=template&id=2c05416a&scoped=true&"
import script from "./Ramipril-game.vue?vue&type=script&lang=js&"
export * from "./Ramipril-game.vue?vue&type=script&lang=js&"
import style0 from "./Ramipril-game.vue?vue&type=style&index=0&id=2c05416a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c05416a",
  null
  
)

export default component.exports