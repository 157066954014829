import { render, staticRenderFns } from "./Clopidogrel.vue?vue&type=template&id=795db166&scoped=true&"
import script from "./Clopidogrel.vue?vue&type=script&lang=js&"
export * from "./Clopidogrel.vue?vue&type=script&lang=js&"
import style0 from "./Clopidogrel.vue?vue&type=style&index=0&id=795db166&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795db166",
  null
  
)

export default component.exports