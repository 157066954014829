<template>
  <div class="clopidogrel-game">

    <div class="game-stage" v-show="!result.win && !result.lose">

      <div class="game-bar">

        <div class="game-bar__timer-section">

          <svg :class="[{ red: time <=5}]" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="18.3333" r="13" stroke="#00A03B" stroke-width="2"/>
            <path d="M17.7913 1H10.208" stroke="#00A03B" stroke-width="2"/>
            <path d="M14 31.3333L14 28.0833" stroke="#00A03B" stroke-width="2"/>
            <path d="M14 8.58331L14 5.33331" stroke="#00A03B" stroke-width="2"/>
            <path d="M1 18.3333L4.25 18.3333" stroke="#00A03B" stroke-width="2"/>
            <path d="M23.75 18.3333L27 18.3333" stroke="#00A03B" stroke-width="2"/>
            <path d="M4.80737 9.14093L7.10547 11.439" stroke="#00A03B" stroke-width="2"/>
            <path d="M20.8943 25.2277L23.1924 27.5258" stroke="#00A03B" stroke-width="2"/>
            <path d="M23.1926 9.14093L20.8945 11.439" stroke="#00A03B" stroke-width="2"/>
            <path d="M7.10571 25.2277L4.80762 27.5258" stroke="#00A03B" stroke-width="2"/>
            <path d="M14.0003 18.7246L18.6045 10.75" stroke="#00A03B" stroke-width="2"/>
          </svg>

          <timer :class="[{ red: time <=5}]" :time="prettyTime"></timer>

        </div>

        <div ref="gameBare" class="game-bar__health-wrapper">

          <svg :class="{ active:  hp >= 3 }" width="38" height="33" viewBox="0 0 38 33" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6032 2.86942C12.805 -0.956476 6.64686 -0.956474 2.84865 2.86943C-0.949555 6.69533 -0.94955 12.8983 2.84866 16.7242L5.24546 19.1385L5.23886 19.1452L18.9934 33L19 32.9933L19.0066 33L32.7611 19.1452L32.7545 19.1385L35.1513 16.7242C38.9496 12.8983 38.9496 6.69533 35.1513 2.86943C31.3531 -0.956474 25.195 -0.956476 21.3968 2.86942L19 5.28371L16.6032 2.86942Z"
                fill="#C40000"/>
          </svg>

          <svg :class="{ active:  hp >= 2 }" width="38" height="33" viewBox="0 0 38 33" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6032 2.86942C12.805 -0.956476 6.64686 -0.956474 2.84865 2.86943C-0.949555 6.69533 -0.94955 12.8983 2.84866 16.7242L5.24546 19.1385L5.23886 19.1452L18.9934 33L19 32.9933L19.0066 33L32.7611 19.1452L32.7545 19.1385L35.1513 16.7242C38.9496 12.8983 38.9496 6.69533 35.1513 2.86943C31.3531 -0.956474 25.195 -0.956476 21.3968 2.86942L19 5.28371L16.6032 2.86942Z"
                fill="#C40000"/>
          </svg>

          <svg :class="{ active:  hp >= 1  }" width="38" height="33" viewBox="0 0 38 33" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6032 2.86942C12.805 -0.956476 6.64686 -0.956474 2.84865 2.86943C-0.949555 6.69533 -0.94955 12.8983 2.84866 16.7242L5.24546 19.1385L5.23886 19.1452L18.9934 33L19 32.9933L19.0066 33L32.7611 19.1452L32.7545 19.1385L35.1513 16.7242C38.9496 12.8983 38.9496 6.69533 35.1513 2.86943C31.3531 -0.956474 25.195 -0.956476 21.3968 2.86942L19 5.28371L16.6032 2.86942Z"
            />
          </svg>

        </div>

      </div>

      <div class="game">

        <div ref="wrapper" class="game__action" :style="heightContent">

          <div class="top-wrapper">
            <img class="top-img" src="../assets/img/game-clopidogrel/game-top.svg" alt="">

            <img v-show="light" class="light light1" src="../assets/img/game-clopidogrel/l1.png" alt="">
            <img v-show="light" class="light light2" src="../assets/img/game-clopidogrel/l2.png" alt="">
            <img v-show="light" class="light light3" src="../assets/img/game-clopidogrel/l3.png" alt="">
          </div>

          <svg class="slider" viewBox="0 0 749 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M502.945 33.709H500.61L499.481 25.8933H504.074L502.945 33.709Z" fill="#824E3E"/>
            <path d="M690.919 33.709H688.585L687.456 25.8933H692.048L690.919 33.709Z" fill="#824E3E"/>
            <path d="M494.626 32.329L492.696 31.018L496.16 23.9204L499.959 26.499L494.626 32.329Z" fill="#824E3E"/>
            <path d="M682.6 32.329L680.671 31.018L684.134 23.9204L687.933 26.499L682.6 32.329Z" fill="#824E3E"/>
            <path d="M489.412 27.1265L488.376 25.0393L494.88 20.553L496.922 24.6621L489.412 27.1265Z" fill="#824E3E"/>
            <path d="M677.387 27.1265L676.351 25.0393L682.854 20.553L684.896 24.6621L677.387 27.1265Z" fill="#824E3E"/>
            <path d="M487.408 19.1077L487.444 16.7775L495.286 15.7765L495.212 20.3625L487.408 19.1077Z" fill="#824E3E"/>
            <path d="M675.383 19.1077L675.419 16.7775L683.261 15.7765L683.186 20.3625L675.383 19.1077Z" fill="#824E3E"/>
            <path d="M488.729 10.9729L489.95 8.9873L497.207 12.1172L494.802 16.0268L488.729 10.9729Z" fill="#824E3E"/>
            <path d="M676.703 10.9729L677.925 8.9873L685.182 12.1172L682.777 16.0268L676.703 10.9729Z" fill="#824E3E"/>
            <path d="M493.649 5.48381L495.693 4.36133L500.453 10.6646L496.428 12.8769L493.649 5.48381Z" fill="#824E3E"/>
            <path d="M681.624 5.48381L683.668 4.36133L688.427 10.6646L684.403 12.8769L681.624 5.48381Z" fill="#824E3E"/>
            <path d="M501.83 3.30774L504.163 3.38753L505.023 11.2376L500.433 11.0799L501.83 3.30774Z" fill="#824E3E"/>
            <path d="M689.805 3.30774L692.137 3.38753L692.998 11.2376L688.407 11.0799L689.805 3.30774Z" fill="#824E3E"/>
            <path d="M509.853 4.76208L511.818 6.01694L508.558 13.2106L504.688 10.7408L509.853 4.76208Z" fill="#824E3E"/>
            <path d="M697.827 4.76208L699.793 6.01694L696.533 13.2106L692.663 10.7408L697.827 4.76208Z" fill="#824E3E"/>
            <path d="M515.253 9.77966L516.342 11.8415L509.949 16.4855L507.808 12.4272L515.253 9.77966Z" fill="#824E3E"/>
            <path d="M703.228 9.77966L704.317 11.8415L697.924 16.4855L695.782 12.4272L703.228 9.77966Z" fill="#824E3E"/>
            <path d="M517.324 18.0868L517.195 20.4134L509.321 21.1061L509.577 16.5237L517.324 18.0868Z" fill="#824E3E"/>
            <path d="M705.299 18.0868L705.17 20.4134L697.296 21.1061L697.552 16.5237L705.299 18.0868Z" fill="#824E3E"/>
            <path d="M515.716 25.8807L514.447 27.8355L507.269 24.5297L509.768 20.6799L515.716 25.8807Z" fill="#824E3E"/>
            <path d="M703.691 25.8807L702.422 27.8355L695.243 24.5297L697.743 20.6799L703.691 25.8807Z" fill="#824E3E"/>
            <path d="M510.784 31.3189L508.769 32.4976L503.843 26.3213L507.807 24.0038L510.784 31.3189Z" fill="#824E3E"/>
            <path d="M698.759 31.3189L696.744 32.4976L691.817 26.3213L695.782 24.0038L698.759 31.3189Z" fill="#824E3E"/>
            <path d="M502.945 33.709H500.61L499.481 25.8933H504.074L502.945 33.709Z" fill="#824E3E"/>
            <path d="M690.919 33.709H688.585L687.456 25.8933H692.048L690.919 33.709Z" fill="#824E3E"/>
            <path
                d="M502.342 29.2372C508.415 29.2372 513.338 24.3189 513.338 18.2518C513.338 12.1847 508.415 7.26636 502.342 7.26636C496.269 7.26636 491.346 12.1847 491.346 18.2518C491.346 24.3189 496.269 29.2372 502.342 29.2372Z"
                fill="#D98268"/>
            <path
                d="M690.317 29.2372C696.39 29.2372 701.313 24.3189 701.313 18.2518C701.313 12.1847 696.39 7.26636 690.317 7.26636C684.243 7.26636 679.32 12.1847 679.32 18.2518C679.32 24.3189 684.243 29.2372 690.317 29.2372Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M491.549 18.7033C491.549 15.7896 492.708 12.9954 494.771 10.9353C496.833 8.87521 499.63 7.71787 502.547 7.71787C504.644 7.71872 506.696 8.31879 508.463 9.44724C510.229 10.5757 511.635 12.1854 512.516 14.0864C511.689 12.0691 510.28 10.3431 508.468 9.12828C506.656 7.91344 504.524 7.26466 502.342 7.26453C499.425 7.26453 496.628 8.42187 494.566 10.482C492.503 12.5421 491.344 15.3363 491.344 18.2499C491.345 19.8449 491.697 21.4202 492.373 22.865C491.829 21.5448 491.549 20.131 491.549 18.7033V18.7033Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M679.523 18.7033C679.524 15.7896 680.683 12.9954 682.745 10.9353C684.808 8.87521 687.605 7.71787 690.521 7.71787C692.618 7.71872 694.671 8.31879 696.437 9.44724C698.204 10.5757 699.61 12.1854 700.49 14.0864C699.664 12.0691 698.255 10.3431 696.443 9.12828C694.631 7.91344 692.498 7.26466 690.316 7.26453C687.4 7.26453 684.603 8.42187 682.54 10.482C680.478 12.5421 679.319 15.3363 679.318 18.2499C679.32 19.8449 679.671 21.4202 680.348 22.865C679.804 21.5448 679.524 20.131 679.523 18.7033V18.7033Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M509.256 9.71265C510.42 10.7407 511.351 12.0041 511.989 13.419C512.627 14.8339 512.957 16.3681 512.957 17.92C512.957 20.8335 511.798 23.6277 509.736 25.6878C507.674 27.748 504.877 28.9054 501.961 28.9054C499.443 28.908 497.001 28.0434 495.047 26.4573C497.054 28.2485 499.651 29.2382 502.342 29.2372C505.257 29.2372 508.054 28.0806 510.116 26.0216C512.178 23.9626 513.337 21.1698 513.338 18.2573C513.34 16.6188 512.974 15.0008 512.268 13.5221C511.561 12.0434 510.532 10.7417 509.256 9.71265Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M697.231 9.71265C698.394 10.7407 699.326 12.0041 699.964 13.419C700.602 14.8339 700.932 16.3681 700.932 17.92C700.932 20.8335 699.773 23.6277 697.711 25.6878C695.649 27.748 692.852 28.9054 689.935 28.9054C687.418 28.908 684.976 28.0434 683.021 26.4573C685.028 28.2485 687.625 29.2382 690.317 29.2372C693.232 29.2372 696.028 28.0806 698.09 26.0216C700.152 23.9626 701.311 21.1698 701.313 18.2573C701.315 16.6188 700.949 15.0008 700.242 13.5221C699.536 12.0434 698.507 10.7417 697.231 9.71265Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M496.466 18.6199C496.466 17.8217 496.624 17.0314 496.929 16.294C497.235 15.5567 497.683 14.8867 498.248 14.3224C498.813 13.7581 499.484 13.3106 500.222 13.0053C500.96 12.7 501.752 12.543 502.551 12.5433C503.597 12.5422 504.627 12.8115 505.539 13.3249C506.451 13.8383 507.215 14.5785 507.756 15.4737C507.246 14.4803 506.471 13.6467 505.517 13.0645C504.563 12.4823 503.467 12.1739 502.349 12.1733C500.735 12.1733 499.188 12.8138 498.047 13.9537C496.906 15.0936 496.265 16.6397 496.265 18.2518C496.265 19.3614 496.571 20.4494 497.15 21.3962C496.703 20.5389 496.468 19.5867 496.466 18.6199V18.6199Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M684.441 18.6199C684.441 17.8217 684.598 17.0314 684.904 16.294C685.21 15.5567 685.658 14.8867 686.223 14.3224C686.788 13.7581 687.459 13.3106 688.197 13.0053C688.935 12.7 689.726 12.543 690.525 12.5433C691.572 12.5422 692.602 12.8115 693.514 13.3249C694.426 13.8383 695.19 14.5785 695.731 15.4737C695.22 14.4803 694.445 13.6467 693.492 13.0645C692.538 12.4823 691.442 12.1739 690.324 12.1733C688.71 12.1733 687.162 12.8138 686.021 13.9537C684.88 15.0936 684.239 16.6397 684.239 18.2518C684.239 19.3614 684.546 20.4494 685.125 21.3962C684.678 20.5389 684.443 19.5867 684.441 18.6199V18.6199Z"
                  fill="#331911"/>
            </g>
            <path
                d="M502.342 9.91565C500.127 9.91565 498.004 10.7945 496.438 12.3588C494.872 13.9232 493.992 16.0449 493.992 18.2572C493.992 20.4695 494.872 22.5912 496.438 24.1555C498.004 25.7198 500.127 26.5987 502.342 26.5987C504.556 26.5987 506.68 25.7198 508.246 24.1555C509.812 22.5912 510.692 20.4695 510.692 18.2572C510.692 16.0449 509.812 13.9232 508.246 12.3588C506.68 10.7945 504.556 9.91565 502.342 9.91565V9.91565ZM502.342 24.3374C501.543 24.3374 500.752 24.1802 500.013 23.8747C499.275 23.5692 498.605 23.1215 498.04 22.5571C497.475 21.9927 497.026 21.3226 496.721 20.5851C496.415 19.8476 496.257 19.0572 496.257 18.259C496.257 16.6469 496.899 15.1008 498.04 13.9609C499.181 12.8209 500.728 12.1806 502.342 12.1806C503.955 12.1806 505.503 12.8207 506.644 13.9602C507.785 15.0998 508.426 16.6454 508.426 18.2572C508.425 19.8683 507.783 21.413 506.642 22.5518C505.501 23.6905 503.955 24.3302 502.342 24.3302V24.3374Z"
                fill="#824E3E"/>
            <path
                d="M690.316 9.91565C688.102 9.91565 685.978 10.7945 684.412 12.3588C682.846 13.9232 681.967 16.0449 681.967 18.2572C681.967 20.4695 682.846 22.5912 684.412 24.1555C685.978 25.7198 688.102 26.5987 690.316 26.5987C692.531 26.5987 694.655 25.7198 696.221 24.1555C697.787 22.5912 698.666 20.4695 698.666 18.2572C698.666 16.0449 697.787 13.9232 696.221 12.3588C694.655 10.7945 692.531 9.91565 690.316 9.91565V9.91565ZM690.316 24.3374C689.517 24.3374 688.726 24.1802 687.988 23.8747C687.25 23.5692 686.579 23.1215 686.014 22.5571C685.449 21.9927 685.001 21.3226 684.695 20.5851C684.389 19.8476 684.232 19.0572 684.232 18.259C684.232 16.6469 684.873 15.1008 686.014 13.9609C687.155 12.8209 688.703 12.1806 690.316 12.1806C691.93 12.1806 693.477 12.8207 694.618 13.9602C695.759 15.0998 696.4 16.6454 696.401 18.2572C696.399 19.8683 695.758 21.413 694.617 22.5518C693.476 23.6905 691.929 24.3302 690.316 24.3302V24.3374Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M494.199 18.6199C494.199 16.4076 495.079 14.2859 496.645 12.7216C498.21 11.1573 500.334 10.2784 502.549 10.2784C504.003 10.279 505.432 10.6596 506.693 11.3823C507.955 12.1051 509.005 13.1448 509.74 14.3984C509.035 13.0464 507.972 11.9133 506.668 11.1224C505.363 10.3314 503.866 9.91285 502.34 9.91211C500.125 9.91211 498.002 10.791 496.436 12.3553C494.87 13.9196 493.99 16.0413 493.99 18.2536C493.99 19.7397 494.39 21.1985 495.148 22.477C494.524 21.2871 494.198 19.9635 494.199 18.6199Z"
                  fill="#E8E6E1"/>
              <path
                  d="M502.551 24.6966C504.164 24.6966 505.711 24.0564 506.852 22.9168C507.993 21.7772 508.633 20.2316 508.633 18.62C508.633 17.5107 508.327 16.4228 507.749 15.4756C508.194 16.3344 508.426 17.2869 508.427 18.2537C508.427 19.8658 507.786 21.4118 506.644 22.5518C505.503 23.6917 503.956 24.3321 502.342 24.3321C501.296 24.3317 500.268 24.0614 499.357 23.5474C498.446 23.0333 497.684 22.293 497.144 21.398C497.654 22.3915 498.429 23.225 499.383 23.807C500.337 24.3889 501.433 24.6968 502.551 24.6966V24.6966Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M682.174 18.6199C682.174 16.4076 683.053 14.2859 684.619 12.7216C686.185 11.1573 688.309 10.2784 690.523 10.2784C691.978 10.279 693.406 10.6596 694.668 11.3823C695.929 12.1051 696.98 13.1448 697.715 14.3984C697.01 13.0464 695.947 11.9133 694.642 11.1224C693.337 10.3314 691.841 9.91285 690.315 9.91211C688.1 9.91211 685.976 10.791 684.41 12.3553C682.845 13.9196 681.965 16.0413 681.965 18.2536C681.965 19.7397 682.365 21.1985 683.123 22.477C682.498 21.2871 682.173 19.9635 682.174 18.6199Z"
                  fill="#E8E6E1"/>
              <path
                  d="M690.526 24.6966C692.139 24.6966 693.686 24.0564 694.827 22.9168C695.967 21.7772 696.608 20.2316 696.608 18.62C696.608 17.5107 696.302 16.4228 695.724 15.4756C696.168 16.3344 696.401 17.2869 696.401 18.2537C696.401 19.8658 695.76 21.4118 694.619 22.5518C693.478 23.6917 691.93 24.3321 690.317 24.3321C689.271 24.3317 688.242 24.0614 687.332 23.5474C686.421 23.0333 685.658 22.293 685.118 21.398C685.629 22.3915 686.403 23.225 687.358 23.807C688.312 24.3889 689.408 24.6968 690.526 24.6966V24.6966Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M491.344 18.2572C491.344 18.4748 491.366 18.6888 491.378 18.9028C492.592 19.5071 493.929 19.8218 495.284 19.8222C496.446 19.8231 497.595 19.5954 498.668 19.1521C499.741 18.7087 500.716 18.0585 501.537 17.2385C502.358 16.4184 503.01 15.4447 503.454 14.3731C503.898 13.3014 504.127 12.1528 504.126 10.9928C504.125 9.72221 503.848 8.46693 503.315 7.31351C502.997 7.28631 502.676 7.26636 502.349 7.26636C500.904 7.26541 499.473 7.54901 498.137 8.10097C496.802 8.65293 495.588 9.46241 494.566 10.4831C493.544 11.5039 492.734 12.7159 492.181 14.0498C491.628 15.3837 491.343 16.8134 491.344 18.2572V18.2572Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M679.318 18.2572C679.318 18.4748 679.34 18.6888 679.353 18.9028C680.566 19.5071 681.903 19.8218 683.259 19.8222C684.42 19.8231 685.57 19.5954 686.643 19.1521C687.716 18.7087 688.691 18.0585 689.512 17.2385C690.333 16.4184 690.984 15.4447 691.429 14.3731C691.873 13.3014 692.101 12.1528 692.101 10.9928C692.1 9.72221 691.823 8.46693 691.289 7.31351C690.972 7.28631 690.65 7.26636 690.324 7.26636C688.878 7.26541 687.447 7.54901 686.112 8.10097C684.776 8.65293 683.563 9.46241 682.541 10.4831C681.519 11.5039 680.708 12.7159 680.155 14.0498C679.602 15.3837 679.318 16.8134 679.318 18.2572V18.2572Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M507.163 8.38879C508.828 10.2935 509.744 12.7367 509.743 15.2651C509.743 18.0493 508.636 20.7194 506.665 22.6881C504.694 24.6567 502.022 25.7627 499.235 25.7627C496.837 25.7646 494.512 24.9439 492.647 23.438C493.586 25.1913 494.984 26.6572 496.691 27.6791C498.399 28.7011 500.352 29.2408 502.342 29.2408C505.258 29.2408 508.055 28.0836 510.117 26.0239C512.179 23.9641 513.338 21.1704 513.338 18.2572C513.338 16.1994 512.759 14.1831 511.667 12.4382C510.576 10.6934 509.015 9.29021 507.163 8.38879V8.38879Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M695.138 8.38879C696.802 10.2935 697.719 12.7367 697.717 15.2651C697.717 18.0493 696.61 20.7194 694.639 22.6881C692.669 24.6567 689.996 25.7627 687.209 25.7627C684.812 25.7646 682.486 24.9439 680.622 23.438C681.561 25.1913 682.959 26.6572 684.666 27.6791C686.373 28.7011 688.326 29.2408 690.317 29.2408C693.233 29.2408 696.03 28.0836 698.092 26.0239C700.154 23.9641 701.313 21.1704 701.313 18.2572C701.313 16.1994 700.734 14.1831 699.642 12.4382C698.55 10.6934 696.989 9.29021 695.138 8.38879V8.38879Z"
                  fill="#FAF8F2"/>
            </g>
            <path
                d="M498.808 18.2572C498.808 17.3208 499.18 16.4228 499.843 15.7607C500.506 15.0985 501.404 14.7266 502.342 14.7266C503.279 14.727 504.177 15.0992 504.84 15.7613C505.502 16.4234 505.874 17.3211 505.874 18.2572C505.874 19.1931 505.502 20.0907 504.839 20.7524C504.177 21.4142 503.279 21.786 502.342 21.786C501.405 21.786 500.506 21.4143 499.843 20.7526C499.181 20.0908 498.808 19.1933 498.808 18.2572V18.2572Z"
                fill="#4E415C"/>
            <path
                d="M686.782 18.2572C686.782 17.3208 687.155 16.4228 687.817 15.7607C688.48 15.0985 689.379 14.7266 690.316 14.7266C691.253 14.727 692.152 15.0992 692.814 15.7613C693.477 16.4234 693.849 17.3211 693.849 18.2572C693.849 19.1931 693.476 20.0907 692.814 20.7524C692.152 21.4142 691.253 21.786 690.316 21.786C689.379 21.786 688.481 21.4143 687.818 20.7526C687.155 20.0908 686.783 19.1933 686.782 18.2572V18.2572Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M505.143 16.1211C505.352 16.5762 505.462 17.0709 505.464 17.5718C505.464 18.5082 505.091 19.4062 504.429 20.0683C503.766 20.7305 502.867 21.1024 501.93 21.1024C501.386 21.1014 500.85 20.9742 500.364 20.731C499.878 20.4877 499.455 20.135 499.129 19.7007C499.408 20.3189 499.859 20.8437 500.429 21.212C500.999 21.5803 501.663 21.7765 502.342 21.777C503.277 21.777 504.174 21.4065 504.836 20.7467C505.498 20.0868 505.872 19.1916 505.874 18.2573C505.874 17.484 505.616 16.7327 505.143 16.1211V16.1211Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M693.117 16.1211C693.327 16.5762 693.436 17.0709 693.438 17.5718C693.438 18.5082 693.066 19.4062 692.403 20.0683C691.741 20.7305 690.842 21.1024 689.904 21.1024C689.361 21.1014 688.825 20.9742 688.339 20.731C687.853 20.4877 687.43 20.135 687.104 19.7007C687.382 20.3189 687.834 20.8437 688.404 21.212C688.973 21.5803 689.638 21.7765 690.316 21.777C691.252 21.777 692.149 21.4065 692.811 20.7467C693.473 20.0868 693.846 19.1916 693.849 18.2573C693.848 17.484 693.591 16.7327 693.117 16.1211V16.1211Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M499.354 19.0043C499.354 18.5407 499.445 18.0816 499.623 17.6532C499.801 17.2249 500.061 16.8356 500.389 16.5078C500.717 16.1799 501.107 15.9199 501.536 15.7424C501.964 15.565 502.424 15.4737 502.888 15.4737C503.359 15.4735 503.826 15.568 504.26 15.7515C504.694 15.935 505.087 16.2037 505.415 16.5418C505.111 15.9931 504.666 15.5357 504.126 15.2168C503.585 14.8979 502.969 14.7293 502.342 14.7284C501.405 14.7284 500.506 15.1001 499.843 15.7619C499.181 16.4236 498.808 17.3211 498.808 18.2572C498.808 19.1782 499.17 20.0623 499.815 20.7198C499.516 20.1973 499.357 19.6063 499.354 19.0043V19.0043Z"
                  fill="#241133"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M687.329 19.0043C687.329 18.5407 687.42 18.0816 687.598 17.6532C687.775 17.2249 688.036 16.8356 688.364 16.5078C688.692 16.1799 689.081 15.9199 689.51 15.7424C689.939 15.565 690.399 15.4737 690.863 15.4737C691.334 15.4735 691.801 15.568 692.235 15.7515C692.669 15.935 693.061 16.2037 693.389 16.5418C693.086 15.9931 692.641 15.5357 692.1 15.2168C691.56 14.8979 690.944 14.7293 690.316 14.7284C689.379 14.7284 688.481 15.1001 687.818 15.7619C687.155 16.4236 686.783 17.3211 686.782 18.2572C686.782 19.1782 687.144 20.0623 687.79 20.7198C687.49 20.1973 687.332 19.6063 687.329 19.0043V19.0043Z"
                  fill="#241133"/>
            </g>
            <path d="M541.995 45.3726L539.714 45.8586L536.977 38.451L541.469 37.4935L541.995 45.3726Z" fill="#824E3E"/>
            <path d="M729.97 45.3726L727.688 45.8586L724.951 38.451L729.444 37.4935L729.97 45.3726Z" fill="#824E3E"/>
            <path d="M548.381 42.3533L546.392 43.5701L541.346 37.4898L545.265 35.0962L548.381 42.3533Z" fill="#824E3E"/>
            <path d="M736.356 42.3533L734.366 43.5701L729.32 37.4898L733.239 35.0962L736.356 42.3533Z" fill="#824E3E"/>
            <path d="M553.631 36.9404L552.243 38.8136L545.285 35.0672L548.019 31.3806L553.631 36.9404Z" fill="#824E3E"/>
            <path d="M741.606 36.9404L740.217 38.8136L733.26 35.0672L735.993 31.3806L741.606 36.9404Z" fill="#824E3E"/>
            <path d="M556.572 30.4921L555.838 32.7044L548.057 31.3172L549.5 26.9633L556.572 30.4921Z" fill="#824E3E"/>
            <path d="M744.546 30.4921L743.813 32.7044L736.031 31.3172L737.474 26.9633L744.546 30.4921Z" fill="#824E3E"/>
            <path d="M557.319 23.5323L557.312 25.8625L549.483 26.9632L549.502 22.3754L557.319 23.5323Z" fill="#824E3E"/>
            <path d="M745.294 23.5323L745.287 25.8625L737.458 26.9632L737.476 22.3754L745.294 23.5323Z" fill="#824E3E"/>
            <path d="M555.67 15.6841L556.488 17.8674L549.556 21.661L547.946 17.3651L555.67 15.6841Z" fill="#824E3E"/>
            <path d="M743.644 15.6841L744.463 17.8674L737.531 21.661L735.921 17.3651L743.644 15.6841Z" fill="#824E3E"/>
            <path d="M551.265 9.33179L552.846 11.0454L547.864 17.1746L544.749 13.8036L551.265 9.33179Z" fill="#824E3E"/>
            <path d="M739.24 9.33179L740.821 11.0454L735.838 17.1746L732.724 13.8036L739.24 9.33179Z" fill="#824E3E"/>
            <path d="M545.374 4.76758L547.419 5.89006L544.64 13.2832L540.614 11.0727L545.374 4.76758Z" fill="#824E3E"/>
            <path d="M733.348 4.76758L735.394 5.89006L732.615 13.2832L728.589 11.0727L733.348 4.76758Z" fill="#824E3E"/>
            <path d="M538.048 2.77283L540.357 3.09561L540.393 10.991L535.845 10.3564L538.048 2.77283Z" fill="#824E3E"/>
            <path d="M726.023 2.77283L728.332 3.09561L728.368 10.991L723.819 10.3564L726.023 2.77283Z" fill="#824E3E"/>
            <path d="M531.223 3.15549L533.53 2.81458L535.793 10.38L531.25 11.0527L531.223 3.15549Z" fill="#824E3E"/>
            <path d="M719.197 3.15549L721.504 2.81458L723.768 10.38L719.224 11.0527L719.197 3.15549Z" fill="#824E3E"/>
            <path d="M524.42 5.861L526.464 4.73853L531.223 11.0418L527.199 13.2541L524.42 5.861Z" fill="#824E3E"/>
            <path d="M712.395 5.861L714.438 4.73853L719.198 11.0418L715.174 13.2541L712.395 5.861Z" fill="#824E3E"/>
            <path d="M519.119 10.4905L520.702 8.77869L527.217 13.2487L524.104 16.6215L519.119 10.4905Z" fill="#824E3E"/>
            <path d="M707.094 10.4905L708.677 8.77869L715.191 13.2487L712.078 16.6215L707.094 10.4905Z" fill="#824E3E"/>
            <path d="M515.531 16.6016L516.484 14.4745L524.086 16.636L522.211 20.8249L515.531 16.6016Z" fill="#824E3E"/>
            <path d="M703.506 16.6016L704.459 14.4745L712.061 16.636L710.186 20.8249L703.506 16.6016Z" fill="#824E3E"/>
            <path d="M514.003 23.6049L514.203 21.2837L522.093 20.825L521.703 25.3965L514.003 23.6049Z" fill="#824E3E"/>
            <path d="M701.978 23.6049L702.177 21.2837L710.068 20.825L709.677 25.3965L701.978 23.6049Z" fill="#824E3E"/>
            <path d="M514.609 30.1602L514.202 27.8663L521.711 25.3965L522.508 29.9136L514.609 30.1602Z" fill="#824E3E"/>
            <path d="M702.583 30.1602L702.177 27.8663L709.686 25.3965L710.483 29.9136L702.583 30.1602Z" fill="#824E3E"/>
            <path d="M517.126 36.4799L516.05 34.4108L522.472 29.8066L524.588 33.8777L517.126 36.4799Z" fill="#824E3E"/>
            <path d="M705.101 36.4799L704.024 34.4108L710.446 29.8066L712.563 33.8777L705.101 36.4799Z" fill="#824E3E"/>
            <path d="M522.076 41.9744L520.319 40.4385L524.621 33.8142L528.077 36.8353L522.076 41.9744Z" fill="#824E3E"/>
            <path d="M710.051 41.9744L708.294 40.4385L712.596 33.8142L716.052 36.8353L710.051 41.9744Z" fill="#824E3E"/>
            <path d="M528.725 45.3943L526.523 44.6218L528.052 36.8733L532.385 38.3947L528.725 45.3943Z" fill="#824E3E"/>
            <path d="M716.7 45.3943L714.498 44.6218L716.026 36.8733L720.359 38.3947L716.7 45.3943Z" fill="#824E3E"/>
            <path d="M535.848 46.2557H533.514L532.385 38.4401H536.977L535.848 46.2557Z" fill="#824E3E"/>
            <path d="M723.823 46.2557H721.488L720.359 38.4401H724.952L723.823 46.2557Z" fill="#824E3E"/>
            <path
                d="M548.259 37.2097C555.116 30.3596 555.116 19.2534 548.259 12.4033C541.402 5.55323 530.285 5.55324 523.428 12.4033C516.571 19.2534 516.571 30.3596 523.428 37.2097C530.285 44.0598 541.402 44.0598 548.259 37.2097Z"
                fill="#D98268"/>
            <path
                d="M736.233 37.2097C743.09 30.3596 743.09 19.2534 736.233 12.4033C729.377 5.55323 718.259 5.55324 711.403 12.4033C704.546 19.2534 704.546 30.3596 711.403 37.2097C718.259 44.0598 729.377 44.0598 736.233 37.2097Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M541.892 8.3562C544.163 9.75142 546.038 11.7042 547.339 14.0284C548.639 16.3526 549.323 18.9709 549.324 21.6337C549.324 25.7765 547.676 29.7496 544.744 32.679C541.812 35.6084 537.835 37.2541 533.688 37.2541C530.022 37.2526 526.473 35.9635 523.662 33.6122C520.851 31.2609 518.957 27.9971 518.312 24.3918C518.312 24.5315 518.29 24.6675 518.29 24.8071C518.29 29.4592 520.14 33.9208 523.433 37.2103C526.725 40.4998 531.191 42.3479 535.848 42.3479C540.505 42.3479 544.971 40.4998 548.263 37.2103C551.556 33.9208 553.406 29.4592 553.406 24.8071C553.404 21.2172 552.298 17.7146 550.239 14.7726C548.18 11.8305 545.267 9.59082 541.892 8.3562V8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M729.867 8.3562C732.137 9.75142 734.012 11.7042 735.313 14.0284C736.614 16.3526 737.297 18.9709 737.298 21.6337C737.298 25.7765 735.651 29.7496 732.719 32.679C729.786 35.6084 725.809 37.2541 721.663 37.2541C717.996 37.2526 714.448 35.9635 711.637 33.6122C708.826 31.2609 706.932 27.9971 706.286 24.3918C706.286 24.5315 706.265 24.6675 706.265 24.8071C706.265 29.4592 708.114 33.9208 711.407 37.2103C714.7 40.4998 719.166 42.3479 723.823 42.3479C728.479 42.3479 732.945 40.4998 736.238 37.2103C739.531 33.9208 741.381 29.4592 741.381 24.8071C741.378 21.2172 740.273 17.7146 738.214 14.7726C736.155 11.8305 733.241 9.59082 729.867 8.3562V8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M518.538 25.3293C518.538 23.0259 518.992 20.7449 519.875 18.6168C520.757 16.4886 522.051 14.555 523.681 12.9261C525.311 11.2973 527.247 10.0053 529.377 9.12379C531.507 8.24229 533.791 7.78858 536.096 7.78858C539.448 7.79025 542.728 8.7505 545.55 10.5558C548.373 12.3612 550.619 14.9361 552.022 17.9761C550.678 14.7994 548.426 12.0891 545.548 10.1837C542.671 8.27827 539.295 7.26229 535.842 7.2627C533.536 7.2627 531.253 7.71651 529.122 8.59824C526.992 9.47998 525.056 10.7724 523.426 12.4015C521.795 14.0307 520.502 15.9649 519.62 18.0934C518.738 20.222 518.284 22.5033 518.284 24.8071C518.286 27.3478 518.844 29.8574 519.918 32.1603C519.004 29.9989 518.534 27.6758 518.538 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M706.513 25.3293C706.513 23.0259 706.967 20.7449 707.849 18.6168C708.732 16.4886 710.025 14.555 711.656 12.9261C713.286 11.2973 715.222 10.0053 717.352 9.12379C719.482 8.24229 721.765 7.78858 724.071 7.78858C727.422 7.79025 730.703 8.7505 733.525 10.5558C736.347 12.3612 738.593 14.9361 739.997 17.9761C738.652 14.7994 736.401 12.0891 733.523 10.1837C730.645 8.27827 727.269 7.26229 723.817 7.2627C721.511 7.2627 719.227 7.71651 717.097 8.59824C714.966 9.47998 713.031 10.7724 711.4 12.4015C709.77 14.0307 708.476 15.9649 707.594 18.0934C706.712 20.222 706.258 22.5033 706.259 24.8071C706.261 27.3478 706.818 29.8574 707.892 32.1603C706.978 29.9989 706.509 27.6758 706.513 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <path
                d="M544.819 28.5219C546.873 23.5699 544.519 17.8927 539.562 15.8415C534.605 13.7903 528.922 16.1419 526.869 21.0939C524.816 26.0459 527.17 31.7231 532.127 33.7743C537.083 35.8255 542.766 33.4739 544.819 28.5219Z"
                fill="#D98268"/>
            <path
                d="M732.794 28.5219C734.847 23.5699 732.493 17.8927 727.537 15.8415C722.58 13.7903 716.897 16.1419 714.844 21.0939C712.79 26.0459 715.144 31.7231 720.101 33.7743C725.058 35.8255 730.741 33.4739 732.794 28.5219Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M526.435 25.2151C526.435 22.6415 527.459 20.1734 529.281 18.3537C531.103 16.534 533.573 15.5118 536.149 15.5118C537.654 15.5113 539.137 15.8612 540.483 16.5335C541.828 17.2059 542.998 18.1822 543.9 19.3851C543.012 18.0657 541.812 16.9848 540.407 16.2379C539.002 15.4909 537.434 15.1008 535.843 15.1019C533.266 15.1019 530.795 16.1244 528.973 17.9445C527.151 19.7646 526.128 22.2331 526.128 24.8071C526.127 26.9124 526.814 28.9604 528.086 30.6389C527.005 29.0369 526.429 27.1475 526.435 25.2151V25.2151Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M714.409 25.2151C714.41 22.6415 715.433 20.1734 717.255 18.3537C719.077 16.534 721.548 15.5118 724.124 15.5118C725.628 15.5113 727.112 15.8612 728.457 16.5335C729.803 17.2059 730.973 18.1822 731.875 19.3851C730.986 18.0657 729.787 16.9848 728.382 16.2379C726.976 15.4909 725.409 15.1008 723.817 15.1019C721.241 15.1019 718.77 16.1244 716.948 17.9445C715.126 19.7646 714.103 22.2331 714.103 24.8071C714.101 26.9124 714.789 28.9604 716.061 30.6389C714.979 29.0369 714.404 27.1475 714.409 25.2151V25.2151Z"
                  fill="#331911"/>
            </g>
            <path
                d="M535.843 11.4843C534.092 11.4843 532.358 11.8289 530.74 12.4984C529.122 13.168 527.652 14.1494 526.414 15.3865C525.175 16.6237 524.193 18.0924 523.523 19.7088C522.853 21.3252 522.509 23.0576 522.509 24.8071C522.509 28.3401 523.914 31.7283 526.414 34.2265C528.915 36.7247 532.306 38.1281 535.843 38.1281C539.38 38.1281 542.771 36.7247 545.272 34.2266C547.773 31.7285 549.178 28.3402 549.179 24.8071C549.179 21.2737 547.774 17.8849 545.273 15.3864C542.772 12.8879 539.38 11.4843 535.843 11.4843ZM535.843 34.5141C533.267 34.5136 530.796 33.491 528.974 31.671C527.152 29.851 526.129 27.3827 526.128 24.8089C526.128 22.2349 527.152 19.7664 528.974 17.9463C530.795 16.1262 533.266 15.1037 535.843 15.1037C538.419 15.1037 540.89 16.1262 542.712 17.9463C544.534 19.7664 545.558 22.2349 545.558 24.8089C545.557 27.3826 544.533 29.8507 542.712 31.6703C540.89 33.49 538.419 34.5123 535.843 34.5123V34.5141Z"
                fill="#824E3E"/>
            <path
                d="M723.818 11.4843C722.066 11.4843 720.332 11.8289 718.714 12.4984C717.096 13.168 715.626 14.1494 714.388 15.3865C713.15 16.6237 712.168 18.0924 711.498 19.7088C710.828 21.3252 710.483 23.0576 710.483 24.8071C710.483 28.3401 711.888 31.7283 714.389 34.2265C716.89 36.7247 720.281 38.1281 723.818 38.1281C727.354 38.1281 730.746 36.7247 733.247 34.2266C735.748 31.7285 737.153 28.3402 737.153 24.8071C737.153 21.2737 735.748 17.8849 733.247 15.3864C730.746 12.8879 727.354 11.4843 723.818 11.4843ZM723.818 34.5141C721.241 34.5136 718.77 33.491 716.949 31.671C715.127 29.851 714.103 27.3827 714.103 24.8089C714.103 22.2349 715.126 19.7664 716.948 17.9463C718.77 16.1262 721.241 15.1037 723.818 15.1037C726.394 15.1037 728.865 16.1262 730.687 17.9463C732.509 19.7664 733.532 22.2349 733.532 24.8089C733.532 27.3826 732.508 29.8507 730.686 31.6703C728.864 33.49 726.394 34.5123 723.818 34.5123V34.5141Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M536.149 34.9203C538.726 34.9203 541.197 33.8978 543.019 32.0777C544.84 30.2577 545.864 27.7891 545.864 25.2151C545.864 23.1097 545.174 21.0621 543.9 19.3851C544.984 20.9857 545.561 22.8747 545.557 24.8071C545.557 27.3811 544.534 29.8496 542.712 31.6697C540.89 33.4898 538.419 34.5123 535.842 34.5123C534.338 34.5123 532.855 34.1623 531.509 33.49C530.164 32.8176 528.994 31.8415 528.092 30.6389C528.98 31.958 530.18 33.0384 531.585 33.785C532.99 34.5316 534.558 34.9215 536.149 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M522.823 25.2151C522.823 23.4656 523.167 21.7333 523.838 20.1169C524.508 18.5006 525.49 17.0321 526.729 15.7951C527.967 14.5581 529.437 13.5769 531.055 12.9076C532.673 12.2382 534.407 11.8938 536.158 11.8941C538.255 11.8943 540.322 12.3897 542.191 13.3398C544.06 14.29 545.677 15.668 546.911 17.3614C545.692 15.5509 544.045 14.068 542.116 13.0431C540.188 12.0182 538.036 11.4829 535.852 11.4843C534.1 11.4843 532.366 11.8289 530.749 12.4984C529.131 13.168 527.661 14.1494 526.422 15.3865C525.184 16.6237 524.202 18.0924 523.532 19.7088C522.862 21.3252 522.517 23.0576 522.518 24.8071C522.518 27.633 523.422 30.3849 525.099 32.6608C523.612 30.4622 522.819 27.8686 522.823 25.2151V25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M724.124 34.9203C726.7 34.9203 729.171 33.8978 730.993 32.0777C732.815 30.2577 733.839 27.7891 733.839 25.2151C733.839 23.1097 733.149 21.0621 731.875 19.3851C732.958 20.9857 733.536 22.8747 733.532 24.8071C733.532 27.3811 732.508 29.8496 730.686 31.6697C728.865 33.4898 726.394 34.5123 723.817 34.5123C722.313 34.5123 720.829 34.1623 719.484 33.49C718.139 32.8176 716.969 31.8415 716.066 30.6389C716.955 31.958 718.155 33.0384 719.56 33.785C720.965 34.5316 722.532 34.9215 724.124 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M710.797 25.2151C710.797 23.4656 711.142 21.7333 711.812 20.1169C712.483 18.5006 713.465 17.0321 714.703 15.7951C715.942 14.5581 717.412 13.5769 719.03 12.9076C720.648 12.2382 722.382 11.8938 724.133 11.8941C726.23 11.8943 728.297 12.3897 730.166 13.3398C732.034 14.29 733.652 15.668 734.886 17.3614C733.666 15.5509 732.019 14.068 730.091 13.0431C728.162 12.0182 726.011 11.4829 723.826 11.4843C722.075 11.4843 720.341 11.8289 718.723 12.4984C717.105 13.168 715.635 14.1494 714.397 15.3865C713.159 16.6237 712.177 18.0924 711.507 19.7088C710.837 21.3252 710.492 23.0576 710.492 24.8071C710.492 27.633 711.397 30.3849 713.073 32.6608C711.586 30.4622 710.793 27.8686 710.797 25.2151V25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M548.982 20.1721C551.656 29.1864 544.871 38.1245 535.57 38.1245C532.674 38.1224 529.85 37.2224 527.487 35.5487C525.125 33.875 523.341 31.51 522.381 28.7802C523.239 31.6685 525.008 34.2026 527.424 36.0051C529.841 37.8075 532.775 38.7818 535.791 38.7827C545.381 38.7864 552.204 29.3188 548.982 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M736.957 20.1721C739.63 29.1864 732.845 38.1245 723.544 38.1245C720.648 38.1224 717.824 37.2224 715.462 35.5487C713.099 33.875 711.315 31.51 710.355 28.7802C711.213 31.6685 712.982 34.2026 715.399 36.0051C717.815 37.8075 720.75 38.7818 723.766 38.7827C733.355 38.7864 740.178 29.3188 736.957 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M518.448 22.5404C520.79 24.2552 523.618 25.1794 526.522 25.1789C528.326 25.1791 530.112 24.8244 531.778 24.135C533.445 23.4456 534.959 22.435 536.234 21.161C537.51 19.887 538.521 18.3744 539.212 16.7097C539.902 15.045 540.257 13.2608 540.257 11.4589C540.254 10.1908 540.075 8.92925 539.724 7.71063C538.451 7.41845 537.149 7.26944 535.843 7.26636C531.581 7.26795 527.466 8.81883 524.265 11.6295C521.064 14.4401 518.997 18.3184 518.448 22.5404V22.5404Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M706.423 22.5404C708.765 24.2552 711.593 25.1794 714.497 25.1789C716.3 25.1791 718.086 24.8244 719.753 24.135C721.419 23.4456 722.933 22.435 724.209 21.161C725.484 19.887 726.496 18.3744 727.186 16.7097C727.877 15.045 728.232 13.2608 728.232 11.4589C728.229 10.1908 728.049 8.92925 727.698 7.71063C726.425 7.41845 725.124 7.26944 723.817 7.26636C719.556 7.26795 715.441 8.81883 712.24 11.6295C709.039 14.4401 706.971 18.3184 706.423 22.5404V22.5404Z"
                  fill="#26170D"/>
            </g>
            <path
                d="M530.201 24.8072C530.2 24.0665 530.346 23.3329 530.629 22.6484C530.912 21.9639 531.328 21.3418 531.851 20.8178C532.375 20.2937 532.998 19.8779 533.682 19.5941C534.367 19.3104 535.101 19.1642 535.843 19.1639C537.339 19.1639 538.775 19.7579 539.833 20.8152C540.891 21.8725 541.486 23.3065 541.486 24.8017C541.486 26.2966 540.891 27.7302 539.832 28.7871C538.774 29.844 537.339 30.4377 535.843 30.4377C534.348 30.4372 532.914 29.844 531.856 28.7883C530.798 27.7327 530.203 26.3008 530.201 24.8072V24.8072Z"
                fill="#4E415C"/>
            <path
                d="M718.176 24.8072C718.175 24.0665 718.32 23.3329 718.604 22.6484C718.887 21.9639 719.302 21.3418 719.826 20.8178C720.35 20.2937 720.972 19.8779 721.657 19.5941C722.342 19.3104 723.076 19.1642 723.817 19.1639C725.314 19.1639 726.749 19.7579 727.808 20.8152C728.866 21.8725 729.461 23.3065 729.461 24.8017C729.46 26.2966 728.865 27.7302 727.807 28.7871C726.749 29.844 725.314 30.4377 723.817 30.4377C722.322 30.4372 720.888 29.844 719.831 28.7883C718.773 27.7327 718.178 26.3008 718.176 24.8072V24.8072Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M540.148 21.1677C540.574 21.9748 540.796 22.8737 540.796 23.7862C540.795 25.2811 540.2 26.7147 539.142 27.7716C538.084 28.8285 536.649 29.4222 535.152 29.4222C534.333 29.4228 533.523 29.2447 532.78 28.9001C532.036 28.5556 531.377 28.053 530.849 27.4275C531.326 28.3377 532.044 29.1 532.924 29.6319C533.804 30.1638 534.813 30.4449 535.842 30.4449C537.339 30.4449 538.774 29.8512 539.832 28.7943C540.89 27.7374 541.485 26.3039 541.485 24.809C541.487 23.4748 541.013 22.1837 540.148 21.1677V21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M728.122 21.1677C728.548 21.9748 728.771 22.8737 728.77 23.7862C728.77 25.2811 728.175 26.7147 727.117 27.7716C726.058 28.8285 724.623 29.4222 723.127 29.4222C722.307 29.4228 721.498 29.2447 720.754 28.9001C720.011 28.5556 719.352 28.053 718.823 27.4275C719.301 28.3377 720.019 29.1 720.899 29.6319C721.779 30.1638 722.788 30.4449 723.817 30.4449C725.313 30.4449 726.748 29.8512 727.807 28.7943C728.865 27.7374 729.46 26.3039 729.46 24.809C729.462 23.4748 728.988 22.1837 728.122 21.1677V21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M530.904 25.8934C530.903 25.1528 531.048 24.4193 531.332 23.7349C531.615 23.0505 532.03 22.4286 532.554 21.9046C533.078 21.3807 533.7 20.9651 534.385 20.6815C535.07 20.3979 535.804 20.252 536.545 20.252C537.375 20.251 538.196 20.4335 538.947 20.7865C539.698 21.1396 540.362 21.6543 540.891 22.2938C540.425 21.3536 539.706 20.5622 538.814 20.0091C537.922 19.456 536.893 19.1632 535.843 19.1639C534.346 19.1644 532.911 19.7586 531.853 20.8159C530.795 21.8731 530.201 23.3068 530.201 24.8017C530.2 26.1142 530.658 27.3857 531.497 28.3958C531.108 27.619 530.904 26.7622 530.904 25.8934V25.8934Z"
                  fill="#241133"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M718.878 25.8934C718.878 25.1528 719.023 24.4193 719.306 23.7349C719.589 23.0505 720.005 22.4286 720.529 21.9046C721.053 21.3807 721.675 20.9651 722.36 20.6815C723.044 20.3979 723.778 20.252 724.52 20.252C725.35 20.251 726.17 20.4335 726.921 20.7865C727.673 21.1396 728.337 21.6543 728.865 22.2938C728.4 21.3536 727.68 20.5622 726.788 20.0091C725.896 19.456 724.867 19.1632 723.817 19.1639C722.321 19.1644 720.886 19.7586 719.828 20.8159C718.77 21.8731 718.176 23.3068 718.176 24.8017C718.175 26.1142 718.633 27.3857 719.472 28.3958C719.082 27.619 718.879 26.7622 718.878 25.8934V25.8934Z"
                  fill="#241133"/>
            </g>
            <path d="M404.01 45.3726L401.728 45.8586L398.991 38.451L403.482 37.4935L404.01 45.3726Z" fill="#824E3E"/>
            <path d="M591.985 45.3726L589.703 45.8586L586.966 38.451L591.457 37.4935L591.985 45.3726Z" fill="#824E3E"/>
            <path d="M410.395 42.3533L408.404 43.5701L403.359 37.4898L407.276 35.0962L410.395 42.3533Z" fill="#824E3E"/>
            <path d="M598.37 42.3533L596.378 43.5701L591.334 37.4898L595.251 35.0962L598.37 42.3533Z" fill="#824E3E"/>
            <path d="M415.645 36.9404L414.256 38.8136L407.297 35.0672L410.032 31.3806L415.645 36.9404Z" fill="#824E3E"/>
            <path d="M603.619 36.9404L602.231 38.8136L595.271 35.0672L598.007 31.3806L603.619 36.9404Z" fill="#824E3E"/>
            <path d="M418.585 30.4921L417.85 32.7044L410.068 31.3172L411.513 26.9633L418.585 30.4921Z" fill="#824E3E"/>
            <path d="M606.56 30.4921L605.825 32.7044L598.043 31.3172L599.488 26.9633L606.56 30.4921Z" fill="#824E3E"/>
            <path d="M419.333 23.5323L419.324 25.8625L411.497 26.9632L411.513 22.3754L419.333 23.5323Z" fill="#824E3E"/>
            <path d="M607.308 23.5323L607.299 25.8625L599.472 26.9632L599.488 22.3754L607.308 23.5323Z" fill="#824E3E"/>
            <path d="M417.683 15.6841L418.5 17.8674L411.568 21.661L409.96 17.3651L417.683 15.6841Z" fill="#824E3E"/>
            <path d="M605.658 15.6841L606.475 17.8674L599.543 21.661L597.935 17.3651L605.658 15.6841Z" fill="#824E3E"/>
            <path d="M413.278 9.33179L414.861 11.0454L409.877 17.1746L406.762 13.8036L413.278 9.33179Z" fill="#824E3E"/>
            <path d="M601.253 9.33179L602.836 11.0454L597.851 17.1746L594.736 13.8036L601.253 9.33179Z" fill="#824E3E"/>
            <path d="M407.387 4.76758L409.431 5.89006L406.652 13.2832L402.628 11.0727L407.387 4.76758Z" fill="#824E3E"/>
            <path d="M595.362 4.76758L597.406 5.89006L594.627 13.2832L590.603 11.0727L595.362 4.76758Z" fill="#824E3E"/>
            <path d="M400.06 2.77283L402.371 3.09561L402.405 10.991L397.856 10.3564L400.06 2.77283Z" fill="#824E3E"/>
            <path d="M588.035 2.77283L590.345 3.09561L590.38 10.991L585.831 10.3564L588.035 2.77283Z" fill="#824E3E"/>
            <path d="M393.234 3.15549L395.543 2.81458L397.805 10.38L393.263 11.0527L393.234 3.15549Z" fill="#824E3E"/>
            <path d="M581.209 3.15549L583.518 2.81458L585.78 10.38L581.238 11.0527L581.209 3.15549Z" fill="#824E3E"/>
            <path d="M386.432 5.861L388.476 4.73853L393.235 11.0418L389.211 13.2541L386.432 5.861Z" fill="#824E3E"/>
            <path d="M574.406 5.861L576.45 4.73853L581.209 11.0418L577.185 13.2541L574.406 5.861Z" fill="#824E3E"/>
            <path d="M381.133 10.4905L382.714 8.77869L389.23 13.2487L386.115 16.6215L381.133 10.4905Z" fill="#824E3E"/>
            <path d="M569.107 10.4905L570.688 8.77869L577.205 13.2487L574.09 16.6215L569.107 10.4905Z" fill="#824E3E"/>
            <path d="M377.545 16.6016L378.498 14.4745L386.1 16.636L384.225 20.8249L377.545 16.6016Z" fill="#824E3E"/>
            <path d="M565.52 16.6016L566.472 14.4745L574.074 16.636L572.199 20.8249L565.52 16.6016Z" fill="#824E3E"/>
            <path d="M376.017 23.6049L376.214 21.2837L384.107 20.825L383.715 25.3965L376.017 23.6049Z" fill="#824E3E"/>
            <path d="M563.991 23.6049L564.189 21.2837L572.081 20.825L571.689 25.3965L563.991 23.6049Z" fill="#824E3E"/>
            <path d="M376.622 30.1602L376.216 27.8663L383.723 25.3965L384.522 29.9136L376.622 30.1602Z" fill="#824E3E"/>
            <path d="M564.597 30.1602L564.19 27.8663L571.698 25.3965L572.497 29.9136L564.597 30.1602Z" fill="#824E3E"/>
            <path d="M379.138 36.4799L378.063 34.4108L384.484 29.8066L386.602 33.8777L379.138 36.4799Z" fill="#824E3E"/>
            <path d="M567.113 36.4799L566.038 34.4108L572.458 29.8066L574.577 33.8777L567.113 36.4799Z" fill="#824E3E"/>
            <path d="M384.088 41.9744L382.333 40.4385L386.635 33.8142L390.091 36.8353L384.088 41.9744Z" fill="#824E3E"/>
            <path d="M572.063 41.9744L570.308 40.4385L574.61 33.8142L578.066 36.8353L572.063 41.9744Z" fill="#824E3E"/>
            <path d="M390.737 45.3943L388.537 44.6218L390.065 36.8733L394.398 38.3947L390.737 45.3943Z" fill="#824E3E"/>
            <path d="M578.712 45.3943L576.512 44.6218L578.04 36.8733L582.373 38.3947L578.712 45.3943Z" fill="#824E3E"/>
            <path d="M397.86 46.2557H395.527L394.398 38.4401H398.991L397.86 46.2557Z" fill="#824E3E"/>
            <path d="M585.835 46.2557H583.502L582.373 38.4401H586.965L585.835 46.2557Z" fill="#824E3E"/>
            <path
                d="M410.271 37.2107C417.128 30.3606 417.128 19.2544 410.271 12.4043C403.415 5.55421 392.298 5.55421 385.441 12.4043C378.584 19.2544 378.584 30.3606 385.441 37.2107C392.298 44.0608 403.415 44.0608 410.271 37.2107Z"
                fill="#D98268"/>
            <path
                d="M598.246 37.2107C605.103 30.3606 605.103 19.2544 598.246 12.4043C591.389 5.55421 580.272 5.55421 573.415 12.4043C566.559 19.2544 566.559 30.3606 573.415 37.2107C580.272 44.0608 591.389 44.0608 598.246 37.2107Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M403.906 8.3562C406.175 9.75208 408.049 11.7051 409.349 14.0293C410.649 16.3535 411.331 18.9714 411.332 21.6337C411.332 25.7765 409.684 29.7496 406.752 32.679C403.82 35.6084 399.843 37.2541 395.696 37.2541C392.03 37.2524 388.481 35.9632 385.67 33.6119C382.858 31.2607 380.964 27.9971 380.318 24.3918C380.318 24.5315 380.298 24.6675 380.298 24.8071C380.298 27.1106 380.752 29.3915 381.634 31.5197C382.517 33.6478 383.81 35.5815 385.44 37.2103C387.071 38.8391 389.006 40.1312 391.137 41.0127C393.267 41.8942 395.55 42.3479 397.856 42.3479C400.162 42.3479 402.445 41.8942 404.575 41.0127C406.705 40.1312 408.641 38.8391 410.271 37.2103C411.902 35.5815 413.195 33.6478 414.077 31.5197C414.96 29.3915 415.414 27.1106 415.414 24.8071C415.412 21.218 414.307 17.7159 412.249 14.774C410.191 11.832 407.279 9.59194 403.906 8.3562V8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M591.88 8.3562C594.15 9.75208 596.023 11.7051 597.323 14.0293C598.623 16.3535 599.306 18.9714 599.306 21.6337C599.306 25.7765 597.659 29.7496 594.727 32.679C591.794 35.6084 587.817 37.2541 583.67 37.2541C580.004 37.2524 576.455 35.9632 573.644 33.6119C570.833 31.2607 568.939 27.9971 568.292 24.3918C568.292 24.5315 568.272 24.6675 568.272 24.8071C568.272 27.1106 568.727 29.3915 569.609 31.5197C570.491 33.6478 571.785 35.5815 573.415 37.2103C575.045 38.8391 576.981 40.1312 579.111 41.0127C581.242 41.8942 583.525 42.3479 585.83 42.3479C588.136 42.3479 590.419 41.8942 592.55 41.0127C594.68 40.1312 596.615 38.8391 598.246 37.2103C599.876 35.5815 601.17 33.6478 602.052 31.5197C602.934 29.3915 603.388 27.1106 603.388 24.8071C603.386 21.218 602.282 17.7159 600.224 14.774C598.166 11.832 595.253 9.59194 591.88 8.3562V8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M380.55 25.3293C380.55 23.0259 381.004 20.7449 381.887 18.6168C382.769 16.4886 384.062 14.555 385.693 12.9261C387.323 11.2973 389.259 10.0053 391.389 9.12379C393.519 8.24229 395.802 7.78858 398.108 7.78858C401.459 7.79003 404.74 8.75019 407.563 10.5555C410.385 12.3609 412.631 14.9359 414.034 17.9761C412.69 14.7994 410.439 12.089 407.561 10.1836C404.684 8.27812 401.308 7.26217 397.856 7.2627C395.55 7.26246 393.267 7.716 391.136 8.59742C389.006 9.47885 387.07 10.7709 385.44 12.3997C383.809 14.0286 382.516 15.9624 381.634 18.0906C380.752 20.2189 380.298 22.4999 380.298 24.8035C380.301 27.3441 380.858 29.8535 381.931 32.1567C381.018 29.9963 380.548 27.6746 380.55 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M568.525 25.3293C568.525 23.0259 568.979 20.7449 569.861 18.6168C570.744 16.4886 572.037 14.555 573.667 12.9261C575.298 11.2973 577.233 10.0053 579.364 9.12379C581.494 8.24229 583.777 7.78858 586.083 7.78858C589.434 7.79003 592.715 8.75019 595.537 10.5555C598.359 12.3609 600.605 14.9359 602.009 17.9761C600.665 14.7994 598.413 12.089 595.536 10.1836C592.658 8.27812 589.283 7.26217 585.83 7.2627C583.525 7.26246 581.241 7.716 579.111 8.59742C576.981 9.47885 575.045 10.7709 573.415 12.3997C571.784 14.0286 570.491 15.9624 569.608 18.0906C568.726 20.2189 568.272 22.4999 568.272 24.8035C568.275 27.3441 568.833 29.8535 569.906 32.1567C568.993 29.9963 568.523 27.6746 568.525 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <path
                d="M407.294 27.0906C408.556 21.8809 405.351 16.6358 400.136 15.3755C394.921 14.1151 389.671 17.3167 388.409 22.5264C387.148 27.7361 390.352 32.9812 395.567 34.2416C400.782 35.5019 406.032 32.3003 407.294 27.0906Z"
                fill="#D98268"/>
            <path
                d="M595.269 27.0906C596.53 21.8809 593.325 16.6358 588.111 15.3755C582.896 14.1151 577.646 17.3167 576.384 22.5264C575.122 27.7361 578.327 32.9812 583.542 34.2416C588.757 35.5019 594.007 32.3003 595.269 27.0906Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M388.447 25.2151C388.447 22.6415 389.471 20.1734 391.293 18.3537C393.114 16.534 395.585 15.5118 398.161 15.5118C399.666 15.5115 401.15 15.8614 402.495 16.5337C403.841 17.206 405.011 18.1823 405.914 19.3851C405.025 18.0659 403.826 16.9852 402.42 16.2383C401.015 15.4914 399.448 15.1011 397.856 15.1019C395.28 15.1019 392.809 16.1244 390.987 17.9445C389.165 19.7646 388.142 22.2331 388.142 24.8071C388.141 26.9129 388.83 28.961 390.104 30.6389C389.021 29.0372 388.444 27.1479 388.447 25.2151Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M576.421 25.2151C576.422 22.6415 577.445 20.1734 579.267 18.3537C581.089 16.534 583.56 15.5118 586.136 15.5118C587.64 15.5115 589.124 15.8614 590.47 16.5337C591.816 17.206 592.986 18.1823 593.888 19.3851C593 18.0659 591.8 16.9852 590.395 16.2383C588.99 15.4914 587.423 15.1011 585.831 15.1019C583.254 15.1019 580.783 16.1244 578.962 17.9445C577.14 19.7646 576.116 22.2331 576.116 24.8071C576.116 26.9129 576.805 28.961 578.078 30.6389C576.996 29.0372 576.418 27.1479 576.421 25.2151Z"
                  fill="#331911"/>
            </g>
            <path
                d="M397.856 11.4843C394.32 11.4843 390.927 12.8879 388.427 15.3864C385.926 17.8849 384.521 21.2737 384.521 24.8071C384.521 28.3402 385.926 31.7285 388.427 34.2266C390.928 36.7247 394.32 38.1281 397.856 38.1281C401.393 38.1281 404.784 36.7247 407.285 34.2265C409.786 31.7283 411.191 28.3401 411.191 24.8071C411.191 23.0576 410.846 21.3252 410.176 19.7088C409.506 18.0924 408.524 16.6237 407.286 15.3865C406.048 14.1494 404.577 13.168 402.96 12.4984C401.342 11.8289 399.608 11.4843 397.856 11.4843V11.4843ZM397.856 34.5141C395.28 34.5141 392.809 33.4916 390.987 31.6715C389.165 29.8514 388.142 27.3829 388.142 24.8089C388.142 22.2349 389.165 19.7664 390.987 17.9463C392.809 16.1262 395.28 15.1037 397.856 15.1037C400.433 15.1037 402.904 16.1262 404.726 17.9463C406.548 19.7664 407.571 22.2349 407.571 24.8089C407.571 27.3826 406.547 29.8507 404.725 31.6703C402.903 33.49 400.433 34.5123 397.856 34.5123V34.5141Z"
                fill="#824E3E"/>
            <path
                d="M585.831 11.4843C582.294 11.4843 578.902 12.8879 576.401 15.3864C573.9 17.8849 572.495 21.2737 572.495 24.8071C572.496 28.3402 573.901 31.7285 576.402 34.2266C578.903 36.7247 582.294 38.1281 585.831 38.1281C589.368 38.1281 592.759 36.7247 595.26 34.2265C597.76 31.7283 599.165 28.3401 599.165 24.8071C599.165 23.0576 598.821 21.3252 598.151 19.7088C597.481 18.0924 596.499 16.6237 595.26 15.3865C594.022 14.1494 592.552 13.168 590.934 12.4984C589.316 11.8289 587.582 11.4843 585.831 11.4843V11.4843ZM585.831 34.5141C583.255 34.5141 580.784 33.4916 578.962 31.6715C577.14 29.8514 576.116 27.3829 576.116 24.8089C576.116 22.2349 577.14 19.7664 578.962 17.9463C580.784 16.1262 583.255 15.1037 585.831 15.1037C588.408 15.1037 590.879 16.1262 592.7 17.9463C594.522 19.7664 595.546 22.2349 595.546 24.8089C595.545 27.3826 594.522 29.8507 592.7 31.6703C590.878 33.49 588.407 34.5123 585.831 34.5123V34.5141Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M398.161 34.9203C400.737 34.9203 403.208 33.8978 405.03 32.0777C406.852 30.2577 407.876 27.7891 407.876 25.2151C407.876 23.1099 407.187 21.0623 405.914 19.3851C406.997 20.9861 407.574 22.8749 407.571 24.8071C407.571 27.3811 406.547 29.8496 404.725 31.6697C402.904 33.4898 400.433 34.5123 397.856 34.5123C396.352 34.5122 394.868 34.1621 393.522 33.4898C392.177 32.8175 391.006 31.8414 390.104 30.6389C390.992 31.9577 392.192 33.038 393.597 33.7846C395.002 34.5311 396.57 34.9212 398.161 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M384.827 25.2151C384.827 21.6822 386.232 18.2939 388.733 15.7957C391.233 13.2975 394.625 11.8941 398.161 11.8941C400.259 11.894 402.326 12.3893 404.195 13.3394C406.064 14.2896 407.682 15.6677 408.916 17.3614C407.696 15.5511 406.049 14.0681 404.121 13.0433C402.192 12.0184 400.041 11.483 397.856 11.4843C394.32 11.4843 390.927 12.8879 388.427 15.3864C385.926 17.8849 384.521 21.2737 384.521 24.8071C384.521 27.633 385.425 30.3849 387.102 32.6608C385.615 30.462 384.823 27.8684 384.827 25.2151V25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M586.136 34.9203C588.712 34.9203 591.183 33.8978 593.005 32.0777C594.827 30.2577 595.85 27.7891 595.85 25.2151C595.851 23.1099 595.162 21.0623 593.888 19.3851C594.971 20.9861 595.548 22.8749 595.545 24.8071C595.545 27.3811 594.522 29.8496 592.7 31.6697C590.878 33.4898 588.407 34.5123 585.831 34.5123C584.326 34.5122 582.842 34.1621 581.497 33.4898C580.151 32.8175 578.981 31.8414 578.078 30.6389C578.967 31.9577 580.167 33.038 581.572 33.7846C582.977 34.5311 584.544 34.9212 586.136 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M572.802 25.2151C572.802 21.6822 574.207 18.2939 576.707 15.7957C579.208 13.2975 582.6 11.8941 586.136 11.8941C588.233 11.894 590.301 12.3893 592.17 13.3394C594.039 14.2896 595.656 15.6677 596.891 17.3614C595.671 15.5511 594.024 14.0681 592.095 13.0433C590.167 12.0184 588.016 11.483 585.831 11.4843C582.294 11.4843 578.902 12.8879 576.401 15.3864C573.9 17.8849 572.495 21.2737 572.495 24.8071C572.495 27.633 573.4 30.3849 575.076 32.6608C573.59 30.462 572.798 27.8684 572.802 25.2151V25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M410.995 20.1721C413.67 29.1864 406.883 38.1245 397.584 38.1245C394.688 38.1227 391.864 37.2228 389.502 35.549C387.139 33.8753 385.355 31.5102 384.396 28.7802C385.253 31.6685 387.022 34.2026 389.439 36.0051C391.855 37.8075 394.79 38.7818 397.806 38.7827C407.394 38.7864 414.217 29.3188 410.995 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M598.969 20.1721C601.645 29.1864 594.858 38.1245 585.559 38.1245C582.663 38.1227 579.839 37.2228 577.476 35.549C575.114 33.8753 573.33 31.5102 572.37 28.7802C573.228 31.6685 574.997 34.2026 577.413 36.0051C579.83 37.8075 582.765 38.7818 585.78 38.7827C595.368 38.7864 602.191 29.3188 598.969 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M380.46 22.5404C382.803 24.2551 385.631 25.1793 388.536 25.1789C390.339 25.1791 392.125 24.8244 393.791 24.135C395.458 23.4456 396.972 22.435 398.247 21.1609C399.522 19.8868 400.534 18.3743 401.224 16.7096C401.914 15.0449 402.269 13.2607 402.269 11.4589C402.267 10.1909 402.088 8.92933 401.737 7.71063C400.464 7.41855 399.163 7.26954 397.856 7.26636C393.595 7.2679 389.479 8.81871 386.278 11.6293C383.077 14.4399 381.009 18.3183 380.46 22.5404Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M568.435 22.5404C570.777 24.2551 573.606 25.1793 576.51 25.1789C578.314 25.1791 580.1 24.8244 581.766 24.135C583.432 23.4456 584.946 22.435 586.222 21.1609C587.497 19.8868 588.509 18.3743 589.199 16.7096C589.889 15.0449 590.244 13.2607 590.244 11.4589C590.241 10.1909 590.062 8.92933 589.712 7.71063C588.439 7.41855 587.137 7.26954 585.831 7.26636C581.569 7.2679 577.454 8.81871 574.253 11.6293C571.051 14.4399 568.983 18.3183 568.435 22.5404Z"
                  fill="#26170D"/>
            </g>
            <path
                d="M392.213 24.8071C392.213 23.3119 392.807 21.8779 393.866 20.8206C394.924 19.7633 396.36 19.1693 397.856 19.1693C399.353 19.1698 400.788 19.764 401.845 20.8212C402.903 21.8784 403.498 23.3122 403.498 24.8071C403.497 26.3017 402.903 27.7349 401.845 28.7918C400.787 29.8486 399.352 30.4426 397.856 30.443C396.36 30.443 394.925 29.8493 393.866 28.7924C392.808 27.7355 392.213 26.302 392.213 24.8071Z"
                fill="#4E415C"/>
            <path
                d="M580.188 24.8071C580.188 23.3119 580.782 21.8779 581.84 20.8206C582.899 19.7633 584.334 19.1693 585.831 19.1693C587.327 19.1698 588.762 19.764 589.82 20.8212C590.878 21.8784 591.472 23.3122 591.472 24.8071C591.472 26.3017 590.877 27.7349 589.819 28.7918C588.762 29.8486 587.327 30.4426 585.831 30.443C584.334 30.443 582.899 29.8493 581.841 28.7924C580.783 27.7355 580.188 26.302 580.188 24.8071Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M402.16 21.1677C402.587 21.9748 402.809 22.8737 402.808 23.7862C402.808 25.281 402.214 26.7145 401.156 27.7715C400.098 28.8284 398.663 29.4222 397.167 29.4222C396.347 29.4231 395.537 29.2451 394.793 28.9005C394.05 28.556 393.39 28.0532 392.861 27.4275C393.339 28.3377 394.057 29.1 394.938 29.6319C395.818 30.1637 396.828 30.4449 397.857 30.4449C399.353 30.4444 400.787 29.8505 401.845 28.7937C402.903 27.7368 403.498 26.3036 403.498 24.809C403.5 23.4748 403.026 22.1837 402.16 21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M590.135 21.1677C590.561 21.9748 590.784 22.8737 590.783 23.7862C590.783 25.281 590.189 26.7145 589.131 27.7715C588.073 28.8284 586.638 29.4222 585.141 29.4222C584.322 29.4231 583.512 29.2451 582.768 28.9005C582.024 28.556 581.365 28.0532 580.836 27.4275C581.314 28.3377 582.032 29.1 582.913 29.6319C583.793 30.1637 584.802 30.4449 585.831 30.4449C587.327 30.4444 588.762 29.8505 589.82 28.7937C590.878 27.7368 591.472 26.3036 591.473 24.809C591.475 23.4748 591 22.1837 590.135 21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M392.915 25.8933C392.916 24.3984 393.511 22.9649 394.569 21.908C395.627 20.8511 397.062 20.2573 398.559 20.2573C399.389 20.2563 400.209 20.4389 400.96 20.7919C401.712 21.1449 402.375 21.6597 402.904 22.2992C402.438 21.3598 401.718 20.569 400.826 20.0161C399.935 19.4631 398.906 19.1699 397.856 19.1693C396.36 19.1693 394.924 19.7633 393.866 20.8206C392.807 21.8779 392.213 23.3119 392.213 24.8071C392.212 26.1198 392.671 27.3913 393.511 28.4012C393.12 27.6229 392.916 26.7641 392.915 25.8933V25.8933Z"
                  fill="#241133"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M580.89 25.8933C580.89 24.3984 581.485 22.9649 582.544 21.908C583.602 20.8511 585.037 20.2573 586.533 20.2573C587.364 20.2563 588.184 20.4389 588.935 20.7919C589.686 21.1449 590.35 21.6597 590.879 22.2992C590.412 21.3598 589.693 20.569 588.801 20.0161C587.909 19.4631 586.88 19.1699 585.831 19.1693C584.334 19.1693 582.899 19.7633 581.84 20.8206C580.782 21.8779 580.188 23.3119 580.188 24.8071C580.187 26.1198 580.646 27.3913 581.485 28.4012C581.094 27.6229 580.89 26.7641 580.89 25.8933V25.8933Z"
                  fill="#241133"/>
            </g>
            <path d="M431.543 36.6049H429.211L428.08 28.7893H432.672L431.543 36.6049Z" fill="#824E3E"/>
            <path d="M619.518 36.6049H617.186L616.055 28.7893H620.647L619.518 36.6049Z" fill="#824E3E"/>
            <path d="M423.224 35.2249L421.295 33.9138L424.758 26.8163L428.557 29.3949L423.224 35.2249Z" fill="#824E3E"/>
            <path d="M611.199 35.2249L609.27 33.9138L612.733 26.8163L616.532 29.3949L611.199 35.2249Z" fill="#824E3E"/>
            <path d="M418.012 30.0223L416.976 27.9351L423.479 23.4489L425.521 27.558L418.012 30.0223Z" fill="#824E3E"/>
            <path d="M605.987 30.0223L604.95 27.9351L611.454 23.4489L613.496 27.558L605.987 30.0223Z" fill="#824E3E"/>
            <path d="M416.008 22.0037L416.046 19.6735L423.886 18.6725L423.811 23.2603L416.008 22.0037Z" fill="#824E3E"/>
            <path d="M603.982 22.0037L604.021 19.6735L611.86 18.6725L611.786 23.2603L603.982 22.0037Z" fill="#824E3E"/>
            <path d="M417.327 13.8688L418.549 11.8832L425.806 15.0149L423.401 18.9227L417.327 13.8688Z" fill="#824E3E"/>
            <path d="M605.302 13.8688L606.523 11.8832L613.78 15.0149L611.375 18.9227L605.302 13.8688Z" fill="#824E3E"/>
            <path d="M422.248 8.38162L424.292 7.25732L429.051 13.5624L425.027 15.7729L422.248 8.38162Z" fill="#824E3E"/>
            <path d="M610.223 8.38162L612.267 7.25732L617.026 13.5624L613.002 15.7729L610.223 8.38162Z" fill="#824E3E"/>
            <path d="M430.429 6.20374L432.761 6.28352L433.622 14.1336L429.031 13.9759L430.429 6.20374Z" fill="#824E3E"/>
            <path d="M618.404 6.20374L620.736 6.28352L621.596 14.1336L617.006 13.9759L618.404 6.20374Z" fill="#824E3E"/>
            <path d="M438.452 7.65808L440.418 8.91294L437.158 16.1066L433.288 13.6368L438.452 7.65808Z" fill="#824E3E"/>
            <path d="M626.427 7.65808L628.393 8.91294L625.133 16.1066L621.263 13.6368L626.427 7.65808Z" fill="#824E3E"/>
            <path d="M443.852 12.6757L444.941 14.7375L438.55 19.3833L436.406 15.325L443.852 12.6757Z" fill="#824E3E"/>
            <path d="M631.827 12.6757L632.916 14.7375L626.525 19.3833L624.381 15.325L631.827 12.6757Z" fill="#824E3E"/>
            <path d="M445.925 20.9827L445.794 23.3093L437.92 24.002L438.176 19.4214L445.925 20.9827Z" fill="#824E3E"/>
            <path d="M633.899 20.9827L633.769 23.3093L625.895 24.002L626.15 19.4214L633.899 20.9827Z" fill="#824E3E"/>
            <path d="M444.315 28.7766L443.046 30.7332L435.867 27.4274L438.367 23.5776L444.315 28.7766Z" fill="#824E3E"/>
            <path d="M632.29 28.7766L631.021 30.7332L623.842 27.4274L626.341 23.5776L632.29 28.7766Z" fill="#824E3E"/>
            <path d="M439.384 34.2168L437.369 35.3936L432.442 29.2191L436.407 26.9016L439.384 34.2168Z" fill="#824E3E"/>
            <path d="M627.358 34.2168L625.343 35.3936L620.417 29.2191L624.381 26.9016L627.358 34.2168Z" fill="#824E3E"/>
            <path d="M431.543 36.6049H429.211L428.08 28.7893H432.672L431.543 36.6049Z" fill="#824E3E"/>
            <path d="M619.518 36.6049H617.186L616.055 28.7893H620.647L619.518 36.6049Z" fill="#824E3E"/>
            <path
                d="M435.203 31.2923C440.806 28.9529 443.451 22.5185 441.109 16.9206C438.767 11.3226 432.326 8.68105 426.723 11.0204C421.12 13.3598 418.475 19.7942 420.817 25.3922C423.159 30.9901 429.6 33.6317 435.203 31.2923Z"
                fill="#D98268"/>
            <path
                d="M623.178 31.2923C628.781 28.9529 631.425 22.5185 629.084 16.9206C626.742 11.3226 620.301 8.68105 614.698 11.0204C609.094 13.3598 606.45 19.7942 608.792 25.3922C611.133 30.9901 617.574 33.6317 623.178 31.2923Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M420.148 21.5993C420.149 18.6856 421.308 15.8914 423.37 13.8313C425.433 11.7712 428.23 10.6139 431.146 10.6139C433.243 10.6151 435.296 11.2156 437.062 12.3443C438.829 13.4731 440.235 15.083 441.115 16.9843C440.289 14.9685 438.881 13.2436 437.071 12.0289C435.261 10.8142 433.131 10.1645 430.95 10.1624C428.034 10.1624 425.237 11.3198 423.175 13.3799C421.113 15.4401 419.954 18.2342 419.954 21.1478C419.955 22.7426 420.306 24.3179 420.981 25.7628C420.434 24.4427 420.151 23.0282 420.148 21.5993V21.5993Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M608.123 21.5993C608.123 18.6856 609.282 15.8914 611.345 13.8313C613.407 11.7712 616.204 10.6139 619.121 10.6139C621.218 10.6151 623.27 11.2156 625.037 12.3443C626.803 13.4731 628.209 15.083 629.09 16.9843C628.263 14.9685 626.856 13.2436 625.046 12.0289C623.236 10.8142 621.105 10.1645 618.925 10.1624C616.009 10.1624 613.212 11.3198 611.149 13.3799C609.087 15.4401 607.929 18.2342 607.929 21.1478C607.93 22.7426 608.28 24.3179 608.956 25.7628C608.409 24.4427 608.125 23.0282 608.123 21.5993V21.5993Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M437.855 12.6104C439.018 13.638 439.95 14.9009 440.589 16.3155C441.227 17.7302 441.557 19.2641 441.558 20.8159C441.557 23.7295 440.398 26.5237 438.336 28.5838C436.273 30.644 433.476 31.8013 430.56 31.8013C428.042 31.8039 425.601 30.9393 423.647 29.3532C425.653 31.1447 428.25 32.1345 430.941 32.1331C433.857 32.1331 436.654 30.9757 438.716 28.9156C440.778 26.8554 441.937 24.0612 441.937 21.1477C441.938 19.5104 441.572 17.8937 440.865 16.4164C440.159 14.939 439.13 13.6385 437.855 12.6104V12.6104Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M625.829 12.6104C626.993 13.638 627.925 14.9009 628.563 16.3155C629.202 17.7302 629.532 19.2641 629.532 20.8159C629.532 23.7295 628.373 26.5237 626.31 28.5838C624.248 30.644 621.451 31.8013 618.534 31.8013C616.017 31.8039 613.576 30.9393 611.622 29.3532C613.628 31.1447 616.225 32.1345 618.915 32.1331C621.832 32.1331 624.629 30.9757 626.691 28.9156C628.753 26.8554 629.912 24.0612 629.912 21.1477C629.913 19.5104 629.546 17.8937 628.84 16.4164C628.133 14.939 627.105 13.6385 625.829 12.6104V12.6104Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M425.065 21.5213C425.065 19.9092 425.706 18.3631 426.847 17.2232C427.988 16.0832 429.536 15.4428 431.15 15.4428C432.195 15.4432 433.224 15.7133 434.134 16.227C435.045 16.7407 435.808 17.4806 436.348 18.3751C435.837 17.3817 435.063 16.5481 434.109 15.9659C433.155 15.3837 432.059 15.0753 430.941 15.0747C429.327 15.0747 427.78 15.7151 426.639 16.8551C425.497 17.995 424.856 19.541 424.856 21.1531C424.856 22.2633 425.163 23.352 425.742 24.2993C425.298 23.4407 425.065 22.488 425.065 21.5213Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M613.04 21.5213C613.04 19.9092 613.681 18.3631 614.822 17.2232C615.963 16.0832 617.51 15.4428 619.124 15.4428C620.17 15.4432 621.198 15.7133 622.109 16.227C623.02 16.7407 623.782 17.4806 624.323 18.3751C623.812 17.3817 623.037 16.5481 622.083 15.9659C621.129 15.3837 620.033 15.0753 618.915 15.0747C617.302 15.0747 615.754 15.7151 614.613 16.8551C613.472 17.995 612.831 19.541 612.831 21.1531C612.831 22.2633 613.138 23.352 613.717 24.2993C613.272 23.4407 613.04 22.488 613.04 21.5213Z"
                  fill="#331911"/>
            </g>
            <path
                d="M430.942 12.8044C428.727 12.8044 426.603 13.6833 425.037 15.2476C423.471 16.8119 422.592 18.9337 422.592 21.146C422.592 23.3583 423.471 25.48 425.037 27.0443C426.603 28.6086 428.727 29.4875 430.942 29.4875C433.156 29.4875 435.28 28.6086 436.846 27.0443C438.412 25.48 439.291 23.3583 439.291 21.146C439.291 18.9337 438.412 16.8119 436.846 15.2476C435.28 13.6833 433.156 12.8044 430.942 12.8044ZM430.942 27.2262C429.328 27.2262 427.78 26.5858 426.639 25.4459C425.498 24.3059 424.857 22.7599 424.857 21.1478C424.857 19.5357 425.498 17.9896 426.639 16.8497C427.78 15.7097 429.328 15.0693 430.942 15.0693C432.555 15.0693 434.103 15.7097 435.244 16.8497C436.385 17.9896 437.026 19.5357 437.026 21.1478C437.026 22.7599 436.385 24.3059 435.244 25.4459C434.103 26.5858 432.555 27.2262 430.942 27.2262V27.2262Z"
                fill="#824E3E"/>
            <path
                d="M618.916 12.8044C616.702 12.8044 614.578 13.6833 613.012 15.2476C611.446 16.8119 610.566 18.9337 610.566 21.146C610.566 23.3583 611.446 25.48 613.012 27.0443C614.578 28.6086 616.702 29.4875 618.916 29.4875C621.131 29.4875 623.254 28.6086 624.82 27.0443C626.386 25.48 627.266 23.3583 627.266 21.146C627.266 18.9337 626.386 16.8119 624.82 15.2476C623.254 13.6833 621.131 12.8044 618.916 12.8044ZM618.916 27.2262C617.302 27.2262 615.755 26.5858 614.614 25.4459C613.473 24.3059 612.832 22.7599 612.832 21.1478C612.832 19.5357 613.473 17.9896 614.614 16.8497C615.755 15.7097 617.302 15.0693 618.916 15.0693C620.53 15.0693 622.077 15.7097 623.218 16.8497C624.359 17.9896 625 19.5357 625 21.1478C625 22.7599 624.359 24.3059 623.218 25.4459C622.077 26.5858 620.53 27.2262 618.916 27.2262V27.2262Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M422.807 21.5213C422.807 19.309 423.687 17.1873 425.253 15.623C426.819 14.0586 428.943 13.1798 431.157 13.1798C432.611 13.1813 434.039 13.562 435.301 14.2843C436.562 15.0066 437.613 16.0454 438.349 17.2979C437.643 15.9459 436.581 14.8129 435.276 14.0219C433.971 13.231 432.474 12.8124 430.948 12.8116C428.734 12.8116 426.61 13.6905 425.044 15.2548C423.478 16.8192 422.599 18.9409 422.599 21.1532C422.599 22.6398 422.999 24.099 423.757 25.3783C423.133 24.1881 422.807 22.8647 422.807 21.5213V21.5213Z"
                  fill="#E8E6E1"/>
              <path
                  d="M431.157 27.5943C431.956 27.5943 432.747 27.437 433.485 27.1315C434.224 26.826 434.894 26.3783 435.459 25.8138C436.024 25.2494 436.472 24.5793 436.777 23.8418C437.083 23.1043 437.24 22.314 437.24 21.5158C437.24 20.406 436.934 19.3175 436.356 18.3696C436.801 19.2283 437.033 20.1809 437.033 21.1477C437.033 22.7598 436.392 24.3059 435.251 25.4458C434.11 26.5857 432.562 27.2261 430.949 27.2261C429.902 27.2261 428.874 26.9559 427.963 26.4419C427.052 25.9278 426.29 25.1873 425.75 24.2921C426.26 25.2864 427.034 26.1208 427.989 26.7034C428.943 27.286 430.039 27.5943 431.157 27.5943V27.5943Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M610.782 21.5213C610.782 19.309 611.662 17.1873 613.228 15.623C614.793 14.0586 616.917 13.1798 619.132 13.1798C620.586 13.1813 622.014 13.562 623.275 14.2843C624.537 15.0066 625.587 16.0454 626.323 17.2979C625.618 15.9459 624.555 14.8129 623.251 14.0219C621.946 13.231 620.449 12.8124 618.923 12.8116C616.708 12.8116 614.585 13.6905 613.019 15.2548C611.453 16.8192 610.573 18.9409 610.573 21.1532C610.573 22.6398 610.973 24.099 611.731 25.3783C611.108 24.1881 610.782 22.8647 610.782 21.5213V21.5213Z"
                  fill="#E8E6E1"/>
              <path
                  d="M619.132 27.5943C619.931 27.5943 620.722 27.437 621.46 27.1315C622.198 26.826 622.869 26.3783 623.434 25.8138C623.998 25.2494 624.446 24.5793 624.752 23.8418C625.058 23.1043 625.215 22.314 625.215 21.5158C625.214 20.406 624.908 19.3175 624.331 18.3696C624.775 19.2283 625.007 20.1809 625.008 21.1477C625.008 22.7598 624.367 24.3059 623.226 25.4458C622.084 26.5857 620.537 27.2261 618.923 27.2261C617.877 27.2261 616.849 26.9559 615.938 26.4419C615.027 25.9278 614.265 25.1873 613.725 24.2921C614.234 25.2864 615.009 26.1208 615.963 26.7034C616.917 27.286 618.014 27.5943 619.132 27.5943V27.5943Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M419.945 21.1478C419.945 21.3654 419.965 21.5794 419.978 21.7951C421.192 22.3986 422.53 22.7133 423.886 22.7145C426.229 22.714 428.475 21.784 430.132 20.1289C431.788 18.4738 432.719 16.2292 432.719 13.8888C432.718 12.6187 432.442 11.3638 431.907 10.2113C431.59 10.1823 431.268 10.1624 430.942 10.1624C429.497 10.1624 428.068 10.4465 426.733 10.9986C425.399 11.5507 424.187 12.3598 423.166 13.3799C422.145 14.4 421.335 15.611 420.782 16.9438C420.23 18.2767 419.945 19.7051 419.945 21.1478V21.1478Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M607.92 21.1478C607.92 21.3654 607.94 21.5794 607.953 21.7951C609.167 22.3986 610.504 22.7133 611.861 22.7145C614.203 22.714 616.45 21.784 618.106 20.1289C619.763 18.4738 620.693 16.2292 620.693 13.8888C620.693 12.6187 620.416 11.3638 619.882 10.2113C619.564 10.1823 619.243 10.1624 618.916 10.1624C617.472 10.1624 616.042 10.4465 614.708 10.9986C613.374 11.5507 612.162 12.3598 611.141 13.3799C610.12 14.4 609.31 15.611 608.757 16.9438C608.204 18.2767 607.92 19.7051 607.92 21.1478V21.1478Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M435.762 11.2866C437.426 13.1906 438.343 15.6332 438.341 18.1611C438.341 20.9452 437.234 23.6154 435.264 25.584C433.293 27.5527 430.62 28.6587 427.833 28.6587C425.436 28.6611 423.11 27.8404 421.246 26.334C422.185 28.0872 423.583 29.5529 425.29 30.5749C426.997 31.5968 428.95 32.1366 430.941 32.1368C433.857 32.1368 436.654 30.9794 438.716 28.9192C440.778 26.8591 441.937 24.0649 441.937 21.1514C441.937 19.094 441.358 17.078 440.267 15.3337C439.175 13.5893 437.614 12.1869 435.762 11.2866Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M623.736 11.2866C625.401 13.1906 626.317 15.6332 626.316 18.1611C626.316 20.9452 625.209 23.6154 623.238 25.584C621.267 27.5527 618.595 28.6587 615.808 28.6587C613.41 28.6611 611.085 27.8404 609.221 26.334C610.16 28.0872 611.557 29.5529 613.265 30.5749C614.972 31.5968 616.925 32.1366 618.915 32.1368C621.832 32.1368 624.629 30.9794 626.691 28.9192C628.753 26.8591 629.912 24.0649 629.912 21.1514C629.912 19.094 629.333 17.078 628.241 15.3337C627.149 13.5893 625.588 12.1869 623.736 11.2866Z"
                  fill="#FAF8F2"/>
            </g>
            <path
                d="M427.407 21.1477C427.407 20.2113 427.78 19.3133 428.442 18.6512C429.105 17.989 430.004 17.6171 430.941 17.6171C431.879 17.6171 432.778 17.989 433.44 18.6512C434.103 19.3133 434.475 20.2113 434.475 21.1477C434.475 22.0841 434.103 22.9821 433.44 23.6443C432.778 24.3064 431.879 24.6784 430.941 24.6784C430.004 24.6784 429.105 24.3064 428.442 23.6443C427.78 22.9821 427.407 22.0841 427.407 21.1477Z"
                fill="#4E415C"/>
            <path
                d="M615.382 21.1477C615.382 20.2113 615.754 19.3133 616.417 18.6512C617.08 17.989 617.979 17.6171 618.916 17.6171C619.853 17.6171 620.752 17.989 621.415 18.6512C622.078 19.3133 622.45 20.2113 622.45 21.1477C622.45 22.0841 622.078 22.9821 621.415 23.6443C620.752 24.3064 619.853 24.6784 618.916 24.6784C617.979 24.6784 617.08 24.3064 616.417 23.6443C615.754 22.9821 615.382 22.0841 615.382 21.1477Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M433.742 19.0188C433.952 19.4739 434.061 19.9686 434.063 20.4695C434.063 20.9331 433.972 21.3923 433.794 21.8206C433.617 22.249 433.356 22.6382 433.028 22.9661C432.7 23.2939 432.311 23.554 431.882 23.7314C431.453 23.9088 430.993 24.0002 430.529 24.0002C429.986 23.9988 429.45 23.8716 428.964 23.6287C428.478 23.3858 428.055 23.0338 427.729 22.6002C428.007 23.2185 428.459 23.7432 429.029 24.1115C429.598 24.4798 430.263 24.676 430.941 24.6765C431.879 24.6765 432.778 24.3046 433.44 23.6424C434.103 22.9803 434.475 22.0823 434.475 21.1459C434.473 20.3755 434.215 19.6276 433.742 19.0188V19.0188Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M621.717 19.0188C621.926 19.4739 622.036 19.9686 622.038 20.4695C622.038 20.9331 621.947 21.3923 621.769 21.8206C621.591 22.249 621.331 22.6382 621.003 22.9661C620.675 23.2939 620.285 23.554 619.856 23.7314C619.428 23.9088 618.968 24.0002 618.504 24.0002C617.961 23.9988 617.425 23.8716 616.939 23.6287C616.453 23.3858 616.03 23.0338 615.703 22.6002C615.982 23.2185 616.433 23.7432 617.003 24.1115C617.573 24.4798 618.237 24.676 618.916 24.6765C619.853 24.6765 620.752 24.3046 621.415 23.6424C622.078 22.9803 622.45 22.0823 622.45 21.1459C622.447 20.3755 622.189 19.6276 621.717 19.0188V19.0188Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M427.954 21.9003C427.954 20.9639 428.326 20.0659 428.989 19.4038C429.651 18.7417 430.55 18.3697 431.488 18.3697C431.959 18.37 432.425 18.4646 432.859 18.6481C433.293 18.8315 433.686 19.1 434.014 19.4378C433.711 18.8891 433.266 18.4317 432.725 18.1128C432.185 17.794 431.569 17.6253 430.941 17.6244C430.004 17.6244 429.105 17.9964 428.442 18.6585C427.78 19.3206 427.407 20.2186 427.407 21.155C427.407 22.076 427.769 22.9601 428.415 23.6176C428.115 23.0946 427.956 22.5029 427.954 21.9003V21.9003Z"
                  fill="#241133"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M615.928 21.9003C615.928 20.9639 616.301 20.0659 616.963 19.4038C617.626 18.7417 618.525 18.3697 619.462 18.3697C619.934 18.37 620.4 18.4646 620.834 18.6481C621.268 18.8315 621.661 19.1 621.989 19.4378C621.685 18.8891 621.24 18.4317 620.7 18.1128C620.159 17.794 619.544 17.6253 618.916 17.6244C617.979 17.6244 617.08 17.9964 616.417 18.6585C615.754 19.3206 615.382 20.2186 615.382 21.155C615.382 22.076 615.744 22.9601 616.389 23.6176C616.09 23.0946 615.931 22.5029 615.928 21.9003V21.9003Z"
                  fill="#241133"/>
            </g>
            <path
                d="M534.379 48.0873C521.339 48.0873 508.604 41.7241 497.376 36.1081C487.842 31.3426 478.841 26.8599 471.582 27.2226C464.608 27.5852 458.413 30.7713 451.24 34.4561C439.414 40.5273 424.697 48.0854 398.455 48.0854C385.205 48.0854 374.432 37.3194 374.432 24.0854C374.432 10.8514 385.208 0.0854492 398.455 0.0854492H534.379C547.63 0.0854492 558.403 10.8514 558.403 24.0854C558.403 37.3194 547.626 48.0873 534.379 48.0873ZM472.459 23.5396C480.424 23.5396 489.467 28.0585 499.017 32.8332C509.886 38.2733 522.205 44.4242 534.379 44.4242C539.777 44.418 544.951 42.2734 548.768 38.4609C552.584 34.6484 554.731 29.4792 554.738 24.0873C554.731 18.6953 552.584 13.5261 548.768 9.71362C544.951 5.9011 539.777 3.75651 534.379 3.75027H398.455C393.058 3.75651 387.883 5.90135 384.066 9.71426C380.25 13.5272 378.103 18.6968 378.096 24.0891C378.103 29.481 380.25 34.6502 384.067 38.4627C387.884 42.2752 393.058 44.4198 398.455 44.4261C423.809 44.4261 438.089 37.0928 449.565 31.2029C457.112 27.3259 463.632 23.9785 471.388 23.5705C471.741 23.5499 472.098 23.5396 472.459 23.5396Z"
                fill="#4E415C"/>
            <path
                d="M722.354 48.0873C709.314 48.0873 696.579 41.7241 685.35 36.1081C675.817 31.3426 666.816 26.8599 659.557 27.2226C652.583 27.5852 646.388 30.7713 639.215 34.4561C627.389 40.5273 612.671 48.0854 586.43 48.0854C573.179 48.0854 562.406 37.3194 562.406 24.0854C562.406 10.8514 573.183 0.0854492 586.43 0.0854492H722.354C735.605 0.0854492 746.378 10.8514 746.378 24.0854C746.378 37.3194 735.601 48.0873 722.354 48.0873ZM660.434 23.5396C668.399 23.5396 677.442 28.0585 686.991 32.8332C697.86 38.2733 710.18 44.4242 722.354 44.4242C727.751 44.418 732.926 42.2734 736.742 38.4609C740.559 34.6484 742.706 29.4792 742.713 24.0873C742.706 18.6953 740.559 13.5261 736.742 9.71362C732.926 5.9011 727.751 3.75651 722.354 3.75027H586.43C581.032 3.75651 575.858 5.90135 572.041 9.71426C568.224 13.5272 566.077 18.6968 566.071 24.0891C566.078 29.481 568.225 34.6502 572.042 38.4627C575.858 42.2752 581.033 44.4198 586.43 44.4261C611.784 44.4261 626.064 37.0928 637.539 31.2029C645.087 27.3259 651.607 23.9785 659.363 23.5705C659.716 23.5499 660.073 23.5396 660.434 23.5396Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M556.705 15.2416C557.598 17.7834 558.053 20.4578 558.052 23.1515C558.052 36.3891 547.276 47.1515 534.029 47.1515C520.989 47.1515 508.254 40.7884 497.023 35.1724C487.49 30.4068 478.489 25.9242 471.232 26.2868C464.258 26.6495 458.063 29.8374 450.889 33.5204C439.064 39.5934 424.344 47.1497 398.103 47.1497C393.291 47.1442 388.591 45.697 384.611 42.9951C380.631 40.2933 377.553 36.461 375.776 31.9935C377.423 36.6913 380.489 40.7625 384.551 43.645C388.613 46.5274 393.471 48.079 398.453 48.0854C424.695 48.0854 439.412 40.5273 451.238 34.4561C458.411 30.7713 464.606 27.5906 471.58 27.2225C478.841 26.8599 487.84 31.3407 497.374 36.1081C508.606 41.7295 521.334 48.0872 534.377 48.0872C547.628 48.0872 558.401 37.3212 558.401 24.0872C558.403 21.0579 557.827 18.056 556.705 15.2416V15.2416Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M744.68 15.2416C745.573 17.7834 746.028 20.4578 746.027 23.1515C746.027 36.3891 735.25 47.1515 722.003 47.1515C708.963 47.1515 696.228 40.7884 684.998 35.1724C675.465 30.4068 666.463 25.9242 659.206 26.2868C652.232 26.6495 646.037 29.8374 638.864 33.5204C627.038 39.5934 612.319 47.1497 586.077 47.1497C581.265 47.1442 576.566 45.697 572.586 42.9951C568.605 40.2933 565.528 36.461 563.751 31.9935C565.398 36.6913 568.464 40.7625 572.526 43.645C576.588 46.5274 581.445 48.079 586.428 48.0854C612.669 48.0854 627.387 40.5273 639.212 34.4561C646.386 30.7713 652.581 27.5906 659.555 27.2225C666.815 26.8599 675.815 31.3407 685.348 36.1081C696.58 41.7295 709.308 48.0872 722.352 48.0872C735.602 48.0872 746.375 37.3212 746.375 24.0872C746.377 21.0579 745.802 18.056 744.68 15.2416V15.2416Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M374.978 25.0212C374.978 11.7835 385.753 1.02297 399.002 1.02297H534.926C539.034 1.02522 543.072 2.07939 546.656 4.0848C550.24 6.09022 553.249 8.97993 555.397 12.4781C553.32 8.72633 550.275 5.59857 546.579 3.41964C542.883 1.24072 538.671 0.0901044 534.379 0.0872803H398.455C385.205 0.0872803 374.432 10.8533 374.432 24.0873C374.427 28.5167 375.657 32.86 377.984 36.6304C376.008 33.0796 374.974 29.0835 374.978 25.0212V25.0212Z"
                  fill="#241133"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M562.953 25.0212C562.953 11.7835 573.727 1.02297 586.976 1.02297H722.9C727.008 1.02522 731.047 2.07939 734.631 4.0848C738.214 6.09022 741.224 8.97993 743.372 12.4781C741.295 8.72633 738.25 5.59857 734.554 3.41964C730.858 1.24072 726.646 0.0901044 722.354 0.0872803H586.43C573.179 0.0872803 562.406 10.8533 562.406 24.0873C562.401 28.5167 563.631 32.86 565.959 36.6304C563.983 33.0796 562.948 29.0835 562.953 25.0212V25.0212Z"
                  fill="#241133"/>
            </g>
            <path
                d="M471.252 23.4996C471.252 23.1768 471.381 22.8673 471.61 22.6391C471.839 22.411 472.149 22.2828 472.472 22.2828C472.795 22.2833 473.104 22.4117 473.332 22.6398C473.561 22.8678 473.689 23.1771 473.69 23.4996C473.69 23.8223 473.561 24.1318 473.333 24.36C473.105 24.5882 472.795 24.7164 472.472 24.7164C472.312 24.7166 472.153 24.6853 472.005 24.6243C471.857 24.5632 471.723 24.4737 471.609 24.3606C471.496 24.2476 471.406 24.1134 471.345 23.9657C471.284 23.8179 471.252 23.6596 471.252 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M659.227 23.4996C659.227 23.1768 659.356 22.8673 659.584 22.6391C659.813 22.411 660.123 22.2828 660.446 22.2828C660.769 22.2833 661.079 22.4117 661.307 22.6398C661.535 22.8678 661.664 23.1771 661.664 23.4996C661.664 23.8223 661.536 24.1318 661.308 24.36C661.079 24.5882 660.769 24.7164 660.446 24.7164C660.286 24.7166 660.128 24.6853 659.98 24.6243C659.832 24.5632 659.697 24.4737 659.584 24.3606C659.471 24.2476 659.381 24.1134 659.319 23.9657C659.258 23.8179 659.227 23.6596 659.227 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M474.176 23.6974C474.176 23.5375 474.207 23.3792 474.268 23.2314C474.329 23.0837 474.419 22.9495 474.532 22.8364C474.645 22.7234 474.779 22.6338 474.927 22.5727C475.075 22.5117 475.234 22.4804 475.394 22.4806C475.717 22.4806 476.027 22.6088 476.255 22.837C476.483 23.0652 476.612 23.3747 476.612 23.6974C476.611 24.0199 476.483 24.3291 476.254 24.5572C476.026 24.7853 475.717 24.9136 475.394 24.9141C475.071 24.9141 474.761 24.7859 474.533 24.5577C474.304 24.3295 474.176 24.0201 474.176 23.6974V23.6974Z"
                fill="#4E415C"/>
            <path
                d="M662.15 23.6974C662.15 23.5375 662.181 23.3792 662.243 23.2314C662.304 23.0837 662.393 22.9495 662.507 22.8364C662.62 22.7234 662.754 22.6338 662.902 22.5727C663.05 22.5117 663.208 22.4804 663.368 22.4806C663.691 22.4806 664.001 22.6088 664.23 22.837C664.458 23.0652 664.586 23.3747 664.586 23.6974C664.586 24.0199 664.457 24.3291 664.229 24.5572C664.001 24.7853 663.691 24.9136 663.368 24.9141C663.045 24.9141 662.736 24.7859 662.507 24.5577C662.279 24.3295 662.15 24.0201 662.15 23.6974V23.6974Z"
                fill="#4E415C"/>
            <path
                d="M477.193 24.2286C477.193 24.0688 477.224 23.9104 477.286 23.7627C477.347 23.615 477.436 23.4807 477.55 23.3677C477.663 23.2547 477.797 23.165 477.945 23.104C478.093 23.0429 478.251 23.0116 478.411 23.0118C478.571 23.0116 478.73 23.0429 478.878 23.104C479.026 23.165 479.16 23.2547 479.273 23.3677C479.386 23.4807 479.476 23.615 479.537 23.7627C479.598 23.9104 479.63 24.0688 479.629 24.2286C479.63 24.3885 479.598 24.5468 479.537 24.6945C479.476 24.8423 479.386 24.9765 479.273 25.0895C479.16 25.2026 479.026 25.2922 478.878 25.3533C478.73 25.4143 478.571 25.4456 478.411 25.4454C478.251 25.4456 478.093 25.4143 477.945 25.3533C477.797 25.2922 477.663 25.2026 477.55 25.0895C477.436 24.9765 477.347 24.8423 477.286 24.6945C477.224 24.5468 477.193 24.3885 477.193 24.2286V24.2286Z"
                fill="#4E415C"/>
            <path
                d="M665.168 24.2286C665.168 24.0688 665.199 23.9104 665.26 23.7627C665.321 23.615 665.411 23.4807 665.524 23.3677C665.637 23.2547 665.772 23.165 665.92 23.104C666.067 23.0429 666.226 23.0116 666.386 23.0118C666.546 23.0116 666.704 23.0429 666.852 23.104C667 23.165 667.135 23.2547 667.248 23.3677C667.361 23.4807 667.451 23.615 667.512 23.7627C667.573 23.9104 667.604 24.0688 667.604 24.2286C667.604 24.3885 667.573 24.5468 667.512 24.6945C667.451 24.8423 667.361 24.9765 667.248 25.0895C667.135 25.2026 667 25.2922 666.852 25.3533C666.704 25.4143 666.546 25.4456 666.386 25.4454C666.226 25.4456 666.067 25.4143 665.92 25.3533C665.772 25.2922 665.637 25.2026 665.524 25.0895C665.411 24.9765 665.321 24.8423 665.26 24.6945C665.199 24.5468 665.168 24.3885 665.168 24.2286V24.2286Z"
                fill="#4E415C"/>
            <path
                d="M480.29 25.0719C480.29 24.912 480.321 24.7537 480.382 24.606C480.443 24.4582 480.533 24.324 480.646 24.211C480.759 24.0979 480.894 24.0083 481.042 23.9472C481.19 23.8862 481.348 23.8549 481.508 23.8551C481.668 23.8549 481.826 23.8862 481.974 23.9472C482.122 24.0083 482.257 24.0979 482.37 24.211C482.483 24.324 482.573 24.4582 482.634 24.606C482.695 24.7537 482.726 24.912 482.726 25.0719C482.726 25.2317 482.695 25.3901 482.634 25.5378C482.573 25.6855 482.483 25.8198 482.37 25.9328C482.257 26.0458 482.122 26.1354 481.974 26.1965C481.826 26.2576 481.668 26.2889 481.508 26.2887C481.348 26.2889 481.19 26.2576 481.042 26.1965C480.894 26.1354 480.759 26.0458 480.646 25.9328C480.533 25.8198 480.443 25.6855 480.382 25.5378C480.321 25.3901 480.29 25.2317 480.29 25.0719V25.0719Z"
                fill="#4E415C"/>
            <path
                d="M668.265 25.0719C668.264 24.912 668.296 24.7537 668.357 24.606C668.418 24.4582 668.508 24.324 668.621 24.211C668.734 24.0979 668.868 24.0083 669.016 23.9472C669.164 23.8862 669.323 23.8549 669.483 23.8551C669.643 23.8549 669.801 23.8862 669.949 23.9472C670.097 24.0083 670.231 24.0979 670.344 24.211C670.458 24.324 670.547 24.4582 670.608 24.606C670.669 24.7537 670.701 24.912 670.701 25.0719C670.701 25.2317 670.669 25.3901 670.608 25.5378C670.547 25.6855 670.458 25.8198 670.344 25.9328C670.231 26.0458 670.097 26.1354 669.949 26.1965C669.801 26.2576 669.643 26.2889 669.483 26.2887C669.323 26.2889 669.164 26.2576 669.016 26.1965C668.868 26.1354 668.734 26.0458 668.621 25.9328C668.508 25.8198 668.418 25.6855 668.357 25.5378C668.296 25.3901 668.264 25.2317 668.265 25.0719V25.0719Z"
                fill="#4E415C"/>
            <path
                d="M484.681 27.3912C485.354 27.3912 485.899 26.8464 485.899 26.1744C485.899 25.5024 485.354 24.9576 484.681 24.9576C484.008 24.9576 483.463 25.5024 483.463 26.1744C483.463 26.8464 484.008 27.3912 484.681 27.3912Z"
                fill="#4E415C"/>
            <path
                d="M672.655 27.3912C673.328 27.3912 673.873 26.8464 673.873 26.1744C673.873 25.5024 673.328 24.9576 672.655 24.9576C671.983 24.9576 671.438 25.5024 671.438 26.1744C671.438 26.8464 671.983 27.3912 672.655 27.3912Z"
                fill="#4E415C"/>
            <path
                d="M486.701 27.4928C486.701 27.333 486.733 27.1748 486.794 27.0271C486.855 26.8795 486.945 26.7454 487.058 26.6324C487.171 26.5194 487.305 26.4298 487.453 26.3686C487.601 26.3075 487.759 26.276 487.919 26.276C488.242 26.2765 488.552 26.4048 488.78 26.6329C489.008 26.861 489.137 27.1702 489.137 27.4928C489.137 27.8155 489.009 28.125 488.78 28.3532C488.552 28.5814 488.242 28.7096 487.919 28.7096C487.759 28.7098 487.601 28.6785 487.453 28.6174C487.305 28.5564 487.171 28.4667 487.057 28.3537C486.944 28.2407 486.855 28.1064 486.793 27.9587C486.732 27.811 486.701 27.6526 486.701 27.4928V27.4928Z"
                fill="#4E415C"/>
            <path
                d="M674.676 27.4928C674.676 27.333 674.707 27.1748 674.768 27.0271C674.83 26.8795 674.919 26.7454 675.033 26.6324C675.146 26.5194 675.28 26.4298 675.428 26.3686C675.575 26.3075 675.734 26.276 675.894 26.276C676.217 26.2765 676.526 26.4048 676.754 26.6329C676.983 26.861 677.111 27.1702 677.112 27.4928C677.112 27.8155 676.983 28.125 676.755 28.3532C676.527 28.5814 676.217 28.7096 675.894 28.7096C675.734 28.7098 675.575 28.6785 675.427 28.6174C675.279 28.5564 675.145 28.4667 675.032 28.3537C674.919 28.2407 674.829 28.1064 674.768 27.9587C674.707 27.811 674.676 27.6526 674.676 27.4928V27.4928Z"
                fill="#4E415C"/>
            <path
                d="M490.014 28.9815C490.014 28.659 490.143 28.3498 490.371 28.1217C490.599 27.8936 490.909 27.7653 491.232 27.7648C491.555 27.7648 491.865 27.8929 492.094 28.121C492.322 28.3492 492.451 28.6587 492.451 28.9815C492.451 29.1415 492.42 29.2998 492.359 29.4476C492.297 29.5953 492.207 29.7295 492.094 29.8426C491.981 29.9556 491.846 30.0452 491.698 30.1062C491.55 30.1673 491.392 30.1985 491.232 30.1983C490.909 30.1983 490.599 30.0701 490.37 29.8419C490.142 29.6137 490.014 29.3042 490.014 28.9815V28.9815Z"
                fill="#4E415C"/>
            <path
                d="M677.988 28.9815C677.989 28.659 678.117 28.3498 678.346 28.1217C678.574 27.8936 678.883 27.7653 679.206 27.7648C679.529 27.7648 679.839 27.8929 680.068 28.121C680.297 28.3492 680.426 28.6587 680.426 28.9815C680.426 29.1415 680.394 29.2998 680.333 29.4476C680.272 29.5953 680.182 29.7295 680.069 29.8426C679.955 29.9556 679.821 30.0452 679.673 30.1062C679.525 30.1673 679.366 30.1985 679.206 30.1983C678.883 30.1983 678.573 30.0701 678.345 29.8419C678.117 29.6137 677.988 29.3042 677.988 28.9815V28.9815Z"
                fill="#4E415C"/>
            <path
                d="M493.383 30.5973C493.383 30.4373 493.414 30.2789 493.475 30.1311C493.536 29.9832 493.626 29.8489 493.739 29.7357C493.852 29.6225 493.986 29.5327 494.134 29.4715C494.282 29.4102 494.441 29.3787 494.601 29.3787C494.924 29.3791 495.234 29.5077 495.462 29.7362C495.69 29.9647 495.819 30.2744 495.819 30.5973C495.818 30.9198 495.69 31.229 495.461 31.4571C495.233 31.6852 494.924 31.8136 494.601 31.814C494.278 31.814 493.968 31.6858 493.74 31.4576C493.511 31.2295 493.383 30.92 493.383 30.5973V30.5973Z"
                fill="#4E415C"/>
            <path
                d="M681.357 30.5973C681.357 30.4373 681.388 30.2789 681.45 30.1311C681.511 29.9832 681.6 29.8489 681.713 29.7357C681.827 29.6225 681.961 29.5327 682.109 29.4715C682.257 29.4102 682.415 29.3787 682.575 29.3787C682.899 29.3791 683.208 29.5077 683.437 29.7362C683.665 29.9647 683.793 30.2744 683.793 30.5973C683.793 30.9198 683.664 31.229 683.436 31.4571C683.208 31.6852 682.898 31.8136 682.575 31.814C682.252 31.814 681.943 31.6858 681.714 31.4576C681.486 31.2295 681.357 30.92 681.357 30.5973V30.5973Z"
                fill="#4E415C"/>
            <path
                d="M496.804 32.2927C496.803 32.1327 496.835 31.9743 496.896 31.8265C496.957 31.6786 497.047 31.5443 497.16 31.4311C497.273 31.3179 497.407 31.2282 497.555 31.1669C497.703 31.1056 497.862 31.0741 498.022 31.0741C498.345 31.0746 498.655 31.2032 498.883 31.4316C499.111 31.6601 499.24 31.9698 499.24 32.2927C499.239 32.6152 499.111 32.9244 498.882 33.1525C498.654 33.3806 498.345 33.509 498.022 33.5094C497.699 33.5094 497.389 33.3812 497.16 33.1531C496.932 32.9249 496.804 32.6154 496.804 32.2927V32.2927Z"
                fill="#4E415C"/>
            <path
                d="M684.778 32.2927C684.778 32.1327 684.809 31.9743 684.87 31.8265C684.932 31.6786 685.021 31.5443 685.134 31.4311C685.248 31.3179 685.382 31.2282 685.53 31.1669C685.678 31.1056 685.836 31.0741 685.996 31.0741C686.319 31.0746 686.629 31.2032 686.858 31.4316C687.086 31.6601 687.214 31.9698 687.214 32.2927C687.214 32.6152 687.085 32.9244 686.857 33.1525C686.629 33.3806 686.319 33.509 685.996 33.5094C685.673 33.5094 685.363 33.3812 685.135 33.1531C684.907 32.9249 684.778 32.6154 684.778 32.2927V32.2927Z"
                fill="#4E415C"/>
            <path
                d="M499.934 33.8522C499.934 33.5294 500.062 33.2197 500.29 32.9912C500.519 32.7628 500.828 32.6341 501.152 32.6337C501.475 32.6337 501.785 32.7621 502.014 32.9906C502.243 33.2191 502.371 33.5291 502.371 33.8522C502.371 34.1751 502.242 34.4846 502.013 34.7127C501.785 34.9409 501.475 35.069 501.152 35.069C500.829 35.0685 500.519 34.9402 500.291 34.7121C500.063 34.484 499.934 34.1748 499.934 33.8522V33.8522Z"
                fill="#4E415C"/>
            <path
                d="M687.908 33.8522C687.908 33.5294 688.036 33.2197 688.265 32.9912C688.493 32.7628 688.803 32.6341 689.126 32.6337C689.45 32.6337 689.76 32.7621 689.989 32.9906C690.217 33.2191 690.346 33.5291 690.346 33.8522C690.345 34.1751 690.217 34.4846 689.988 34.7127C689.759 34.9409 689.449 35.069 689.126 35.069C688.803 35.0685 688.494 34.9402 688.265 34.7121C688.037 34.484 687.909 34.1748 687.908 33.8522V33.8522Z"
                fill="#4E415C"/>
            <path
                d="M502.988 35.3609C502.988 35.201 503.019 35.0426 503.08 34.8947C503.142 34.7469 503.231 34.6125 503.344 34.4994C503.458 34.3862 503.592 34.2964 503.74 34.2351C503.888 34.1739 504.046 34.1423 504.206 34.1423C504.529 34.1428 504.839 34.2714 505.068 34.4999C505.296 34.7284 505.424 35.038 505.424 35.3609C505.424 35.6835 505.295 35.9927 505.067 36.2208C504.839 36.4489 504.529 36.5772 504.206 36.5777C503.883 36.5777 503.573 36.4495 503.345 36.2213C503.117 35.9931 502.988 35.6836 502.988 35.3609V35.3609Z"
                fill="#4E415C"/>
            <path
                d="M690.963 35.3609C690.963 35.201 690.994 35.0426 691.055 34.8947C691.116 34.7469 691.206 34.6125 691.319 34.4994C691.432 34.3862 691.566 34.2964 691.714 34.2351C691.862 34.1739 692.021 34.1423 692.181 34.1423C692.504 34.1428 692.814 34.2714 693.042 34.4999C693.271 34.7284 693.399 35.038 693.399 35.3609C693.398 35.6835 693.27 35.9927 693.042 36.2208C692.813 36.4489 692.504 36.5772 692.181 36.5777C691.858 36.5777 691.548 36.4495 691.32 36.2213C691.091 35.9931 690.963 35.6836 690.963 35.3609V35.3609Z"
                fill="#4E415C"/>
            <path
                d="M506.102 36.8479C506.101 36.688 506.133 36.5297 506.194 36.382C506.255 36.2342 506.345 36.1 506.458 35.9869C506.571 35.8739 506.705 35.7843 506.853 35.7232C507.001 35.6622 507.16 35.6309 507.32 35.6311C507.643 35.6311 507.952 35.7593 508.181 35.9875C508.409 36.2157 508.537 36.5252 508.537 36.8479C508.537 37.1706 508.409 37.4801 508.181 37.7082C507.952 37.9364 507.643 38.0646 507.32 38.0646C507.16 38.0649 507.001 38.0336 506.853 37.9725C506.705 37.9114 506.571 37.8218 506.458 37.7088C506.345 37.5957 506.255 37.4615 506.194 37.3138C506.133 37.1661 506.101 37.0077 506.102 36.8479V36.8479Z"
                fill="#4E415C"/>
            <path
                d="M694.076 36.8479C694.076 36.688 694.107 36.5297 694.168 36.382C694.23 36.2342 694.319 36.1 694.432 35.9869C694.546 35.8739 694.68 35.7843 694.828 35.7232C694.976 35.6622 695.134 35.6309 695.294 35.6311C695.617 35.6311 695.927 35.7593 696.155 35.9875C696.384 36.2157 696.512 36.5252 696.512 36.8479C696.512 37.1706 696.384 37.4801 696.155 37.7082C695.927 37.9364 695.617 38.0646 695.294 38.0646C695.134 38.0649 694.976 38.0336 694.828 37.9725C694.68 37.9114 694.546 37.8218 694.432 37.7088C694.319 37.5957 694.23 37.4615 694.168 37.3138C694.107 37.1661 694.076 37.0077 694.076 36.8479V36.8479Z"
                fill="#4E415C"/>
            <path
                d="M509.267 38.2877C509.266 38.1278 509.298 37.9695 509.359 37.8218C509.42 37.674 509.51 37.5398 509.623 37.4268C509.736 37.3137 509.87 37.2241 510.018 37.1631C510.166 37.102 510.325 37.0707 510.485 37.0709C510.808 37.0709 511.117 37.1991 511.346 37.4273C511.574 37.6555 511.703 37.965 511.703 38.2877C511.702 38.6103 511.574 38.9195 511.345 39.1476C511.117 39.3756 510.807 39.504 510.485 39.5045C510.162 39.5045 509.852 39.3763 509.623 39.1481C509.395 38.9199 509.267 38.6104 509.267 38.2877V38.2877Z"
                fill="#4E415C"/>
            <path
                d="M697.241 38.2877C697.241 38.1278 697.272 37.9695 697.333 37.8218C697.395 37.674 697.484 37.5398 697.597 37.4268C697.711 37.3137 697.845 37.2241 697.993 37.1631C698.141 37.102 698.299 37.0707 698.459 37.0709C698.782 37.0709 699.092 37.1991 699.32 37.4273C699.549 37.6555 699.677 37.965 699.677 38.2877C699.677 38.6103 699.548 38.9195 699.32 39.1476C699.092 39.3756 698.782 39.504 698.459 39.5045C698.136 39.5045 697.826 39.3763 697.598 39.1481C697.37 38.9199 697.241 38.6104 697.241 38.2877V38.2877Z"
                fill="#4E415C"/>
            <path
                d="M513.696 40.8627C514.368 40.8627 514.913 40.318 514.913 39.646C514.913 38.974 514.368 38.4292 513.696 38.4292C513.023 38.4292 512.478 38.974 512.478 39.646C512.478 40.318 513.023 40.8627 513.696 40.8627Z"
                fill="#4E415C"/>
            <path
                d="M701.67 40.8627C702.343 40.8627 702.888 40.318 702.888 39.646C702.888 38.974 702.343 38.4292 701.67 38.4292C700.997 38.4292 700.452 38.974 700.452 39.646C700.452 40.318 700.997 40.8627 701.67 40.8627Z"
                fill="#4E415C"/>
            <path
                d="M515.727 40.8953C515.726 40.7354 515.758 40.577 515.819 40.4292C515.88 40.2813 515.97 40.147 516.083 40.0338C516.196 39.9206 516.33 39.8308 516.478 39.7696C516.626 39.7083 516.784 39.6768 516.945 39.6768C517.105 39.6768 517.263 39.7083 517.411 39.7696C517.559 39.8308 517.693 39.9206 517.806 40.0338C517.92 40.147 518.009 40.2813 518.07 40.4292C518.131 40.577 518.163 40.7354 518.163 40.8953C518.163 41.218 518.034 41.5276 517.806 41.7557C517.577 41.9839 517.268 42.1121 516.945 42.1121C516.622 42.1121 516.312 41.9839 516.083 41.7557C515.855 41.5276 515.727 41.218 515.727 40.8953V40.8953Z"
                fill="#4E415C"/>
            <path
                d="M703.701 40.8953C703.701 40.7354 703.732 40.577 703.793 40.4292C703.854 40.2813 703.944 40.147 704.057 40.0338C704.17 39.9206 704.305 39.8308 704.453 39.7696C704.601 39.7083 704.759 39.6768 704.919 39.6768C705.079 39.6768 705.238 39.7083 705.386 39.7696C705.534 39.8308 705.668 39.9206 705.781 40.0338C705.894 40.147 705.984 40.2813 706.045 40.4292C706.106 40.577 706.137 40.7354 706.137 40.8953C706.137 41.218 706.009 41.5276 705.78 41.7557C705.552 41.9839 705.242 42.1121 704.919 42.1121C704.596 42.1121 704.286 41.9839 704.058 41.7557C703.829 41.5276 703.701 41.218 703.701 40.8953V40.8953Z"
                fill="#4E415C"/>
            <path
                d="M519.011 42.0015C519.011 41.8416 519.042 41.6832 519.103 41.5354C519.164 41.3875 519.254 41.2532 519.367 41.14C519.48 41.0268 519.614 40.937 519.762 40.8758C519.91 40.8145 520.069 40.783 520.229 40.783C520.389 40.783 520.547 40.8145 520.695 40.8758C520.843 40.937 520.977 41.0268 521.091 41.14C521.204 41.2532 521.293 41.3875 521.354 41.5354C521.416 41.6832 521.447 41.8416 521.447 42.0015C521.447 42.3242 521.318 42.6338 521.09 42.8619C520.862 43.0901 520.552 43.2183 520.229 43.2183C519.906 43.2183 519.596 43.0901 519.367 42.8619C519.139 42.6338 519.011 42.3242 519.011 42.0015V42.0015Z"
                fill="#4E415C"/>
            <path
                d="M706.985 42.0015C706.985 41.8416 707.016 41.6832 707.078 41.5354C707.139 41.3875 707.228 41.2532 707.341 41.14C707.455 41.0268 707.589 40.937 707.737 40.8758C707.885 40.8145 708.043 40.783 708.203 40.783C708.363 40.783 708.522 40.8145 708.67 40.8758C708.818 40.937 708.952 41.0268 709.065 41.14C709.178 41.2532 709.268 41.3875 709.329 41.5354C709.39 41.6832 709.422 41.8416 709.421 42.0015C709.421 42.3242 709.293 42.6338 709.065 42.8619C708.836 43.0901 708.526 43.2183 708.203 43.2183C707.88 43.2183 707.571 43.0901 707.342 42.8619C707.114 42.6338 706.985 42.3242 706.985 42.0015V42.0015Z"
                fill="#4E415C"/>
            <path
                d="M522.311 42.9335C522.31 42.7736 522.342 42.6152 522.403 42.4674C522.464 42.3195 522.554 42.1852 522.667 42.072C522.78 41.9588 522.914 41.869 523.062 41.8078C523.21 41.7465 523.368 41.715 523.529 41.715C523.689 41.715 523.847 41.7465 523.995 41.8078C524.143 41.869 524.277 41.9588 524.39 42.072C524.504 42.1852 524.593 42.3195 524.654 42.4674C524.715 42.6152 524.747 42.7736 524.746 42.9335C524.746 43.2563 524.618 43.5658 524.39 43.7939C524.161 44.0221 523.852 44.1503 523.529 44.1503C523.205 44.1503 522.896 44.0221 522.667 43.7939C522.439 43.5658 522.311 43.2563 522.311 42.9335V42.9335Z"
                fill="#4E415C"/>
            <path
                d="M710.285 42.9335C710.285 42.7736 710.316 42.6152 710.377 42.4674C710.438 42.3195 710.528 42.1852 710.641 42.072C710.754 41.9588 710.889 41.869 711.037 41.8078C711.185 41.7465 711.343 41.715 711.503 41.715C711.663 41.715 711.822 41.7465 711.97 41.8078C712.118 41.869 712.252 41.9588 712.365 42.072C712.478 42.1852 712.568 42.3195 712.629 42.4674C712.69 42.6152 712.721 42.7736 712.721 42.9335C712.721 43.2563 712.593 43.5658 712.364 43.7939C712.136 44.0221 711.826 44.1503 711.503 44.1503C711.18 44.1503 710.87 44.0221 710.642 43.7939C710.413 43.5658 710.285 43.2563 710.285 42.9335V42.9335Z"
                fill="#4E415C"/>
            <path
                d="M525.631 43.6607C525.631 43.5009 525.662 43.3426 525.723 43.1948C525.784 43.0471 525.874 42.9129 525.987 42.7998C526.1 42.6868 526.235 42.5972 526.382 42.5361C526.53 42.475 526.689 42.4437 526.849 42.444C527.172 42.444 527.482 42.5722 527.71 42.8004C527.938 43.0285 528.067 43.338 528.067 43.6607C528.066 43.9833 527.938 44.2925 527.71 44.5206C527.481 44.7487 527.172 44.877 526.849 44.8775C526.526 44.8775 526.216 44.7493 525.988 44.5211C525.759 44.2929 525.631 43.9834 525.631 43.6607V43.6607Z"
                fill="#4E415C"/>
            <path
                d="M713.605 43.6607C713.605 43.5009 713.637 43.3426 713.698 43.1948C713.759 43.0471 713.849 42.9129 713.962 42.7998C714.075 42.6868 714.209 42.5972 714.357 42.5361C714.505 42.475 714.663 42.4437 714.823 42.444C715.146 42.444 715.456 42.5722 715.685 42.8004C715.913 43.0285 716.041 43.338 716.041 43.6607C716.041 43.9833 715.912 44.2925 715.684 44.5206C715.456 44.7487 715.146 44.877 714.823 44.8775C714.5 44.8775 714.191 44.7493 713.962 44.5211C713.734 44.2929 713.605 43.9834 713.605 43.6607V43.6607Z"
                fill="#4E415C"/>
            <path
                d="M528.959 44.1504C528.959 43.8277 529.087 43.5182 529.316 43.29C529.544 43.0618 529.854 42.9336 530.177 42.9336C530.5 42.9341 530.809 43.0624 531.038 43.2905C531.266 43.5186 531.394 43.8278 531.395 44.1504C531.395 44.4733 531.267 44.7829 531.038 45.0114C530.81 45.2399 530.5 45.3685 530.177 45.369C530.017 45.369 529.858 45.3374 529.71 45.2762C529.563 45.2149 529.428 45.1251 529.315 45.0119C529.202 44.8988 529.112 44.7644 529.051 44.6166C528.99 44.4687 528.959 44.3103 528.959 44.1504Z"
                fill="#4E415C"/>
            <path
                d="M716.934 44.1504C716.934 43.8277 717.062 43.5182 717.29 43.29C717.519 43.0618 717.829 42.9336 718.152 42.9336C718.474 42.9341 718.784 43.0624 719.012 43.2905C719.241 43.5186 719.369 43.8278 719.37 44.1504C719.37 44.4733 719.241 44.7829 719.013 45.0114C718.785 45.2399 718.475 45.3685 718.152 45.369C717.991 45.369 717.833 45.3374 717.685 45.2762C717.537 45.2149 717.403 45.1251 717.29 45.0119C717.177 44.8988 717.087 44.7644 717.026 44.6166C716.965 44.4687 716.933 44.3103 716.934 44.1504Z"
                fill="#4E415C"/>
            <path
                d="M532.288 44.3698C532.288 44.0471 532.416 43.7377 532.645 43.5095C532.873 43.2813 533.183 43.1531 533.506 43.1531C533.666 43.1528 533.825 43.1841 533.973 43.2452C534.121 43.3062 534.255 43.3958 534.368 43.5088C534.482 43.6218 534.572 43.7561 534.633 43.9038C534.694 44.0515 534.726 44.2099 534.726 44.3698C534.725 44.6927 534.597 45.0022 534.368 45.2303C534.139 45.4585 533.829 45.5866 533.506 45.5866C533.183 45.5861 532.874 45.4578 532.645 45.2297C532.417 45.0016 532.289 44.6924 532.288 44.3698Z"
                fill="#4E415C"/>
            <path
                d="M720.263 44.3698C720.263 44.0471 720.391 43.7377 720.619 43.5095C720.848 43.2813 721.158 43.1531 721.481 43.1531C721.641 43.1528 721.799 43.1841 721.947 43.2452C722.095 43.3062 722.23 43.3958 722.343 43.5088C722.456 43.6218 722.546 43.7561 722.608 43.9038C722.669 44.0515 722.7 44.2099 722.7 44.3698C722.7 44.6927 722.571 45.0022 722.343 45.2303C722.114 45.4585 721.804 45.5866 721.481 45.5866C721.158 45.5861 720.848 45.4578 720.62 45.2297C720.392 45.0016 720.263 44.6924 720.263 44.3698Z"
                fill="#4E415C"/>
            <path
                d="M535.779 44.2175C535.779 44.0577 535.81 43.8993 535.872 43.7516C535.933 43.6039 536.022 43.4696 536.136 43.3566C536.249 43.2436 536.383 43.1539 536.531 43.0929C536.679 43.0318 536.837 43.0005 536.997 43.0007C537.157 43.0005 537.316 43.0318 537.464 43.0929C537.612 43.1539 537.746 43.2436 537.859 43.3566C537.972 43.4696 538.062 43.6039 538.123 43.7516C538.184 43.8993 538.215 44.0577 538.215 44.2175C538.215 44.3774 538.184 44.5357 538.123 44.6834C538.062 44.8312 537.972 44.9654 537.859 45.0784C537.746 45.1915 537.612 45.2811 537.464 45.3422C537.316 45.4032 537.157 45.4345 536.997 45.4343C536.837 45.4345 536.679 45.4032 536.531 45.3422C536.383 45.2811 536.249 45.1915 536.136 45.0784C536.022 44.9654 535.933 44.8312 535.872 44.6834C535.81 44.5357 535.779 44.3774 535.779 44.2175V44.2175Z"
                fill="#4E415C"/>
            <path
                d="M723.754 44.2175C723.754 44.0577 723.785 43.8993 723.846 43.7516C723.907 43.6039 723.997 43.4696 724.11 43.3566C724.223 43.2436 724.358 43.1539 724.505 43.0929C724.653 43.0318 724.812 43.0005 724.972 43.0007C725.132 43.0005 725.29 43.0318 725.438 43.0929C725.586 43.1539 725.72 43.2436 725.834 43.3566C725.947 43.4696 726.036 43.6039 726.098 43.7516C726.159 43.8993 726.19 44.0577 726.19 44.2175C726.19 44.3774 726.159 44.5357 726.098 44.6834C726.036 44.8312 725.947 44.9654 725.834 45.0784C725.72 45.1915 725.586 45.2811 725.438 45.3422C725.29 45.4032 725.132 45.4345 724.972 45.4343C724.812 45.4345 724.653 45.4032 724.505 45.3422C724.358 45.2811 724.223 45.1915 724.11 45.0784C723.997 44.9654 723.907 44.8312 723.846 44.6834C723.785 44.5357 723.754 44.3774 723.754 44.2175V44.2175Z"
                fill="#4E415C"/>
            <path
                d="M539.159 43.4813C539.159 43.3215 539.191 43.1633 539.252 43.0157C539.313 42.868 539.403 42.7339 539.516 42.6209C539.629 42.5079 539.763 42.4183 539.911 42.3571C540.059 42.296 540.217 42.2645 540.377 42.2645C540.7 42.265 541.01 42.3934 541.238 42.6214C541.466 42.8495 541.595 43.1587 541.595 43.4813C541.595 43.804 541.467 44.1135 541.238 44.3417C541.01 44.5699 540.7 44.6981 540.377 44.6981C540.217 44.6983 540.059 44.667 539.911 44.6059C539.763 44.5449 539.629 44.4553 539.515 44.3422C539.402 44.2292 539.313 44.0949 539.251 43.9472C539.19 43.7995 539.159 43.6411 539.159 43.4813V43.4813Z"
                fill="#4E415C"/>
            <path
                d="M727.134 43.4813C727.134 43.3215 727.165 43.1633 727.226 43.0157C727.288 42.868 727.377 42.7339 727.491 42.6209C727.604 42.5079 727.738 42.4183 727.886 42.3571C728.033 42.296 728.192 42.2645 728.352 42.2645C728.675 42.265 728.984 42.3934 729.212 42.6214C729.441 42.8495 729.569 43.1587 729.57 43.4813C729.57 43.804 729.441 44.1135 729.213 44.3417C728.985 44.5699 728.675 44.6981 728.352 44.6981C728.192 44.6983 728.033 44.667 727.885 44.6059C727.737 44.5449 727.603 44.4553 727.49 44.3422C727.377 44.2292 727.287 44.0949 727.226 43.9472C727.165 43.7995 727.134 43.6411 727.134 43.4813V43.4813Z"
                fill="#4E415C"/>
            <path
                d="M542.313 42.2082C542.313 42.0484 542.345 41.89 542.406 41.7423C542.467 41.5946 542.557 41.4603 542.67 41.3473C542.783 41.2343 542.917 41.1447 543.065 41.0836C543.213 41.0225 543.371 40.9912 543.531 40.9915C543.691 40.9912 543.85 41.0225 543.998 41.0836C544.146 41.1447 544.28 41.2343 544.393 41.3473C544.506 41.4603 544.596 41.5946 544.657 41.7423C544.718 41.89 544.75 42.0484 544.749 42.2082C544.749 42.368 544.718 42.5262 544.657 42.6739C544.595 42.8215 544.506 42.9556 544.393 43.0686C544.28 43.1816 544.145 43.2712 543.998 43.3324C543.85 43.3935 543.691 43.425 543.531 43.425C543.372 43.425 543.213 43.3935 543.065 43.3324C542.918 43.2712 542.783 43.1816 542.67 43.0686C542.557 42.9556 542.467 42.8215 542.406 42.6739C542.345 42.5262 542.313 42.368 542.313 42.2082V42.2082Z"
                fill="#4E415C"/>
            <path
                d="M730.288 42.2082C730.288 42.0484 730.319 41.89 730.38 41.7423C730.441 41.5946 730.531 41.4603 730.644 41.3473C730.757 41.2343 730.892 41.1447 731.04 41.0836C731.188 41.0225 731.346 40.9912 731.506 40.9915C731.666 40.9912 731.825 41.0225 731.972 41.0836C732.12 41.1447 732.255 41.2343 732.368 41.3473C732.481 41.4603 732.571 41.5946 732.632 41.7423C732.693 41.89 732.724 42.0484 732.724 42.2082C732.724 42.368 732.693 42.5262 732.631 42.6739C732.57 42.8215 732.48 42.9556 732.367 43.0686C732.254 43.1816 732.12 43.2712 731.972 43.3324C731.824 43.3935 731.666 43.425 731.506 43.425C731.346 43.425 731.188 43.3935 731.04 43.3324C730.892 43.2712 730.758 43.1816 730.645 43.0686C730.532 42.9556 730.442 42.8215 730.381 42.6739C730.32 42.5262 730.288 42.368 730.288 42.2082V42.2082Z"
                fill="#4E415C"/>
            <path
                d="M545.176 40.4475C545.176 40.2875 545.207 40.1292 545.269 39.9814C545.33 39.8337 545.42 39.6995 545.533 39.5865C545.646 39.4734 545.781 39.3838 545.929 39.3228C546.077 39.2618 546.235 39.2305 546.396 39.2307C546.556 39.2307 546.714 39.2622 546.862 39.3233C547.009 39.3845 547.144 39.4741 547.257 39.5871C547.37 39.7001 547.46 39.8342 547.521 39.9819C547.582 40.1295 547.614 40.2877 547.614 40.4475C547.614 40.7702 547.485 41.0797 547.257 41.3079C547.028 41.5361 546.719 41.6642 546.396 41.6642C546.235 41.6645 546.077 41.6332 545.929 41.5722C545.781 41.5111 545.646 41.4215 545.533 41.3085C545.42 41.1955 545.33 41.0613 545.269 40.9135C545.207 40.7658 545.176 40.6074 545.176 40.4475V40.4475Z"
                fill="#4E415C"/>
            <path
                d="M733.15 40.4475C733.15 40.2875 733.182 40.1292 733.243 39.9814C733.305 39.8337 733.394 39.6995 733.508 39.5865C733.621 39.4734 733.756 39.3838 733.904 39.3228C734.051 39.2618 734.21 39.2305 734.37 39.2307C734.53 39.2307 734.688 39.2622 734.836 39.3233C734.984 39.3845 735.118 39.4741 735.231 39.5871C735.345 39.7001 735.434 39.8342 735.495 39.9819C735.557 40.1295 735.588 40.2877 735.588 40.4475C735.588 40.7702 735.46 41.0797 735.231 41.3079C735.003 41.5361 734.693 41.6642 734.37 41.6642C734.21 41.6645 734.051 41.6332 733.904 41.5722C733.756 41.5111 733.621 41.4215 733.508 41.3085C733.394 41.1955 733.305 41.0613 733.243 40.9135C733.182 40.7658 733.15 40.6074 733.15 40.4475V40.4475Z"
                fill="#4E415C"/>
            <path
                d="M547.701 38.2532C547.701 37.9304 547.829 37.6207 548.058 37.3922C548.286 37.1637 548.596 37.0351 548.919 37.0347C549.243 37.0347 549.553 37.163 549.782 37.3916C550.01 37.6201 550.139 37.9301 550.139 38.2532C550.138 38.5761 550.01 38.8856 549.781 39.1137C549.552 39.3419 549.242 39.47 548.919 39.47C548.596 39.4695 548.287 39.3412 548.058 39.1131C547.83 38.885 547.702 38.5758 547.701 38.2532V38.2532Z"
                fill="#4E415C"/>
            <path
                d="M735.676 38.2532C735.676 37.9304 735.804 37.6207 736.032 37.3922C736.261 37.1637 736.571 37.0351 736.894 37.0347C737.217 37.0347 737.528 37.163 737.756 37.3916C737.985 37.6201 738.114 37.9301 738.114 38.2532C738.113 38.5761 737.984 38.8856 737.756 39.1137C737.527 39.3419 737.217 39.47 736.894 39.47C736.571 39.4695 736.261 39.3412 736.033 39.1131C735.805 38.885 735.676 38.5758 735.676 38.2532V38.2532Z"
                fill="#4E415C"/>
            <path
                d="M549.836 35.6747C549.836 35.3521 549.965 35.0429 550.193 34.8148C550.422 34.5867 550.731 34.4584 551.054 34.4579C551.214 34.4579 551.372 34.4894 551.52 34.5505C551.668 34.6117 551.802 34.7013 551.915 34.8143C552.028 34.9273 552.118 35.0614 552.179 35.209C552.24 35.3566 552.272 35.5149 552.272 35.6747C552.272 35.8346 552.241 35.993 552.18 36.1409C552.119 36.2887 552.029 36.423 551.916 36.5362C551.803 36.6494 551.668 36.7392 551.52 36.8005C551.373 36.8617 551.214 36.8933 551.054 36.8933C550.731 36.8928 550.421 36.7642 550.193 36.5357C549.964 36.3072 549.836 35.9975 549.836 35.6747V35.6747Z"
                fill="#4E415C"/>
            <path
                d="M737.811 35.6747C737.811 35.3521 737.939 35.0429 738.168 34.8148C738.396 34.5867 738.706 34.4584 739.029 34.4579C739.188 34.4579 739.347 34.4894 739.495 34.5505C739.642 34.6117 739.777 34.7013 739.89 34.8143C740.003 34.9273 740.093 35.0614 740.154 35.209C740.215 35.3566 740.246 35.5149 740.246 35.6747C740.247 35.8346 740.215 35.993 740.154 36.1409C740.093 36.2887 740.004 36.423 739.89 36.5362C739.777 36.6494 739.643 36.7392 739.495 36.8005C739.347 36.8617 739.189 36.8933 739.029 36.8933C738.705 36.8928 738.396 36.7642 738.167 36.5357C737.939 36.3072 737.811 35.9975 737.811 35.6747V35.6747Z"
                fill="#4E415C"/>
            <path
                d="M551.527 32.7643C551.527 32.4415 551.656 32.1321 551.884 31.9039C552.112 31.6757 552.422 31.5475 552.745 31.5475C552.905 31.5475 553.064 31.579 553.211 31.6401C553.359 31.7013 553.493 31.7909 553.607 31.9039C553.72 32.0169 553.809 32.151 553.871 32.2986C553.932 32.4462 553.963 32.6045 553.963 32.7643C553.964 32.9241 553.932 33.0824 553.871 33.2302C553.81 33.3779 553.72 33.5121 553.607 33.6252C553.494 33.7382 553.36 33.8278 553.212 33.8889C553.064 33.95 552.905 33.9813 552.745 33.981C552.585 33.9813 552.427 33.95 552.279 33.8889C552.131 33.8278 551.997 33.7382 551.884 33.6252C551.77 33.5121 551.681 33.3779 551.62 33.2302C551.558 33.0824 551.527 32.9241 551.527 32.7643Z"
                fill="#4E415C"/>
            <path
                d="M739.502 32.7643C739.502 32.4415 739.63 32.1321 739.859 31.9039C740.087 31.6757 740.397 31.5475 740.72 31.5475C740.88 31.5475 741.038 31.579 741.186 31.6401C741.334 31.7013 741.468 31.7909 741.581 31.9039C741.694 32.0169 741.784 32.151 741.845 32.2986C741.906 32.4462 741.938 32.6045 741.938 32.7643C741.938 32.9241 741.907 33.0824 741.846 33.2302C741.785 33.3779 741.695 33.5121 741.582 33.6252C741.469 33.7382 741.334 33.8278 741.186 33.8889C741.038 33.95 740.88 33.9813 740.72 33.981C740.56 33.9813 740.401 33.95 740.254 33.8889C740.106 33.8278 739.971 33.7382 739.858 33.6252C739.745 33.5121 739.655 33.3779 739.594 33.2302C739.533 33.0824 739.502 32.9241 739.502 32.7643Z"
                fill="#4E415C"/>
            <path
                d="M552.724 29.5818C552.724 29.2592 552.853 28.95 553.081 28.7219C553.309 28.4938 553.619 28.3655 553.942 28.365C554.265 28.365 554.575 28.4931 554.803 28.7213C555.032 28.9494 555.161 29.2589 555.161 29.5818C555.161 29.7417 555.13 29.9001 555.068 30.0478C555.007 30.1956 554.917 30.3298 554.804 30.4428C554.691 30.5558 554.556 30.6454 554.408 30.7064C554.26 30.7675 554.102 30.7988 553.942 30.7985C553.619 30.7985 553.309 30.6703 553.08 30.4422C552.852 30.214 552.724 29.9045 552.724 29.5818V29.5818Z"
                fill="#4E415C"/>
            <path
                d="M740.698 29.5818C740.699 29.2592 740.827 28.95 741.056 28.7219C741.284 28.4938 741.593 28.3655 741.916 28.365C742.239 28.365 742.549 28.4931 742.778 28.7213C743.007 28.9494 743.136 29.2589 743.136 29.5818C743.136 29.7417 743.104 29.9001 743.043 30.0478C742.982 30.1956 742.892 30.3298 742.779 30.4428C742.665 30.5558 742.531 30.6454 742.383 30.7064C742.235 30.7675 742.076 30.7988 741.916 30.7985C741.593 30.7985 741.283 30.6703 741.055 30.4422C740.827 30.214 740.698 29.9045 740.698 29.5818V29.5818Z"
                fill="#4E415C"/>
            <path
                d="M553.375 26.1707C553.375 26.0108 553.406 25.8524 553.467 25.7045C553.528 25.5567 553.618 25.4223 553.731 25.3092C553.844 25.196 553.979 25.1062 554.126 25.0449C554.274 24.9837 554.433 24.9521 554.593 24.9521C554.916 24.9526 555.226 25.0812 555.454 25.3097C555.683 25.5382 555.811 25.8479 555.811 26.1707C555.81 26.4933 555.682 26.8025 555.454 27.0306C555.225 27.2587 554.916 27.387 554.593 27.3875C554.27 27.3875 553.96 27.2593 553.732 27.0311C553.503 26.8029 553.375 26.4934 553.375 26.1707V26.1707Z"
                fill="#4E415C"/>
            <path
                d="M741.35 26.1707C741.349 26.0108 741.381 25.8524 741.442 25.7045C741.503 25.5567 741.593 25.4223 741.706 25.3092C741.819 25.196 741.953 25.1062 742.101 25.0449C742.249 24.9837 742.407 24.9521 742.568 24.9521C742.891 24.9526 743.201 25.0812 743.429 25.3097C743.657 25.5382 743.786 25.8479 743.786 26.1707C743.785 26.4933 743.657 26.8025 743.428 27.0306C743.2 27.2587 742.89 27.387 742.568 27.3875C742.245 27.3875 741.935 27.2593 741.706 27.0311C741.478 26.8029 741.35 26.4934 741.35 26.1707V26.1707Z"
                fill="#4E415C"/>
            <path
                d="M553.428 22.6093C553.428 22.2866 553.556 21.9772 553.784 21.749C554.013 21.5208 554.323 21.3926 554.646 21.3926C554.968 21.394 555.276 21.5228 555.503 21.7509C555.731 21.9789 555.858 22.2876 555.858 22.6093C555.858 22.7692 555.827 22.9275 555.766 23.0753C555.705 23.223 555.615 23.3572 555.502 23.4703C555.389 23.5833 555.255 23.6729 555.107 23.734C554.959 23.795 554.8 23.8264 554.64 23.8261C554.318 23.8252 554.009 23.6965 553.782 23.4684C553.555 23.2402 553.427 22.9313 553.428 22.6093V22.6093Z"
                fill="#4E415C"/>
            <path
                d="M741.402 22.6093C741.402 22.2866 741.531 21.9772 741.759 21.749C741.987 21.5208 742.297 21.3926 742.62 21.3926C742.942 21.394 743.251 21.5228 743.478 21.7509C743.705 21.9789 743.833 22.2876 743.833 22.6093C743.833 22.7692 743.802 22.9275 743.741 23.0753C743.679 23.223 743.59 23.3572 743.477 23.4703C743.363 23.5833 743.229 23.6729 743.081 23.734C742.933 23.795 742.775 23.8264 742.615 23.8261C742.293 23.8252 741.984 23.6965 741.757 23.4684C741.529 23.2402 741.402 22.9313 741.402 22.6093V22.6093Z"
                fill="#4E415C"/>
            <path
                d="M552.876 19.1639C552.876 19.0041 552.907 18.8459 552.969 18.6983C553.03 18.5507 553.12 18.4165 553.233 18.3035C553.346 18.1905 553.48 18.1009 553.628 18.0398C553.776 17.9786 553.934 17.9471 554.094 17.9471C554.254 17.9467 554.413 17.9778 554.561 18.0387C554.709 18.0997 554.844 18.1893 554.957 18.3023C555.07 18.4154 555.16 18.5497 555.221 18.6976C555.283 18.8454 555.314 19.0039 555.314 19.1639C555.314 19.3238 555.283 19.4821 555.222 19.6298C555.16 19.7776 555.071 19.9118 554.958 20.0248C554.844 20.1379 554.71 20.2275 554.562 20.2886C554.414 20.3496 554.256 20.3809 554.096 20.3807C553.936 20.3809 553.777 20.3496 553.629 20.2886C553.481 20.2276 553.347 20.1379 553.233 20.0249C553.12 19.9119 553.03 19.7777 552.969 19.63C552.908 19.4822 552.876 19.3239 552.876 19.1639V19.1639Z"
                fill="#4E415C"/>
            <path
                d="M740.851 19.1639C740.851 19.0041 740.882 18.8459 740.943 18.6983C741.005 18.5507 741.094 18.4165 741.207 18.3035C741.32 18.1905 741.455 18.1009 741.602 18.0398C741.75 17.9786 741.909 17.9471 742.069 17.9471C742.229 17.9467 742.387 17.9778 742.535 18.0387C742.684 18.0997 742.818 18.1893 742.931 18.3023C743.045 18.4154 743.135 18.5497 743.196 18.6976C743.257 18.8454 743.289 19.0039 743.288 19.1639C743.289 19.3238 743.257 19.4821 743.196 19.6298C743.135 19.7776 743.045 19.9118 742.932 20.0248C742.819 20.1379 742.685 20.2275 742.537 20.2886C742.389 20.3496 742.23 20.3809 742.07 20.3807C741.91 20.3809 741.752 20.3496 741.604 20.2886C741.456 20.2276 741.321 20.1379 741.208 20.0249C741.095 19.9119 741.005 19.7777 740.943 19.63C740.882 19.4822 740.851 19.3239 740.851 19.1639V19.1639Z"
                fill="#4E415C"/>
            <path
                d="M551.769 15.9343C551.769 15.6116 551.897 15.3021 552.125 15.0739C552.354 14.8457 552.664 14.7175 552.987 14.7175C553.147 14.7173 553.305 14.7486 553.453 14.8096C553.601 14.8707 553.736 14.9603 553.849 15.0733C553.962 15.1863 554.052 15.3205 554.113 15.4682C554.175 15.616 554.206 15.7744 554.206 15.9343C554.206 16.2572 554.077 16.5667 553.848 16.7948C553.62 17.0229 553.31 17.1511 552.987 17.1511C552.664 17.1506 552.354 17.0223 552.126 16.7942C551.898 16.5661 551.769 16.2569 551.769 15.9343V15.9343Z"
                fill="#4E415C"/>
            <path
                d="M739.743 15.9343C739.743 15.6116 739.871 15.3021 740.1 15.0739C740.328 14.8457 740.638 14.7175 740.961 14.7175C741.121 14.7173 741.28 14.7486 741.428 14.8096C741.576 14.8707 741.71 14.9603 741.824 15.0733C741.937 15.1863 742.027 15.3205 742.088 15.4682C742.149 15.616 742.181 15.7744 742.181 15.9343C742.18 16.2572 742.052 16.5667 741.823 16.7948C741.594 17.0229 741.284 17.1511 740.961 17.1511C740.638 17.1506 740.329 17.0223 740.1 16.7942C739.872 16.5661 739.744 16.2569 739.743 15.9343V15.9343Z"
                fill="#4E415C"/>
            <path
                d="M550.158 12.9748C550.158 12.815 550.19 12.6568 550.251 12.5092C550.312 12.3616 550.402 12.2274 550.515 12.1144C550.628 12.0015 550.762 11.9118 550.91 11.8507C551.058 11.7895 551.216 11.7581 551.376 11.7581C551.536 11.7578 551.695 11.7891 551.843 11.8502C551.99 11.9113 552.125 12.0009 552.238 12.1139C552.351 12.227 552.441 12.3612 552.502 12.5089C552.563 12.6566 552.594 12.815 552.594 12.9748C552.594 13.1347 552.563 13.293 552.502 13.4407C552.441 13.5885 552.351 13.7227 552.238 13.8358C552.125 13.9488 551.99 14.0384 551.843 14.0995C551.695 14.1605 551.536 14.1918 551.376 14.1916C551.216 14.1916 551.058 14.1601 550.91 14.099C550.762 14.0378 550.628 13.9482 550.515 13.8352C550.402 13.7222 550.312 13.5881 550.251 13.4405C550.19 13.2928 550.158 13.1346 550.158 12.9748V12.9748Z"
                fill="#4E415C"/>
            <path
                d="M738.133 12.9748C738.133 12.815 738.164 12.6568 738.226 12.5092C738.287 12.3616 738.376 12.2274 738.49 12.1144C738.603 12.0015 738.737 11.9118 738.885 11.8507C739.032 11.7895 739.191 11.7581 739.351 11.7581C739.511 11.7578 739.669 11.7891 739.817 11.8502C739.965 11.9113 740.099 12.0009 740.213 12.1139C740.326 12.227 740.415 12.3612 740.477 12.5089C740.538 12.6566 740.569 12.815 740.569 12.9748C740.569 13.1347 740.538 13.293 740.477 13.4407C740.415 13.5885 740.326 13.7227 740.213 13.8358C740.099 13.9488 739.965 14.0384 739.817 14.0995C739.669 14.1605 739.511 14.1918 739.351 14.1916C739.191 14.1916 739.032 14.1601 738.885 14.099C738.737 14.0378 738.603 13.9482 738.49 13.8352C738.376 13.7222 738.287 13.5881 738.226 13.4405C738.164 13.2928 738.133 13.1346 738.133 12.9748V12.9748Z"
                fill="#4E415C"/>
            <path
                d="M549.317 11.555C549.989 11.555 550.535 11.0102 550.535 10.3382C550.535 9.66623 549.989 9.12146 549.317 9.12146C548.644 9.12146 548.099 9.66623 548.099 10.3382C548.099 11.0102 548.644 11.555 549.317 11.555Z"
                fill="#4E415C"/>
            <path
                d="M737.291 11.555C737.964 11.555 738.509 11.0102 738.509 10.3382C738.509 9.66623 737.964 9.12146 737.291 9.12146C736.619 9.12146 736.073 9.66623 736.073 10.3382C736.073 11.0102 736.619 11.555 737.291 11.555Z"
                fill="#4E415C"/>
            <path
                d="M545.635 8.07333C545.635 7.75077 545.764 7.44157 545.992 7.21349C546.22 6.9854 546.53 6.85705 546.853 6.85657C547.176 6.85657 547.486 6.98477 547.714 7.21296C547.942 7.44114 548.071 7.75063 548.071 8.07333C548.071 8.23319 548.04 8.39154 547.978 8.53927C547.917 8.68701 547.828 8.82123 547.715 8.93427C547.601 9.0473 547.467 9.13691 547.319 9.19797C547.171 9.25904 547.013 9.29036 546.853 9.29012C546.53 9.29012 546.22 9.16192 545.992 8.93373C545.763 8.70555 545.635 8.39604 545.635 8.07333V8.07333Z"
                fill="#4E415C"/>
            <path
                d="M733.609 8.07333C733.61 7.75077 733.738 7.44157 733.967 7.21349C734.195 6.9854 734.504 6.85705 734.827 6.85657C735.15 6.85657 735.46 6.98477 735.689 7.21296C735.917 7.44114 736.045 7.75063 736.045 8.07333C736.046 8.23319 736.014 8.39154 735.953 8.53927C735.892 8.68701 735.802 8.82123 735.689 8.93427C735.576 9.0473 735.442 9.13691 735.294 9.19797C735.146 9.25904 734.987 9.29036 734.827 9.29012C734.504 9.29012 734.195 9.16192 733.966 8.93373C733.738 8.70555 733.609 8.39604 733.609 8.07333V8.07333Z"
                fill="#4E415C"/>
            <path
                d="M542.827 6.23459C542.827 5.91188 542.955 5.6024 543.184 5.37421C543.412 5.14602 543.722 5.01782 544.045 5.01782C544.368 5.0183 544.678 5.14666 544.906 5.37474C545.134 5.60283 545.263 5.91203 545.263 6.23459C545.263 6.5573 545.135 6.8668 544.906 7.09499C544.678 7.32318 544.368 7.45138 544.045 7.45138C543.885 7.45162 543.727 7.42029 543.579 7.35923C543.431 7.29816 543.296 7.20856 543.183 7.09552C543.07 6.98249 542.98 6.84826 542.919 6.70053C542.858 6.55279 542.827 6.39445 542.827 6.23459V6.23459Z"
                fill="#4E415C"/>
            <path
                d="M730.802 6.23459C730.802 5.91188 730.93 5.6024 731.158 5.37421C731.387 5.14602 731.697 5.01782 732.02 5.01782C732.343 5.0183 732.652 5.14666 732.88 5.37474C733.109 5.60283 733.237 5.91203 733.238 6.23459C733.238 6.5573 733.109 6.8668 732.881 7.09499C732.653 7.32318 732.343 7.45138 732.02 7.45138C731.86 7.45162 731.701 7.42029 731.553 7.35923C731.405 7.29816 731.271 7.20856 731.158 7.09552C731.045 6.98249 730.955 6.84826 730.894 6.70053C730.833 6.55279 730.802 6.39445 730.802 6.23459V6.23459Z"
                fill="#4E415C"/>
            <path
                d="M539.722 4.87447C539.722 4.55159 539.85 4.24192 540.078 4.01345C540.307 3.78497 540.616 3.65636 540.94 3.65588C541.1 3.65588 541.258 3.68742 541.406 3.74868C541.554 3.80994 541.688 3.89973 541.802 4.01291C541.915 4.12609 542.004 4.26045 542.065 4.40828C542.127 4.55612 542.158 4.71452 542.158 4.87447C542.158 5.19717 542.029 5.50668 541.801 5.73487C541.572 5.96306 541.263 6.09125 540.94 6.09125C540.617 6.09077 540.307 5.96242 540.079 5.73433C539.851 5.50625 539.722 5.19703 539.722 4.87447V4.87447Z"
                fill="#4E415C"/>
            <path
                d="M727.696 4.87447C727.696 4.55159 727.825 4.24192 728.053 4.01345C728.281 3.78497 728.591 3.65636 728.914 3.65588C729.074 3.65588 729.233 3.68742 729.381 3.74868C729.529 3.80994 729.663 3.89973 729.776 4.01291C729.889 4.12609 729.979 4.26045 730.04 4.40828C730.101 4.55612 730.132 4.71452 730.132 4.87447C730.132 5.19717 730.004 5.50668 729.775 5.73487C729.547 5.96306 729.237 6.09125 728.914 6.09125C728.591 6.09077 728.282 5.96242 728.054 5.73433C727.825 5.50625 727.697 5.19703 727.696 4.87447V4.87447Z"
                fill="#4E415C"/>
            <path
                d="M536.374 4.04221C536.374 3.88235 536.405 3.72402 536.466 3.57629C536.527 3.42856 536.617 3.29433 536.73 3.1813C536.843 3.06826 536.978 2.97863 537.126 2.91757C537.274 2.85651 537.432 2.8252 537.592 2.82544C537.915 2.82544 538.225 2.95364 538.453 3.18183C538.682 3.41002 538.81 3.7195 538.81 4.04221C538.809 4.36477 538.681 4.67399 538.453 4.90208C538.224 5.13016 537.915 5.25849 537.592 5.25897C537.269 5.25897 536.959 5.1308 536.731 4.90261C536.502 4.67442 536.374 4.36492 536.374 4.04221V4.04221Z"
                fill="#4E415C"/>
            <path
                d="M724.349 4.04221C724.348 3.88235 724.38 3.72402 724.441 3.57629C724.502 3.42856 724.592 3.29433 724.705 3.1813C724.818 3.06826 724.952 2.97863 725.1 2.91757C725.248 2.85651 725.407 2.8252 725.567 2.82544C725.89 2.82544 726.199 2.95364 726.428 3.18183C726.656 3.41002 726.785 3.7195 726.785 4.04221C726.784 4.36477 726.656 4.67399 726.427 4.90208C726.199 5.13016 725.889 5.25849 725.567 5.25897C725.244 5.25897 724.934 5.1308 724.705 4.90261C724.477 4.67442 724.349 4.36492 724.349 4.04221V4.04221Z"
                fill="#4E415C"/>
            <path
                d="M532.852 3.7883C532.852 3.46559 532.98 3.15611 533.208 2.92792C533.437 2.69973 533.747 2.57153 534.07 2.57153C534.23 2.5713 534.388 2.6026 534.536 2.66366C534.684 2.72473 534.818 2.81436 534.931 2.92739C535.044 3.04043 535.134 3.17465 535.195 3.32238C535.256 3.47012 535.288 3.62845 535.287 3.7883C535.288 3.94816 535.256 4.10651 535.195 4.25424C535.134 4.40197 535.044 4.5362 534.931 4.64923C534.818 4.76227 534.684 4.85187 534.536 4.91294C534.388 4.974 534.23 5.00533 534.07 5.00509C533.747 5.00509 533.437 4.87689 533.208 4.6487C532.98 4.42051 532.852 4.11101 532.852 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M720.826 3.7883C720.826 3.46559 720.954 3.15611 721.183 2.92792C721.411 2.69973 721.721 2.57153 722.044 2.57153C722.204 2.5713 722.363 2.6026 722.511 2.66366C722.658 2.72473 722.793 2.81436 722.906 2.92739C723.019 3.04043 723.109 3.17465 723.17 3.32238C723.231 3.47012 723.262 3.62845 723.262 3.7883C723.262 3.94816 723.231 4.10651 723.17 4.25424C723.109 4.40197 723.019 4.5362 722.906 4.64923C722.793 4.76227 722.658 4.85187 722.511 4.91294C722.363 4.974 722.204 5.00533 722.044 5.00509C721.721 5.00509 721.411 4.87689 721.183 4.6487C720.954 4.42051 720.826 4.11101 720.826 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M529.428 3.7883C529.428 3.46559 529.556 3.15611 529.784 2.92792C530.013 2.69973 530.323 2.57153 530.646 2.57153C530.806 2.5713 530.964 2.6026 531.112 2.66364C531.26 2.72468 531.395 2.81427 531.508 2.92728C531.621 3.04029 531.711 3.17451 531.773 3.32225C531.834 3.47 531.865 3.62836 531.865 3.7883C531.865 3.94824 531.834 4.10663 531.773 4.25437C531.711 4.40212 531.621 4.53633 531.508 4.64934C531.395 4.76236 531.26 4.85194 531.112 4.91298C530.964 4.97402 530.806 5.00533 530.646 5.00509C530.323 5.00509 530.013 4.87689 529.784 4.6487C529.556 4.42051 529.428 4.11101 529.428 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M717.402 3.7883C717.402 3.46559 717.531 3.15611 717.759 2.92792C717.987 2.69973 718.297 2.57153 718.62 2.57153C718.78 2.5713 718.939 2.6026 719.087 2.66364C719.235 2.72468 719.369 2.81427 719.483 2.92728C719.596 3.04029 719.686 3.17451 719.747 3.32225C719.809 3.47 719.84 3.62836 719.84 3.7883C719.84 3.94824 719.809 4.10663 719.747 4.25437C719.686 4.40212 719.596 4.53633 719.483 4.64934C719.369 4.76236 719.235 4.85194 719.087 4.91298C718.939 4.97402 718.78 5.00533 718.62 5.00509C718.297 5.00509 717.987 4.87689 717.759 4.6487C717.531 4.42051 717.402 4.11101 717.402 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M526.006 3.7883C526.006 3.62845 526.037 3.47012 526.098 3.32238C526.159 3.17465 526.249 3.04043 526.362 2.92739C526.475 2.81436 526.61 2.72473 526.757 2.66366C526.905 2.6026 527.064 2.5713 527.224 2.57153C527.547 2.57153 527.857 2.69973 528.085 2.92792C528.313 3.15611 528.442 3.46559 528.442 3.7883C528.442 4.11101 528.313 4.42051 528.085 4.6487C527.857 4.87689 527.547 5.00509 527.224 5.00509C527.064 5.00533 526.905 4.974 526.757 4.91294C526.61 4.85187 526.475 4.76227 526.362 4.64923C526.249 4.5362 526.159 4.40197 526.098 4.25424C526.037 4.10651 526.006 3.94816 526.006 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M713.98 3.7883C713.98 3.62845 714.012 3.47012 714.073 3.32238C714.134 3.17465 714.224 3.04043 714.337 2.92739C714.45 2.81436 714.584 2.72473 714.732 2.66366C714.88 2.6026 715.038 2.5713 715.198 2.57153C715.521 2.57153 715.831 2.69973 716.06 2.92792C716.288 3.15611 716.416 3.46559 716.416 3.7883C716.416 4.11101 716.288 4.42051 716.06 4.6487C715.831 4.87689 715.521 5.00509 715.198 5.00509C715.038 5.00533 714.88 4.974 714.732 4.91294C714.584 4.85187 714.45 4.76227 714.337 4.64923C714.224 4.5362 714.134 4.40197 714.073 4.25424C714.012 4.10651 713.98 3.94816 713.98 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M523.801 5.00508C524.474 5.00508 525.019 4.46031 525.019 3.78831C525.019 3.1163 524.474 2.57153 523.801 2.57153C523.128 2.57153 522.583 3.1163 522.583 3.78831C522.583 4.46031 523.128 5.00508 523.801 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M711.776 5.00508C712.448 5.00508 712.994 4.46031 712.994 3.78831C712.994 3.1163 712.448 2.57153 711.776 2.57153C711.103 2.57153 710.558 3.1163 710.558 3.78831C710.558 4.46031 711.103 5.00508 711.776 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M520.379 5.00508C521.052 5.00508 521.597 4.46031 521.597 3.78831C521.597 3.1163 521.052 2.57153 520.379 2.57153C519.706 2.57153 519.161 3.1163 519.161 3.78831C519.161 4.46031 519.706 5.00508 520.379 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M708.354 5.00508C709.026 5.00508 709.572 4.46031 709.572 3.78831C709.572 3.1163 709.026 2.57153 708.354 2.57153C707.681 2.57153 707.136 3.1163 707.136 3.78831C707.136 4.46031 707.681 5.00508 708.354 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M515.743 3.7883C515.743 3.62836 515.775 3.47 515.836 3.32225C515.897 3.17451 515.987 3.04029 516.101 2.92728C516.214 2.81427 516.348 2.72468 516.496 2.66364C516.644 2.6026 516.803 2.5713 516.963 2.57153C517.286 2.57153 517.596 2.69973 517.824 2.92792C518.053 3.15611 518.181 3.46559 518.181 3.7883C518.181 4.11101 518.053 4.42051 517.824 4.6487C517.596 4.87689 517.286 5.00509 516.963 5.00509C516.803 5.00533 516.644 4.97402 516.496 4.91298C516.348 4.85194 516.214 4.76236 516.101 4.64934C515.987 4.53633 515.897 4.40212 515.836 4.25437C515.775 4.10663 515.743 3.94824 515.743 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M703.718 3.7883C703.718 3.62836 703.749 3.47 703.811 3.32225C703.872 3.17451 703.962 3.04029 704.075 2.92728C704.188 2.81427 704.323 2.72468 704.471 2.66364C704.619 2.6026 704.777 2.5713 704.938 2.57153C705.261 2.57153 705.57 2.69973 705.799 2.92792C706.027 3.15611 706.156 3.46559 706.156 3.7883C706.156 4.11101 706.027 4.42051 705.799 4.6487C705.57 4.87689 705.261 5.00509 704.938 5.00509C704.777 5.00533 704.619 4.97402 704.471 4.91298C704.323 4.85194 704.188 4.76236 704.075 4.64934C703.962 4.53633 703.872 4.40212 703.811 4.25437C703.749 4.10663 703.718 3.94824 703.718 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M512.314 3.7883C512.314 3.62845 512.346 3.47012 512.407 3.32238C512.468 3.17465 512.558 3.04043 512.671 2.92739C512.784 2.81436 512.918 2.72473 513.066 2.66366C513.214 2.6026 513.372 2.5713 513.532 2.57153C513.692 2.5713 513.851 2.6026 513.999 2.66366C514.147 2.72473 514.281 2.81436 514.394 2.92739C514.507 3.04043 514.597 3.17465 514.658 3.32238C514.719 3.47012 514.751 3.62845 514.75 3.7883C514.751 3.94816 514.719 4.10651 514.658 4.25424C514.597 4.40197 514.507 4.5362 514.394 4.64923C514.281 4.76227 514.147 4.85187 513.999 4.91294C513.851 4.974 513.692 5.00533 513.532 5.00509C513.372 5.00533 513.214 4.974 513.066 4.91294C512.918 4.85187 512.784 4.76227 512.671 4.64923C512.558 4.5362 512.468 4.40197 512.407 4.25424C512.346 4.10651 512.314 3.94816 512.314 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M700.289 3.7883C700.289 3.62845 700.32 3.47012 700.381 3.32238C700.442 3.17465 700.532 3.04043 700.645 2.92739C700.758 2.81436 700.893 2.72473 701.041 2.66366C701.189 2.6026 701.347 2.5713 701.507 2.57153C701.667 2.5713 701.826 2.6026 701.973 2.66366C702.121 2.72473 702.256 2.81436 702.369 2.92739C702.482 3.04043 702.572 3.17465 702.633 3.32238C702.694 3.47012 702.725 3.62845 702.725 3.7883C702.725 3.94816 702.694 4.10651 702.633 4.25424C702.572 4.40197 702.482 4.5362 702.369 4.64923C702.256 4.76227 702.121 4.85187 701.973 4.91294C701.826 4.974 701.667 5.00533 701.507 5.00509C701.347 5.00533 701.189 4.974 701.041 4.91294C700.893 4.85187 700.758 4.76227 700.645 4.64923C700.532 4.5362 700.442 4.40197 700.381 4.25424C700.32 4.10651 700.289 3.94816 700.289 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M508.891 3.7883C508.89 3.62845 508.922 3.47012 508.983 3.32238C509.044 3.17465 509.134 3.04043 509.247 2.92739C509.36 2.81436 509.494 2.72473 509.642 2.66366C509.79 2.6026 509.949 2.5713 510.109 2.57153C510.269 2.5713 510.427 2.6026 510.575 2.66366C510.723 2.72473 510.857 2.81436 510.97 2.92739C511.084 3.04043 511.173 3.17465 511.234 3.32238C511.295 3.47012 511.327 3.62845 511.327 3.7883C511.327 3.94816 511.295 4.10651 511.234 4.25424C511.173 4.40197 511.084 4.5362 510.97 4.64923C510.857 4.76227 510.723 4.85187 510.575 4.91294C510.427 4.974 510.269 5.00533 510.109 5.00509C509.949 5.00533 509.79 4.974 509.642 4.91294C509.494 4.85187 509.36 4.76227 509.247 4.64923C509.134 4.5362 509.044 4.40197 508.983 4.25424C508.922 4.10651 508.89 3.94816 508.891 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M696.865 3.7883C696.865 3.62845 696.896 3.47012 696.957 3.32238C697.019 3.17465 697.108 3.04043 697.221 2.92739C697.335 2.81436 697.469 2.72473 697.617 2.66366C697.765 2.6026 697.923 2.5713 698.083 2.57153C698.243 2.5713 698.402 2.6026 698.55 2.66366C698.697 2.72473 698.832 2.81436 698.945 2.92739C699.058 3.04043 699.148 3.17465 699.209 3.32238C699.27 3.47012 699.301 3.62845 699.301 3.7883C699.301 3.94816 699.27 4.10651 699.209 4.25424C699.148 4.40197 699.058 4.5362 698.945 4.64923C698.832 4.76227 698.697 4.85187 698.55 4.91294C698.402 4.974 698.243 5.00533 698.083 5.00509C697.923 5.00533 697.765 4.974 697.617 4.91294C697.469 4.85187 697.335 4.76227 697.221 4.64923C697.108 4.5362 697.019 4.40197 696.957 4.25424C696.896 4.10651 696.865 3.94816 696.865 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M505.468 3.7883C505.468 3.46559 505.596 3.15611 505.825 2.92792C506.053 2.69973 506.363 2.57153 506.686 2.57153C506.846 2.5713 507.004 2.6026 507.152 2.66364C507.3 2.72468 507.435 2.81427 507.548 2.92728C507.661 3.04029 507.751 3.17451 507.813 3.32225C507.874 3.47 507.906 3.62836 507.906 3.7883C507.906 3.94824 507.874 4.10663 507.813 4.25437C507.751 4.40212 507.661 4.53633 507.548 4.64934C507.435 4.76236 507.3 4.85194 507.152 4.91298C507.004 4.97402 506.846 5.00533 506.686 5.00509C506.363 5.00509 506.053 4.87689 505.825 4.6487C505.596 4.42051 505.468 4.11101 505.468 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M693.442 3.7883C693.442 3.46559 693.571 3.15611 693.799 2.92792C694.028 2.69973 694.337 2.57153 694.66 2.57153C694.82 2.5713 694.979 2.6026 695.127 2.66364C695.275 2.72468 695.409 2.81427 695.523 2.92728C695.636 3.04029 695.726 3.17451 695.787 3.32225C695.849 3.47 695.88 3.62836 695.88 3.7883C695.88 3.94824 695.849 4.10663 695.787 4.25437C695.726 4.40212 695.636 4.53633 695.523 4.64934C695.409 4.76236 695.275 4.85194 695.127 4.91298C694.979 4.97402 694.82 5.00533 694.66 5.00509C694.337 5.00509 694.028 4.87689 693.799 4.6487C693.571 4.42051 693.442 4.11101 693.442 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M503.264 5.00508C503.937 5.00508 504.482 4.46031 504.482 3.78831C504.482 3.1163 503.937 2.57153 503.264 2.57153C502.591 2.57153 502.046 3.1163 502.046 3.78831C502.046 4.46031 502.591 5.00508 503.264 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M691.238 5.00508C691.911 5.00508 692.456 4.46031 692.456 3.78831C692.456 3.1163 691.911 2.57153 691.238 2.57153C690.566 2.57153 690.021 3.1163 690.021 3.78831C690.021 4.46031 690.566 5.00508 691.238 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M498.623 3.7883C498.623 3.62845 498.654 3.47012 498.715 3.32238C498.776 3.17465 498.866 3.04043 498.979 2.92739C499.092 2.81436 499.227 2.72473 499.375 2.66366C499.523 2.6026 499.681 2.5713 499.841 2.57153C500.001 2.5713 500.16 2.6026 500.307 2.66366C500.455 2.72473 500.59 2.81436 500.703 2.92739C500.816 3.04043 500.906 3.17465 500.967 3.32238C501.028 3.47012 501.059 3.62845 501.059 3.7883C501.059 3.94816 501.028 4.10651 500.967 4.25424C500.906 4.40197 500.816 4.5362 500.703 4.64923C500.59 4.76227 500.455 4.85187 500.307 4.91294C500.16 4.974 500.001 5.00533 499.841 5.00509C499.681 5.00533 499.523 4.974 499.375 4.91294C499.227 4.85187 499.092 4.76227 498.979 4.64923C498.866 4.5362 498.776 4.40197 498.715 4.25424C498.654 4.10651 498.623 3.94816 498.623 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M686.598 3.7883C686.597 3.62845 686.629 3.47012 686.69 3.32238C686.751 3.17465 686.841 3.04043 686.954 2.92739C687.067 2.81436 687.201 2.72473 687.349 2.66366C687.497 2.6026 687.656 2.5713 687.816 2.57153C687.976 2.5713 688.134 2.6026 688.282 2.66366C688.43 2.72473 688.564 2.81436 688.677 2.92739C688.791 3.04043 688.88 3.17465 688.941 3.32238C689.002 3.47012 689.034 3.62845 689.034 3.7883C689.034 3.94816 689.002 4.10651 688.941 4.25424C688.88 4.40197 688.791 4.5362 688.677 4.64923C688.564 4.76227 688.43 4.85187 688.282 4.91294C688.134 4.974 687.976 5.00533 687.816 5.00509C687.656 5.00533 687.497 4.974 687.349 4.91294C687.201 4.85187 687.067 4.76227 686.954 4.64923C686.841 4.5362 686.751 4.40197 686.69 4.25424C686.629 4.10651 686.597 3.94816 686.598 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M495.199 3.7883C495.199 3.46559 495.328 3.15611 495.556 2.92792C495.784 2.69973 496.094 2.57153 496.417 2.57153C496.577 2.5713 496.736 2.6026 496.884 2.66366C497.031 2.72473 497.166 2.81436 497.279 2.92739C497.392 3.04043 497.482 3.17465 497.543 3.32238C497.604 3.47012 497.635 3.62845 497.635 3.7883C497.635 3.94816 497.604 4.10651 497.543 4.25424C497.482 4.40197 497.392 4.5362 497.279 4.64923C497.166 4.76227 497.031 4.85187 496.884 4.91294C496.736 4.974 496.577 5.00533 496.417 5.00509C496.094 5.00509 495.784 4.87689 495.556 4.6487C495.328 4.42051 495.199 4.11101 495.199 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M683.174 3.7883C683.174 3.46559 683.302 3.15611 683.531 2.92792C683.759 2.69973 684.069 2.57153 684.392 2.57153C684.552 2.5713 684.71 2.6026 684.858 2.66366C685.006 2.72473 685.14 2.81436 685.254 2.92739C685.367 3.04043 685.456 3.17465 685.518 3.32238C685.579 3.47012 685.61 3.62845 685.61 3.7883C685.61 3.94816 685.579 4.10651 685.518 4.25424C685.456 4.40197 685.367 4.5362 685.254 4.64923C685.14 4.76227 685.006 4.85187 684.858 4.91294C684.71 4.974 684.552 5.00533 684.392 5.00509C684.069 5.00509 683.759 4.87689 683.531 4.6487C683.302 4.42051 683.174 4.11101 683.174 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M492.995 5.00508C493.668 5.00508 494.213 4.46031 494.213 3.78831C494.213 3.1163 493.668 2.57153 492.995 2.57153C492.323 2.57153 491.777 3.1163 491.777 3.78831C491.777 4.46031 492.323 5.00508 492.995 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M680.97 5.00508C681.643 5.00508 682.188 4.46031 682.188 3.78831C682.188 3.1163 681.643 2.57153 680.97 2.57153C680.297 2.57153 679.752 3.1163 679.752 3.78831C679.752 4.46031 680.297 5.00508 680.97 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M488.354 3.7883C488.354 3.62845 488.386 3.47012 488.447 3.32238C488.508 3.17465 488.598 3.04043 488.711 2.92739C488.824 2.81436 488.958 2.72473 489.106 2.66366C489.254 2.6026 489.412 2.5713 489.572 2.57153C489.895 2.57153 490.205 2.69973 490.434 2.92792C490.662 3.15611 490.79 3.46559 490.79 3.7883C490.79 4.11101 490.662 4.42051 490.434 4.6487C490.205 4.87689 489.895 5.00509 489.572 5.00509C489.412 5.00533 489.254 4.974 489.106 4.91294C488.958 4.85187 488.824 4.76227 488.711 4.64923C488.598 4.5362 488.508 4.40197 488.447 4.25424C488.386 4.10651 488.354 3.94816 488.354 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M676.329 3.7883C676.329 3.62845 676.36 3.47012 676.421 3.32238C676.482 3.17465 676.572 3.04043 676.685 2.92739C676.798 2.81436 676.933 2.72473 677.081 2.66366C677.229 2.6026 677.387 2.5713 677.547 2.57153C677.87 2.57153 678.18 2.69973 678.408 2.92792C678.637 3.15611 678.765 3.46559 678.765 3.7883C678.765 4.11101 678.637 4.42051 678.408 4.6487C678.18 4.87689 677.87 5.00509 677.547 5.00509C677.387 5.00533 677.229 4.974 677.081 4.91294C676.933 4.85187 676.798 4.76227 676.685 4.64923C676.572 4.5362 676.482 4.40197 676.421 4.25424C676.36 4.10651 676.329 3.94816 676.329 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M484.931 3.7883C484.93 3.62845 484.962 3.47012 485.023 3.32238C485.084 3.17465 485.174 3.04043 485.287 2.92739C485.4 2.81436 485.534 2.72473 485.682 2.66366C485.83 2.6026 485.989 2.5713 486.149 2.57153C486.309 2.5713 486.467 2.6026 486.615 2.66366C486.763 2.72473 486.897 2.81436 487.01 2.92739C487.124 3.04043 487.213 3.17465 487.274 3.32238C487.335 3.47012 487.367 3.62845 487.367 3.7883C487.367 3.94816 487.335 4.10651 487.274 4.25424C487.213 4.40197 487.124 4.5362 487.01 4.64923C486.897 4.76227 486.763 4.85187 486.615 4.91294C486.467 4.974 486.309 5.00533 486.149 5.00509C485.989 5.00533 485.83 4.974 485.682 4.91294C485.534 4.85187 485.4 4.76227 485.287 4.64923C485.174 4.5362 485.084 4.40197 485.023 4.25424C484.962 4.10651 484.93 3.94816 484.931 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M672.905 3.7883C672.905 3.62845 672.936 3.47012 672.997 3.32238C673.059 3.17465 673.148 3.04043 673.261 2.92739C673.375 2.81436 673.509 2.72473 673.657 2.66366C673.805 2.6026 673.963 2.5713 674.123 2.57153C674.283 2.5713 674.442 2.6026 674.59 2.66366C674.738 2.72473 674.872 2.81436 674.985 2.92739C675.098 3.04043 675.188 3.17465 675.249 3.32238C675.31 3.47012 675.341 3.62845 675.341 3.7883C675.341 3.94816 675.31 4.10651 675.249 4.25424C675.188 4.40197 675.098 4.5362 674.985 4.64923C674.872 4.76227 674.738 4.85187 674.59 4.91294C674.442 4.974 674.283 5.00533 674.123 5.00509C673.963 5.00533 673.805 4.974 673.657 4.91294C673.509 4.85187 673.375 4.76227 673.261 4.64923C673.148 4.5362 673.059 4.40197 672.997 4.25424C672.936 4.10651 672.905 3.94816 672.905 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M482.728 5.00508C483.4 5.00508 483.946 4.46031 483.946 3.78831C483.946 3.1163 483.4 2.57153 482.728 2.57153C482.055 2.57153 481.51 3.1163 481.51 3.78831C481.51 4.46031 482.055 5.00508 482.728 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M670.702 5.00508C671.375 5.00508 671.92 4.46031 671.92 3.78831C671.92 3.1163 671.375 2.57153 670.702 2.57153C670.03 2.57153 669.484 3.1163 669.484 3.78831C669.484 4.46031 670.03 5.00508 670.702 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M478.086 3.7883C478.086 3.62845 478.117 3.47012 478.178 3.32238C478.239 3.17465 478.329 3.04043 478.442 2.92739C478.555 2.81436 478.69 2.72473 478.838 2.66366C478.985 2.6026 479.144 2.5713 479.304 2.57153C479.627 2.57153 479.937 2.69973 480.165 2.92792C480.394 3.15611 480.522 3.46559 480.522 3.7883C480.522 4.11101 480.394 4.42051 480.165 4.6487C479.937 4.87689 479.627 5.00509 479.304 5.00509C479.144 5.00533 478.985 4.974 478.838 4.91294C478.69 4.85187 478.555 4.76227 478.442 4.64923C478.329 4.5362 478.239 4.40197 478.178 4.25424C478.117 4.10651 478.086 3.94816 478.086 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M666.061 3.7883C666.06 3.62845 666.092 3.47012 666.153 3.32238C666.214 3.17465 666.304 3.04043 666.417 2.92739C666.53 2.81436 666.664 2.72473 666.812 2.66366C666.96 2.6026 667.118 2.5713 667.279 2.57153C667.602 2.57153 667.911 2.69973 668.14 2.92792C668.368 3.15611 668.496 3.46559 668.496 3.7883C668.496 4.11101 668.368 4.42051 668.14 4.6487C667.911 4.87689 667.602 5.00509 667.279 5.00509C667.118 5.00533 666.96 4.974 666.812 4.91294C666.664 4.85187 666.53 4.76227 666.417 4.64923C666.304 4.5362 666.214 4.40197 666.153 4.25424C666.092 4.10651 666.06 3.94816 666.061 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M474.663 3.7883C474.663 3.62845 474.694 3.47012 474.755 3.32238C474.816 3.17465 474.906 3.04043 475.019 2.92739C475.132 2.81436 475.267 2.72473 475.415 2.66366C475.563 2.6026 475.721 2.5713 475.881 2.57153C476.041 2.5713 476.2 2.6026 476.347 2.66366C476.495 2.72473 476.63 2.81436 476.743 2.92739C476.856 3.04043 476.946 3.17465 477.007 3.32238C477.068 3.47012 477.099 3.62845 477.099 3.7883C477.099 3.94816 477.068 4.10651 477.007 4.25424C476.946 4.40197 476.856 4.5362 476.743 4.64923C476.63 4.76227 476.495 4.85187 476.347 4.91294C476.2 4.974 476.041 5.00533 475.881 5.00509C475.721 5.00533 475.563 4.974 475.415 4.91294C475.267 4.85187 475.132 4.76227 475.019 4.64923C474.906 4.5362 474.816 4.40197 474.755 4.25424C474.694 4.10651 474.663 3.94816 474.663 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M662.638 3.7883C662.637 3.62845 662.669 3.47012 662.73 3.32238C662.791 3.17465 662.881 3.04043 662.994 2.92739C663.107 2.81436 663.241 2.72473 663.389 2.66366C663.537 2.6026 663.696 2.5713 663.856 2.57153C664.016 2.5713 664.174 2.6026 664.322 2.66366C664.47 2.72473 664.604 2.81436 664.717 2.92739C664.831 3.04043 664.92 3.17465 664.981 3.32238C665.043 3.47012 665.074 3.62845 665.074 3.7883C665.074 3.94816 665.043 4.10651 664.981 4.25424C664.92 4.40197 664.831 4.5362 664.717 4.64923C664.604 4.76227 664.47 4.85187 664.322 4.91294C664.174 4.974 664.016 5.00533 663.856 5.00509C663.696 5.00533 663.537 4.974 663.389 4.91294C663.241 4.85187 663.107 4.76227 662.994 4.64923C662.881 4.5362 662.791 4.40197 662.73 4.25424C662.669 4.10651 662.637 3.94816 662.638 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M471.239 3.7883C471.239 3.46559 471.368 3.15611 471.596 2.92792C471.824 2.69973 472.134 2.57153 472.457 2.57153C472.617 2.5713 472.776 2.6026 472.924 2.66366C473.071 2.72473 473.206 2.81436 473.319 2.92739C473.432 3.04043 473.522 3.17465 473.583 3.32238C473.644 3.47012 473.675 3.62845 473.675 3.7883C473.675 3.94816 473.644 4.10651 473.583 4.25424C473.522 4.40197 473.432 4.5362 473.319 4.64923C473.206 4.76227 473.071 4.85187 472.924 4.91294C472.776 4.974 472.617 5.00533 472.457 5.00509C472.134 5.00509 471.824 4.87689 471.596 4.6487C471.368 4.42051 471.239 4.11101 471.239 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M659.214 3.7883C659.214 3.46559 659.342 3.15611 659.571 2.92792C659.799 2.69973 660.109 2.57153 660.432 2.57153C660.592 2.5713 660.75 2.6026 660.898 2.66366C661.046 2.72473 661.18 2.81436 661.294 2.92739C661.407 3.04043 661.496 3.17465 661.558 3.32238C661.619 3.47012 661.65 3.62845 661.65 3.7883C661.65 3.94816 661.619 4.10651 661.558 4.25424C661.496 4.40197 661.407 4.5362 661.294 4.64923C661.18 4.76227 661.046 4.85187 660.898 4.91294C660.75 4.974 660.592 5.00533 660.432 5.00509C660.109 5.00509 659.799 4.87689 659.571 4.6487C659.342 4.42051 659.214 4.11101 659.214 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M467.823 3.7883C467.823 3.62836 467.855 3.47 467.916 3.32225C467.977 3.17451 468.067 3.04029 468.181 2.92728C468.294 2.81427 468.428 2.72468 468.576 2.66364C468.724 2.6026 468.883 2.5713 469.043 2.57153C469.366 2.57153 469.676 2.69973 469.904 2.92792C470.133 3.15611 470.261 3.46559 470.261 3.7883C470.261 4.11101 470.133 4.42051 469.904 4.6487C469.676 4.87689 469.366 5.00509 469.043 5.00509C468.883 5.00533 468.724 4.97402 468.576 4.91298C468.428 4.85194 468.294 4.76236 468.181 4.64934C468.067 4.53633 467.977 4.40212 467.916 4.25437C467.855 4.10663 467.823 3.94824 467.823 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M655.798 3.7883C655.798 3.62836 655.829 3.47 655.891 3.32225C655.952 3.17451 656.042 3.04029 656.155 2.92728C656.269 2.81427 656.403 2.72468 656.551 2.66364C656.699 2.6026 656.858 2.5713 657.018 2.57153C657.341 2.57153 657.65 2.69973 657.879 2.92792C658.107 3.15611 658.236 3.46559 658.236 3.7883C658.236 4.11101 658.107 4.42051 657.879 4.6487C657.65 4.87689 657.341 5.00509 657.018 5.00509C656.858 5.00533 656.699 4.97402 656.551 4.91298C656.403 4.85194 656.269 4.76236 656.155 4.64934C656.042 4.53633 655.952 4.40212 655.891 4.25437C655.829 4.10663 655.798 3.94824 655.798 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M464.395 3.7883C464.394 3.62845 464.426 3.47012 464.487 3.32238C464.548 3.17465 464.638 3.04043 464.751 2.92739C464.864 2.81436 464.998 2.72473 465.146 2.66366C465.294 2.6026 465.452 2.5713 465.612 2.57153C465.936 2.57153 466.245 2.69973 466.474 2.92792C466.702 3.15611 466.83 3.46559 466.83 3.7883C466.83 4.11101 466.702 4.42051 466.474 4.6487C466.245 4.87689 465.936 5.00509 465.612 5.00509C465.452 5.00533 465.294 4.974 465.146 4.91294C464.998 4.85187 464.864 4.76227 464.751 4.64923C464.638 4.5362 464.548 4.40197 464.487 4.25424C464.426 4.10651 464.394 3.94816 464.395 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M652.369 3.7883C652.369 3.62845 652.4 3.47012 652.461 3.32238C652.522 3.17465 652.612 3.04043 652.725 2.92739C652.838 2.81436 652.973 2.72473 653.121 2.66366C653.269 2.6026 653.427 2.5713 653.587 2.57153C653.91 2.57153 654.22 2.69973 654.448 2.92792C654.677 3.15611 654.805 3.46559 654.805 3.7883C654.805 4.11101 654.677 4.42051 654.448 4.6487C654.22 4.87689 653.91 5.00509 653.587 5.00509C653.427 5.00533 653.269 4.974 653.121 4.91294C652.973 4.85187 652.838 4.76227 652.725 4.64923C652.612 4.5362 652.522 4.40197 652.461 4.25424C652.4 4.10651 652.369 3.94816 652.369 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M462.191 5.00508C462.863 5.00508 463.409 4.46031 463.409 3.78831C463.409 3.1163 462.863 2.57153 462.191 2.57153C461.518 2.57153 460.973 3.1163 460.973 3.78831C460.973 4.46031 461.518 5.00508 462.191 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M650.165 5.00508C650.838 5.00508 651.383 4.46031 651.383 3.78831C651.383 3.1163 650.838 2.57153 650.165 2.57153C649.493 2.57153 648.947 3.1163 648.947 3.78831C648.947 4.46031 649.493 5.00508 650.165 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M457.548 3.7883C457.548 3.46559 457.676 3.15611 457.905 2.92792C458.133 2.69973 458.443 2.57153 458.766 2.57153C458.926 2.5713 459.084 2.6026 459.232 2.66366C459.38 2.72473 459.514 2.81436 459.628 2.92739C459.741 3.04043 459.83 3.17465 459.892 3.32238C459.953 3.47012 459.984 3.62845 459.984 3.7883C459.984 3.94816 459.953 4.10651 459.892 4.25424C459.83 4.40197 459.741 4.5362 459.628 4.64923C459.514 4.76227 459.38 4.85187 459.232 4.91294C459.084 4.974 458.926 5.00533 458.766 5.00509C458.443 5.00509 458.133 4.87689 457.905 4.6487C457.676 4.42051 457.548 4.11101 457.548 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M645.522 3.7883C645.522 3.46559 645.651 3.15611 645.879 2.92792C646.108 2.69973 646.417 2.57153 646.74 2.57153C646.9 2.5713 647.059 2.6026 647.207 2.66366C647.355 2.72473 647.489 2.81436 647.602 2.92739C647.715 3.04043 647.805 3.17465 647.866 3.32238C647.927 3.47012 647.959 3.62845 647.958 3.7883C647.959 3.94816 647.927 4.10651 647.866 4.25424C647.805 4.40197 647.715 4.5362 647.602 4.64923C647.489 4.76227 647.355 4.85187 647.207 4.91294C647.059 4.974 646.9 5.00533 646.74 5.00509C646.417 5.00509 646.108 4.87689 645.879 4.6487C645.651 4.42051 645.522 4.11101 645.522 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M455.344 5.00508C456.017 5.00508 456.562 4.46031 456.562 3.78831C456.562 3.1163 456.017 2.57153 455.344 2.57153C454.671 2.57153 454.126 3.1163 454.126 3.78831C454.126 4.46031 454.671 5.00508 455.344 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M643.319 5.00508C643.991 5.00508 644.537 4.46031 644.537 3.78831C644.537 3.1163 643.991 2.57153 643.319 2.57153C642.646 2.57153 642.101 3.1163 642.101 3.78831C642.101 4.46031 642.646 5.00508 643.319 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M451.921 5.00508C452.594 5.00508 453.139 4.46031 453.139 3.78831C453.139 3.1163 452.594 2.57153 451.921 2.57153C451.248 2.57153 450.703 3.1163 450.703 3.78831C450.703 4.46031 451.248 5.00508 451.921 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M639.896 5.00508C640.568 5.00508 641.114 4.46031 641.114 3.78831C641.114 3.1163 640.568 2.57153 639.896 2.57153C639.223 2.57153 638.678 3.1163 638.678 3.78831C638.678 4.46031 639.223 5.00508 639.896 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M447.279 3.7883C447.279 3.46559 447.408 3.15611 447.636 2.92792C447.864 2.69973 448.174 2.57153 448.497 2.57153C448.657 2.5713 448.816 2.6026 448.964 2.66366C449.112 2.72473 449.246 2.81436 449.359 2.92739C449.472 3.04043 449.562 3.17465 449.623 3.32238C449.684 3.47012 449.715 3.62845 449.715 3.7883C449.715 3.94816 449.684 4.10651 449.623 4.25424C449.562 4.40197 449.472 4.5362 449.359 4.64923C449.246 4.76227 449.112 4.85187 448.964 4.91294C448.816 4.974 448.657 5.00533 448.497 5.00509C448.174 5.00509 447.864 4.87689 447.636 4.6487C447.408 4.42051 447.279 4.11101 447.279 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M635.254 3.7883C635.254 3.46559 635.382 3.15611 635.611 2.92792C635.839 2.69973 636.149 2.57153 636.472 2.57153C636.632 2.5713 636.79 2.6026 636.938 2.66366C637.086 2.72473 637.22 2.81436 637.334 2.92739C637.447 3.04043 637.537 3.17465 637.598 3.32238C637.659 3.47012 637.69 3.62845 637.69 3.7883C637.69 3.94816 637.659 4.10651 637.598 4.25424C637.537 4.40197 637.447 4.5362 637.334 4.64923C637.22 4.76227 637.086 4.85187 636.938 4.91294C636.79 4.974 636.632 5.00533 636.472 5.00509C636.149 5.00509 635.839 4.87689 635.611 4.6487C635.382 4.42051 635.254 4.11101 635.254 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M445.075 5.00508C445.748 5.00508 446.293 4.46031 446.293 3.78831C446.293 3.1163 445.748 2.57153 445.075 2.57153C444.403 2.57153 443.857 3.1163 443.857 3.78831C443.857 4.46031 444.403 5.00508 445.075 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M633.05 5.00508C633.723 5.00508 634.268 4.46031 634.268 3.78831C634.268 3.1163 633.723 2.57153 633.05 2.57153C632.377 2.57153 631.832 3.1163 631.832 3.78831C631.832 4.46031 632.377 5.00508 633.05 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M441.653 5.00508C442.325 5.00508 442.871 4.46031 442.871 3.78831C442.871 3.1163 442.325 2.57153 441.653 2.57153C440.98 2.57153 440.435 3.1163 440.435 3.78831C440.435 4.46031 440.98 5.00508 441.653 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M629.627 5.00508C630.3 5.00508 630.845 4.46031 630.845 3.78831C630.845 3.1163 630.3 2.57153 629.627 2.57153C628.954 2.57153 628.409 3.1163 628.409 3.78831C628.409 4.46031 628.954 5.00508 629.627 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M437.011 3.7883C437.011 3.62845 437.042 3.47012 437.103 3.32238C437.164 3.17465 437.254 3.04043 437.367 2.92739C437.48 2.81436 437.614 2.72473 437.762 2.66366C437.91 2.6026 438.069 2.5713 438.229 2.57153C438.389 2.5713 438.547 2.6026 438.695 2.66366C438.843 2.72473 438.977 2.81436 439.09 2.92739C439.204 3.04043 439.293 3.17465 439.354 3.32238C439.416 3.47012 439.447 3.62845 439.447 3.7883C439.447 3.94816 439.416 4.10651 439.354 4.25424C439.293 4.40197 439.204 4.5362 439.09 4.64923C438.977 4.76227 438.843 4.85187 438.695 4.91294C438.547 4.974 438.389 5.00533 438.229 5.00509C438.069 5.00533 437.91 4.974 437.762 4.91294C437.614 4.85187 437.48 4.76227 437.367 4.64923C437.254 4.5362 437.164 4.40197 437.103 4.25424C437.042 4.10651 437.011 3.94816 437.011 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M624.985 3.7883C624.985 3.62845 625.016 3.47012 625.078 3.32238C625.139 3.17465 625.228 3.04043 625.342 2.92739C625.455 2.81436 625.589 2.72473 625.737 2.66366C625.885 2.6026 626.043 2.5713 626.203 2.57153C626.363 2.5713 626.522 2.6026 626.67 2.66366C626.818 2.72473 626.952 2.81436 627.065 2.92739C627.178 3.04043 627.268 3.17465 627.329 3.32238C627.39 3.47012 627.422 3.62845 627.421 3.7883C627.422 3.94816 627.39 4.10651 627.329 4.25424C627.268 4.40197 627.178 4.5362 627.065 4.64923C626.952 4.76227 626.818 4.85187 626.67 4.91294C626.522 4.974 626.363 5.00533 626.203 5.00509C626.043 5.00533 625.885 4.974 625.737 4.91294C625.589 4.85187 625.455 4.76227 625.342 4.64923C625.228 4.5362 625.139 4.40197 625.078 4.25424C625.016 4.10651 624.985 3.94816 624.985 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M433.588 3.7883C433.588 3.46559 433.716 3.15611 433.945 2.92792C434.173 2.69973 434.483 2.57153 434.806 2.57153C434.966 2.5713 435.124 2.6026 435.272 2.66366C435.42 2.72473 435.554 2.81436 435.668 2.92739C435.781 3.04043 435.87 3.17465 435.932 3.32238C435.993 3.47012 436.024 3.62845 436.024 3.7883C436.024 3.94816 435.993 4.10651 435.932 4.25424C435.87 4.40197 435.781 4.5362 435.668 4.64923C435.554 4.76227 435.42 4.85187 435.272 4.91294C435.124 4.974 434.966 5.00533 434.806 5.00509C434.483 5.00509 434.173 4.87689 433.945 4.6487C433.716 4.42051 433.588 4.11101 433.588 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M621.562 3.7883C621.562 3.46559 621.691 3.15611 621.919 2.92792C622.148 2.69973 622.457 2.57153 622.78 2.57153C622.94 2.5713 623.099 2.6026 623.247 2.66366C623.395 2.72473 623.529 2.81436 623.642 2.92739C623.755 3.04043 623.845 3.17465 623.906 3.32238C623.967 3.47012 623.999 3.62845 623.998 3.7883C623.999 3.94816 623.967 4.10651 623.906 4.25424C623.845 4.40197 623.755 4.5362 623.642 4.64923C623.529 4.76227 623.395 4.85187 623.247 4.91294C623.099 4.974 622.94 5.00533 622.78 5.00509C622.457 5.00509 622.148 4.87689 621.919 4.6487C621.691 4.42051 621.562 4.11101 621.562 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M430.164 3.7883C430.164 3.62836 430.196 3.47 430.257 3.32225C430.318 3.17451 430.408 3.04029 430.521 2.92728C430.635 2.81427 430.769 2.72468 430.917 2.66364C431.065 2.6026 431.224 2.5713 431.384 2.57153C431.707 2.57153 432.017 2.69973 432.245 2.92792C432.473 3.15611 432.602 3.46559 432.602 3.7883C432.602 4.11101 432.473 4.42051 432.245 4.6487C432.017 4.87689 431.707 5.00509 431.384 5.00509C431.224 5.00533 431.065 4.97402 430.917 4.91298C430.769 4.85194 430.635 4.76236 430.521 4.64934C430.408 4.53633 430.318 4.40212 430.257 4.25437C430.196 4.10663 430.164 3.94824 430.164 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M618.139 3.7883C618.139 3.62836 618.17 3.47 618.232 3.32225C618.293 3.17451 618.383 3.04029 618.496 2.92728C618.609 2.81427 618.744 2.72468 618.892 2.66364C619.04 2.6026 619.198 2.5713 619.358 2.57153C619.681 2.57153 619.991 2.69973 620.22 2.92792C620.448 3.15611 620.576 3.46559 620.576 3.7883C620.576 4.11101 620.448 4.42051 620.22 4.6487C619.991 4.87689 619.681 5.00509 619.358 5.00509C619.198 5.00533 619.04 4.97402 618.892 4.91298C618.744 4.85194 618.609 4.76236 618.496 4.64934C618.383 4.53633 618.293 4.40212 618.232 4.25437C618.17 4.10663 618.139 3.94824 618.139 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M426.742 3.7883C426.742 3.62845 426.773 3.47012 426.834 3.32238C426.896 3.17465 426.985 3.04043 427.098 2.92739C427.212 2.81436 427.346 2.72473 427.494 2.66366C427.642 2.6026 427.8 2.5713 427.96 2.57153C428.12 2.5713 428.279 2.6026 428.427 2.66366C428.574 2.72473 428.709 2.81436 428.822 2.92739C428.935 3.04043 429.025 3.17465 429.086 3.32238C429.147 3.47012 429.178 3.62845 429.178 3.7883C429.178 3.94816 429.147 4.10651 429.086 4.25424C429.025 4.40197 428.935 4.5362 428.822 4.64923C428.709 4.76227 428.574 4.85187 428.427 4.91294C428.279 4.974 428.12 5.00533 427.96 5.00509C427.8 5.00533 427.642 4.974 427.494 4.91294C427.346 4.85187 427.212 4.76227 427.098 4.64923C426.985 4.5362 426.896 4.40197 426.834 4.25424C426.773 4.10651 426.742 3.94816 426.742 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M614.717 3.7883C614.717 3.62845 614.748 3.47012 614.809 3.32238C614.87 3.17465 614.96 3.04043 615.073 2.92739C615.186 2.81436 615.321 2.72473 615.468 2.66366C615.616 2.6026 615.775 2.5713 615.935 2.57153C616.095 2.5713 616.253 2.6026 616.401 2.66366C616.549 2.72473 616.683 2.81436 616.797 2.92739C616.91 3.04043 616.999 3.17465 617.061 3.32238C617.122 3.47012 617.153 3.62845 617.153 3.7883C617.153 3.94816 617.122 4.10651 617.061 4.25424C616.999 4.40197 616.91 4.5362 616.797 4.64923C616.683 4.76227 616.549 4.85187 616.401 4.91294C616.253 4.974 616.095 5.00533 615.935 5.00509C615.775 5.00533 615.616 4.974 615.468 4.91294C615.321 4.85187 615.186 4.76227 615.073 4.64923C614.96 4.5362 614.87 4.40197 614.809 4.25424C614.748 4.10651 614.717 3.94816 614.717 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M423.319 3.7883C423.319 3.46559 423.448 3.15611 423.676 2.92792C423.904 2.69973 424.214 2.57153 424.537 2.57153C424.697 2.5713 424.856 2.6026 425.004 2.66366C425.152 2.72473 425.286 2.81436 425.399 2.92739C425.512 3.04043 425.602 3.17465 425.663 3.32238C425.724 3.47012 425.756 3.62845 425.755 3.7883C425.756 3.94816 425.724 4.10651 425.663 4.25424C425.602 4.40197 425.512 4.5362 425.399 4.64923C425.286 4.76227 425.152 4.85187 425.004 4.91294C424.856 4.974 424.697 5.00533 424.537 5.00509C424.214 5.00509 423.904 4.87689 423.676 4.6487C423.448 4.42051 423.319 4.11101 423.319 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M611.294 3.7883C611.294 3.46559 611.422 3.15611 611.651 2.92792C611.879 2.69973 612.189 2.57153 612.512 2.57153C612.672 2.5713 612.83 2.6026 612.978 2.66366C613.126 2.72473 613.261 2.81436 613.374 2.92739C613.487 3.04043 613.577 3.17465 613.638 3.32238C613.699 3.47012 613.73 3.62845 613.73 3.7883C613.73 3.94816 613.699 4.10651 613.638 4.25424C613.577 4.40197 613.487 4.5362 613.374 4.64923C613.261 4.76227 613.126 4.85187 612.978 4.91294C612.83 4.974 612.672 5.00533 612.512 5.00509C612.189 5.00509 611.879 4.87689 611.651 4.6487C611.422 4.42051 611.294 4.11101 611.294 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M421.115 5.00508C421.788 5.00508 422.333 4.46031 422.333 3.78831C422.333 3.1163 421.788 2.57153 421.115 2.57153C420.443 2.57153 419.897 3.1163 419.897 3.78831C419.897 4.46031 420.443 5.00508 421.115 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M609.09 5.00508C609.763 5.00508 610.308 4.46031 610.308 3.78831C610.308 3.1163 609.763 2.57153 609.09 2.57153C608.417 2.57153 607.872 3.1163 607.872 3.78831C607.872 4.46031 608.417 5.00508 609.09 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M416.475 3.7883C416.474 3.62845 416.506 3.47012 416.567 3.32238C416.628 3.17465 416.718 3.04043 416.831 2.92739C416.944 2.81436 417.078 2.72473 417.226 2.66366C417.374 2.6026 417.533 2.5713 417.693 2.57153C418.016 2.57153 418.325 2.69973 418.554 2.92792C418.782 3.15611 418.911 3.46559 418.911 3.7883C418.911 4.11101 418.782 4.42051 418.554 4.6487C418.325 4.87689 418.016 5.00509 417.693 5.00509C417.533 5.00533 417.374 4.974 417.226 4.91294C417.078 4.85187 416.944 4.76227 416.831 4.64923C416.718 4.5362 416.628 4.40197 416.567 4.25424C416.506 4.10651 416.474 3.94816 416.475 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M604.449 3.7883C604.449 3.62845 604.48 3.47012 604.541 3.32238C604.603 3.17465 604.692 3.04043 604.805 2.92739C604.919 2.81436 605.053 2.72473 605.201 2.66366C605.349 2.6026 605.507 2.5713 605.667 2.57153C605.99 2.57153 606.3 2.69973 606.528 2.92792C606.757 3.15611 606.885 3.46559 606.885 3.7883C606.885 4.11101 606.757 4.42051 606.528 4.6487C606.3 4.87689 605.99 5.00509 605.667 5.00509C605.507 5.00533 605.349 4.974 605.201 4.91294C605.053 4.85187 604.919 4.76227 604.805 4.64923C604.692 4.5362 604.603 4.40197 604.541 4.25424C604.48 4.10651 604.449 3.94816 604.449 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M413.051 3.7883C413.051 3.62845 413.082 3.47012 413.143 3.32238C413.204 3.17465 413.294 3.04043 413.407 2.92739C413.52 2.81436 413.654 2.72473 413.802 2.66366C413.95 2.6026 414.109 2.5713 414.269 2.57153C414.429 2.5713 414.587 2.6026 414.735 2.66366C414.883 2.72473 415.017 2.81436 415.131 2.92739C415.244 3.04043 415.333 3.17465 415.394 3.32238C415.456 3.47012 415.487 3.62845 415.487 3.7883C415.487 3.94816 415.456 4.10651 415.394 4.25424C415.333 4.40197 415.244 4.5362 415.131 4.64923C415.017 4.76227 414.883 4.85187 414.735 4.91294C414.587 4.974 414.429 5.00533 414.269 5.00509C414.109 5.00533 413.95 4.974 413.802 4.91294C413.654 4.85187 413.52 4.76227 413.407 4.64923C413.294 4.5362 413.204 4.40197 413.143 4.25424C413.082 4.10651 413.051 3.94816 413.051 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M601.025 3.7883C601.025 3.62845 601.056 3.47012 601.118 3.32238C601.179 3.17465 601.268 3.04043 601.382 2.92739C601.495 2.81436 601.629 2.72473 601.777 2.66366C601.925 2.6026 602.083 2.5713 602.243 2.57153C602.403 2.5713 602.562 2.6026 602.71 2.66366C602.858 2.72473 602.992 2.81436 603.105 2.92739C603.218 3.04043 603.308 3.17465 603.369 3.32238C603.43 3.47012 603.462 3.62845 603.461 3.7883C603.462 3.94816 603.43 4.10651 603.369 4.25424C603.308 4.40197 603.218 4.5362 603.105 4.64923C602.992 4.76227 602.858 4.85187 602.71 4.91294C602.562 4.974 602.403 5.00533 602.243 5.00509C602.083 5.00533 601.925 4.974 601.777 4.91294C601.629 4.85187 601.495 4.76227 601.382 4.64923C601.268 4.5362 601.179 4.40197 601.118 4.25424C601.056 4.10651 601.025 3.94816 601.025 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M409.628 3.7883C409.628 3.62851 409.659 3.4703 409.721 3.32267C409.782 3.17505 409.872 3.04091 409.985 2.92792C410.098 2.81493 410.232 2.7253 410.38 2.66415C410.528 2.603 410.686 2.57153 410.846 2.57153C411.006 2.5713 411.164 2.6026 411.312 2.66366C411.46 2.72473 411.595 2.81436 411.708 2.92739C411.821 3.04043 411.911 3.17465 411.972 3.32238C412.033 3.47012 412.064 3.62845 412.064 3.7883C412.064 3.94816 412.033 4.10651 411.972 4.25424C411.911 4.40197 411.821 4.5362 411.708 4.64923C411.595 4.76227 411.46 4.85187 411.312 4.91294C411.164 4.974 411.006 5.00533 410.846 5.00509C410.686 5.00509 410.528 4.9736 410.38 4.91245C410.232 4.8513 410.098 4.76169 409.985 4.6487C409.872 4.53571 409.782 4.40158 409.721 4.25395C409.659 4.10633 409.628 3.94809 409.628 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M597.603 3.7883C597.603 3.62851 597.634 3.4703 597.695 3.32267C597.756 3.17505 597.846 3.04091 597.959 2.92792C598.072 2.81493 598.207 2.7253 598.354 2.66415C598.502 2.603 598.661 2.57153 598.821 2.57153C598.981 2.5713 599.139 2.6026 599.287 2.66366C599.435 2.72473 599.569 2.81436 599.682 2.92739C599.795 3.04043 599.885 3.17465 599.946 3.32238C600.007 3.47012 600.039 3.62845 600.038 3.7883C600.039 3.94816 600.007 4.10651 599.946 4.25424C599.885 4.40197 599.795 4.5362 599.682 4.64923C599.569 4.76227 599.435 4.85187 599.287 4.91294C599.139 4.974 598.981 5.00533 598.821 5.00509C598.661 5.00509 598.502 4.9736 598.354 4.91245C598.207 4.8513 598.072 4.76169 597.959 4.6487C597.846 4.53571 597.756 4.40158 597.695 4.25395C597.634 4.10633 597.603 3.94809 597.603 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M406.204 3.7883C406.204 3.62836 406.236 3.47 406.297 3.32225C406.358 3.17451 406.448 3.04029 406.561 2.92728C406.675 2.81427 406.809 2.72468 406.957 2.66364C407.105 2.6026 407.264 2.5713 407.424 2.57153C407.747 2.57153 408.057 2.69973 408.285 2.92792C408.514 3.15611 408.642 3.46559 408.642 3.7883C408.642 4.11101 408.514 4.42051 408.285 4.6487C408.057 4.87689 407.747 5.00509 407.424 5.00509C407.264 5.00533 407.105 4.97402 406.957 4.91298C406.809 4.85194 406.675 4.76236 406.561 4.64934C406.448 4.53633 406.358 4.40212 406.297 4.25437C406.236 4.10663 406.204 3.94824 406.204 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M594.179 3.7883C594.179 3.62836 594.21 3.47 594.272 3.32225C594.333 3.17451 594.423 3.04029 594.536 2.92728C594.649 2.81427 594.784 2.72468 594.932 2.66364C595.08 2.6026 595.238 2.5713 595.398 2.57153C595.722 2.57153 596.031 2.69973 596.26 2.92792C596.488 3.15611 596.616 3.46559 596.616 3.7883C596.616 4.11101 596.488 4.42051 596.26 4.6487C596.031 4.87689 595.722 5.00509 595.398 5.00509C595.238 5.00533 595.08 4.97402 594.932 4.91298C594.784 4.85194 594.649 4.76236 594.536 4.64934C594.423 4.53633 594.333 4.40212 594.272 4.25437C594.21 4.10663 594.179 3.94824 594.179 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M402.782 3.7883C402.782 3.62845 402.813 3.47012 402.874 3.32238C402.936 3.17465 403.025 3.04043 403.138 2.92739C403.252 2.81436 403.386 2.72473 403.534 2.66366C403.682 2.6026 403.84 2.5713 404 2.57153C404.16 2.5713 404.319 2.6026 404.467 2.66366C404.614 2.72473 404.749 2.81436 404.862 2.92739C404.975 3.04043 405.065 3.17465 405.126 3.32238C405.187 3.47012 405.218 3.62845 405.218 3.7883C405.218 3.94816 405.187 4.10651 405.126 4.25424C405.065 4.40197 404.975 4.5362 404.862 4.64923C404.749 4.76227 404.614 4.85187 404.467 4.91294C404.319 4.974 404.16 5.00533 404 5.00509C403.84 5.00533 403.682 4.974 403.534 4.91294C403.386 4.85187 403.252 4.76227 403.138 4.64923C403.025 4.5362 402.936 4.40197 402.874 4.25424C402.813 4.10651 402.782 3.94816 402.782 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M590.757 3.7883C590.757 3.62845 590.788 3.47012 590.849 3.32238C590.91 3.17465 591 3.04043 591.113 2.92739C591.226 2.81436 591.361 2.72473 591.508 2.66366C591.656 2.6026 591.815 2.5713 591.975 2.57153C592.135 2.5713 592.293 2.6026 592.441 2.66366C592.589 2.72473 592.723 2.81436 592.837 2.92739C592.95 3.04043 593.039 3.17465 593.101 3.32238C593.162 3.47012 593.193 3.62845 593.193 3.7883C593.193 3.94816 593.162 4.10651 593.101 4.25424C593.039 4.40197 592.95 4.5362 592.837 4.64923C592.723 4.76227 592.589 4.85187 592.441 4.91294C592.293 4.974 592.135 5.00533 591.975 5.00509C591.815 5.00533 591.656 4.974 591.508 4.91294C591.361 4.85187 591.226 4.76227 591.113 4.64923C591 4.5362 590.91 4.40197 590.849 4.25424C590.788 4.10651 590.757 3.94816 590.757 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M399.359 3.7883C399.359 3.62845 399.39 3.47012 399.452 3.32238C399.513 3.17465 399.602 3.04043 399.716 2.92739C399.829 2.81436 399.963 2.72473 400.111 2.66366C400.259 2.6026 400.417 2.5713 400.577 2.57153C400.737 2.5713 400.896 2.6026 401.044 2.66366C401.192 2.72473 401.326 2.81436 401.439 2.92739C401.552 3.04043 401.642 3.17465 401.703 3.32238C401.764 3.47012 401.796 3.62845 401.795 3.7883C401.796 3.94816 401.764 4.10651 401.703 4.25424C401.642 4.40197 401.552 4.5362 401.439 4.64923C401.326 4.76227 401.192 4.85187 401.044 4.91294C400.896 4.974 400.737 5.00533 400.577 5.00509C400.417 5.00533 400.259 4.974 400.111 4.91294C399.963 4.85187 399.829 4.76227 399.716 4.64923C399.602 4.5362 399.513 4.40197 399.452 4.25424C399.39 4.10651 399.359 3.94816 399.359 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M587.334 3.7883C587.334 3.62845 587.365 3.47012 587.426 3.32238C587.487 3.17465 587.577 3.04043 587.69 2.92739C587.803 2.81436 587.938 2.72473 588.086 2.66366C588.233 2.6026 588.392 2.5713 588.552 2.57153C588.712 2.5713 588.87 2.6026 589.018 2.66366C589.166 2.72473 589.301 2.81436 589.414 2.92739C589.527 3.04043 589.617 3.17465 589.678 3.32238C589.739 3.47012 589.77 3.62845 589.77 3.7883C589.77 3.94816 589.739 4.10651 589.678 4.25424C589.617 4.40197 589.527 4.5362 589.414 4.64923C589.301 4.76227 589.166 4.85187 589.018 4.91294C588.87 4.974 588.712 5.00533 588.552 5.00509C588.392 5.00533 588.233 4.974 588.086 4.91294C587.938 4.85187 587.803 4.76227 587.69 4.64923C587.577 4.5362 587.487 4.40197 587.426 4.25424C587.365 4.10651 587.334 3.94816 587.334 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M397.098 5.05037C397.771 5.05037 398.316 4.5056 398.316 3.83359C398.316 3.16159 397.771 2.61682 397.098 2.61682C396.425 2.61682 395.88 3.16159 395.88 3.83359C395.88 4.5056 396.425 5.05037 397.098 5.05037Z"
                fill="#4E415C"/>
            <path
                d="M585.072 5.05037C585.745 5.05037 586.29 4.5056 586.29 3.83359C586.29 3.16159 585.745 2.61682 585.072 2.61682C584.4 2.61682 583.854 3.16159 583.854 3.83359C583.854 4.5056 584.4 5.05037 585.072 5.05037Z"
                fill="#4E415C"/>
            <path
                d="M392.422 4.36494C392.422 4.04207 392.55 3.73238 392.779 3.5039C393.007 3.27542 393.317 3.14684 393.64 3.14636C393.8 3.14636 393.958 3.17789 394.106 3.23916C394.254 3.30042 394.389 3.39019 394.502 3.50337C394.615 3.61655 394.705 3.7509 394.766 3.89874C394.827 4.04657 394.858 4.205 394.858 4.36494C394.858 4.52473 394.826 4.68295 394.765 4.83057C394.704 4.9782 394.614 5.11233 394.501 5.22532C394.388 5.33831 394.254 5.42795 394.106 5.48909C393.958 5.55024 393.8 5.58171 393.64 5.58171C393.317 5.58123 393.007 5.45288 392.779 5.22479C392.551 4.99671 392.422 4.68751 392.422 4.36494V4.36494Z"
                fill="#4E415C"/>
            <path
                d="M580.396 4.36494C580.396 4.04207 580.525 3.73238 580.753 3.5039C580.981 3.27542 581.291 3.14684 581.614 3.14636C581.775 3.14636 581.933 3.17789 582.081 3.23916C582.229 3.30042 582.363 3.39019 582.476 3.50337C582.589 3.61655 582.679 3.7509 582.74 3.89874C582.801 4.04657 582.833 4.205 582.832 4.36494C582.832 4.52473 582.801 4.68295 582.74 4.83057C582.679 4.9782 582.589 5.11233 582.476 5.22532C582.363 5.33831 582.228 5.42795 582.081 5.48909C581.933 5.55024 581.774 5.58171 581.614 5.58171C581.292 5.58123 580.982 5.45288 580.754 5.22479C580.525 4.99671 580.397 4.68751 580.396 4.36494V4.36494Z"
                fill="#4E415C"/>
            <path
                d="M390.402 6.66792C391.074 6.66792 391.62 6.12315 391.62 5.45115C391.62 4.77914 391.074 4.23438 390.402 4.23438C389.729 4.23438 389.184 4.77914 389.184 5.45115C389.184 6.12315 389.729 6.66792 390.402 6.66792Z"
                fill="#4E415C"/>
            <path
                d="M578.376 6.66792C579.049 6.66792 579.594 6.12315 579.594 5.45115C579.594 4.77914 579.049 4.23438 578.376 4.23438C577.703 4.23438 577.158 4.77914 577.158 5.45115C577.158 6.12315 577.703 6.66792 578.376 6.66792Z"
                fill="#4E415C"/>
            <path
                d="M386.21 7.04333C386.21 6.72062 386.338 6.41112 386.567 6.18293C386.795 5.95474 387.105 5.82654 387.428 5.82654C387.751 5.82654 388.061 5.95474 388.289 6.18293C388.518 6.41112 388.646 6.72062 388.646 7.04333C388.646 7.20327 388.615 7.36167 388.554 7.50951C388.493 7.65734 388.403 7.7917 388.29 7.90488C388.177 8.01806 388.042 8.10785 387.894 8.16911C387.747 8.23038 387.588 8.26191 387.428 8.26191C387.268 8.26191 387.109 8.23038 386.961 8.16911C386.814 8.10785 386.679 8.01806 386.566 7.90488C386.453 7.7917 386.363 7.65734 386.302 7.50951C386.241 7.36167 386.21 7.20327 386.21 7.04333V7.04333Z"
                fill="#4E415C"/>
            <path
                d="M574.185 7.04333C574.185 6.72062 574.313 6.41112 574.541 6.18293C574.77 5.95474 575.08 5.82654 575.403 5.82654C575.726 5.82654 576.035 5.95474 576.264 6.18293C576.492 6.41112 576.621 6.72062 576.621 7.04333C576.621 7.20327 576.589 7.36167 576.528 7.50951C576.467 7.65734 576.378 7.7917 576.264 7.90488C576.151 8.01806 576.017 8.10785 575.869 8.16911C575.721 8.23038 575.563 8.26191 575.403 8.26191C575.242 8.26191 575.084 8.23038 574.936 8.16911C574.788 8.10785 574.654 8.01806 574.541 7.90488C574.428 7.7917 574.338 7.65734 574.277 7.50951C574.216 7.36167 574.184 7.20327 574.185 7.04333V7.04333Z"
                fill="#4E415C"/>
            <path
                d="M383.559 9.08884C383.559 8.92905 383.59 8.77083 383.651 8.62321C383.713 8.47558 383.802 8.34145 383.915 8.22846C384.028 8.11547 384.163 8.02584 384.31 7.96469C384.458 7.90354 384.617 7.87207 384.777 7.87207C385.1 7.87207 385.409 8.00027 385.638 8.22846C385.866 8.45665 385.995 8.76613 385.995 9.08884C385.995 9.24869 385.963 9.40704 385.902 9.55478C385.841 9.70251 385.751 9.83673 385.638 9.94977C385.525 10.0628 385.391 10.1524 385.243 10.2135C385.095 10.2746 384.937 10.3059 384.777 10.3056C384.617 10.3059 384.458 10.2746 384.31 10.2135C384.162 10.1524 384.028 10.0628 383.915 9.94977C383.802 9.83673 383.712 9.70251 383.651 9.55478C383.59 9.40704 383.558 9.24869 383.559 9.08884V9.08884Z"
                fill="#4E415C"/>
            <path
                d="M571.533 9.08884C571.533 8.92905 571.565 8.77083 571.626 8.62321C571.687 8.47558 571.777 8.34145 571.89 8.22846C572.003 8.11547 572.137 8.02584 572.285 7.96469C572.433 7.90354 572.591 7.87207 572.751 7.87207C573.074 7.87207 573.384 8.00027 573.612 8.22846C573.841 8.45665 573.969 8.76613 573.969 9.08884C573.969 9.24869 573.938 9.40704 573.877 9.55478C573.816 9.70251 573.726 9.83673 573.613 9.94977C573.5 10.0628 573.365 10.1524 573.218 10.2135C573.07 10.2746 572.911 10.3059 572.751 10.3056C572.591 10.3059 572.433 10.2746 572.285 10.2135C572.137 10.1524 572.003 10.0628 571.889 9.94977C571.776 9.83673 571.687 9.70251 571.625 9.55478C571.564 9.40704 571.533 9.24869 571.533 9.08884V9.08884Z"
                fill="#4E415C"/>
            <path
                d="M381.278 11.535C381.278 11.2123 381.407 10.9028 381.635 10.6746C381.863 10.4464 382.173 10.3182 382.496 10.3182C382.819 10.3182 383.129 10.4464 383.358 10.6746C383.586 10.9028 383.714 11.2123 383.714 11.535C383.715 11.6949 383.683 11.8532 383.622 12.0009C383.561 12.1487 383.471 12.2829 383.358 12.3959C383.245 12.509 383.111 12.5986 382.963 12.6597C382.815 12.7207 382.656 12.752 382.496 12.7518C382.336 12.752 382.178 12.7207 382.03 12.6597C381.882 12.5986 381.748 12.509 381.635 12.3959C381.521 12.2829 381.432 12.1487 381.371 12.0009C381.309 11.8532 381.278 11.6949 381.278 11.535V11.535Z"
                fill="#4E415C"/>
            <path
                d="M569.253 11.535C569.253 11.2123 569.381 10.9028 569.61 10.6746C569.838 10.4464 570.148 10.3182 570.471 10.3182C570.794 10.3182 571.104 10.4464 571.332 10.6746C571.561 10.9028 571.689 11.2123 571.689 11.535C571.689 11.6949 571.658 11.8532 571.597 12.0009C571.536 12.1487 571.446 12.2829 571.333 12.3959C571.22 12.509 571.085 12.5986 570.937 12.6597C570.789 12.7207 570.631 12.752 570.471 12.7518C570.311 12.752 570.152 12.7207 570.005 12.6597C569.857 12.5986 569.722 12.509 569.609 12.3959C569.496 12.2829 569.406 12.1487 569.345 12.0009C569.284 11.8532 569.253 11.6949 569.253 11.535V11.535Z"
                fill="#4E415C"/>
            <path
                d="M379.425 14.3313C379.425 14.1713 379.456 14.013 379.518 13.8652C379.579 13.7175 379.669 13.5833 379.782 13.4702C379.895 13.3572 380.03 13.2677 380.178 13.2066C380.326 13.1456 380.484 13.1143 380.645 13.1145C380.968 13.1145 381.277 13.2427 381.506 13.4709C381.734 13.6991 381.863 14.0086 381.863 14.3313C381.862 14.6538 381.734 14.9631 381.505 15.1911C381.277 15.4192 380.967 15.5476 380.645 15.5481C380.321 15.5481 380.011 15.4199 379.783 15.1918C379.554 14.9636 379.425 14.6541 379.425 14.3313V14.3313Z"
                fill="#4E415C"/>
            <path
                d="M567.399 14.3313C567.399 14.1713 567.431 14.013 567.492 13.8652C567.554 13.7175 567.644 13.5833 567.757 13.4702C567.87 13.3572 568.005 13.2677 568.153 13.2066C568.301 13.1456 568.459 13.1143 568.619 13.1145C568.942 13.1145 569.252 13.2427 569.48 13.4709C569.709 13.6991 569.837 14.0086 569.837 14.3313C569.837 14.6538 569.708 14.9631 569.48 15.1911C569.252 15.4192 568.942 15.5476 568.619 15.5481C568.296 15.5481 567.986 15.4199 567.757 15.1918C567.529 14.9636 567.4 14.6541 567.399 14.3313V14.3313Z"
                fill="#4E415C"/>
            <path
                d="M378.042 17.4231C378.042 17.2633 378.073 17.1051 378.135 16.9574C378.196 16.8098 378.286 16.6757 378.399 16.5627C378.512 16.4497 378.646 16.3601 378.794 16.2989C378.942 16.2378 379.1 16.2063 379.26 16.2063C379.42 16.2061 379.578 16.2374 379.726 16.2985C379.874 16.3595 380.009 16.4491 380.122 16.5622C380.235 16.6752 380.325 16.8094 380.386 16.9572C380.447 17.1049 380.478 17.2632 380.478 17.4231C380.478 17.5829 380.447 17.7413 380.386 17.889C380.325 18.0367 380.235 18.171 380.122 18.284C380.009 18.397 379.874 18.4867 379.726 18.5477C379.578 18.6088 379.42 18.6401 379.26 18.6399C379.1 18.6399 378.942 18.6084 378.794 18.5472C378.646 18.4861 378.512 18.3965 378.399 18.2835C378.286 18.1705 378.196 18.0363 378.135 17.8887C378.073 17.7411 378.042 17.5829 378.042 17.4231V17.4231Z"
                fill="#4E415C"/>
            <path
                d="M566.017 17.4231C566.017 17.2633 566.048 17.1051 566.109 16.9574C566.171 16.8098 566.26 16.6757 566.373 16.5627C566.486 16.4497 566.621 16.3601 566.768 16.2989C566.916 16.2378 567.075 16.2063 567.235 16.2063C567.395 16.2061 567.553 16.2374 567.701 16.2985C567.849 16.3595 567.983 16.4491 568.096 16.5622C568.209 16.6752 568.299 16.8094 568.36 16.9572C568.421 17.1049 568.453 17.2632 568.453 17.4231C568.453 17.5829 568.421 17.7413 568.36 17.889C568.299 18.0367 568.209 18.171 568.096 18.284C567.983 18.397 567.849 18.4867 567.701 18.5477C567.553 18.6088 567.395 18.6401 567.235 18.6399C567.075 18.6399 566.916 18.6084 566.768 18.5472C566.621 18.4861 566.486 18.3965 566.373 18.2835C566.26 18.1705 566.171 18.0363 566.109 17.8887C566.048 17.7411 566.017 17.5829 566.017 17.4231V17.4231Z"
                fill="#4E415C"/>
            <path
                d="M377.19 20.7596C377.19 20.4369 377.319 20.1274 377.547 19.8992C377.776 19.671 378.085 19.5428 378.408 19.5428C378.731 19.5428 379.041 19.671 379.27 19.8992C379.498 20.1274 379.626 20.4369 379.626 20.7596C379.627 20.9195 379.595 21.0778 379.534 21.2256C379.473 21.3733 379.383 21.5075 379.27 21.6205C379.157 21.7336 379.023 21.8232 378.875 21.8843C378.727 21.9453 378.568 21.9766 378.408 21.9764C378.248 21.9766 378.09 21.9453 377.942 21.8843C377.794 21.8232 377.66 21.7336 377.547 21.6205C377.433 21.5075 377.344 21.3733 377.283 21.2256C377.222 21.0778 377.19 20.9195 377.19 20.7596V20.7596Z"
                fill="#4E415C"/>
            <path
                d="M565.165 20.7596C565.165 20.4369 565.293 20.1274 565.522 19.8992C565.75 19.671 566.06 19.5428 566.383 19.5428C566.706 19.5428 567.016 19.671 567.244 19.8992C567.473 20.1274 567.601 20.4369 567.601 20.7596C567.601 20.9195 567.57 21.0778 567.509 21.2256C567.448 21.3733 567.358 21.5075 567.245 21.6205C567.132 21.7336 566.997 21.8232 566.849 21.8843C566.702 21.9453 566.543 21.9766 566.383 21.9764C566.223 21.9766 566.065 21.9453 565.917 21.8843C565.769 21.8232 565.634 21.7336 565.521 21.6205C565.408 21.5075 565.318 21.3733 565.257 21.2256C565.196 21.0778 565.165 20.9195 565.165 20.7596V20.7596Z"
                fill="#4E415C"/>
            <path
                d="M376.918 24.2903C376.918 23.9675 377.046 23.658 377.275 23.4299C377.503 23.2017 377.813 23.0735 378.136 23.0735C378.296 23.0732 378.454 23.1046 378.602 23.1656C378.75 23.2267 378.885 23.3163 378.998 23.4293C379.111 23.5424 379.201 23.6766 379.262 23.8243C379.323 23.9721 379.354 24.1304 379.354 24.2903C379.354 24.613 379.226 24.9224 378.997 25.1506C378.769 25.3788 378.459 25.507 378.136 25.507C377.813 25.5065 377.504 25.3782 377.275 25.1501C377.047 24.922 376.918 24.6128 376.918 24.2903V24.2903Z"
                fill="#4E415C"/>
            <path
                d="M564.893 24.2903C564.893 23.9675 565.021 23.658 565.249 23.4299C565.478 23.2017 565.788 23.0735 566.111 23.0735C566.271 23.0732 566.429 23.1046 566.577 23.1656C566.725 23.2267 566.859 23.3163 566.972 23.4293C567.085 23.5424 567.175 23.6766 567.236 23.8243C567.297 23.9721 567.329 24.1304 567.329 24.2903C567.329 24.613 567.2 24.9224 566.972 25.1506C566.743 25.3788 566.434 25.507 566.111 25.507C565.788 25.5065 565.478 25.3782 565.25 25.1501C565.022 24.922 564.893 24.6128 564.893 24.2903V24.2903Z"
                fill="#4E415C"/>
            <path
                d="M377.26 27.801C377.26 27.6411 377.291 27.4828 377.352 27.3351C377.413 27.1873 377.503 27.0531 377.616 26.9401C377.729 26.827 377.863 26.7374 378.011 26.6764C378.159 26.6153 378.318 26.584 378.478 26.5842C378.801 26.5842 379.111 26.7124 379.339 26.9406C379.567 27.1688 379.696 27.4783 379.696 27.801C379.696 28.1237 379.567 28.4332 379.339 28.6614C379.111 28.8896 378.801 29.0178 378.478 29.0178C378.318 29.018 378.159 28.9867 378.011 28.9256C377.863 28.8646 377.729 28.7749 377.616 28.6619C377.503 28.5489 377.413 28.4146 377.352 28.2669C377.291 28.1192 377.26 27.9609 377.26 27.801V27.801Z"
                fill="#4E415C"/>
            <path
                d="M565.234 27.801C565.234 27.6411 565.265 27.4828 565.327 27.3351C565.388 27.1873 565.477 27.0531 565.591 26.9401C565.704 26.827 565.838 26.7374 565.986 26.6764C566.134 26.6153 566.292 26.584 566.452 26.5842C566.775 26.5842 567.085 26.7124 567.314 26.9406C567.542 27.1688 567.67 27.4783 567.67 27.801C567.67 28.1237 567.542 28.4332 567.314 28.6614C567.085 28.8896 566.775 29.0178 566.452 29.0178C566.292 29.018 566.134 28.9867 565.986 28.9256C565.838 28.8646 565.704 28.7749 565.591 28.6619C565.477 28.5489 565.388 28.4146 565.327 28.2669C565.265 28.1192 565.234 27.9609 565.234 27.801V27.801Z"
                fill="#4E415C"/>
            <path
                d="M378.173 31.1123C378.173 30.7897 378.302 30.4805 378.53 30.2524C378.758 30.0243 379.068 29.896 379.391 29.8955C379.551 29.8955 379.709 29.927 379.857 29.9881C380.005 30.0493 380.139 30.1389 380.252 30.2519C380.365 30.3649 380.455 30.499 380.516 30.6466C380.577 30.7942 380.609 30.9525 380.609 31.1123C380.609 31.2722 380.578 31.4306 380.517 31.5785C380.455 31.7263 380.366 31.8606 380.253 31.9738C380.14 32.087 380.005 32.1768 379.857 32.2381C379.709 32.2993 379.551 32.3309 379.391 32.3309C379.068 32.3304 378.758 32.2018 378.529 31.9733C378.301 31.7448 378.173 31.4351 378.173 31.1123Z"
                fill="#4E415C"/>
            <path
                d="M566.147 31.1123C566.148 30.7897 566.276 30.4805 566.505 30.2524C566.733 30.0243 567.043 29.896 567.365 29.8955C567.525 29.8955 567.684 29.927 567.832 29.9881C567.979 30.0493 568.114 30.1389 568.227 30.2519C568.34 30.3649 568.429 30.499 568.491 30.6466C568.552 30.7942 568.583 30.9525 568.583 31.1123C568.584 31.2722 568.552 31.4306 568.491 31.5785C568.43 31.7263 568.34 31.8606 568.227 31.9738C568.114 32.087 567.98 32.1768 567.832 32.2381C567.684 32.2993 567.526 32.3309 567.365 32.3309C567.042 32.3304 566.732 32.2018 566.504 31.9733C566.276 31.7448 566.147 31.4351 566.147 31.1123Z"
                fill="#4E415C"/>
            <path
                d="M379.606 34.175C379.606 33.8523 379.735 33.5428 379.963 33.3146C380.192 33.0865 380.501 32.9583 380.824 32.9583C380.985 32.958 381.143 32.9893 381.291 33.0504C381.439 33.1114 381.574 33.201 381.687 33.314C381.8 33.427 381.89 33.5612 381.951 33.709C382.013 33.8567 382.044 34.0151 382.044 34.175C382.044 34.4979 381.915 34.8074 381.686 35.0355C381.458 35.2637 381.148 35.3918 380.824 35.3918C380.502 35.3913 380.192 35.263 379.964 35.0349C379.735 34.8068 379.607 34.4976 379.606 34.175V34.175Z"
                fill="#4E415C"/>
            <path
                d="M567.581 34.175C567.581 33.8523 567.709 33.5428 567.938 33.3146C568.166 33.0865 568.476 32.9583 568.799 32.9583C568.959 32.958 569.118 32.9893 569.266 33.0504C569.414 33.1114 569.548 33.201 569.661 33.314C569.775 33.427 569.865 33.5612 569.926 33.709C569.987 33.8567 570.019 34.0151 570.019 34.175C570.018 34.4979 569.89 34.8074 569.661 35.0355C569.432 35.2637 569.122 35.3918 568.799 35.3918C568.476 35.3913 568.167 35.263 567.938 35.0349C567.71 34.8068 567.582 34.4976 567.581 34.175V34.175Z"
                fill="#4E415C"/>
            <path
                d="M381.515 36.9349C381.515 36.775 381.546 36.6166 381.608 36.4689C381.669 36.3211 381.759 36.1869 381.872 36.0739C381.985 35.9609 382.12 35.8713 382.268 35.8102C382.416 35.7492 382.574 35.7179 382.734 35.7181C383.057 35.7181 383.367 35.8463 383.596 36.0745C383.824 36.3027 383.952 36.6122 383.952 36.9349C383.952 37.2575 383.823 37.5667 383.595 37.7948C383.367 38.0229 383.057 38.1512 382.734 38.1517C382.411 38.1517 382.101 38.0235 381.873 37.7954C381.644 37.5673 381.515 37.2578 381.515 36.9349V36.9349Z"
                fill="#4E415C"/>
            <path
                d="M569.489 36.9349C569.489 36.775 569.521 36.6166 569.582 36.4689C569.643 36.3211 569.733 36.1869 569.847 36.0739C569.96 35.9609 570.094 35.8713 570.242 35.8102C570.39 35.7492 570.549 35.7179 570.709 35.7181C571.032 35.7181 571.342 35.8463 571.57 36.0745C571.799 36.3027 571.927 36.6122 571.927 36.9349C571.927 37.2575 571.798 37.5667 571.57 37.7948C571.341 38.0229 571.032 38.1512 570.709 38.1517C570.386 38.1517 570.076 38.0235 569.847 37.7954C569.618 37.5673 569.49 37.2578 569.489 36.9349V36.9349Z"
                fill="#4E415C"/>
            <path
                d="M383.84 39.3359C383.84 39.0133 383.969 38.7041 384.197 38.4761C384.425 38.248 384.735 38.1196 385.058 38.1191C385.381 38.1191 385.691 38.2473 385.919 38.4755C386.147 38.7037 386.276 39.0132 386.276 39.3359C386.277 39.497 386.247 39.6567 386.186 39.8059C386.125 39.955 386.035 40.0906 385.921 40.2047C385.807 40.3187 385.672 40.409 385.523 40.4703C385.373 40.5316 385.214 40.5627 385.052 40.5617C384.892 40.561 384.734 40.5287 384.586 40.4667C384.438 40.4047 384.304 40.3141 384.191 40.2002C384.079 40.0864 383.99 39.9514 383.929 39.8031C383.869 39.6548 383.839 39.496 383.84 39.3359V39.3359Z"
                fill="#4E415C"/>
            <path
                d="M571.814 39.3359C571.815 39.0133 571.943 38.7041 572.172 38.4761C572.4 38.248 572.71 38.1196 573.032 38.1191C573.355 38.1191 573.665 38.2473 573.894 38.4755C574.122 38.7037 574.25 39.0132 574.25 39.3359C574.252 39.497 574.221 39.6567 574.16 39.8059C574.099 39.955 574.009 40.0906 573.896 40.2047C573.782 40.3187 573.646 40.409 573.497 40.4703C573.348 40.5316 573.188 40.5627 573.027 40.5617C572.867 40.561 572.708 40.5287 572.56 40.4667C572.413 40.4047 572.279 40.3141 572.166 40.2002C572.053 40.0864 571.964 39.9514 571.904 39.8031C571.844 39.6548 571.813 39.496 571.814 39.3359V39.3359Z"
                fill="#4E415C"/>
            <path
                d="M386.529 41.3324C386.53 41.0095 386.659 40.7 386.887 40.4719C387.116 40.2437 387.426 40.1156 387.749 40.1156C388.072 40.1161 388.381 40.2444 388.61 40.4725C388.838 40.7006 388.967 41.0098 388.967 41.3324C388.967 41.6551 388.839 41.9646 388.61 42.1927C388.382 42.4209 388.072 42.5491 387.749 42.5491C387.589 42.5494 387.43 42.5181 387.282 42.457C387.134 42.396 387 42.3064 386.887 42.1934C386.773 42.0804 386.684 41.9462 386.622 41.7984C386.561 41.6507 386.529 41.4923 386.529 41.3324V41.3324Z"
                fill="#4E415C"/>
            <path
                d="M574.504 41.3324C574.504 41.0095 574.633 40.7 574.862 40.4719C575.091 40.2437 575.4 40.1156 575.724 40.1156C576.047 40.1161 576.356 40.2444 576.584 40.4725C576.813 40.7006 576.941 41.0098 576.942 41.3324C576.942 41.6551 576.813 41.9646 576.585 42.1927C576.357 42.4209 576.047 42.5491 575.724 42.5491C575.564 42.5494 575.405 42.5181 575.257 42.457C575.109 42.396 574.975 42.3064 574.861 42.1934C574.748 42.0804 574.658 41.9462 574.597 41.7984C574.535 41.6507 574.504 41.4923 574.504 41.3324V41.3324Z"
                fill="#4E415C"/>
            <path
                d="M389.535 42.8719C389.535 42.712 389.566 42.5535 389.627 42.4057C389.688 42.2579 389.778 42.1235 389.891 42.0104C390.004 41.8972 390.139 41.8074 390.287 41.7461C390.435 41.6849 390.593 41.6533 390.753 41.6533C390.913 41.6533 391.072 41.6849 391.22 41.7461C391.368 41.8074 391.502 41.8972 391.615 42.0104C391.728 42.1235 391.818 42.2579 391.879 42.4057C391.94 42.5535 391.971 42.712 391.971 42.8719C391.971 43.1946 391.843 43.5041 391.614 43.7323C391.386 43.9605 391.076 44.0887 390.753 44.0887C390.43 44.0887 390.12 43.9605 389.892 43.7323C389.663 43.5041 389.535 43.1946 389.535 42.8719V42.8719Z"
                fill="#4E415C"/>
            <path
                d="M577.51 42.8719C577.51 42.712 577.541 42.5535 577.602 42.4057C577.663 42.2579 577.753 42.1235 577.866 42.0104C577.979 41.8972 578.113 41.8074 578.261 41.7461C578.409 41.6849 578.568 41.6533 578.728 41.6533C578.888 41.6533 579.046 41.6849 579.194 41.7461C579.342 41.8074 579.476 41.8972 579.59 42.0104C579.703 42.1235 579.792 42.2579 579.854 42.4057C579.915 42.5535 579.946 42.712 579.946 42.8719C579.946 43.1946 579.817 43.5041 579.589 43.7323C579.361 43.9605 579.051 44.0887 578.728 44.0887C578.405 44.0887 578.095 43.9605 577.867 43.7323C577.638 43.5041 577.51 43.1946 577.51 42.8719V42.8719Z"
                fill="#4E415C"/>
            <path
                d="M392.803 43.8983C392.802 43.7385 392.834 43.5801 392.895 43.4324C392.956 43.2847 393.046 43.1504 393.159 43.0374C393.272 42.9243 393.406 42.8347 393.554 42.7737C393.702 42.7126 393.861 42.6813 394.021 42.6815C394.344 42.6815 394.654 42.8097 394.882 43.0379C395.11 43.2661 395.239 43.5756 395.239 43.8983C395.239 44.0581 395.207 44.2163 395.146 44.3639C395.085 44.5116 394.995 44.6457 394.882 44.7587C394.769 44.8717 394.635 44.9613 394.487 45.0225C394.339 45.0836 394.181 45.1151 394.021 45.1151C393.861 45.1153 393.702 45.084 393.554 45.0229C393.406 44.9619 393.272 44.8723 393.159 44.7592C393.046 44.6462 392.956 44.512 392.895 44.3642C392.834 44.2165 392.802 44.0582 392.803 43.8983V43.8983Z"
                fill="#4E415C"/>
            <path
                d="M580.777 43.8983C580.777 43.7385 580.808 43.5801 580.87 43.4324C580.931 43.2847 581.02 43.1504 581.134 43.0374C581.247 42.9243 581.381 42.8347 581.529 42.7737C581.677 42.7126 581.835 42.6813 581.995 42.6815C582.318 42.6815 582.628 42.8097 582.857 43.0379C583.085 43.2661 583.213 43.5756 583.213 43.8983C583.213 44.0581 583.182 44.2163 583.121 44.3639C583.059 44.5116 582.97 44.6457 582.857 44.7587C582.743 44.8717 582.609 44.9613 582.461 45.0225C582.314 45.0836 582.155 45.1151 581.995 45.1151C581.835 45.1153 581.677 45.084 581.529 45.0229C581.381 44.9619 581.247 44.8723 581.134 44.7592C581.02 44.6462 580.931 44.512 580.87 44.3642C580.808 44.2165 580.777 44.0582 580.777 43.8983V43.8983Z"
                fill="#4E415C"/>
            <path
                d="M396.279 44.3698C396.28 44.0473 396.408 43.7381 396.637 43.51C396.865 43.2819 397.174 43.1536 397.497 43.1531C397.82 43.1531 398.13 43.2813 398.359 43.5095C398.587 43.7377 398.715 44.0471 398.715 44.3698C398.715 44.5297 398.684 44.688 398.623 44.8358C398.562 44.9835 398.472 45.1177 398.359 45.2308C398.246 45.3438 398.112 45.4334 397.964 45.4945C397.816 45.5555 397.657 45.5868 397.497 45.5866C397.337 45.5866 397.179 45.5551 397.031 45.494C396.883 45.4328 396.749 45.3432 396.636 45.2302C396.523 45.1172 396.433 44.9831 396.372 44.8355C396.311 44.6878 396.279 44.5296 396.279 44.3698V44.3698Z"
                fill="#4E415C"/>
            <path
                d="M584.254 44.3698C584.254 44.0473 584.383 43.7381 584.611 43.51C584.839 43.2819 585.149 43.1536 585.472 43.1531C585.795 43.1531 586.105 43.2813 586.333 43.5095C586.562 43.7377 586.69 44.0471 586.69 44.3698C586.69 44.5297 586.659 44.688 586.598 44.8358C586.537 44.9835 586.447 45.1177 586.334 45.2308C586.22 45.3438 586.086 45.4334 585.938 45.4945C585.79 45.5555 585.632 45.5868 585.472 45.5866C585.312 45.5866 585.154 45.5551 585.006 45.494C584.858 45.4328 584.724 45.3432 584.611 45.2302C584.498 45.1172 584.408 44.9831 584.347 44.8355C584.285 44.6878 584.254 44.5296 584.254 44.3698V44.3698Z"
                fill="#4E415C"/>
            <path
                d="M400.737 44.3371C400.737 44.1773 400.769 44.0191 400.83 43.8715C400.891 43.7239 400.981 43.5897 401.094 43.4767C401.207 43.3638 401.341 43.2741 401.489 43.213C401.637 43.1518 401.795 43.1204 401.955 43.1204C402.115 43.1204 402.274 43.1518 402.421 43.213C402.569 43.2741 402.703 43.3638 402.817 43.4767C402.93 43.5897 403.019 43.7239 403.081 43.8715C403.142 44.0191 403.173 44.1773 403.173 44.3371C403.173 44.497 403.142 44.6553 403.081 44.8031C403.02 44.9508 402.93 45.085 402.817 45.1981C402.704 45.3111 402.57 45.4007 402.422 45.4618C402.274 45.5229 402.115 45.5542 401.955 45.5539C401.795 45.5542 401.637 45.5229 401.489 45.4618C401.341 45.4007 401.207 45.3111 401.094 45.1981C400.98 45.085 400.891 44.9508 400.83 44.8031C400.768 44.6553 400.737 44.497 400.737 44.3371V44.3371Z"
                fill="#4E415C"/>
            <path
                d="M588.712 44.3371C588.712 44.1773 588.743 44.0191 588.805 43.8715C588.866 43.7239 588.956 43.5897 589.069 43.4767C589.182 43.3638 589.316 43.2741 589.464 43.213C589.612 43.1518 589.77 43.1204 589.93 43.1204C590.09 43.1204 590.248 43.1518 590.396 43.213C590.544 43.2741 590.678 43.3638 590.791 43.4767C590.904 43.5897 590.994 43.7239 591.055 43.8715C591.116 44.0191 591.148 44.1773 591.148 44.3371C591.148 44.497 591.117 44.6553 591.056 44.8031C590.994 44.9508 590.905 45.085 590.792 45.1981C590.679 45.3111 590.544 45.4007 590.396 45.4618C590.248 45.5229 590.09 45.5542 589.93 45.5539C589.77 45.5542 589.611 45.5229 589.464 45.4618C589.316 45.4007 589.181 45.3111 589.068 45.1981C588.955 45.085 588.865 44.9508 588.804 44.8031C588.743 44.6553 588.712 44.497 588.712 44.3371V44.3371Z"
                fill="#4E415C"/>
            <path
                d="M405.286 44.125C405.286 43.8023 405.414 43.4928 405.643 43.2646C405.871 43.0364 406.181 42.9082 406.504 42.9082C406.827 42.9087 407.136 43.037 407.365 43.2651C407.593 43.4932 407.722 43.8024 407.722 44.125C407.722 44.4479 407.594 44.7575 407.365 44.986C407.137 45.2145 406.827 45.3431 406.504 45.3436C406.344 45.3436 406.185 45.312 406.038 45.2508C405.89 45.1895 405.755 45.0997 405.642 44.9865C405.529 44.8734 405.439 44.739 405.378 44.5912C405.317 44.4433 405.286 44.2849 405.286 44.125V44.125Z"
                fill="#4E415C"/>
            <path
                d="M593.261 44.125C593.261 43.8023 593.389 43.4928 593.617 43.2646C593.846 43.0364 594.156 42.9082 594.479 42.9082C594.802 42.9087 595.111 43.037 595.339 43.2651C595.568 43.4932 595.696 43.8024 595.697 44.125C595.697 44.4479 595.568 44.7575 595.34 44.986C595.112 45.2145 594.802 45.3431 594.479 45.3436C594.319 45.3436 594.16 45.312 594.012 45.2508C593.864 45.1895 593.73 45.0997 593.617 44.9865C593.504 44.8734 593.414 44.739 593.353 44.5912C593.292 44.4433 593.261 44.2849 593.261 44.125V44.125Z"
                fill="#4E415C"/>
            <path
                d="M409.584 43.7569C409.584 43.4342 409.712 43.1247 409.941 42.8965C410.169 42.6684 410.479 42.5402 410.802 42.5402C411.125 42.5406 411.434 42.669 411.663 42.8971C411.891 43.1252 412.019 43.4344 412.02 43.7569C412.02 44.0796 411.892 44.3891 411.663 44.6173C411.435 44.8455 411.125 44.9737 410.802 44.9737C410.642 44.9739 410.483 44.9426 410.336 44.8816C410.188 44.8205 410.053 44.7309 409.94 44.6179C409.827 44.5048 409.737 44.3706 409.676 44.2228C409.615 44.0751 409.584 43.9168 409.584 43.7569V43.7569Z"
                fill="#4E415C"/>
            <path
                d="M597.559 43.7569C597.559 43.4342 597.687 43.1247 597.915 42.8965C598.144 42.6684 598.454 42.5402 598.777 42.5402C599.099 42.5406 599.409 42.669 599.637 42.8971C599.866 43.1252 599.994 43.4344 599.995 43.7569C599.995 44.0796 599.866 44.3891 599.638 44.6173C599.409 44.8455 599.1 44.9737 598.777 44.9737C598.617 44.9739 598.458 44.9426 598.31 44.8816C598.162 44.8205 598.028 44.7309 597.915 44.6179C597.802 44.5048 597.712 44.3706 597.651 44.2228C597.59 44.0751 597.558 43.9168 597.559 43.7569V43.7569Z"
                fill="#4E415C"/>
            <path
                d="M413.645 43.2455C413.645 42.9228 413.773 42.6133 414.001 42.3851C414.23 42.1569 414.539 42.0287 414.862 42.0287C415.185 42.0292 415.495 42.1575 415.723 42.3856C415.952 42.6137 416.08 42.9229 416.08 43.2455C416.08 43.5682 415.952 43.8777 415.724 44.1059C415.495 44.334 415.186 44.4622 414.862 44.4622C414.702 44.4625 414.544 44.4312 414.396 44.3701C414.248 44.309 414.114 44.2194 414.001 44.1064C413.888 43.9933 413.798 43.8591 413.737 43.7114C413.676 43.5637 413.644 43.4053 413.645 43.2455V43.2455Z"
                fill="#4E415C"/>
            <path
                d="M601.619 43.2455C601.619 42.9228 601.747 42.6133 601.976 42.3851C602.204 42.1569 602.514 42.0287 602.837 42.0287C603.16 42.0292 603.47 42.1575 603.698 42.3856C603.926 42.6137 604.055 42.9229 604.055 43.2455C604.055 43.5682 603.927 43.8777 603.698 44.1059C603.47 44.334 603.16 44.4622 602.837 44.4622C602.677 44.4625 602.519 44.4312 602.371 44.3701C602.223 44.309 602.088 44.2194 601.975 44.1064C601.862 43.9933 601.772 43.8591 601.711 43.7114C601.65 43.5637 601.619 43.4053 601.619 43.2455V43.2455Z"
                fill="#4E415C"/>
            <path
                d="M417.479 42.6054C417.48 42.2829 417.608 41.9737 417.837 41.7456C418.065 41.5175 418.375 41.3892 418.697 41.3887C419.021 41.3887 419.331 41.5168 419.559 41.7449C419.788 41.9731 419.917 42.2826 419.917 42.6054C419.917 42.7654 419.886 42.9237 419.824 43.0715C419.763 43.2192 419.673 43.3534 419.56 43.4665C419.447 43.5795 419.312 43.6691 419.164 43.7301C419.016 43.7911 418.858 43.8224 418.697 43.8222C418.374 43.8222 418.065 43.694 417.836 43.4658C417.608 43.2376 417.479 42.9281 417.479 42.6054V42.6054Z"
                fill="#4E415C"/>
            <path
                d="M605.454 42.6054C605.455 42.2829 605.583 41.9737 605.811 41.7456C606.04 41.5175 606.349 41.3892 606.672 41.3887C606.995 41.3887 607.305 41.5168 607.534 41.7449C607.763 41.9731 607.891 42.2826 607.892 42.6054C607.892 42.7654 607.86 42.9237 607.799 43.0715C607.738 43.2192 607.648 43.3534 607.534 43.4665C607.421 43.5795 607.287 43.6691 607.139 43.7301C606.991 43.7911 606.832 43.8224 606.672 43.8222C606.349 43.8222 606.039 43.694 605.811 43.4658C605.582 43.2376 605.454 42.9281 605.454 42.6054V42.6054Z"
                fill="#4E415C"/>
            <path
                d="M421.106 41.851C421.106 41.6911 421.138 41.5327 421.199 41.3848C421.26 41.237 421.349 41.1027 421.463 40.9895C421.576 40.8763 421.71 40.7865 421.858 40.7252C422.006 40.664 422.164 40.6324 422.324 40.6324C422.648 40.6329 422.957 40.7615 423.186 40.99C423.414 41.2185 423.542 41.5282 423.542 41.851C423.542 42.1736 423.413 42.4828 423.185 42.7109C422.957 42.939 422.647 43.0673 422.324 43.0678C422.001 43.0678 421.692 42.9396 421.463 42.7114C421.235 42.4832 421.106 42.1737 421.106 41.851V41.851Z"
                fill="#4E415C"/>
            <path
                d="M609.081 41.851C609.081 41.6911 609.112 41.5327 609.173 41.3848C609.234 41.237 609.324 41.1027 609.437 40.9895C609.55 40.8763 609.685 40.7865 609.833 40.7252C609.98 40.664 610.139 40.6324 610.299 40.6324C610.622 40.6329 610.932 40.7615 611.16 40.99C611.389 41.2185 611.517 41.5282 611.517 41.851C611.517 42.1736 611.388 42.4828 611.16 42.7109C610.931 42.939 610.622 43.0673 610.299 43.0678C609.976 43.0678 609.666 42.9396 609.438 42.7114C609.209 42.4832 609.081 42.1737 609.081 41.851V41.851Z"
                fill="#4E415C"/>
            <path
                d="M424.533 40.9933C424.533 40.8334 424.564 40.6751 424.625 40.5273C424.687 40.3796 424.776 40.2454 424.889 40.1323C425.003 40.0193 425.137 39.9297 425.285 39.8686C425.433 39.8076 425.591 39.7763 425.751 39.7765C425.911 39.7763 426.07 39.8076 426.218 39.8686C426.365 39.9297 426.5 40.0193 426.613 40.1323C426.726 40.2454 426.816 40.3796 426.877 40.5273C426.938 40.6751 426.969 40.8334 426.969 40.9933C426.969 41.1531 426.938 41.3114 426.877 41.4592C426.816 41.6069 426.726 41.7411 426.613 41.8542C426.5 41.9672 426.365 42.0568 426.218 42.1179C426.07 42.179 425.911 42.2103 425.751 42.21C425.591 42.2103 425.433 42.179 425.285 42.1179C425.137 42.0568 425.003 41.9672 424.889 41.8542C424.776 41.7411 424.687 41.6069 424.625 41.4592C424.564 41.3114 424.533 41.1531 424.533 40.9933Z"
                fill="#4E415C"/>
            <path
                d="M612.508 40.9933C612.508 40.8334 612.539 40.6751 612.6 40.5273C612.661 40.3796 612.751 40.2454 612.864 40.1323C612.977 40.0193 613.112 39.9297 613.259 39.8686C613.407 39.8076 613.566 39.7763 613.726 39.7765C613.886 39.7763 614.044 39.8076 614.192 39.8686C614.34 39.9297 614.474 40.0193 614.588 40.1323C614.701 40.2454 614.79 40.3796 614.852 40.5273C614.913 40.6751 614.944 40.8334 614.944 40.9933C614.944 41.1531 614.913 41.3114 614.852 41.4592C614.79 41.6069 614.701 41.7411 614.588 41.8542C614.474 41.9672 614.34 42.0568 614.192 42.1179C614.044 42.179 613.886 42.2103 613.726 42.21C613.566 42.2103 613.407 42.179 613.259 42.1179C613.112 42.0568 612.977 41.9672 612.864 41.8542C612.751 41.7411 612.661 41.6069 612.6 41.4592C612.539 41.3114 612.508 41.1531 612.508 40.9933Z"
                fill="#4E415C"/>
            <path
                d="M427.775 40.0486C427.776 39.726 427.904 39.4168 428.133 39.1887C428.361 38.9606 428.67 38.8323 428.993 38.8318C429.316 38.8318 429.626 38.96 429.855 39.1882C430.083 39.4164 430.211 39.7259 430.211 40.0486C430.212 40.2085 430.18 40.3669 430.119 40.5148C430.058 40.6626 429.968 40.7969 429.855 40.9101C429.742 41.0233 429.608 41.1131 429.46 41.1744C429.312 41.2356 429.153 41.2672 428.993 41.2672C428.67 41.2667 428.36 41.1381 428.132 40.9096C427.904 40.6811 427.775 40.3715 427.775 40.0486V40.0486Z"
                fill="#4E415C"/>
            <path
                d="M615.75 40.0486C615.75 39.726 615.879 39.4168 616.107 39.1887C616.336 38.9606 616.645 38.8323 616.968 38.8318C617.291 38.8318 617.601 38.96 617.829 39.1882C618.058 39.4164 618.186 39.7259 618.186 40.0486C618.186 40.2085 618.155 40.3669 618.094 40.5148C618.033 40.6626 617.943 40.7969 617.83 40.9101C617.717 41.0233 617.582 41.1131 617.434 41.1744C617.287 41.2356 617.128 41.2672 616.968 41.2672C616.645 41.2667 616.335 41.1381 616.107 40.9096C615.878 40.6811 615.75 40.3715 615.75 40.0486V40.0486Z"
                fill="#4E415C"/>
            <path
                d="M430.847 39.0312C430.847 38.7085 430.975 38.399 431.203 38.1708C431.432 37.9427 431.742 37.8145 432.065 37.8145C432.388 37.8145 432.697 37.9427 432.926 38.1708C433.154 38.399 433.283 38.7085 433.283 39.0312C433.283 39.1911 433.252 39.3494 433.19 39.4972C433.129 39.6449 433.04 39.7791 432.926 39.8922C432.813 40.0052 432.679 40.0948 432.531 40.1559C432.383 40.2169 432.225 40.2482 432.065 40.248C431.905 40.2482 431.746 40.2169 431.598 40.1559C431.45 40.0948 431.316 40.0052 431.203 39.8922C431.09 39.7791 431 39.6449 430.939 39.4972C430.878 39.3494 430.846 39.1911 430.847 39.0312V39.0312Z"
                fill="#4E415C"/>
            <path
                d="M618.821 39.0312C618.821 38.7085 618.95 38.399 619.178 38.1708C619.406 37.9427 619.716 37.8145 620.039 37.8145C620.362 37.8145 620.672 37.9427 620.9 38.1708C621.129 38.399 621.257 38.7085 621.257 39.0312C621.257 39.1911 621.226 39.3494 621.165 39.4972C621.104 39.6449 621.014 39.7791 620.901 39.8922C620.788 40.0052 620.654 40.0948 620.506 40.1559C620.358 40.2169 620.199 40.2482 620.039 40.248C619.879 40.2482 619.721 40.2169 619.573 40.1559C619.425 40.0948 619.291 40.0052 619.177 39.8922C619.064 39.7791 618.975 39.6449 618.914 39.4972C618.852 39.3494 618.821 39.1911 618.821 39.0312V39.0312Z"
                fill="#4E415C"/>
            <path
                d="M433.758 37.9541C433.758 37.7942 433.789 37.6359 433.85 37.4882C433.911 37.3404 434.001 37.2062 434.114 37.0932C434.227 36.9801 434.362 36.8905 434.509 36.8294C434.657 36.7684 434.816 36.7371 434.976 36.7373C435.136 36.7371 435.294 36.7684 435.442 36.8294C435.59 36.8905 435.724 36.9801 435.838 37.0932C435.951 37.2062 436.04 37.3404 436.102 37.4882C436.163 37.6359 436.194 37.7942 436.194 37.9541C436.194 38.1139 436.163 38.2723 436.102 38.42C436.04 38.5677 435.951 38.702 435.838 38.815C435.724 38.928 435.59 39.0176 435.442 39.0787C435.294 39.1398 435.136 39.1711 434.976 39.1708C434.816 39.1711 434.657 39.1398 434.509 39.0787C434.362 39.0176 434.227 38.928 434.114 38.815C434.001 38.702 433.911 38.5677 433.85 38.42C433.789 38.2723 433.758 38.1139 433.758 37.9541V37.9541Z"
                fill="#4E415C"/>
            <path
                d="M621.732 37.9541C621.732 37.7942 621.764 37.6359 621.825 37.4882C621.886 37.3404 621.975 37.2062 622.089 37.0932C622.202 36.9801 622.336 36.8905 622.484 36.8294C622.632 36.7684 622.79 36.7371 622.95 36.7373C623.11 36.7371 623.269 36.7684 623.417 36.8294C623.565 36.8905 623.699 36.9801 623.812 37.0932C623.925 37.2062 624.015 37.3404 624.076 37.4882C624.137 37.6359 624.169 37.7942 624.168 37.9541C624.169 38.1139 624.137 38.2723 624.076 38.42C624.015 38.5677 623.925 38.702 623.812 38.815C623.699 38.928 623.565 39.0176 623.417 39.0787C623.269 39.1398 623.11 39.1711 622.95 39.1708C622.79 39.1711 622.632 39.1398 622.484 39.0787C622.336 39.0176 622.202 38.928 622.089 38.815C621.975 38.702 621.886 38.5677 621.825 38.42C621.764 38.2723 621.732 38.1139 621.732 37.9541V37.9541Z"
                fill="#4E415C"/>
            <path
                d="M436.524 36.8317C436.524 36.6718 436.556 36.5135 436.617 36.3657C436.678 36.218 436.767 36.0838 436.881 35.9707C436.994 35.8577 437.128 35.7681 437.276 35.707C437.424 35.6459 437.582 35.6146 437.742 35.6149C438.065 35.6149 438.375 35.7431 438.604 35.9713C438.832 36.1994 438.96 36.5089 438.96 36.8317C438.96 37.1542 438.831 37.4634 438.603 37.6915C438.375 37.9196 438.065 38.0479 437.742 38.0484C437.582 38.0484 437.424 38.017 437.276 37.9558C437.129 37.8947 436.994 37.805 436.881 37.692C436.768 37.579 436.678 37.4449 436.617 37.2973C436.556 37.1497 436.524 36.9914 436.524 36.8317V36.8317Z"
                fill="#4E415C"/>
            <path
                d="M624.499 36.8317C624.499 36.6718 624.53 36.5135 624.591 36.3657C624.652 36.218 624.742 36.0838 624.855 35.9707C624.968 35.8577 625.103 35.7681 625.251 35.707C625.399 35.6459 625.557 35.6146 625.717 35.6149C626.04 35.6149 626.35 35.7431 626.578 35.9713C626.807 36.1994 626.935 36.5089 626.935 36.8317C626.934 37.1542 626.806 37.4634 626.578 37.6915C626.349 37.9196 626.04 38.0479 625.717 38.0484C625.557 38.0484 625.399 38.017 625.251 37.9558C625.103 37.8947 624.969 37.805 624.856 37.692C624.743 37.579 624.653 37.4449 624.592 37.2973C624.531 37.1497 624.499 36.9914 624.499 36.8317V36.8317Z"
                fill="#4E415C"/>
            <path
                d="M439.156 35.6765C439.156 35.3538 439.285 35.0443 439.513 34.8161C439.741 34.5879 440.051 34.4597 440.374 34.4597C440.534 34.4595 440.693 34.4908 440.841 34.5518C440.989 34.6129 441.123 34.7025 441.237 34.8155C441.35 34.9285 441.44 35.0627 441.501 35.2104C441.562 35.3582 441.594 35.5166 441.594 35.6765C441.594 35.9994 441.465 36.3089 441.236 36.537C441.007 36.7651 440.697 36.8933 440.374 36.8933C440.051 36.8928 439.742 36.7644 439.514 36.5364C439.285 36.3083 439.157 35.9991 439.156 35.6765V35.6765Z"
                fill="#4E415C"/>
            <path
                d="M627.131 35.6765C627.131 35.3538 627.259 35.0443 627.488 34.8161C627.716 34.5879 628.026 34.4597 628.349 34.4597C628.509 34.4595 628.668 34.4908 628.815 34.5518C628.963 34.6129 629.098 34.7025 629.211 34.8155C629.325 34.9285 629.414 35.0627 629.476 35.2104C629.537 35.3582 629.569 35.5166 629.569 35.6765C629.568 35.9994 629.439 36.3089 629.211 36.537C628.982 36.7651 628.672 36.8933 628.349 36.8933C628.026 36.8928 627.716 36.7644 627.488 36.5364C627.26 36.3083 627.131 35.9991 627.131 35.6765V35.6765Z"
                fill="#4E415C"/>
            <path
                d="M441.67 34.5033C441.67 34.1806 441.798 33.8711 442.027 33.6429C442.255 33.4147 442.565 33.2865 442.888 33.2865C443.048 33.2863 443.206 33.3176 443.354 33.3786C443.502 33.4397 443.636 33.5293 443.75 33.6424C443.863 33.7554 443.953 33.8896 444.014 34.0374C444.075 34.1851 444.106 34.3434 444.106 34.5033C444.106 34.6631 444.075 34.8215 444.014 34.9692C443.953 35.1169 443.863 35.2512 443.75 35.3642C443.636 35.4772 443.502 35.5668 443.354 35.6279C443.206 35.689 443.048 35.7203 442.888 35.7201C442.565 35.7201 442.255 35.5919 442.027 35.3637C441.798 35.1355 441.67 34.826 441.67 34.5033V34.5033Z"
                fill="#4E415C"/>
            <path
                d="M629.645 34.5033C629.645 34.1806 629.773 33.8711 630.001 33.6429C630.23 33.4147 630.539 33.2865 630.862 33.2865C631.023 33.2863 631.181 33.3176 631.329 33.3786C631.477 33.4397 631.611 33.5293 631.724 33.6424C631.837 33.7554 631.927 33.8896 631.988 34.0374C632.049 34.1851 632.081 34.3434 632.08 34.5033C632.081 34.6631 632.049 34.8215 631.988 34.9692C631.927 35.1169 631.837 35.2512 631.724 35.3642C631.611 35.4772 631.477 35.5668 631.329 35.6279C631.181 35.689 631.023 35.7203 630.862 35.7201C630.539 35.7201 630.23 35.5919 630.001 35.3637C629.773 35.1355 629.645 34.826 629.645 34.5033V34.5033Z"
                fill="#4E415C"/>
            <path
                d="M444.077 33.3264C444.077 33.0035 444.205 32.6938 444.434 32.4653C444.662 32.2369 444.972 32.1083 445.295 32.1078C445.455 32.1078 445.614 32.1393 445.762 32.2006C445.91 32.2618 446.044 32.3516 446.157 32.4648C446.27 32.578 446.36 32.7124 446.421 32.8602C446.482 33.008 446.513 33.1664 446.513 33.3264C446.513 33.4862 446.482 33.6444 446.42 33.792C446.359 33.9396 446.269 34.0738 446.156 34.1868C446.043 34.2998 445.909 34.3894 445.761 34.4505C445.613 34.5117 445.455 34.5432 445.295 34.5432C444.972 34.5427 444.663 34.4143 444.434 34.1862C444.206 33.9582 444.078 33.6489 444.077 33.3264V33.3264Z"
                fill="#4E415C"/>
            <path
                d="M632.052 33.3264C632.052 33.0035 632.18 32.6938 632.408 32.4653C632.637 32.2369 632.947 32.1083 633.27 32.1078C633.43 32.1078 633.588 32.1393 633.736 32.2006C633.884 32.2618 634.018 32.3516 634.132 32.4648C634.245 32.578 634.334 32.7124 634.396 32.8602C634.457 33.008 634.488 33.1664 634.488 33.3264C634.488 33.4862 634.456 33.6444 634.395 33.792C634.334 33.9396 634.244 34.0738 634.131 34.1868C634.018 34.2998 633.884 34.3894 633.736 34.4505C633.588 34.5117 633.43 34.5432 633.27 34.5432C632.947 34.5427 632.637 34.4143 632.409 34.1862C632.181 33.9582 632.052 33.6489 632.052 33.3264V33.3264Z"
                fill="#4E415C"/>
            <path
                d="M446.392 32.1586C446.391 31.9987 446.423 31.8404 446.484 31.6926C446.545 31.5449 446.635 31.4107 446.748 31.2976C446.861 31.1846 446.995 31.095 447.143 31.0339C447.291 30.9729 447.45 30.9415 447.61 30.9418C447.77 30.9418 447.928 30.9733 448.076 31.0344C448.223 31.0956 448.358 31.1852 448.471 31.2982C448.584 31.4111 448.674 31.5453 448.735 31.6929C448.796 31.8405 448.828 31.9988 448.828 32.1586C448.827 32.4811 448.699 32.7903 448.47 33.0184C448.242 33.2465 447.932 33.3748 447.61 33.3753C447.287 33.3753 446.977 33.2471 446.748 33.0189C446.52 32.7908 446.392 32.4813 446.392 32.1586V32.1586Z"
                fill="#4E415C"/>
            <path
                d="M634.366 32.1586C634.366 31.9987 634.397 31.8404 634.458 31.6926C634.52 31.5449 634.609 31.4107 634.722 31.2976C634.836 31.1846 634.97 31.095 635.118 31.0339C635.266 30.9729 635.424 30.9415 635.584 30.9418C635.744 30.9418 635.902 30.9733 636.05 31.0344C636.198 31.0956 636.332 31.1852 636.445 31.2982C636.558 31.4111 636.648 31.5453 636.709 31.6929C636.771 31.8405 636.802 31.9988 636.802 32.1586C636.802 32.4811 636.673 32.7903 636.445 33.0184C636.217 33.2465 635.907 33.3748 635.584 33.3753C635.261 33.3753 634.951 33.2471 634.723 33.0189C634.495 32.7908 634.366 32.4813 634.366 32.1586V32.1586Z"
                fill="#4E415C"/>
            <path
                d="M448.736 30.9508C448.736 30.7909 448.767 30.6326 448.829 30.4849C448.89 30.3371 448.979 30.2029 449.093 30.0898C449.206 29.9768 449.34 29.8872 449.488 29.8261C449.636 29.7651 449.794 29.7338 449.954 29.734C450.277 29.734 450.587 29.8622 450.816 30.0904C451.044 30.3186 451.172 30.6281 451.172 30.9508C451.172 31.2733 451.043 31.5826 450.815 31.8106C450.587 32.0387 450.277 32.1671 449.954 32.1675C449.631 32.1675 449.321 32.0393 449.093 31.8112C448.865 31.583 448.736 31.2735 448.736 30.9508V30.9508Z"
                fill="#4E415C"/>
            <path
                d="M636.711 30.9508C636.711 30.7909 636.742 30.6326 636.803 30.4849C636.864 30.3371 636.954 30.2029 637.067 30.0898C637.18 29.9768 637.315 29.8872 637.463 29.8261C637.61 29.7651 637.769 29.7338 637.929 29.734C638.252 29.734 638.562 29.8622 638.79 30.0904C639.019 30.3186 639.147 30.6281 639.147 30.9508C639.146 31.2733 639.018 31.5826 638.79 31.8106C638.561 32.0387 638.252 32.1671 637.929 32.1675C637.606 32.1675 637.296 32.0393 637.068 31.8112C636.839 31.583 636.711 31.2735 636.711 30.9508V30.9508Z"
                fill="#4E415C"/>
            <path
                d="M451.801 29.395C451.801 29.2352 451.832 29.0768 451.893 28.9291C451.954 28.7813 452.044 28.6471 452.157 28.5341C452.27 28.421 452.404 28.3314 452.552 28.2704C452.7 28.2093 452.859 28.178 453.019 28.1782C453.179 28.1782 453.337 28.2097 453.485 28.2709C453.633 28.332 453.767 28.4216 453.88 28.5346C453.993 28.6476 454.083 28.7817 454.144 28.9294C454.205 29.077 454.237 29.2352 454.237 29.395C454.237 29.7177 454.108 30.0272 453.88 30.2554C453.652 30.4836 453.342 30.6118 453.019 30.6118C452.859 30.612 452.7 30.5807 452.552 30.5196C452.404 30.4586 452.27 30.369 452.157 30.2559C452.044 30.1429 451.954 30.0087 451.893 29.8609C451.832 29.7132 451.801 29.5549 451.801 29.395V29.395Z"
                fill="#4E415C"/>
            <path
                d="M639.775 29.395C639.775 29.2352 639.806 29.0768 639.868 28.9291C639.929 28.7813 640.018 28.6471 640.132 28.5341C640.245 28.421 640.379 28.3314 640.527 28.2704C640.675 28.2093 640.833 28.178 640.993 28.1782C641.153 28.1782 641.312 28.2097 641.459 28.2709C641.607 28.332 641.741 28.4216 641.855 28.5346C641.968 28.6476 642.057 28.7817 642.119 28.9294C642.18 29.077 642.211 29.2352 642.211 29.395C642.211 29.7177 642.083 30.0272 641.855 30.2554C641.626 30.4836 641.316 30.6118 640.993 30.6118C640.833 30.612 640.675 30.5807 640.527 30.5196C640.379 30.4586 640.245 30.369 640.132 30.2559C640.018 30.1429 639.929 30.0087 639.868 29.8609C639.806 29.7132 639.775 29.5549 639.775 29.395V29.395Z"
                fill="#4E415C"/>
            <path
                d="M454.908 27.8825C454.909 27.56 455.037 27.2508 455.265 27.0227C455.494 26.7946 455.803 26.6663 456.126 26.6658C456.449 26.6658 456.759 26.7939 456.988 27.022C457.217 27.2502 457.345 27.5597 457.346 27.8825C457.346 28.0425 457.314 28.2009 457.253 28.3486C457.192 28.4964 457.102 28.6306 456.989 28.7436C456.875 28.8566 456.741 28.9462 456.593 29.0072C456.445 29.0683 456.286 29.0996 456.126 29.0993C455.803 29.0993 455.493 28.9711 455.265 28.7429C455.037 28.5147 454.908 28.2052 454.908 27.8825V27.8825Z"
                fill="#4E415C"/>
            <path
                d="M642.883 27.8825C642.883 27.56 643.012 27.2508 643.24 27.0227C643.468 26.7946 643.778 26.6663 644.101 26.6658C644.424 26.6658 644.734 26.7939 644.963 27.022C645.191 27.2502 645.32 27.5597 645.321 27.8825C645.321 28.0425 645.289 28.2009 645.228 28.3486C645.166 28.4964 645.076 28.6306 644.963 28.7436C644.85 28.8566 644.715 28.9462 644.567 29.0072C644.419 29.0683 644.261 29.0996 644.101 29.0993C643.778 29.0993 643.468 28.9711 643.24 28.7429C643.011 28.5147 642.883 28.2052 642.883 27.8825V27.8825Z"
                fill="#4E415C"/>
            <path
                d="M458.066 26.49C458.066 26.1673 458.195 25.8578 458.423 25.6296C458.652 25.4014 458.961 25.2732 459.284 25.2732C459.607 25.2732 459.917 25.4014 460.146 25.6296C460.374 25.8578 460.502 26.1673 460.502 26.49C460.503 26.6498 460.471 26.8082 460.41 26.9559C460.349 27.1036 460.259 27.2379 460.146 27.3509C460.033 27.4639 459.899 27.5536 459.751 27.6146C459.603 27.6757 459.444 27.707 459.284 27.7067C459.124 27.707 458.966 27.6757 458.818 27.6146C458.67 27.5536 458.536 27.4639 458.423 27.3509C458.309 27.2379 458.22 27.1036 458.159 26.9559C458.098 26.8082 458.066 26.6498 458.066 26.49Z"
                fill="#4E415C"/>
            <path
                d="M646.041 26.49C646.041 26.1673 646.169 25.8578 646.398 25.6296C646.626 25.4014 646.936 25.2732 647.259 25.2732C647.582 25.2732 647.892 25.4014 648.12 25.6296C648.349 25.8578 648.477 26.1673 648.477 26.49C648.477 26.6498 648.446 26.8082 648.385 26.9559C648.324 27.1036 648.234 27.2379 648.121 27.3509C648.008 27.4639 647.873 27.5536 647.725 27.6146C647.577 27.6757 647.419 27.707 647.259 27.7067C647.099 27.707 646.94 27.6757 646.793 27.6146C646.645 27.5536 646.51 27.4639 646.397 27.3509C646.284 27.2379 646.194 27.1036 646.133 26.9559C646.072 26.8082 646.041 26.6498 646.041 26.49Z"
                fill="#4E415C"/>
            <path
                d="M461.276 25.2859C461.276 24.9631 461.405 24.6536 461.633 24.4255C461.861 24.1973 462.171 24.0691 462.494 24.0691C462.817 24.0691 463.127 24.1973 463.356 24.4255C463.584 24.6536 463.712 24.9631 463.712 25.2859C463.713 25.4457 463.681 25.604 463.62 25.7518C463.559 25.8995 463.469 26.0337 463.356 26.1468C463.243 26.2598 463.109 26.3494 462.961 26.4105C462.813 26.4716 462.654 26.5029 462.494 26.5026C462.334 26.5029 462.176 26.4716 462.028 26.4105C461.88 26.3494 461.746 26.2598 461.633 26.1468C461.519 26.0337 461.43 25.8995 461.369 25.7518C461.307 25.604 461.276 25.4457 461.276 25.2859V25.2859Z"
                fill="#4E415C"/>
            <path
                d="M649.251 25.2859C649.251 24.9631 649.379 24.6536 649.608 24.4255C649.836 24.1973 650.146 24.0691 650.469 24.0691C650.792 24.0691 651.102 24.1973 651.33 24.4255C651.559 24.6536 651.687 24.9631 651.687 25.2859C651.687 25.4457 651.656 25.604 651.595 25.7518C651.534 25.8995 651.444 26.0337 651.331 26.1468C651.218 26.2598 651.083 26.3494 650.935 26.4105C650.787 26.4716 650.629 26.5029 650.469 26.5026C650.309 26.5029 650.15 26.4716 650.003 26.4105C649.855 26.3494 649.72 26.2598 649.607 26.1468C649.494 26.0337 649.404 25.8995 649.343 25.7518C649.282 25.604 649.251 25.4457 649.251 25.2859V25.2859Z"
                fill="#4E415C"/>
            <path
                d="M464.541 24.341C464.541 24.1811 464.572 24.0227 464.633 23.8748C464.694 23.727 464.784 23.5926 464.897 23.4795C465.01 23.3663 465.145 23.2765 465.292 23.2152C465.44 23.154 465.599 23.1224 465.759 23.1224C465.919 23.1224 466.078 23.154 466.225 23.2152C466.373 23.2765 466.508 23.3663 466.621 23.4795C466.734 23.5926 466.824 23.727 466.885 23.8748C466.946 24.0227 466.977 24.1811 466.977 24.341C466.977 24.5008 466.945 24.659 466.884 24.8067C466.823 24.9543 466.733 25.0884 466.62 25.2014C466.507 25.3144 466.373 25.404 466.225 25.4652C466.077 25.5263 465.919 25.5578 465.759 25.5578C465.599 25.5578 465.441 25.5263 465.293 25.4652C465.145 25.404 465.011 25.3144 464.898 25.2014C464.785 25.0884 464.695 24.9543 464.634 24.8067C464.573 24.659 464.541 24.5008 464.541 24.341V24.341Z"
                fill="#4E415C"/>
            <path
                d="M652.516 24.341C652.515 24.1811 652.547 24.0227 652.608 23.8748C652.669 23.727 652.759 23.5926 652.872 23.4795C652.985 23.3663 653.119 23.2765 653.267 23.2152C653.415 23.154 653.573 23.1224 653.734 23.1224C653.894 23.1224 654.052 23.154 654.2 23.2152C654.348 23.2765 654.482 23.3663 654.595 23.4795C654.709 23.5926 654.798 23.727 654.859 23.8748C654.92 24.0227 654.952 24.1811 654.952 24.341C654.952 24.5008 654.92 24.659 654.859 24.8067C654.798 24.9543 654.708 25.0884 654.595 25.2014C654.482 25.3144 654.347 25.404 654.2 25.4652C654.052 25.5263 653.894 25.5578 653.734 25.5578C653.574 25.5578 653.415 25.5263 653.267 25.4652C653.12 25.404 652.985 25.3144 652.872 25.2014C652.759 25.0884 652.67 24.9543 652.608 24.8067C652.547 24.659 652.516 24.5008 652.516 24.341V24.341Z"
                fill="#4E415C"/>
            <path
                d="M467.865 23.7209C467.866 23.398 467.994 23.0886 468.223 22.8604C468.452 22.6323 468.762 22.5042 469.085 22.5042C469.408 22.5046 469.717 22.633 469.946 22.861C470.174 23.0891 470.303 23.3984 470.303 23.7209C470.303 24.0438 470.175 24.3535 469.946 24.5819C469.718 24.8104 469.408 24.939 469.085 24.9395C468.925 24.9395 468.766 24.908 468.618 24.8467C468.47 24.7855 468.336 24.6957 468.223 24.5826C468.109 24.4694 468.019 24.3351 467.958 24.1873C467.897 24.0394 467.865 23.8809 467.865 23.7209V23.7209Z"
                fill="#4E415C"/>
            <path
                d="M655.84 23.7209C655.84 23.398 655.969 23.0886 656.198 22.8604C656.426 22.6323 656.736 22.5042 657.06 22.5042C657.382 22.5046 657.692 22.633 657.92 22.861C658.149 23.0891 658.277 23.3984 658.278 23.7209C658.278 24.0438 658.149 24.3535 657.921 24.5819C657.693 24.8104 657.383 24.939 657.06 24.9395C656.899 24.9395 656.741 24.908 656.593 24.8467C656.445 24.7855 656.31 24.6957 656.197 24.5826C656.084 24.4694 655.994 24.3351 655.933 24.1873C655.871 24.0394 655.84 23.8809 655.84 23.7209V23.7209Z"
                fill="#4E415C"/>
            <path
                d="M471.252 23.4996C471.252 23.1768 471.381 22.8673 471.61 22.6391C471.839 22.411 472.149 22.2828 472.472 22.2828C472.795 22.2833 473.104 22.4117 473.332 22.6398C473.561 22.8678 473.689 23.1771 473.69 23.4996C473.69 23.8223 473.561 24.1318 473.333 24.36C473.105 24.5882 472.795 24.7164 472.472 24.7164C472.312 24.7166 472.153 24.6853 472.005 24.6243C471.857 24.5632 471.723 24.4737 471.609 24.3606C471.496 24.2476 471.406 24.1134 471.345 23.9657C471.284 23.8179 471.252 23.6596 471.252 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M659.227 23.4996C659.227 23.1768 659.356 22.8673 659.584 22.6391C659.813 22.411 660.123 22.2828 660.446 22.2828C660.769 22.2833 661.079 22.4117 661.307 22.6398C661.535 22.8678 661.664 23.1771 661.664 23.4996C661.664 23.8223 661.536 24.1318 661.308 24.36C661.079 24.5882 660.769 24.7164 660.446 24.7164C660.286 24.7166 660.128 24.6853 659.98 24.6243C659.832 24.5632 659.697 24.4737 659.584 24.3606C659.471 24.2476 659.381 24.1134 659.319 23.9657C659.258 23.8179 659.227 23.6596 659.227 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M477.563 45.1197C482.994 41.7364 484.652 34.595 481.265 29.169C477.879 23.743 470.731 22.0871 465.299 25.4703C459.868 28.8536 458.21 35.995 461.597 41.421C464.983 46.847 472.132 48.503 477.563 45.1197Z"
                fill="#E8D4C1"/>
            <path
                d="M665.538 45.1197C670.969 41.7364 672.627 34.595 669.24 29.169C665.853 23.743 658.705 22.0871 653.274 25.4703C647.842 28.8536 646.185 35.995 649.571 41.421C652.958 46.847 660.106 48.503 665.538 45.1197Z"
                fill="#E8D4C1"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M460.165 33.0416C461.624 34.3998 463.544 35.1547 465.538 35.1542C467.638 35.1542 469.652 34.3208 471.137 32.8374C472.621 31.354 473.456 29.3421 473.456 27.2443C473.455 25.809 473.061 24.4012 472.317 23.1732C472.08 23.1587 471.844 23.137 471.602 23.137C468.822 23.1383 466.136 24.1403 464.036 25.9594C461.935 27.7784 460.561 30.2927 460.165 33.0416V33.0416Z"
                  fill="#232830"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M648.14 33.0416C649.598 34.3998 651.518 35.1547 653.513 35.1542C655.612 35.1542 657.626 34.3208 659.111 32.8374C660.596 31.354 661.43 29.3421 661.43 27.2443C661.429 25.809 661.036 24.4012 660.292 23.1732C660.054 23.1587 659.818 23.137 659.577 23.137C656.797 23.1383 654.111 24.1403 652.01 25.9594C649.91 27.7784 648.536 30.2927 648.14 33.0416V33.0416Z"
                  fill="#232830"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M478.02 25.0809C479.246 26.9529 479.899 29.1417 479.897 31.3788C479.897 34.4444 478.677 37.3844 476.507 39.552C474.337 41.7195 471.394 42.9372 468.326 42.9372C466.041 42.9402 463.806 42.2642 461.907 40.9951C462.956 42.6097 464.393 43.9366 466.086 44.8551C467.779 45.7736 469.675 46.2544 471.602 46.2539C474.67 46.2539 477.613 45.0361 479.783 42.8685C481.953 40.7009 483.172 37.7609 483.172 34.6954C483.173 32.7929 482.703 30.9196 481.804 29.2422C480.905 27.5648 479.606 26.1353 478.02 25.0809V25.0809Z"
                  fill="#FAFAFA"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M665.995 25.0809C667.221 26.9529 667.873 29.1417 667.872 31.3788C667.871 34.4444 666.652 37.3844 664.482 39.552C662.312 41.7195 659.369 42.9372 656.3 42.9372C654.015 42.9402 651.781 42.2642 649.882 40.9951C650.931 42.6097 652.367 43.9366 654.06 44.8551C655.754 45.7736 657.65 46.2544 659.577 46.2539C662.645 46.2539 665.588 45.0361 667.758 42.8685C669.927 40.7009 671.146 37.7609 671.146 34.6954C671.147 32.7929 670.677 30.9196 669.779 29.2422C668.88 27.5648 667.58 26.1353 665.995 25.0809V25.0809Z"
                  fill="#FAFAFA"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M460.53 35.2376C460.529 33.7196 460.828 32.2164 461.41 30.8138C461.991 29.4113 462.843 28.1369 463.918 27.0634C464.992 25.9899 466.268 25.1383 467.671 24.5574C469.075 23.9764 470.58 23.6773 472.099 23.6773C475.155 23.6743 478.086 24.8821 480.251 27.0357C479.169 25.8101 477.838 24.8286 476.347 24.1564C474.855 23.4842 473.238 23.1367 471.602 23.137C468.533 23.1374 465.591 24.3556 463.421 26.5235C461.251 28.6914 460.032 31.6316 460.032 34.6972C460.031 36.222 460.332 37.7318 460.919 39.1394C461.505 40.547 462.366 41.8243 463.45 42.8973C461.568 40.7889 460.529 38.0625 460.53 35.2376V35.2376Z"
                  fill="#786A56"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M648.504 35.2376C648.504 33.7196 648.803 32.2164 649.384 30.8138C649.966 29.4113 650.818 28.1369 651.892 27.0634C652.967 25.9899 654.242 25.1383 655.646 24.5574C657.05 23.9764 658.554 23.6773 660.074 23.6773C663.129 23.6743 666.061 24.8821 668.226 27.0357C667.143 25.8101 665.812 24.8286 664.321 24.1564C662.83 23.4842 661.213 23.1367 659.577 23.137C656.508 23.1374 653.565 24.3556 651.395 26.5235C649.226 28.6914 648.007 31.6316 648.007 34.6972C648.005 36.222 648.307 37.7318 648.893 39.1394C649.48 40.547 650.34 41.8243 651.425 42.8973C649.543 40.7889 648.503 38.0625 648.504 35.2376V35.2376Z"
                  fill="#786A56"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M478.832 25.6793C480.004 26.7582 480.94 28.068 481.579 29.5262C482.219 30.9844 482.55 32.5593 482.55 34.1514C482.549 37.2171 481.33 40.157 479.16 42.3245C476.99 44.4921 474.047 45.7098 470.978 45.7098C468.349 45.7126 465.799 44.8167 463.75 43.1711C465.883 45.1539 468.689 46.2555 471.602 46.2538C474.671 46.2538 477.614 45.0361 479.783 42.8684C481.953 40.7008 483.172 37.7609 483.172 34.6954C483.173 32.9635 482.783 31.2536 482.032 29.6927C481.28 28.1319 480.187 26.7602 478.832 25.6793V25.6793Z"
                  fill="#F2F0ED"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M666.807 25.6793C667.979 26.7582 668.914 28.068 669.554 29.5262C670.194 30.9844 670.524 32.5593 670.524 34.1514C670.524 37.2171 669.304 40.157 667.134 42.3245C664.964 44.4921 662.021 45.7098 658.953 45.7098C656.324 45.7126 653.773 44.8167 651.725 43.1711C653.858 45.1539 656.663 46.2555 659.577 46.2538C662.645 46.2538 665.588 45.0361 667.758 42.8684C669.928 40.7008 671.147 37.7609 671.147 34.6954C671.147 32.9635 670.758 31.2536 670.006 29.6927C669.255 28.1319 668.161 26.7602 666.807 25.6793V25.6793Z"
                  fill="#F2F0ED"/>
            </g>
            <path
                d="M465.518 34.6973C465.518 33.0852 466.159 31.5391 467.3 30.3992C468.441 29.2593 469.988 28.6189 471.602 28.6189C473.216 28.6189 474.763 29.2593 475.904 30.3992C477.045 31.5391 477.686 33.0852 477.686 34.6973C477.686 36.3094 477.045 37.8555 475.904 38.9954C474.763 40.1353 473.216 40.7758 471.602 40.7758C469.988 40.7758 468.441 40.1353 467.3 38.9954C466.159 37.8555 465.518 36.3094 465.518 34.6973V34.6973Z"
                fill="#FAECDE"/>
            <path
                d="M653.492 34.6973C653.492 33.0852 654.133 31.5391 655.274 30.3992C656.415 29.2593 657.963 28.6189 659.577 28.6189C661.19 28.6189 662.738 29.2593 663.879 30.3992C665.02 31.5391 665.661 33.0852 665.661 34.6973C665.661 36.3094 665.02 37.8555 663.879 38.9954C662.738 40.1353 661.19 40.7758 659.577 40.7758C657.963 40.7758 656.415 40.1353 655.274 38.9954C654.133 37.8555 653.492 36.3094 653.492 34.6973V34.6973Z"
                fill="#FAECDE"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M476.149 30.668C476.994 31.7355 477.454 33.057 477.452 34.418C477.452 36.0301 476.811 37.5762 475.67 38.7161C474.529 39.8561 472.982 40.4965 471.368 40.4965C470.509 40.4954 469.661 40.3121 468.879 39.9589C468.096 39.6056 467.398 39.0904 466.83 38.4473C467.398 39.1728 468.123 39.7595 468.952 40.1629C469.78 40.5664 470.69 40.776 471.611 40.7757C473.225 40.7757 474.772 40.1353 475.914 38.9954C477.055 37.8555 477.696 36.3094 477.696 34.6973C477.693 33.21 477.143 31.7756 476.149 30.668V30.668Z"
                  fill="#F2F0ED"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M664.124 30.668C664.969 31.7355 665.428 33.057 665.427 34.418C665.427 36.0301 664.786 37.5762 663.645 38.7161C662.504 39.8561 660.956 40.4965 659.343 40.4965C658.484 40.4954 657.636 40.3121 656.853 39.9589C656.071 39.6056 655.373 39.0904 654.805 38.4473C655.372 39.1728 656.098 39.7595 656.926 40.1629C657.755 40.5664 658.664 40.776 659.586 40.7757C661.199 40.7757 662.747 40.1353 663.888 38.9954C665.029 37.8555 665.67 36.3094 665.67 34.6973C665.668 33.21 665.117 31.7756 664.124 30.668V30.668Z"
                  fill="#F2F0ED"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M465.81 35.1216C465.81 33.5095 466.451 31.9634 467.592 30.8235C468.733 29.6836 470.281 29.0432 471.894 29.0432C472.842 29.0431 473.777 29.265 474.624 29.6911C475.471 30.1172 476.205 30.7356 476.77 31.4967C476.226 30.6159 475.466 29.8887 474.561 29.3844C473.657 28.88 472.638 28.6152 471.602 28.6152C469.988 28.6152 468.441 29.2556 467.3 30.3956C466.159 31.5355 465.518 33.0816 465.518 34.6937C465.518 36.0013 465.942 37.2738 466.726 38.3204C466.127 37.3608 465.81 36.2526 465.81 35.1216V35.1216Z"
                  fill="#786A56"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M653.784 35.1216C653.784 33.5095 654.425 31.9634 655.566 30.8235C656.708 29.6836 658.255 29.0432 659.869 29.0432C660.817 29.0431 661.752 29.265 662.598 29.6911C663.445 30.1172 664.18 30.7356 664.744 31.4967C664.201 30.6159 663.44 29.8887 662.536 29.3844C661.631 28.88 660.612 28.6152 659.577 28.6152C657.963 28.6152 656.415 29.2556 655.274 30.3956C654.133 31.5355 653.492 33.0816 653.492 34.6937C653.492 36.0013 653.916 37.2738 654.701 38.3204C654.102 37.3608 653.784 36.2526 653.784 35.1216V35.1216Z"
                  fill="#786A56"/>
            </g>
            <path
                d="M469.292 34.6973C469.292 34.394 469.351 34.0936 469.467 33.8134C469.583 33.5331 469.754 33.2784 469.968 33.0638C470.183 32.8493 470.438 32.6791 470.718 32.563C470.998 32.4469 471.299 32.3871 471.603 32.3871C471.906 32.3871 472.207 32.4469 472.487 32.563C472.768 32.6791 473.023 32.8493 473.237 33.0638C473.452 33.2784 473.622 33.5331 473.738 33.8134C473.854 34.0936 473.914 34.394 473.913 34.6973C473.913 35.3096 473.67 35.8967 473.237 36.3296C472.803 36.7625 472.216 37.0057 471.603 37.0057C471.299 37.0057 470.999 36.946 470.718 36.83C470.438 36.714 470.183 36.544 469.969 36.3296C469.754 36.1153 469.584 35.8608 469.468 35.5807C469.352 35.3007 469.292 35.0005 469.292 34.6973Z"
                fill="#4E415C"/>
            <path
                d="M657.267 34.6973C657.266 34.394 657.326 34.0936 657.442 33.8134C657.558 33.5331 657.728 33.2784 657.943 33.0638C658.157 32.8493 658.412 32.6791 658.693 32.563C658.973 32.4469 659.274 32.3871 659.577 32.3871C659.881 32.3871 660.182 32.4469 660.462 32.563C660.742 32.6791 660.997 32.8493 661.212 33.0638C661.426 33.2784 661.597 33.5331 661.713 33.8134C661.829 34.0936 661.888 34.394 661.888 34.6973C661.888 35.3096 661.645 35.8967 661.211 36.3296C660.778 36.7625 660.19 37.0057 659.577 37.0057C659.274 37.0057 658.973 36.946 658.693 36.83C658.413 36.714 658.158 36.544 657.943 36.3296C657.729 36.1153 657.559 35.8608 657.442 35.5807C657.326 35.3007 657.267 35.0005 657.267 34.6973Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M473.156 32.9982C473.35 33.3375 473.453 33.7209 473.455 34.1116C473.455 34.724 473.212 35.3113 472.779 35.7445C472.345 36.1777 471.758 36.4213 471.145 36.4218C470.568 36.4203 470.014 36.2022 469.591 35.8107C469.788 36.1715 470.079 36.4726 470.433 36.6828C470.787 36.893 471.19 37.0046 471.602 37.0057C472.215 37.0057 472.803 36.7625 473.236 36.3296C473.669 35.8967 473.913 35.3095 473.913 34.6973C473.913 34.3769 473.845 34.0602 473.715 33.7675C473.584 33.4748 473.394 33.2128 473.156 32.9982Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M661.13 32.9982C661.324 33.3375 661.427 33.7209 661.43 34.1116C661.43 34.724 661.187 35.3113 660.753 35.7445C660.32 36.1777 659.732 36.4213 659.119 36.4218C658.543 36.4203 657.988 36.2022 657.565 35.8107C657.763 36.1715 658.054 36.4726 658.408 36.6828C658.761 36.893 659.165 37.0046 659.577 37.0057C660.189 37.0057 660.777 36.7625 661.211 36.3296C661.644 35.8967 661.887 35.3095 661.887 34.6973C661.887 34.3769 661.82 34.0602 661.689 33.7675C661.559 33.4748 661.368 33.2128 661.13 32.9982Z"
                  fill="#DBD3E6"/>
            </g>
            <path d="M316.43 33.709H314.098L312.967 25.8933H317.561L316.43 33.709Z" fill="#824E3E"/>
            <path d="M308.113 32.329L306.184 31.018L309.647 23.9204L313.444 26.499L308.113 32.329Z" fill="#824E3E"/>
            <path d="M302.899 27.1265L301.862 25.0393L308.368 20.553L310.41 24.6621L302.899 27.1265Z" fill="#824E3E"/>
            <path d="M300.895 19.1077L300.933 16.7775L308.772 15.7765L308.698 20.3625L300.895 19.1077Z" fill="#824E3E"/>
            <path d="M302.214 10.9729L303.435 8.9873L310.692 12.1172L308.287 16.0268L302.214 10.9729Z" fill="#824E3E"/>
            <path d="M307.135 5.48381L309.179 4.36133L313.94 10.6646L309.916 12.8769L307.135 5.48381Z" fill="#824E3E"/>
            <path d="M315.316 3.30774L317.648 3.38753L318.509 11.2376L313.918 11.0799L315.316 3.30774Z" fill="#824E3E"/>
            <path d="M323.339 4.76208L325.305 6.01694L322.047 13.2106L318.175 10.7408L323.339 4.76208Z" fill="#824E3E"/>
            <path d="M328.741 9.77966L329.83 11.8415L323.437 16.4855L321.293 12.4272L328.741 9.77966Z" fill="#824E3E"/>
            <path d="M330.811 18.0868L330.681 20.4134L322.807 21.1061L323.063 16.5237L330.811 18.0868Z" fill="#824E3E"/>
            <path d="M329.204 25.8807L327.933 27.8355L320.756 24.5297L323.254 20.6799L329.204 25.8807Z" fill="#824E3E"/>
            <path d="M324.269 31.3189L322.256 32.4976L317.33 26.3213L321.294 24.0038L324.269 31.3189Z" fill="#824E3E"/>
            <path d="M316.43 33.709H314.098L312.967 25.8933H317.561L316.43 33.709Z" fill="#824E3E"/>
            <path
                d="M315.829 29.2372C321.902 29.2372 326.825 24.3189 326.825 18.2518C326.825 12.1847 321.902 7.26636 315.829 7.26636C309.756 7.26636 304.833 12.1847 304.833 18.2518C304.833 24.3189 309.756 29.2372 315.829 29.2372Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M305.037 18.7033C305.037 15.7898 306.195 12.9956 308.258 10.9354C310.32 8.87526 313.117 7.71789 316.033 7.71789C318.13 7.71846 320.183 8.31862 321.949 9.44745C323.716 10.5763 325.122 12.1866 326.002 14.0883C325.176 12.0723 323.768 10.3474 321.958 9.13264C320.148 7.91791 318.018 7.26829 315.837 7.26636C312.921 7.26636 310.124 8.42373 308.062 10.4839C305.999 12.5441 304.841 15.3383 304.841 18.2518C304.842 19.8466 305.192 21.4219 305.868 22.8668C305.321 21.5466 305.039 20.1321 305.037 18.7033V18.7033Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M322.741 9.71265C323.906 10.7403 324.838 12.0036 325.476 13.4185C326.115 14.8335 326.445 16.3679 326.444 17.92C326.444 20.8335 325.286 23.6277 323.224 25.6878C321.161 27.748 318.364 28.9054 315.448 28.9054C312.93 28.908 310.488 28.0434 308.534 26.4573C310.54 28.2486 313.137 29.2383 315.827 29.2372C318.744 29.2372 321.541 28.0798 323.603 26.0197C325.665 23.9595 326.824 21.1653 326.824 18.2518C326.825 16.6143 326.458 14.9973 325.752 13.5196C325.046 12.042 324.017 10.7412 322.741 9.71265Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M309.952 18.6199C309.952 17.8218 310.109 17.0314 310.415 16.2941C310.721 15.5567 311.169 14.8867 311.734 14.3224C312.299 13.7582 312.97 13.3106 313.708 13.0053C314.446 12.7001 315.237 12.5431 316.036 12.5433C317.082 12.5437 318.11 12.8138 319.021 13.3275C319.932 13.8412 320.694 14.5811 321.235 15.4755C320.724 14.4819 319.95 13.6481 318.996 13.0658C318.042 12.4835 316.945 12.1754 315.828 12.1752C315.029 12.1752 314.237 12.3324 313.499 12.6379C312.761 12.9433 312.09 13.3911 311.525 13.9555C310.96 14.52 310.512 15.19 310.206 15.9275C309.901 16.665 309.743 17.4554 309.743 18.2536C309.744 19.3631 310.05 20.4509 310.629 21.398C310.184 20.5395 309.952 19.5867 309.952 18.6199V18.6199Z"
                  fill="#331911"/>
            </g>
            <path
                d="M315.827 9.91565C313.613 9.91565 311.489 10.7945 309.923 12.3588C308.357 13.9232 307.478 16.0449 307.478 18.2572C307.478 20.4695 308.357 22.5912 309.923 24.1555C311.489 25.7198 313.613 26.5987 315.827 26.5987C318.042 26.5987 320.166 25.7198 321.731 24.1555C323.297 22.5912 324.177 20.4695 324.177 18.2572C324.177 16.0449 323.297 13.9232 321.731 12.3588C320.166 10.7945 318.042 9.91565 315.827 9.91565V9.91565ZM315.827 24.3374C314.214 24.3374 312.666 23.697 311.525 22.5571C310.384 21.4172 309.743 19.8711 309.743 18.259C309.743 16.6469 310.384 15.1008 311.525 13.9609C312.666 12.8209 314.214 12.1806 315.827 12.1806C317.441 12.1806 318.989 12.8209 320.13 13.9609C321.271 15.1008 321.912 16.6469 321.912 18.259C321.91 19.8698 321.268 21.414 320.127 22.5524C318.986 23.6908 317.44 24.3302 315.827 24.3302V24.3374Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M307.685 18.6199C307.685 16.4076 308.565 14.2859 310.131 12.7216C311.697 11.1573 313.821 10.2784 316.035 10.2784C317.489 10.2798 318.918 10.6607 320.18 11.3833C321.442 12.1059 322.492 13.1453 323.228 14.3984C322.523 13.0461 321.46 11.9129 320.155 11.1219C318.85 10.3309 317.353 9.91252 315.826 9.91211C314.73 9.91211 313.644 10.1279 312.631 10.5471C311.618 10.9663 310.697 11.5807 309.922 12.3553C309.147 13.1299 308.532 14.0494 308.112 15.0615C307.693 16.0735 307.477 17.1582 307.477 18.2536C307.477 19.7399 307.878 21.1986 308.636 22.477C308.011 21.2873 307.685 19.9636 307.685 18.6199V18.6199Z"
                  fill="#E8E6E1"/>
              <path
                  d="M316.037 24.6966C317.651 24.6966 319.198 24.0564 320.339 22.9169C321.48 21.7774 322.121 20.2318 322.122 18.62C322.121 17.5105 321.814 16.4227 321.236 15.4756C321.681 16.3341 321.913 17.2868 321.913 18.2537C321.913 19.8658 321.272 21.4118 320.131 22.5518C318.99 23.6917 317.442 24.3321 315.828 24.3321C314.782 24.3313 313.754 24.0609 312.844 23.5469C311.933 23.0329 311.17 22.2927 310.63 21.398C311.14 22.3915 311.915 23.225 312.869 23.807C313.823 24.3889 314.919 24.6968 316.037 24.6966V24.6966Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M304.831 18.2573C304.831 18.4749 304.851 18.6889 304.864 18.9028C306.078 19.507 307.415 19.8217 308.772 19.8222C311.114 19.8222 313.361 18.8926 315.018 17.2379C316.675 15.5831 317.606 13.3387 317.606 10.9983C317.605 9.7277 317.328 8.47242 316.795 7.319C316.475 7.2918 316.154 7.27185 315.827 7.27185C312.911 7.27185 310.114 8.42923 308.052 10.4894C305.99 12.5496 304.831 15.3437 304.831 18.2573V18.2573Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M320.649 8.38879C322.314 10.2933 323.231 12.7365 323.23 15.2651C323.23 16.6437 322.958 18.0088 322.43 19.2824C321.902 20.556 321.128 21.7133 320.152 22.6881C319.176 23.6629 318.018 24.4361 316.743 24.9637C315.468 25.4912 314.102 25.7627 312.722 25.7627C310.324 25.7646 307.999 24.9439 306.135 23.438C307.073 25.1913 308.47 26.6572 310.178 27.6792C311.885 28.7012 313.838 29.2409 315.828 29.2408C318.744 29.2408 321.541 28.0834 323.603 26.0232C325.665 23.9631 326.824 21.1689 326.824 18.2554C326.824 16.1978 326.245 14.1817 325.153 12.4371C324.061 10.6926 322.5 9.28973 320.649 8.38879V8.38879Z"
                  fill="#FAF8F2"/>
            </g>
            <path
                d="M312.294 18.2572C312.294 17.3208 312.666 16.4228 313.329 15.7607C313.992 15.0985 314.891 14.7266 315.828 14.7266C316.765 14.7266 317.664 15.0985 318.327 15.7607C318.99 16.4228 319.362 17.3208 319.362 18.2572C319.362 19.1933 318.989 20.0908 318.326 20.7526C317.664 21.4143 316.765 21.786 315.828 21.786C314.891 21.786 313.992 21.4143 313.33 20.7526C312.667 20.0908 312.294 19.1933 312.294 18.2572Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M318.629 16.1211C318.839 16.5759 318.949 17.0708 318.95 17.5718C318.95 18.5079 318.578 19.4056 317.916 20.0677C317.253 20.7298 316.355 21.1019 315.418 21.1024C314.874 21.1011 314.338 20.9738 313.852 20.7306C313.365 20.4874 312.942 20.1349 312.615 19.7007C312.894 20.3187 313.346 20.8432 313.916 21.2114C314.485 21.5796 315.149 21.776 315.828 21.777C316.763 21.777 317.661 21.4065 318.323 20.7468C318.986 20.087 319.359 19.1917 319.362 18.2573C319.361 17.4838 319.103 16.7325 318.629 16.1211V16.1211Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M312.842 19.0043C312.842 18.5408 312.933 18.0817 313.11 17.6533C313.288 17.225 313.548 16.8358 313.876 16.5079C314.204 16.18 314.594 15.9199 315.022 15.7425C315.451 15.565 315.91 15.4737 316.374 15.4737C316.846 15.4731 317.312 15.5674 317.747 15.7509C318.181 15.9344 318.573 16.2034 318.901 16.5418C318.597 15.9931 318.152 15.5357 317.612 15.2168C317.072 14.8979 316.456 14.7293 315.828 14.7284C314.891 14.7284 313.992 15.1004 313.329 15.7625C312.666 16.4246 312.294 17.3226 312.294 18.259C312.294 19.18 312.656 20.0641 313.301 20.7216C313.003 20.1981 312.845 19.6066 312.842 19.0043Z"
                  fill="#241133"/>
            </g>
            <path d="M355.484 45.3726L353.202 45.8586L350.465 38.451L354.956 37.4935L355.484 45.3726Z" fill="#824E3E"/>
            <path d="M361.869 42.3533L359.877 43.5701L354.833 37.4898L358.75 35.0962L361.869 42.3533Z" fill="#824E3E"/>
            <path d="M367.118 36.9404L365.73 38.8136L358.771 35.0672L361.506 31.3806L367.118 36.9404Z" fill="#824E3E"/>
            <path d="M370.057 30.4921L369.324 32.7044L361.542 31.3172L362.987 26.9633L370.057 30.4921Z" fill="#824E3E"/>
            <path d="M370.807 23.5323L370.798 25.8625L362.971 26.9632L362.987 22.3754L370.807 23.5323Z" fill="#824E3E"/>
            <path d="M369.157 15.6841L369.974 17.8674L363.042 21.661L361.434 17.3651L369.157 15.6841Z" fill="#824E3E"/>
            <path d="M364.752 9.33179L366.335 11.0454L361.35 17.1746L358.235 13.8036L364.752 9.33179Z" fill="#824E3E"/>
            <path d="M358.861 4.76758L360.905 5.89006L358.126 13.2832L354.102 11.0727L358.861 4.76758Z" fill="#824E3E"/>
            <path d="M351.534 2.77283L353.844 3.09561L353.879 10.991L349.33 10.3564L351.534 2.77283Z" fill="#824E3E"/>
            <path d="M344.709 3.15549L347.018 2.81458L349.28 10.38L344.736 11.0527L344.709 3.15549Z" fill="#824E3E"/>
            <path d="M337.905 5.861L339.949 4.73853L344.708 11.0418L340.684 13.2541L337.905 5.861Z" fill="#824E3E"/>
            <path d="M332.605 10.4905L334.188 8.77869L340.705 13.2487L337.59 16.6215L332.605 10.4905Z" fill="#824E3E"/>
            <path d="M329.019 16.6016L329.972 14.4745L337.573 16.636L335.697 20.8249L329.019 16.6016Z" fill="#824E3E"/>
            <path d="M327.49 23.6049L327.688 21.2837L335.58 20.825L335.188 25.3965L327.49 23.6049Z" fill="#824E3E"/>
            <path d="M328.094 30.1602L327.689 27.8663L335.197 25.3965L335.996 29.9136L328.094 30.1602Z" fill="#824E3E"/>
            <path d="M330.612 36.4799L329.537 34.4108L335.957 29.8066L338.076 33.8777L330.612 36.4799Z" fill="#824E3E"/>
            <path d="M335.562 41.9744L333.807 40.4385L338.109 33.8142L341.565 36.8353L335.562 41.9744Z" fill="#824E3E"/>
            <path d="M342.211 45.3943L340.009 44.6218L341.537 36.8733L345.87 38.3947L342.211 45.3943Z" fill="#824E3E"/>
            <path d="M349.333 46.2557H347.001L345.87 38.4401H350.464L349.333 46.2557Z" fill="#824E3E"/>
            <path
                d="M361.746 37.2103C368.603 30.3602 368.603 19.254 361.746 12.4039C354.889 5.55383 343.772 5.55384 336.915 12.4039C330.059 19.254 330.059 30.3602 336.915 37.2103C343.772 44.0604 354.889 44.0604 361.746 37.2103Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M355.378 8.3562C357.648 9.75186 359.522 11.7048 360.822 14.029C362.123 16.3532 362.805 18.9712 362.806 21.6337C362.806 25.7765 361.159 29.7496 358.226 32.679C355.294 35.6084 351.317 37.2541 347.17 37.2541C343.504 37.2527 339.955 35.9636 337.144 33.6124C334.332 31.2611 332.438 27.9973 331.792 24.3918C331.792 24.5315 331.771 24.6675 331.771 24.8071C331.771 29.4594 333.621 33.9209 336.914 37.2104C340.207 40.4999 344.673 42.3479 349.33 42.3479C351.636 42.3479 353.919 41.8942 356.049 41.0127C358.18 40.1312 360.115 38.8391 361.746 37.2103C363.376 35.5815 364.669 33.6478 365.552 31.5197C366.434 29.3915 366.888 27.1106 366.888 24.8071C366.886 21.2177 365.781 17.7154 363.723 14.7734C361.665 11.8313 358.752 9.59142 355.378 8.3562V8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M332.025 25.3293C332.025 23.0259 332.479 20.7449 333.361 18.6168C334.244 16.4886 335.537 14.555 337.167 12.9261C338.798 11.2973 340.733 10.0053 342.863 9.12379C344.994 8.24229 347.277 7.78858 349.583 7.78858C352.934 7.79003 356.215 8.75019 359.037 10.5555C361.859 12.3609 364.105 14.9359 365.509 17.9761C364.165 14.7994 361.913 12.089 359.036 10.1836C356.158 8.27812 352.782 7.26217 349.33 7.2627C347.024 7.26246 344.741 7.71598 342.611 8.59738C340.48 9.47878 338.544 10.7708 336.914 12.3996C335.283 14.0285 333.99 15.9622 333.107 18.0905C332.225 20.2187 331.771 22.4998 331.771 24.8035C331.772 27.3442 332.33 29.8538 333.404 32.1567C332.491 29.9962 332.022 27.6745 332.025 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <path
                d="M358.306 28.5208C360.359 23.5688 358.005 17.8916 353.048 15.8404C348.091 13.7892 342.409 16.1408 340.355 21.0928C338.302 26.0448 340.656 31.722 345.613 33.7732C350.57 35.8244 356.253 33.4728 358.306 28.5208Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M339.92 25.2151C339.921 22.6415 340.944 20.1734 342.766 18.3537C344.588 16.534 347.059 15.5118 349.635 15.5118C351.139 15.5115 352.623 15.8614 353.969 16.5337C355.315 17.206 356.485 18.1823 357.387 19.3851C356.499 18.0659 355.299 16.9852 353.894 16.2383C352.489 15.4914 350.922 15.1011 349.33 15.1019C346.753 15.1019 344.282 16.1244 342.461 17.9445C340.639 19.7646 339.615 22.2331 339.615 24.8071C339.615 26.9129 340.304 28.961 341.577 30.6389C340.495 29.0372 339.917 27.1479 339.92 25.2151V25.2151Z"
                  fill="#331911"/>
            </g>
            <path
                d="M349.33 11.4843C345.793 11.4843 342.401 12.8879 339.9 15.3864C337.399 17.8849 335.994 21.2737 335.994 24.8071C335.994 26.5566 336.339 28.289 337.009 29.9053C337.68 31.5216 338.662 32.9902 339.9 34.2271C341.139 35.4641 342.609 36.4453 344.227 37.1147C345.845 37.784 347.579 38.1284 349.33 38.1281C352.867 38.1281 356.258 36.7247 358.759 34.2265C361.259 31.7283 362.664 28.3401 362.664 24.8071C362.664 23.0576 362.32 21.3252 361.65 19.7088C360.98 18.0924 359.998 16.6237 358.759 15.3865C357.521 14.1494 356.051 13.168 354.433 12.4984C352.815 11.8289 351.081 11.4843 349.33 11.4843V11.4843ZM349.33 34.5141C346.754 34.5141 344.283 33.4916 342.461 31.6715C340.639 29.8514 339.615 27.3829 339.615 24.8089C339.615 22.2349 340.639 19.7664 342.461 17.9463C344.283 16.1262 346.754 15.1037 349.33 15.1037C351.906 15.1042 354.377 16.1269 356.198 17.947C358.02 19.767 359.043 22.2353 359.043 24.8089C359.042 27.3823 358.019 29.8501 356.198 31.6697C354.376 33.4893 351.906 34.5118 349.33 34.5123V34.5141Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M349.635 34.9203C352.211 34.9203 354.682 33.8978 356.504 32.0777C358.326 30.2577 359.349 27.7891 359.349 25.2151C359.349 23.11 358.66 21.0627 357.387 19.3851C358.47 20.9863 359.046 22.875 359.043 24.8071C359.043 27.3808 358.019 29.8491 356.198 31.6691C354.376 33.4891 351.906 34.5118 349.33 34.5123C347.825 34.5122 346.341 34.1621 344.996 33.4898C343.65 32.8175 342.48 31.8414 341.577 30.6389C342.466 31.9577 343.666 33.038 345.071 33.7846C346.476 34.5311 348.043 34.9212 349.635 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M336.301 25.2151C336.301 21.6822 337.706 18.2939 340.206 15.7957C342.707 13.2975 346.099 11.8941 349.635 11.8941C351.732 11.8939 353.799 12.3891 355.668 13.3393C357.537 14.2894 359.154 15.6677 360.388 17.3614C359.168 15.5512 357.522 14.0684 355.594 13.0435C353.665 12.0186 351.514 11.4832 349.33 11.4843C345.793 11.4843 342.401 12.8879 339.9 15.3864C337.399 17.8849 335.994 21.2737 335.994 24.8071C335.994 27.633 336.899 30.3849 338.575 32.6608C337.089 30.462 336.297 27.8684 336.301 25.2151V25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M362.468 20.1721C365.144 29.1864 358.357 38.1245 349.058 38.1245C346.161 38.1243 343.336 37.225 340.974 35.551C338.611 33.8771 336.827 31.5111 335.869 28.7802C336.727 31.6687 338.496 34.2029 340.912 36.0054C343.329 37.8079 346.264 38.7821 349.28 38.7827C358.867 38.7864 365.69 29.3188 362.468 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M331.934 22.5404C334.276 24.2551 337.105 25.1793 340.009 25.1789C341.813 25.1791 343.599 24.8244 345.265 24.135C346.931 23.4456 348.445 22.435 349.721 21.1609C350.996 19.8868 352.008 18.3743 352.698 16.7096C353.388 15.0449 353.743 13.2607 353.743 11.4589C353.739 10.1909 353.56 8.92952 353.211 7.71063C351.938 7.41866 350.636 7.26965 349.33 7.26636C345.068 7.2679 340.953 8.81871 337.752 11.6293C334.55 14.4399 332.482 18.3183 331.934 22.5404V22.5404Z"
                  fill="#26170D"/>
            </g>
            <path
                d="M343.687 24.8072C343.686 24.0663 343.831 23.3326 344.115 22.648C344.398 21.9634 344.813 21.3412 345.337 20.8171C345.862 20.293 346.484 19.8773 347.169 19.5936C347.854 19.31 348.588 19.1639 349.33 19.1639C350.826 19.1644 352.261 19.7586 353.319 20.8159C354.377 21.8731 354.971 23.3068 354.971 24.8017C354.971 26.2963 354.376 27.7296 353.318 28.7864C352.261 29.8433 350.826 30.4372 349.33 30.4377C347.834 30.4377 346.4 29.8447 345.342 28.789C344.284 27.7333 343.688 26.3011 343.687 24.8072V24.8072Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M353.634 21.1677C354.06 21.9748 354.283 22.8737 354.282 23.7862C354.282 25.2808 353.687 26.7141 352.629 27.7709C351.571 28.8278 350.137 29.4217 348.641 29.4222C347.821 29.4231 347.011 29.2451 346.267 28.9005C345.523 28.556 344.864 28.0532 344.335 27.4275C344.813 28.3378 345.531 29.1002 346.411 29.6321C347.292 30.164 348.301 30.4451 349.33 30.4449C350.826 30.4444 352.261 29.8505 353.319 28.7937C354.377 27.7368 354.971 26.3036 354.972 24.809C354.974 23.4748 354.499 22.1837 353.634 21.1677V21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M344.393 25.8934C344.393 24.3988 344.988 22.9655 346.046 21.9087C347.103 20.8518 348.538 20.2579 350.034 20.2574C350.866 20.2548 351.689 20.4366 352.442 20.7896C353.195 21.1427 353.861 21.6583 354.39 22.2993C353.924 21.357 353.202 20.564 352.308 20.01C351.414 19.4559 350.382 19.1628 349.33 19.1639C347.833 19.1639 346.398 19.7579 345.339 20.8152C344.281 21.8725 343.687 23.3065 343.687 24.8017C343.685 26.1142 344.144 27.3857 344.983 28.3958C344.595 27.6184 344.393 26.7618 344.393 25.8934V25.8934Z"
                  fill="#241133"/>
            </g>
            <path d="M217.495 45.3726L215.214 45.8586L212.477 38.451L216.969 37.4935L217.495 45.3726Z" fill="#824E3E"/>
            <path d="M223.881 42.3533L221.892 43.5701L216.846 37.4898L220.765 35.0962L223.881 42.3533Z" fill="#824E3E"/>
            <path d="M229.13 36.9404L227.742 38.8136L220.784 35.0672L223.518 31.3806L229.13 36.9404Z" fill="#824E3E"/>
            <path d="M232.071 30.4921L231.337 32.7044L223.556 31.3172L224.999 26.9633L232.071 30.4921Z" fill="#824E3E"/>
            <path d="M232.818 23.5323L232.811 25.8625L224.982 26.9632L225.001 22.3754L232.818 23.5323Z" fill="#824E3E"/>
            <path d="M231.169 15.6841L231.987 17.8674L225.055 21.661L223.445 17.3651L231.169 15.6841Z" fill="#824E3E"/>
            <path d="M226.765 9.33179L228.346 11.0454L223.364 17.1746L220.249 13.8036L226.765 9.33179Z" fill="#824E3E"/>
            <path d="M220.874 4.76758L222.919 5.89006L220.14 13.2832L216.114 11.0727L220.874 4.76758Z" fill="#824E3E"/>
            <path d="M213.547 2.77283L215.856 3.09561L215.891 10.991L211.344 10.3564L213.547 2.77283Z" fill="#824E3E"/>
            <path d="M206.723 3.15549L209.03 2.81458L211.293 10.38L206.75 11.0527L206.723 3.15549Z" fill="#824E3E"/>
            <path d="M199.917 5.861L201.963 4.73853L206.722 11.0418L202.698 13.2541L199.917 5.861Z" fill="#824E3E"/>
            <path d="M194.619 10.4905L196.202 8.77869L202.717 13.2487L199.604 16.6215L194.619 10.4905Z" fill="#824E3E"/>
            <path d="M191.03 16.6016L191.983 14.4745L199.585 16.636L197.71 20.8249L191.03 16.6016Z" fill="#824E3E"/>
            <path d="M189.502 23.6049L189.702 21.2837L197.592 20.825L197.202 25.3965L189.502 23.6049Z" fill="#824E3E"/>
            <path d="M190.108 30.1602L189.703 27.8663L197.211 25.3965L198.007 29.9136L190.108 30.1602Z" fill="#824E3E"/>
            <path d="M192.626 36.4799L191.55 34.4108L197.972 29.8066L200.088 33.8777L192.626 36.4799Z" fill="#824E3E"/>
            <path d="M197.574 41.9744L195.818 40.4385L200.12 33.8142L203.576 36.8353L197.574 41.9744Z" fill="#824E3E"/>
            <path d="M204.224 45.3943L202.022 44.6218L203.551 36.8733L207.884 38.3947L204.224 45.3943Z" fill="#824E3E"/>
            <path d="M211.347 46.2557H209.013L207.884 38.4401H212.476L211.347 46.2557Z" fill="#824E3E"/>
            <path
                d="M223.759 37.2108C230.616 30.3607 230.616 19.2545 223.759 12.4044C216.902 5.55433 205.785 5.55433 198.928 12.4044C192.071 19.2545 192.071 30.3607 198.928 37.2108C205.785 44.0609 216.902 44.0609 223.759 37.2108Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M217.392 8.3562C219.661 9.75208 221.535 11.7051 222.835 14.0293C224.135 16.3535 224.818 18.9714 224.818 21.6337C224.818 25.7765 223.171 29.7496 220.238 32.679C217.306 35.6084 213.329 37.2541 209.182 37.2541C205.516 37.2526 201.967 35.9635 199.156 33.6122C196.345 31.2609 194.452 27.9971 193.806 24.3918C193.806 24.5315 193.784 24.6675 193.784 24.8071C193.784 27.1106 194.238 29.3915 195.121 31.5197C196.003 33.6478 197.296 35.5815 198.927 37.2103C200.557 38.8391 202.493 40.1312 204.623 41.0127C206.753 41.8942 209.036 42.3479 211.342 42.3479C213.648 42.3479 215.931 41.8942 218.061 41.0127C220.192 40.1312 222.127 38.8391 223.758 37.2103C225.388 35.5815 226.681 33.6478 227.564 31.5197C228.446 29.3915 228.9 27.1106 228.9 24.8071C228.898 21.2179 227.794 17.7157 225.736 14.7738C223.678 11.8318 220.765 9.59171 217.392 8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M194.038 25.3293C194.038 23.0259 194.492 20.7449 195.375 18.6168C196.257 16.4886 197.55 14.555 199.181 12.9261C200.811 11.2973 202.747 10.0053 204.877 9.12379C207.007 8.24229 209.291 7.78858 211.596 7.78858C214.947 7.78993 218.228 8.75009 221.05 10.5555C223.872 12.3609 226.117 14.9359 227.521 17.9761C226.176 14.7994 223.925 12.089 221.048 10.1836C218.17 8.27812 214.794 7.26217 211.342 7.2627C206.685 7.2627 202.22 9.11073 198.927 12.4003C195.634 15.6898 193.784 20.1514 193.784 24.8035C193.786 27.3442 194.344 29.8538 195.418 32.1567C194.504 29.9964 194.035 27.6745 194.038 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <path
                d="M220.778 27.0904C222.04 21.8806 218.835 16.6356 213.62 15.3752C208.406 14.1148 203.155 17.3164 201.894 22.5262C200.632 27.7359 203.837 32.981 209.052 34.2413C214.267 35.5017 219.517 32.3001 220.778 27.0904Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M201.934 25.2151C201.934 22.6415 202.958 20.1734 204.78 18.3537C206.602 16.534 209.072 15.5118 211.648 15.5118C213.153 15.5113 214.637 15.8612 215.982 16.5335C217.327 17.2059 218.497 18.1822 219.399 19.3851C218.511 18.0657 217.311 16.9848 215.906 16.2379C214.501 15.4909 212.933 15.1008 211.342 15.1019C208.765 15.1019 206.294 16.1244 204.472 17.9445C202.65 19.7646 201.627 22.2331 201.627 24.8071C201.627 26.9131 202.317 28.9611 203.591 30.6389C202.507 29.0375 201.93 27.148 201.934 25.2151V25.2151Z"
                  fill="#331911"/>
            </g>
            <path
                d="M211.342 11.4843C209.591 11.4843 207.857 11.8289 206.239 12.4984C204.621 13.168 203.151 14.1494 201.913 15.3865C200.674 16.6237 199.692 18.0924 199.022 19.7088C198.352 21.3252 198.008 23.0576 198.008 24.8071C198.008 28.3401 199.413 31.7283 201.913 34.2265C204.414 36.7247 207.805 38.1281 211.342 38.1281C214.879 38.1281 218.27 36.7247 220.771 34.2266C223.272 31.7285 224.677 28.3402 224.678 24.8071C224.678 21.2737 223.273 17.8849 220.772 15.3864C218.271 12.8879 214.879 11.4843 211.342 11.4843ZM211.342 34.5141C208.765 34.5141 206.294 33.4916 204.473 31.6715C202.651 29.8514 201.627 27.3829 201.627 24.8089C201.627 23.5343 201.878 22.2721 202.366 21.0944C202.854 19.9167 203.57 18.8465 204.472 17.9451C205.374 17.0437 206.445 16.3287 207.624 15.8409C208.803 15.353 210.066 15.1019 211.342 15.1019C213.918 15.1019 216.389 16.1244 218.211 17.9445C220.033 19.7646 221.057 22.2331 221.057 24.8071C221.057 27.3811 220.033 29.8496 218.211 31.6697C216.389 33.4898 213.918 34.5123 211.342 34.5123V34.5141Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M211.648 34.9203C214.224 34.9198 216.695 33.8971 218.516 32.0771C220.338 30.2571 221.361 27.7888 221.361 25.2151C221.362 23.1099 220.673 21.0623 219.399 19.3851C220.483 20.9857 221.06 22.8747 221.056 24.8071C221.056 27.3811 220.033 29.8496 218.211 31.6697C216.389 33.4898 213.918 34.5123 211.342 34.5123C209.837 34.5123 208.354 34.1623 207.008 33.49C205.663 32.8176 204.493 31.8415 203.591 30.6389C204.479 31.958 205.679 33.0384 207.084 33.785C208.489 34.5316 210.057 34.9215 211.648 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M198.313 25.2151C198.313 23.4656 198.658 21.7333 199.328 20.1169C199.998 18.5006 200.98 17.0321 202.219 15.7951C203.457 14.5581 204.927 13.5769 206.545 12.9076C208.163 12.2382 209.897 11.8938 211.649 11.8941C213.745 11.8943 215.813 12.3897 217.681 13.3398C219.55 14.29 221.167 15.668 222.402 17.3614C221.182 15.5509 219.535 14.068 217.606 13.0431C215.678 12.0182 213.526 11.4829 211.342 11.4843C209.591 11.4843 207.857 11.8289 206.239 12.4984C204.621 13.168 203.151 14.1494 201.913 15.3865C200.674 16.6237 199.692 18.0924 199.022 19.7088C198.352 21.3252 198.008 23.0576 198.008 24.8071C198.008 27.633 198.912 30.3849 200.589 32.6608C199.101 30.4626 198.308 27.8687 198.313 25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M224.482 20.1721C227.156 29.1864 220.371 38.1245 211.07 38.1245C208.174 38.1227 205.349 37.2228 202.987 35.549C200.624 33.8753 198.84 31.5102 197.881 28.7802C198.739 31.6685 200.508 34.2026 202.924 36.0051C205.341 37.8075 208.275 38.7818 211.291 38.7827C220.881 38.7864 227.704 29.3188 224.482 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M193.947 22.5404C196.289 24.2554 199.117 25.1797 202.021 25.1789C203.825 25.1791 205.611 24.8244 207.277 24.135C208.943 23.4456 210.457 22.435 211.733 21.1609C213.008 19.8868 214.02 18.3743 214.71 16.7096C215.4 15.0449 215.755 13.2607 215.755 11.4589C215.752 10.1909 215.573 8.92933 215.223 7.71063C213.95 7.41845 212.648 7.26944 211.342 7.26636C207.08 7.26795 202.965 8.81883 199.764 11.6295C196.563 14.4401 194.496 18.3184 193.947 22.5404Z"
                  fill="#26170D"/>
            </g>
            <path
                d="M205.7 24.8072C205.699 24.0664 205.844 23.3328 206.128 22.6481C206.411 21.9635 206.826 21.3414 207.35 20.8172C207.874 20.2931 208.496 19.8773 209.181 19.5936C209.866 19.31 210.6 19.1639 211.342 19.1639C212.838 19.1639 214.274 19.7579 215.332 20.8152C216.39 21.8725 216.985 23.3065 216.985 24.8017C216.986 25.5424 216.84 26.2759 216.557 26.9603C216.273 27.6447 215.857 28.2667 215.333 28.7906C214.809 29.3145 214.187 29.7301 213.502 30.0136C212.817 30.2972 212.083 30.4431 211.342 30.4431C209.845 30.4431 208.411 29.8493 207.353 28.7924C206.295 27.7354 205.7 26.3019 205.7 24.8072V24.8072Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M215.648 21.1677C216.074 21.9748 216.296 22.8737 216.296 23.7862C216.295 25.2811 215.7 26.7147 214.642 27.7716C213.584 28.8285 212.149 29.4222 210.652 29.4222C209.833 29.4228 209.023 29.2447 208.28 28.9001C207.536 28.5556 206.877 28.053 206.349 27.4275C206.826 28.3373 207.544 29.0993 208.424 29.6309C209.305 30.1624 210.314 30.4433 211.342 30.4431C212.838 30.4431 214.273 29.8493 215.331 28.7924C216.389 27.7354 216.984 26.3019 216.984 24.8072C216.986 23.4739 216.512 22.1834 215.648 21.1677V21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M206.403 25.8934C206.403 24.3986 206.997 22.9651 208.055 21.9082C209.113 20.8512 210.548 20.2574 212.044 20.2574C212.874 20.2564 213.695 20.439 214.446 20.792C215.197 21.145 215.861 21.6597 216.39 22.2993C215.925 21.358 215.206 20.5655 214.314 20.0114C213.422 19.4573 212.392 19.1637 211.342 19.1639C210.601 19.1639 209.867 19.3098 209.182 19.5931C208.498 19.8765 207.876 20.2918 207.352 20.8153C206.828 21.3389 206.413 21.9604 206.129 22.6444C205.846 23.3284 205.7 24.0614 205.7 24.8017C205.699 26.1142 206.158 27.3857 206.996 28.3958C206.607 27.619 206.403 26.7622 206.403 25.8934V25.8934Z"
                  fill="#241133"/>
            </g>
            <path d="M245.03 36.6049H242.697L241.568 28.7893H246.161L245.03 36.6049Z" fill="#824E3E"/>
            <path d="M236.713 35.2249L234.783 33.9138L238.247 26.8163L242.044 29.3949L236.713 35.2249Z" fill="#824E3E"/>
            <path d="M231.499 30.0223L230.461 27.9351L236.966 23.4489L239.008 27.558L231.499 30.0223Z" fill="#824E3E"/>
            <path d="M229.493 22.0037L229.531 19.6735L237.371 18.6725L237.297 23.2603L229.493 22.0037Z" fill="#824E3E"/>
            <path d="M230.813 13.8688L232.037 11.8832L239.292 15.0149L236.887 18.9227L230.813 13.8688Z" fill="#824E3E"/>
            <path d="M235.734 8.38162L237.778 7.25732L242.539 13.5624L238.515 15.7729L235.734 8.38162Z" fill="#824E3E"/>
            <path d="M243.917 6.20374L246.248 6.28352L247.108 14.1336L242.518 13.9759L243.917 6.20374Z" fill="#824E3E"/>
            <path d="M251.938 7.65808L253.904 8.91294L250.645 16.1066L246.775 13.6368L251.938 7.65808Z" fill="#824E3E"/>
            <path d="M257.34 12.6757L258.427 14.7375L252.036 19.3833L249.893 15.325L257.34 12.6757Z" fill="#824E3E"/>
            <path d="M259.411 20.9827L259.281 23.3093L251.408 24.002L251.662 19.4214L259.411 20.9827Z" fill="#824E3E"/>
            <path d="M257.802 28.7766L256.532 30.7332L249.354 27.4274L251.854 23.5776L257.802 28.7766Z" fill="#824E3E"/>
            <path d="M252.869 34.2168L250.856 35.3936L245.93 29.2191L249.894 26.9016L252.869 34.2168Z" fill="#824E3E"/>
            <path d="M245.03 36.6049H242.697L241.568 28.7893H246.161L245.03 36.6049Z" fill="#824E3E"/>
            <path
                d="M248.693 31.302C254.297 28.9626 256.941 22.5281 254.599 16.9302C252.257 11.3323 245.817 8.69069 240.213 11.0301C234.61 13.3694 231.966 19.8039 234.307 25.4018C236.649 30.9997 243.09 33.6413 248.693 31.302Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M233.636 21.5993C233.636 18.6858 234.794 15.8916 236.857 13.8314C238.919 11.7713 241.716 10.6139 244.632 10.6139C246.729 10.6149 248.782 11.2152 250.548 12.344C252.314 13.4728 253.721 15.0829 254.601 16.9843C253.774 14.9671 252.365 13.2412 250.553 12.0264C248.741 10.8116 246.609 10.1627 244.427 10.1624C241.511 10.1624 238.714 11.3198 236.651 13.3799C234.589 15.4401 233.431 18.2342 233.431 21.1478C233.432 22.7426 233.782 24.3179 234.458 25.7628C233.915 24.4417 233.636 23.0274 233.636 21.5993V21.5993Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M251.341 12.6104C252.505 13.6377 253.437 14.9006 254.076 16.3153C254.714 17.7299 255.044 19.2641 255.044 20.8159C255.044 23.7294 253.885 26.5236 251.823 28.5837C249.761 30.6439 246.964 31.8013 244.048 31.8013C241.53 31.8039 239.088 30.9393 237.134 29.3532C239.14 31.1445 241.736 32.1342 244.427 32.1331C247.343 32.1331 250.14 30.9757 252.203 28.9156C254.265 26.8554 255.423 24.0612 255.423 21.1477C255.424 19.5104 255.058 17.8937 254.352 16.4164C253.645 14.939 252.616 13.6385 251.341 12.6104Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M238.551 21.5213C238.551 19.9092 239.193 18.3631 240.334 17.2232C241.475 16.0832 243.022 15.4428 244.636 15.4428C245.682 15.4432 246.71 15.7133 247.621 16.227C248.531 16.7407 249.294 17.4806 249.835 18.3751C249.324 17.3813 248.55 16.5474 247.595 15.9651C246.641 15.3828 245.545 15.0747 244.427 15.0747C242.813 15.0747 241.266 15.7151 240.125 16.8551C238.984 17.995 238.343 19.541 238.343 21.1531C238.344 22.2632 238.65 23.3516 239.229 24.2993C238.784 23.4407 238.552 22.488 238.551 21.5213Z"
                  fill="#331911"/>
            </g>
            <path
                d="M244.427 12.8044C242.212 12.8044 240.089 13.6833 238.523 15.2476C236.957 16.8119 236.077 18.9337 236.077 21.146C236.077 23.3583 236.957 25.48 238.523 27.0443C240.089 28.6086 242.212 29.4875 244.427 29.4875C246.641 29.4875 248.765 28.6086 250.331 27.0443C251.897 25.48 252.777 23.3583 252.777 21.146C252.777 18.9337 251.897 16.8119 250.331 15.2476C248.765 13.6833 246.641 12.8044 244.427 12.8044ZM244.427 27.2262C242.813 27.2262 241.266 26.5858 240.125 25.4459C238.984 24.3059 238.342 22.7599 238.342 21.1478C238.342 19.5357 238.984 17.9896 240.125 16.8497C241.266 15.7097 242.813 15.0693 244.427 15.0693C246.041 15.0693 247.588 15.7097 248.729 16.8497C249.87 17.9896 250.511 19.5357 250.511 21.1478C250.511 22.7599 249.87 24.3059 248.729 25.4459C247.588 26.5858 246.041 27.2262 244.427 27.2262V27.2262Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M236.284 21.5213C236.284 19.309 237.164 17.1873 238.73 15.623C240.295 14.0586 242.419 13.1798 244.634 13.1798C246.088 13.181 247.516 13.5616 248.778 14.2839C250.04 15.0062 251.091 16.0451 251.827 17.2979C251.122 15.9456 250.059 14.8124 248.754 14.0214C247.448 13.2305 245.951 12.8121 244.425 12.8116C242.21 12.8116 240.087 13.6905 238.521 15.2548C236.955 16.8192 236.075 18.9409 236.075 21.1532C236.076 22.6399 236.476 24.0992 237.235 25.3783C236.61 24.1886 236.284 22.8649 236.284 21.5213V21.5213Z"
                  fill="#E8E6E1"/>
              <path
                  d="M244.636 27.5943C246.25 27.5943 247.797 26.9539 248.938 25.8139C250.079 24.674 250.72 23.1279 250.72 21.5158C250.72 20.4057 250.414 19.317 249.834 18.3696C250.279 19.2283 250.511 20.1809 250.512 21.1477C250.512 22.7598 249.87 24.3059 248.729 25.4458C247.588 26.5857 246.041 27.2261 244.427 27.2261C243.381 27.2257 242.353 26.9554 241.442 26.4414C240.531 25.9274 239.769 25.187 239.229 24.2921C239.739 25.2862 240.513 26.1205 241.467 26.7031C242.421 27.2857 243.518 27.5941 244.636 27.5943V27.5943Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M233.431 21.1478C233.431 21.3654 233.451 21.5794 233.463 21.7951C234.678 22.3984 236.015 22.7131 237.371 22.7145C239.714 22.7145 241.961 21.7847 243.618 20.1295C245.275 18.4744 246.206 16.2296 246.206 13.8888C246.205 12.6188 245.928 11.3641 245.394 10.2113C245.075 10.1823 244.754 10.1624 244.427 10.1624C242.983 10.1624 241.553 10.4465 240.219 10.9986C238.885 11.5507 237.672 12.3598 236.651 13.3799C235.63 14.4 234.82 15.611 234.268 16.9438C233.715 18.2767 233.431 19.7051 233.431 21.1478V21.1478Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M249.247 11.2866C250.912 13.1905 251.829 15.633 251.829 18.1611C251.829 20.9452 250.721 23.6154 248.751 25.584C246.78 27.5527 244.107 28.6587 241.321 28.6587C238.923 28.6614 236.597 27.8407 234.733 26.334C235.672 28.0873 237.069 29.5532 238.776 30.5752C240.483 31.5972 242.436 32.1369 244.426 32.1368C247.343 32.1368 250.14 30.9794 252.202 28.9192C254.264 26.8591 255.423 24.0649 255.423 21.1514C255.423 19.094 254.844 17.078 253.752 15.3337C252.66 13.5893 251.099 12.1869 249.247 11.2866V11.2866Z"
                  fill="#FAF8F2"/>
            </g>
            <path
                d="M240.895 21.1477C240.895 20.2116 241.267 19.3139 241.929 18.6518C242.591 17.9897 243.49 17.6175 244.427 17.6171C245.364 17.6171 246.263 17.989 246.926 18.6512C247.589 19.3133 247.961 20.2113 247.961 21.1477C247.961 22.0841 247.589 22.9821 246.926 23.6443C246.263 24.3064 245.364 24.6784 244.427 24.6784C243.49 24.6779 242.591 24.3057 241.929 23.6436C241.267 22.9815 240.895 22.0838 240.895 21.1477V21.1477Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M247.229 19.0188C247.438 19.474 247.547 19.9687 247.549 20.4695C247.549 21.4056 247.177 22.3033 246.514 22.9654C245.852 23.6275 244.953 23.9997 244.016 24.0002C243.473 23.9989 242.937 23.8718 242.451 23.629C241.964 23.3861 241.541 23.0339 241.214 22.6002C241.493 23.2182 241.944 23.7427 242.514 24.1109C243.084 24.4792 243.748 24.6755 244.427 24.6765C245.364 24.6765 246.263 24.3046 246.926 23.6424C247.588 22.9803 247.961 22.0823 247.961 21.1459C247.958 20.3758 247.701 19.6282 247.229 19.0188V19.0188Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M241.441 21.9003C241.441 20.9642 241.813 20.0665 242.475 19.4044C243.138 18.7424 244.036 18.3702 244.973 18.3697C245.444 18.3695 245.911 18.464 246.345 18.6475C246.779 18.831 247.172 19.0997 247.5 19.4378C247.196 18.889 246.751 18.4314 246.211 18.1126C245.67 17.7937 245.054 17.6251 244.427 17.6244C243.49 17.6249 242.591 17.9971 241.929 18.6591C241.267 19.3212 240.895 20.219 240.895 21.155C240.894 22.0757 241.255 22.9599 241.9 23.6176C241.602 23.0941 241.444 22.5026 241.441 21.9003Z"
                  fill="#241133"/>
            </g>
            <path
                d="M347.867 48.0873C334.825 48.0873 322.091 41.7241 310.861 36.1081C301.328 31.3426 292.326 26.8599 285.069 27.2226C278.096 27.5852 271.901 30.7713 264.727 34.4561C252.9 40.5273 238.182 48.0854 211.941 48.0854C198.69 48.0854 187.917 37.3194 187.917 24.0854C187.917 10.8514 198.694 0.0854492 211.941 0.0854492H347.867C361.117 0.0854492 371.89 10.8514 371.89 24.0854C371.89 37.3194 361.114 48.0873 347.867 48.0873ZM285.944 23.5396C293.911 23.5396 302.952 28.0585 312.502 32.8332C323.371 38.2733 335.691 44.4242 347.867 44.4242C353.264 44.418 358.438 42.2734 362.254 38.4608C366.071 34.6482 368.217 29.479 368.224 24.0873C368.217 18.6955 366.071 13.5263 362.254 9.71373C358.438 5.90116 353.264 3.75651 347.867 3.75027H211.941C206.543 3.75651 201.369 5.90141 197.552 9.71437C193.736 13.5273 191.589 18.697 191.584 24.0891C191.59 29.4805 193.737 34.6493 197.554 38.4614C201.37 42.2736 206.544 44.418 211.941 44.4242C237.295 44.4242 251.576 37.091 263.05 31.2011C270.599 27.3241 277.117 23.9766 284.875 23.5686C285.229 23.5487 285.581 23.5396 285.944 23.5396Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M370.192 15.2416C371.084 17.7834 371.54 20.4578 371.539 23.1515C371.539 36.3891 360.762 47.1515 347.515 47.1515C334.475 47.1515 321.74 40.7884 310.511 35.1724C300.978 30.4068 291.977 25.9242 284.718 26.2868C277.744 26.6495 271.549 29.8374 264.376 33.5204C252.55 39.5934 237.833 47.1497 211.591 47.1497C206.779 47.1442 202.079 45.697 198.099 42.9951C194.119 40.2933 191.042 36.461 189.265 31.9935C190.911 36.6913 193.977 40.7625 198.039 43.645C202.101 46.5274 206.959 48.079 211.941 48.0854C238.183 48.0854 252.9 40.5273 264.728 34.4561C271.901 30.7713 278.096 27.5906 285.07 27.2225C292.331 26.8599 301.329 31.3407 310.862 36.1081C322.094 41.7295 334.822 48.0872 347.867 48.0872C361.118 48.0872 371.891 37.3212 371.891 24.0872C371.892 21.0576 371.316 18.0555 370.192 15.2416V15.2416Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M188.471 25.0212C188.471 11.7835 199.247 1.02297 212.494 1.02297H348.418C352.526 1.02491 356.565 2.07893 360.15 4.08436C363.734 6.08979 366.744 8.97967 368.892 12.4781C366.814 8.72659 363.769 5.59904 360.074 3.42015C356.378 1.24126 352.165 0.0904859 347.874 0.0872803H211.941C198.69 0.0872803 187.917 10.8533 187.917 24.0873C187.912 28.5167 189.142 32.86 191.469 36.6304C189.496 33.0789 188.464 29.0829 188.471 25.0212Z"
                  fill="#241133"/>
            </g>
            <path
                d="M284.739 23.4996C284.739 23.1769 284.868 22.8674 285.096 22.6392C285.324 22.411 285.634 22.2828 285.957 22.2828C286.28 22.2828 286.59 22.411 286.818 22.6392C287.047 22.8674 287.175 23.1769 287.175 23.4996C287.175 23.6595 287.144 23.8178 287.083 23.9655C287.022 24.1133 286.932 24.2475 286.819 24.3605C286.706 24.4736 286.571 24.5632 286.424 24.6243C286.276 24.6853 286.117 24.7166 285.957 24.7164C285.797 24.7166 285.639 24.6853 285.491 24.6243C285.343 24.5632 285.209 24.4736 285.095 24.3605C284.982 24.2475 284.893 24.1133 284.831 23.9655C284.77 23.8178 284.739 23.6595 284.739 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M287.665 23.6974C287.665 23.5375 287.696 23.3792 287.757 23.2314C287.818 23.0837 287.908 22.9495 288.021 22.8364C288.134 22.7234 288.269 22.6338 288.417 22.5727C288.565 22.5117 288.723 22.4804 288.883 22.4806C289.043 22.4804 289.201 22.5117 289.349 22.5727C289.497 22.6338 289.632 22.7234 289.745 22.8364C289.858 22.9495 289.948 23.0837 290.009 23.2314C290.07 23.3792 290.101 23.5375 290.101 23.6974C290.101 24.0201 289.973 24.3295 289.744 24.5577C289.516 24.7859 289.206 24.9141 288.883 24.9141C288.56 24.9141 288.25 24.7859 288.022 24.5577C287.793 24.3295 287.665 24.0201 287.665 23.6974V23.6974Z"
                fill="#4E415C"/>
            <path
                d="M290.679 24.2286C290.679 23.9059 290.807 23.5964 291.035 23.3682C291.264 23.14 291.574 23.0118 291.897 23.0118C292.057 23.0116 292.215 23.0429 292.363 23.1039C292.511 23.165 292.646 23.2546 292.759 23.3676C292.872 23.4806 292.962 23.6148 293.024 23.7626C293.085 23.9103 293.116 24.0687 293.116 24.2286C293.116 24.3886 293.085 24.5469 293.024 24.6947C292.962 24.8424 292.872 24.9766 292.759 25.0897C292.646 25.2027 292.511 25.2922 292.363 25.3533C292.215 25.4143 292.057 25.4456 291.897 25.4454C291.574 25.4454 291.264 25.3172 291.035 25.089C290.807 24.8608 290.679 24.5513 290.679 24.2286Z"
                fill="#4E415C"/>
            <path
                d="M293.775 25.0719C293.775 24.7492 293.904 24.4397 294.132 24.2115C294.361 23.9833 294.67 23.8551 294.993 23.8551C295.153 23.8549 295.312 23.8862 295.46 23.9472C295.608 24.0083 295.742 24.0979 295.855 24.211C295.968 24.324 296.058 24.4582 296.119 24.606C296.18 24.7537 296.212 24.912 296.211 25.0719C296.212 25.2317 296.18 25.3901 296.119 25.5378C296.058 25.6855 295.968 25.8198 295.855 25.9328C295.742 26.0458 295.608 26.1354 295.46 26.1965C295.312 26.2576 295.153 26.2889 294.993 26.2887C294.67 26.2887 294.361 26.1605 294.132 25.9323C293.904 25.7041 293.775 25.3946 293.775 25.0719Z"
                fill="#4E415C"/>
            <path
                d="M298.168 27.3912C298.841 27.3912 299.386 26.8464 299.386 26.1744C299.386 25.5024 298.841 24.9576 298.168 24.9576C297.496 24.9576 296.95 25.5024 296.95 26.1744C296.95 26.8464 297.496 27.3912 298.168 27.3912Z"
                fill="#4E415C"/>
            <path
                d="M300.191 27.4928C300.191 27.333 300.223 27.1748 300.284 27.0271C300.345 26.8795 300.435 26.7454 300.548 26.6324C300.661 26.5194 300.795 26.4298 300.943 26.3686C301.091 26.3075 301.249 26.276 301.409 26.276C301.732 26.276 302.042 26.4042 302.271 26.6324C302.499 26.8606 302.627 27.1701 302.627 27.4928C302.628 27.6526 302.596 27.811 302.535 27.9587C302.474 28.1064 302.384 28.2407 302.271 28.3537C302.158 28.4667 302.024 28.5564 301.876 28.6174C301.728 28.6785 301.569 28.7098 301.409 28.7096C301.249 28.7098 301.091 28.6785 300.943 28.6174C300.795 28.5564 300.661 28.4667 300.548 28.3537C300.434 28.2407 300.345 28.1064 300.284 27.9587C300.223 27.811 300.191 27.6526 300.191 27.4928V27.4928Z"
                fill="#4E415C"/>
            <path
                d="M303.501 28.9815C303.501 28.6588 303.629 28.3493 303.858 28.1211C304.086 27.8929 304.396 27.7648 304.719 27.7648C305.042 27.7653 305.351 27.8936 305.58 28.1217C305.808 28.3498 305.936 28.659 305.937 28.9815C305.937 29.3042 305.809 29.6137 305.58 29.8419C305.352 30.0701 305.042 30.1983 304.719 30.1983C304.559 30.1985 304.4 30.1672 304.253 30.1062C304.105 30.0451 303.97 29.9555 303.857 29.8424C303.744 29.7294 303.654 29.5952 303.593 29.4475C303.532 29.2997 303.501 29.1414 303.501 28.9815V28.9815Z"
                fill="#4E415C"/>
            <path
                d="M306.868 30.5973C306.868 30.2744 306.996 29.9647 307.225 29.7362C307.453 29.5077 307.763 29.3791 308.086 29.3787C308.246 29.3787 308.405 29.4102 308.553 29.4715C308.701 29.5327 308.835 29.6225 308.948 29.7357C309.061 29.8489 309.151 29.9832 309.212 30.1311C309.273 30.2789 309.304 30.4373 309.304 30.5973C309.304 30.92 309.176 31.2295 308.947 31.4576C308.719 31.6858 308.409 31.814 308.086 31.814C307.763 31.8136 307.454 31.6852 307.225 31.4571C306.997 31.229 306.869 30.9198 306.868 30.5973V30.5973Z"
                fill="#4E415C"/>
            <path
                d="M310.29 32.2927C310.29 31.9698 310.418 31.6601 310.647 31.4316C310.875 31.2032 311.185 31.0746 311.508 31.0741C311.668 31.0741 311.827 31.1056 311.975 31.1669C312.122 31.2282 312.257 31.3179 312.37 31.4311C312.483 31.5443 312.573 31.6786 312.634 31.8265C312.695 31.9743 312.726 32.1327 312.726 32.2927C312.726 32.6154 312.598 32.9249 312.369 33.1531C312.141 33.3812 311.831 33.5094 311.508 33.5094C311.185 33.509 310.876 33.3806 310.647 33.1525C310.419 32.9244 310.291 32.6152 310.29 32.2927V32.2927Z"
                fill="#4E415C"/>
            <path
                d="M313.421 33.8522C313.421 33.6923 313.452 33.5339 313.513 33.386C313.574 33.2382 313.664 33.1039 313.777 32.9907C313.89 32.8775 314.024 32.7877 314.172 32.7265C314.32 32.6652 314.479 32.6337 314.639 32.6337C314.799 32.6337 314.957 32.6652 315.105 32.7265C315.253 32.7877 315.388 32.8775 315.501 32.9907C315.614 33.1039 315.704 33.2382 315.765 33.386C315.826 33.5339 315.857 33.6923 315.857 33.8522C315.857 34.175 315.728 34.4844 315.5 34.7126C315.272 34.9408 314.962 35.069 314.639 35.069C314.479 35.069 314.321 35.0375 314.173 34.9764C314.025 34.9153 313.891 34.8256 313.778 34.7126C313.665 34.5996 313.575 34.4655 313.514 34.3179C313.452 34.1703 313.421 34.012 313.421 33.8522V33.8522Z"
                fill="#4E415C"/>
            <path
                d="M316.474 35.3609C316.473 35.201 316.505 35.0426 316.566 34.8947C316.627 34.7469 316.717 34.6125 316.83 34.4994C316.943 34.3862 317.077 34.2964 317.225 34.2351C317.373 34.1739 317.532 34.1423 317.692 34.1423C317.852 34.1423 318.01 34.1739 318.158 34.2351C318.306 34.2964 318.44 34.3862 318.553 34.4994C318.667 34.6125 318.756 34.7469 318.817 34.8947C318.878 35.0426 318.91 35.201 318.91 35.3609C318.91 35.6836 318.781 35.9931 318.553 36.2213C318.324 36.4495 318.015 36.5777 317.692 36.5777C317.369 36.5777 317.059 36.4495 316.83 36.2213C316.602 35.9931 316.474 35.6836 316.474 35.3609V35.3609Z"
                fill="#4E415C"/>
            <path
                d="M319.587 36.8479C319.587 36.688 319.618 36.5297 319.679 36.382C319.74 36.2342 319.83 36.1 319.943 35.9869C320.056 35.8739 320.191 35.7843 320.339 35.7232C320.486 35.6622 320.645 35.6309 320.805 35.6311C320.965 35.6309 321.123 35.6622 321.271 35.7232C321.419 35.7843 321.554 35.8739 321.667 35.9869C321.78 36.1 321.87 36.2342 321.931 36.382C321.992 36.5297 322.023 36.688 322.023 36.8479C322.023 37.0077 321.992 37.1661 321.931 37.3138C321.87 37.4615 321.78 37.5957 321.667 37.7088C321.554 37.8218 321.419 37.9114 321.271 37.9725C321.123 38.0336 320.965 38.0649 320.805 38.0646C320.645 38.0649 320.486 38.0336 320.339 37.9725C320.191 37.9114 320.056 37.8218 319.943 37.7088C319.83 37.5957 319.74 37.4615 319.679 37.3138C319.618 37.1661 319.587 37.0077 319.587 36.8479V36.8479Z"
                fill="#4E415C"/>
            <path
                d="M322.752 38.2877C322.752 37.965 322.88 37.6555 323.109 37.4273C323.337 37.1991 323.647 37.0709 323.97 37.0709C324.13 37.0707 324.288 37.102 324.436 37.1631C324.584 37.2241 324.719 37.3137 324.832 37.4268C324.945 37.5398 325.035 37.674 325.096 37.8218C325.157 37.9695 325.188 38.1278 325.188 38.2877C325.188 38.6104 325.06 38.9199 324.831 39.1481C324.603 39.3763 324.293 39.5045 323.97 39.5045C323.647 39.504 323.338 39.3756 323.109 39.1476C322.881 38.9195 322.752 38.6103 322.752 38.2877Z"
                fill="#4E415C"/>
            <path
                d="M327.183 40.8627C327.855 40.8627 328.401 40.318 328.401 39.646C328.401 38.974 327.855 38.4292 327.183 38.4292C326.51 38.4292 325.965 38.974 325.965 39.646C325.965 40.318 326.51 40.8627 327.183 40.8627Z"
                fill="#4E415C"/>
            <path
                d="M329.213 40.8953C329.213 40.5725 329.341 40.2628 329.57 40.0343C329.798 39.8058 330.108 39.6772 330.431 39.6768C330.591 39.6768 330.749 39.7083 330.897 39.7696C331.045 39.8308 331.18 39.9206 331.293 40.0338C331.406 40.147 331.496 40.2813 331.557 40.4292C331.618 40.577 331.649 40.7354 331.649 40.8953C331.649 41.218 331.521 41.5276 331.292 41.7557C331.064 41.9839 330.754 42.1121 330.431 42.1121C330.108 42.1116 329.798 41.9833 329.57 41.7552C329.342 41.5271 329.213 41.2179 329.213 40.8953V40.8953Z"
                fill="#4E415C"/>
            <path
                d="M332.492 42.0015C332.492 41.6787 332.62 41.369 332.849 41.1405C333.077 40.912 333.387 40.7834 333.71 40.783C333.87 40.783 334.029 40.8145 334.177 40.8758C334.325 40.937 334.459 41.0268 334.572 41.14C334.685 41.2532 334.775 41.3875 334.836 41.5354C334.897 41.6832 334.928 41.8416 334.928 42.0015C334.928 42.3242 334.8 42.6338 334.571 42.8619C334.343 43.0901 334.033 43.2183 333.71 43.2183C333.387 43.2178 333.078 43.0895 332.849 42.8614C332.621 42.6333 332.493 42.3241 332.492 42.0015V42.0015Z"
                fill="#4E415C"/>
            <path
                d="M335.796 42.9335C335.796 42.6107 335.924 42.301 336.153 42.0725C336.381 41.844 336.691 41.7154 337.014 41.715C337.174 41.715 337.332 41.7465 337.48 41.8078C337.628 41.869 337.763 41.9588 337.876 42.072C337.989 42.1852 338.079 42.3195 338.14 42.4674C338.201 42.6152 338.232 42.7736 338.232 42.9335C338.232 43.2563 338.104 43.5658 337.875 43.7939C337.647 44.0221 337.337 44.1503 337.014 44.1503C336.691 44.1499 336.381 44.0215 336.153 43.7934C335.925 43.5653 335.796 43.2561 335.796 42.9335V42.9335Z"
                fill="#4E415C"/>
            <path
                d="M339.116 43.6607C339.116 43.5009 339.147 43.3426 339.208 43.1948C339.27 43.0471 339.359 42.9129 339.472 42.7998C339.586 42.6868 339.72 42.5972 339.868 42.5361C340.016 42.475 340.174 42.4437 340.334 42.444C340.494 42.4437 340.653 42.475 340.801 42.5361C340.948 42.5972 341.083 42.6868 341.196 42.7998C341.309 42.9129 341.399 43.0471 341.46 43.1948C341.521 43.3426 341.552 43.5009 341.552 43.6607C341.552 43.9834 341.424 44.2929 341.195 44.5211C340.967 44.7493 340.657 44.8775 340.334 44.8775C340.011 44.8775 339.701 44.7493 339.473 44.5211C339.245 44.2929 339.116 43.9834 339.116 43.6607V43.6607Z"
                fill="#4E415C"/>
            <path
                d="M342.445 44.1504C342.445 43.8277 342.574 43.5182 342.802 43.29C343.03 43.0618 343.34 42.9336 343.663 42.9336C343.986 42.9336 344.296 43.0618 344.525 43.29C344.753 43.5182 344.881 43.8277 344.881 44.1504C344.881 44.3103 344.85 44.4687 344.789 44.6166C344.728 44.7644 344.638 44.8988 344.525 45.0119C344.412 45.1251 344.278 45.2149 344.13 45.2762C343.982 45.3374 343.823 45.369 343.663 45.369C343.503 45.369 343.345 45.3374 343.197 45.2762C343.049 45.2149 342.915 45.1251 342.801 45.0119C342.688 44.8988 342.599 44.7644 342.538 44.6166C342.476 44.4687 342.445 44.3103 342.445 44.1504Z"
                fill="#4E415C"/>
            <path
                d="M345.775 44.3698C345.775 44.21 345.806 44.0517 345.868 43.9039C345.929 43.7562 346.018 43.6219 346.132 43.5089C346.245 43.3959 346.379 43.3063 346.527 43.2452C346.675 43.1841 346.833 43.1528 346.993 43.1531C347.315 43.1545 347.623 43.2834 347.85 43.5115C348.077 43.7396 348.205 44.0482 348.204 44.3698C348.204 44.5296 348.173 44.6878 348.111 44.8355C348.05 44.9831 347.96 45.1172 347.847 45.2302C347.734 45.3432 347.6 45.4328 347.452 45.494C347.304 45.5551 347.146 45.5866 346.986 45.5866C346.664 45.5847 346.356 45.4557 346.13 45.2277C345.903 44.9997 345.775 44.6913 345.775 44.3698V44.3698Z"
                fill="#4E415C"/>
            <path
                d="M349.265 44.2175C349.265 44.0577 349.296 43.8995 349.357 43.7519C349.419 43.6042 349.508 43.4701 349.621 43.3571C349.734 43.2441 349.869 43.1545 350.017 43.0934C350.164 43.0322 350.323 43.0007 350.483 43.0007C350.643 43.0005 350.801 43.0318 350.949 43.0928C351.097 43.1539 351.232 43.2435 351.345 43.3565C351.458 43.4695 351.548 43.6037 351.61 43.7515C351.671 43.8992 351.702 44.0576 351.702 44.2175C351.702 44.3775 351.671 44.5358 351.61 44.6836C351.548 44.8313 351.458 44.9655 351.345 45.0785C351.232 45.1916 351.097 45.2811 350.949 45.3422C350.801 45.4032 350.643 45.4345 350.483 45.4343C350.16 45.4343 349.85 45.3061 349.621 45.0779C349.393 44.8497 349.265 44.5402 349.265 44.2175V44.2175Z"
                fill="#4E415C"/>
            <path
                d="M352.649 43.4813C352.649 43.1586 352.778 42.8491 353.006 42.6209C353.235 42.3927 353.544 42.2645 353.867 42.2645C354.19 42.2645 354.5 42.3927 354.729 42.6209C354.957 42.8491 355.085 43.1586 355.085 43.4813C355.086 43.6411 355.054 43.7995 354.993 43.9472C354.932 44.0949 354.842 44.2292 354.729 44.3422C354.616 44.4553 354.482 44.5449 354.334 44.6059C354.186 44.667 354.027 44.6983 353.867 44.6981C353.707 44.6983 353.549 44.667 353.401 44.6059C353.253 44.5449 353.119 44.4553 353.006 44.3422C352.892 44.2292 352.803 44.0949 352.742 43.9472C352.681 43.7995 352.649 43.6411 352.649 43.4813V43.4813Z"
                fill="#4E415C"/>
            <path
                d="M355.799 42.2082C355.799 41.8855 355.927 41.576 356.156 41.3478C356.384 41.1197 356.694 40.9915 357.017 40.9915C357.177 40.9912 357.335 41.0225 357.483 41.0836C357.631 41.1446 357.766 41.2342 357.879 41.3472C357.993 41.4602 358.082 41.5944 358.144 41.7422C358.205 41.8899 358.237 42.0483 358.237 42.2082C358.236 42.5311 358.107 42.8406 357.879 43.0687C357.65 43.2969 357.34 43.425 357.017 43.425C356.694 43.4245 356.384 43.2962 356.156 43.0681C355.928 42.84 355.799 42.5308 355.799 42.2082V42.2082Z"
                fill="#4E415C"/>
            <path
                d="M358.663 40.4475C358.663 40.2876 358.694 40.1293 358.755 39.9816C358.816 39.8338 358.906 39.6996 359.019 39.5866C359.132 39.4735 359.267 39.3839 359.415 39.3228C359.563 39.2618 359.721 39.2305 359.881 39.2307C360.041 39.2305 360.2 39.2618 360.347 39.3228C360.495 39.3839 360.63 39.4735 360.743 39.5866C360.856 39.6996 360.946 39.8338 361.007 39.9816C361.068 40.1293 361.099 40.2876 361.099 40.4475C361.099 40.6073 361.068 40.7657 361.007 40.9134C360.946 41.0611 360.856 41.1954 360.743 41.3084C360.63 41.4214 360.495 41.5111 360.347 41.5721C360.2 41.6332 360.041 41.6645 359.881 41.6642C359.721 41.6645 359.563 41.6332 359.415 41.5721C359.267 41.5111 359.132 41.4214 359.019 41.3084C358.906 41.1954 358.816 41.0611 358.755 40.9134C358.694 40.7657 358.663 40.6073 358.663 40.4475V40.4475Z"
                fill="#4E415C"/>
            <path
                d="M361.188 38.2532C361.188 38.0933 361.22 37.9349 361.281 37.787C361.342 37.6392 361.431 37.5049 361.545 37.3917C361.658 37.2785 361.792 37.1887 361.94 37.1275C362.088 37.0662 362.246 37.0347 362.406 37.0347C362.73 37.0351 363.039 37.1637 363.268 37.3922C363.496 37.6207 363.624 37.9304 363.624 38.2532C363.624 38.5758 363.495 38.885 363.267 39.1131C363.039 39.3412 362.729 39.4695 362.406 39.47C362.083 39.47 361.774 39.3418 361.545 39.1136C361.317 38.8854 361.188 38.576 361.188 38.2532V38.2532Z"
                fill="#4E415C"/>
            <path
                d="M363.321 35.6747C363.322 35.3518 363.45 35.0423 363.679 34.8142C363.908 34.586 364.218 34.4579 364.541 34.4579C364.864 34.4584 365.173 34.5867 365.402 34.8148C365.63 35.0429 365.759 35.3521 365.759 35.6747C365.759 35.9975 365.631 36.3072 365.402 36.5357C365.174 36.7642 364.864 36.8928 364.541 36.8933C364.218 36.8933 363.907 36.7649 363.679 36.5363C363.45 36.3078 363.321 35.9979 363.321 35.6747V35.6747Z"
                fill="#4E415C"/>
            <path
                d="M365.013 32.7643C365.013 32.4417 365.142 32.1325 365.37 31.9044C365.598 31.6763 365.908 31.548 366.231 31.5475C366.553 31.5489 366.861 31.6777 367.089 31.9057C367.317 32.1336 367.445 32.4423 367.445 32.7643C367.445 32.9242 367.413 33.0826 367.352 33.2303C367.291 33.378 367.201 33.5123 367.088 33.6253C366.974 33.7383 366.84 33.8279 366.692 33.8889C366.544 33.95 366.385 33.9813 366.225 33.981C365.903 33.9796 365.595 33.8508 365.368 33.6227C365.14 33.3947 365.013 33.086 365.013 32.7643V32.7643Z"
                fill="#4E415C"/>
            <path
                d="M366.211 29.5818C366.211 29.259 366.339 28.9496 366.568 28.7214C366.796 28.4932 367.106 28.365 367.429 28.365C367.752 28.365 368.062 28.4932 368.29 28.7214C368.519 28.9496 368.647 29.259 368.647 29.5818C368.647 29.7416 368.616 29.9 368.555 30.0477C368.494 30.1954 368.404 30.3297 368.291 30.4427C368.178 30.5557 368.043 30.6453 367.895 30.7064C367.747 30.7675 367.589 30.7988 367.429 30.7985C367.269 30.7988 367.11 30.7675 366.963 30.7064C366.815 30.6453 366.68 30.5557 366.567 30.4427C366.454 30.3297 366.364 30.1954 366.303 30.0477C366.242 29.9 366.211 29.7416 366.211 29.5818V29.5818Z"
                fill="#4E415C"/>
            <path
                d="M366.86 26.1707C366.86 26.0108 366.891 25.8524 366.953 25.7045C367.014 25.5567 367.103 25.4223 367.216 25.3092C367.33 25.196 367.464 25.1062 367.612 25.0449C367.76 24.9837 367.918 24.9521 368.078 24.9521C368.238 24.9521 368.397 24.9837 368.545 25.0449C368.693 25.1062 368.827 25.196 368.94 25.3092C369.053 25.4223 369.143 25.5567 369.204 25.7045C369.265 25.8524 369.297 26.0108 369.296 26.1707C369.296 26.4934 369.168 26.8029 368.94 27.0311C368.711 27.2593 368.401 27.3875 368.078 27.3875C367.755 27.3875 367.446 27.2593 367.217 27.0311C366.989 26.8029 366.86 26.4934 366.86 26.1707V26.1707Z"
                fill="#4E415C"/>
            <path
                d="M366.913 22.6093C366.914 22.2868 367.042 21.9776 367.27 21.7495C367.499 21.5214 367.808 21.3931 368.131 21.3926C368.454 21.3926 368.764 21.5208 368.992 21.749C369.221 21.9772 369.349 22.2866 369.349 22.6093C369.349 22.7692 369.318 22.9275 369.257 23.0753C369.196 23.223 369.106 23.3572 368.993 23.4703C368.88 23.5833 368.745 23.6729 368.597 23.734C368.45 23.795 368.291 23.8264 368.131 23.8261C367.808 23.8261 367.498 23.6979 367.27 23.4697C367.041 23.2415 366.913 22.9321 366.913 22.6093V22.6093Z"
                fill="#4E415C"/>
            <path
                d="M366.356 19.1639C366.356 19.004 366.388 18.8456 366.449 18.6979C366.511 18.5501 366.601 18.4159 366.714 18.3029C366.827 18.1899 366.962 18.1003 367.11 18.0392C367.258 17.9782 367.416 17.9469 367.576 17.9471C367.736 17.9471 367.895 17.9786 368.042 18.0398C368.19 18.1009 368.324 18.1905 368.437 18.3035C368.551 18.4165 368.64 18.5507 368.701 18.6983C368.763 18.8459 368.794 19.0041 368.794 19.1639C368.794 19.4866 368.666 19.7961 368.437 20.0243C368.209 20.2525 367.899 20.3807 367.576 20.3807C367.416 20.3809 367.258 20.3496 367.11 20.2886C366.962 20.2276 366.827 20.1379 366.714 20.0249C366.601 19.9119 366.511 19.7777 366.449 19.63C366.388 19.4822 366.356 19.3239 366.356 19.1639V19.1639Z"
                fill="#4E415C"/>
            <path
                d="M365.256 15.9343C365.256 15.7744 365.287 15.6161 365.348 15.4684C365.409 15.3206 365.499 15.1864 365.612 15.0734C365.725 14.9604 365.86 14.8707 366.007 14.8097C366.155 14.7486 366.314 14.7173 366.474 14.7175C366.634 14.7173 366.792 14.7486 366.94 14.8097C367.088 14.8707 367.222 14.9604 367.336 15.0734C367.449 15.1864 367.538 15.3206 367.6 15.4684C367.661 15.6161 367.692 15.7744 367.692 15.9343C367.692 16.257 367.563 16.5665 367.335 16.7947C367.107 17.0229 366.797 17.1511 366.474 17.1511C366.151 17.1511 365.841 17.0229 365.613 16.7947C365.384 16.5665 365.256 16.257 365.256 15.9343V15.9343Z"
                fill="#4E415C"/>
            <path
                d="M363.646 12.9748C363.646 12.815 363.678 12.6566 363.739 12.5089C363.8 12.3612 363.89 12.227 364.003 12.1139C364.116 12.0009 364.25 11.9113 364.398 11.8502C364.546 11.7891 364.704 11.7578 364.864 11.7581C365.187 11.7581 365.497 11.8863 365.726 12.1144C365.954 12.3426 366.082 12.6521 366.082 12.9748C366.082 13.2975 365.954 13.607 365.726 13.8352C365.497 14.0634 365.187 14.1916 364.864 14.1916C364.704 14.1918 364.546 14.1605 364.398 14.0995C364.25 14.0384 364.116 13.9488 364.003 13.8358C363.89 13.7227 363.8 13.5885 363.739 13.4407C363.678 13.293 363.646 13.1347 363.646 12.9748V12.9748Z"
                fill="#4E415C"/>
            <path
                d="M362.802 11.555C363.475 11.555 364.02 11.0102 364.02 10.3382C364.02 9.66623 363.475 9.12146 362.802 9.12146C362.129 9.12146 361.584 9.66623 361.584 10.3382C361.584 11.0102 362.129 11.555 362.802 11.555Z"
                fill="#4E415C"/>
            <path
                d="M359.123 8.07333C359.123 7.75063 359.251 7.44114 359.48 7.21296C359.708 6.98477 360.018 6.85657 360.341 6.85657C360.664 6.85705 360.973 6.9854 361.202 7.21349C361.43 7.44157 361.558 7.75077 361.559 8.07333C361.559 8.39604 361.431 8.70555 361.202 8.93373C360.974 9.16192 360.664 9.29012 360.341 9.29012C360.181 9.29036 360.023 9.25904 359.875 9.19797C359.727 9.13691 359.592 9.0473 359.479 8.93427C359.366 8.82123 359.276 8.68701 359.215 8.53927C359.154 8.39154 359.123 8.23319 359.123 8.07333V8.07333Z"
                fill="#4E415C"/>
            <path
                d="M356.313 6.23459C356.313 5.91188 356.441 5.6024 356.669 5.37421C356.898 5.14602 357.207 5.01782 357.53 5.01782C357.853 5.01782 358.163 5.14602 358.392 5.37421C358.62 5.6024 358.748 5.91188 358.748 6.23459C358.749 6.39445 358.717 6.55279 358.656 6.70053C358.595 6.84826 358.505 6.98249 358.392 7.09552C358.279 7.20856 358.145 7.29816 357.997 7.35923C357.849 7.42029 357.69 7.45162 357.53 7.45138C357.37 7.45162 357.212 7.42029 357.064 7.35923C356.916 7.29816 356.782 7.20856 356.669 7.09552C356.556 6.98249 356.466 6.84826 356.405 6.70053C356.344 6.55279 356.312 6.39445 356.313 6.23459V6.23459Z"
                fill="#4E415C"/>
            <path
                d="M353.209 4.87447C353.209 4.71452 353.24 4.55612 353.301 4.40828C353.362 4.26045 353.452 4.12609 353.565 4.01291C353.678 3.89973 353.813 3.80994 353.96 3.74868C354.108 3.68742 354.267 3.65588 354.427 3.65588C354.75 3.65636 355.06 3.78497 355.288 4.01345C355.517 4.24192 355.645 4.55159 355.645 4.87447C355.644 5.19703 355.516 5.50625 355.288 5.73433C355.059 5.96242 354.75 6.09077 354.427 6.09125C354.104 6.09125 353.794 5.96306 353.566 5.73487C353.337 5.50668 353.209 5.19717 353.209 4.87447V4.87447Z"
                fill="#4E415C"/>
            <path
                d="M349.86 4.04221C349.86 3.7195 349.989 3.41002 350.217 3.18183C350.446 2.95364 350.755 2.82544 351.078 2.82544C351.238 2.8252 351.397 2.85651 351.545 2.91757C351.693 2.97863 351.827 3.06826 351.94 3.1813C352.053 3.29433 352.143 3.42856 352.204 3.57629C352.265 3.72402 352.297 3.88235 352.296 4.04221C352.296 4.202 352.265 4.36023 352.204 4.50786C352.142 4.65548 352.053 4.78962 351.94 4.90261C351.826 5.0156 351.692 5.10521 351.544 5.16636C351.397 5.22751 351.238 5.25897 351.078 5.25897C350.755 5.25849 350.446 5.13016 350.218 4.90208C349.989 4.67399 349.861 4.36477 349.86 4.04221Z"
                fill="#4E415C"/>
            <path
                d="M346.337 3.7883C346.337 3.62836 346.368 3.47 346.43 3.32225C346.491 3.17451 346.581 3.04029 346.694 2.92728C346.808 2.81427 346.942 2.72468 347.09 2.66364C347.238 2.6026 347.397 2.5713 347.557 2.57153C347.88 2.57153 348.19 2.69973 348.418 2.92792C348.646 3.15611 348.775 3.46559 348.775 3.7883C348.775 4.11101 348.646 4.42051 348.418 4.6487C348.19 4.87689 347.88 5.00509 347.557 5.00509C347.397 5.00533 347.238 4.97402 347.09 4.91298C346.942 4.85194 346.808 4.76236 346.694 4.64934C346.581 4.53633 346.491 4.40212 346.43 4.25437C346.368 4.10663 346.337 3.94824 346.337 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M342.915 3.7883C342.915 3.62845 342.946 3.47012 343.007 3.32238C343.068 3.17465 343.158 3.04043 343.271 2.92739C343.384 2.81436 343.519 2.72473 343.667 2.66366C343.815 2.6026 343.973 2.5713 344.133 2.57153C344.456 2.57153 344.766 2.69973 344.994 2.92792C345.223 3.15611 345.351 3.46559 345.351 3.7883C345.351 4.11101 345.223 4.42051 344.994 4.6487C344.766 4.87689 344.456 5.00509 344.133 5.00509C343.973 5.00533 343.815 4.974 343.667 4.91294C343.519 4.85187 343.384 4.76227 343.271 4.64923C343.158 4.5362 343.068 4.40197 343.007 4.25424C342.946 4.10651 342.915 3.94816 342.915 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M339.492 3.7883C339.492 3.62845 339.523 3.47012 339.584 3.32238C339.646 3.17465 339.735 3.04043 339.848 2.92739C339.962 2.81436 340.096 2.72473 340.244 2.66366C340.392 2.6026 340.55 2.5713 340.71 2.57153C340.87 2.5713 341.029 2.6026 341.177 2.66366C341.324 2.72473 341.459 2.81436 341.572 2.92739C341.685 3.04043 341.775 3.17465 341.836 3.32238C341.897 3.47012 341.928 3.62845 341.928 3.7883C341.928 3.94816 341.897 4.10651 341.836 4.25424C341.775 4.40197 341.685 4.5362 341.572 4.64923C341.459 4.76227 341.324 4.85187 341.177 4.91294C341.029 4.974 340.87 5.00533 340.71 5.00509C340.55 5.00533 340.392 4.974 340.244 4.91294C340.096 4.85187 339.962 4.76227 339.848 4.64923C339.735 4.5362 339.646 4.40197 339.584 4.25424C339.523 4.10651 339.492 3.94816 339.492 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M337.288 5.00508C337.961 5.00508 338.506 4.46031 338.506 3.78831C338.506 3.1163 337.961 2.57153 337.288 2.57153C336.616 2.57153 336.07 3.1163 336.07 3.78831C336.07 4.46031 336.616 5.00508 337.288 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M333.864 5.00508C334.537 5.00508 335.082 4.46031 335.082 3.78831C335.082 3.1163 334.537 2.57153 333.864 2.57153C333.192 2.57153 332.646 3.1163 332.646 3.78831C332.646 4.46031 333.192 5.00508 333.864 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M329.224 3.7883C329.223 3.62845 329.255 3.47012 329.316 3.32238C329.377 3.17465 329.467 3.04043 329.58 2.92739C329.693 2.81436 329.827 2.72473 329.975 2.66366C330.123 2.6026 330.282 2.5713 330.442 2.57153C330.602 2.5713 330.76 2.6026 330.908 2.66366C331.056 2.72473 331.19 2.81436 331.303 2.92739C331.416 3.04043 331.506 3.17465 331.567 3.32238C331.628 3.47012 331.66 3.62845 331.66 3.7883C331.66 3.94816 331.628 4.10651 331.567 4.25424C331.506 4.40197 331.416 4.5362 331.303 4.64923C331.19 4.76227 331.056 4.85187 330.908 4.91294C330.76 4.974 330.602 5.00533 330.442 5.00509C330.282 5.00533 330.123 4.974 329.975 4.91294C329.827 4.85187 329.693 4.76227 329.58 4.64923C329.467 4.5362 329.377 4.40197 329.316 4.25424C329.255 4.10651 329.223 3.94816 329.224 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M325.8 3.7883C325.8 3.46559 325.928 3.15611 326.157 2.92792C326.385 2.69973 326.695 2.57153 327.018 2.57153C327.178 2.5713 327.336 2.6026 327.484 2.66366C327.632 2.72473 327.766 2.81436 327.88 2.92739C327.993 3.04043 328.082 3.17465 328.144 3.32238C328.205 3.47012 328.236 3.62845 328.236 3.7883C328.236 3.94816 328.205 4.10651 328.144 4.25424C328.082 4.40197 327.993 4.5362 327.88 4.64923C327.766 4.76227 327.632 4.85187 327.484 4.91294C327.336 4.974 327.178 5.00533 327.018 5.00509C326.695 5.00509 326.385 4.87689 326.157 4.6487C325.928 4.42051 325.8 4.11101 325.8 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M322.377 3.7883C322.377 3.62836 322.409 3.47 322.47 3.32225C322.531 3.17451 322.621 3.04029 322.734 2.92728C322.848 2.81427 322.982 2.72468 323.13 2.66364C323.278 2.6026 323.437 2.5713 323.597 2.57153C323.92 2.57153 324.23 2.69973 324.458 2.92792C324.686 3.15611 324.815 3.46559 324.815 3.7883C324.815 4.11101 324.686 4.42051 324.458 4.6487C324.23 4.87689 323.92 5.00509 323.597 5.00509C323.437 5.00533 323.278 4.97402 323.13 4.91298C322.982 4.85194 322.848 4.76236 322.734 4.64934C322.621 4.53633 322.531 4.40212 322.47 4.25437C322.409 4.10663 322.377 3.94824 322.377 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M318.955 3.7883C318.955 3.62845 318.986 3.47012 319.047 3.32238C319.108 3.17465 319.198 3.04043 319.311 2.92739C319.424 2.81436 319.559 2.72473 319.707 2.66366C319.855 2.6026 320.013 2.5713 320.173 2.57153C320.496 2.57153 320.806 2.69973 321.034 2.92792C321.263 3.15611 321.391 3.46559 321.391 3.7883C321.391 4.11101 321.263 4.42051 321.034 4.6487C320.806 4.87689 320.496 5.00509 320.173 5.00509C320.013 5.00533 319.855 4.974 319.707 4.91294C319.559 4.85187 319.424 4.76227 319.311 4.64923C319.198 4.5362 319.108 4.40197 319.047 4.25424C318.986 4.10651 318.955 3.94816 318.955 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M316.751 5.00508C317.424 5.00508 317.969 4.46031 317.969 3.78831C317.969 3.1163 317.424 2.57153 316.751 2.57153C316.079 2.57153 315.533 3.1163 315.533 3.78831C315.533 4.46031 316.079 5.00508 316.751 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M312.108 3.7883C312.108 3.46559 312.237 3.15611 312.465 2.92792C312.694 2.69973 313.003 2.57153 313.326 2.57153C313.486 2.5713 313.645 2.6026 313.793 2.66366C313.941 2.72473 314.075 2.81436 314.188 2.92739C314.301 3.04043 314.391 3.17465 314.452 3.32238C314.513 3.47012 314.545 3.62845 314.544 3.7883C314.545 3.94816 314.513 4.10651 314.452 4.25424C314.391 4.40197 314.301 4.5362 314.188 4.64923C314.075 4.76227 313.941 4.85187 313.793 4.91294C313.645 4.974 313.486 5.00533 313.326 5.00509C313.003 5.00509 312.694 4.87689 312.465 4.6487C312.237 4.42051 312.108 4.11101 312.108 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M308.687 3.7883C308.686 3.62845 308.718 3.47012 308.779 3.32238C308.84 3.17465 308.93 3.04043 309.043 2.92739C309.156 2.81436 309.29 2.72473 309.438 2.66366C309.586 2.6026 309.744 2.5713 309.904 2.57153C310.228 2.57153 310.537 2.69973 310.766 2.92792C310.994 3.15611 311.122 3.46559 311.122 3.7883C311.122 4.11101 310.994 4.42051 310.766 4.6487C310.537 4.87689 310.228 5.00509 309.904 5.00509C309.744 5.00533 309.586 4.974 309.438 4.91294C309.29 4.85187 309.156 4.76227 309.043 4.64923C308.93 4.5362 308.84 4.40197 308.779 4.25424C308.718 4.10651 308.686 3.94816 308.687 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M306.482 5.00508C307.154 5.00508 307.7 4.46031 307.7 3.78831C307.7 3.1163 307.154 2.57153 306.482 2.57153C305.809 2.57153 305.264 3.1163 305.264 3.78831C305.264 4.46031 305.809 5.00508 306.482 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M301.84 3.7883C301.84 3.46559 301.968 3.15611 302.197 2.92792C302.425 2.69973 302.735 2.57153 303.058 2.57153C303.218 2.5713 303.376 2.6026 303.524 2.66366C303.672 2.72473 303.806 2.81436 303.92 2.92739C304.033 3.04043 304.122 3.17465 304.184 3.32238C304.245 3.47012 304.276 3.62845 304.276 3.7883C304.276 3.94816 304.245 4.10651 304.184 4.25424C304.122 4.40197 304.033 4.5362 303.92 4.64923C303.806 4.76227 303.672 4.85187 303.524 4.91294C303.376 4.974 303.218 5.00533 303.058 5.00509C302.735 5.00509 302.425 4.87689 302.197 4.6487C301.968 4.42051 301.84 4.11101 301.84 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M298.417 3.7883C298.417 3.46559 298.545 3.15611 298.774 2.92792C299.002 2.69973 299.312 2.57153 299.635 2.57153C299.795 2.5713 299.954 2.6026 300.102 2.66364C300.25 2.72468 300.384 2.81427 300.497 2.92728C300.611 3.04029 300.701 3.17451 300.762 3.32225C300.823 3.47 300.855 3.62836 300.855 3.7883C300.855 3.94824 300.823 4.10663 300.762 4.25437C300.701 4.40212 300.611 4.53633 300.497 4.64934C300.384 4.76236 300.25 4.85194 300.102 4.91298C299.954 4.97402 299.795 5.00533 299.635 5.00509C299.312 5.00509 299.002 4.87689 298.774 4.6487C298.545 4.42051 298.417 4.11101 298.417 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M296.213 5.00508C296.886 5.00508 297.431 4.46031 297.431 3.78831C297.431 3.1163 296.886 2.57153 296.213 2.57153C295.54 2.57153 294.995 3.1163 294.995 3.78831C294.995 4.46031 295.54 5.00508 296.213 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M291.571 3.7883C291.571 3.62845 291.602 3.47012 291.664 3.32238C291.725 3.17465 291.814 3.04043 291.927 2.92739C292.041 2.81436 292.175 2.72473 292.323 2.66366C292.471 2.6026 292.629 2.5713 292.789 2.57153C292.949 2.5713 293.108 2.6026 293.256 2.66366C293.404 2.72473 293.538 2.81436 293.651 2.92739C293.764 3.04043 293.854 3.17465 293.915 3.32238C293.976 3.47012 294.007 3.62845 294.007 3.7883C294.007 3.94816 293.976 4.10651 293.915 4.25424C293.854 4.40197 293.764 4.5362 293.651 4.64923C293.538 4.76227 293.404 4.85187 293.256 4.91294C293.108 4.974 292.949 5.00533 292.789 5.00509C292.629 5.00533 292.471 4.974 292.323 4.91294C292.175 4.85187 292.041 4.76227 291.927 4.64923C291.814 4.5362 291.725 4.40197 291.664 4.25424C291.602 4.10651 291.571 3.94816 291.571 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M288.148 3.7883C288.148 3.46559 288.277 3.15611 288.505 2.92792C288.734 2.69973 289.043 2.57153 289.366 2.57153C289.526 2.5713 289.685 2.6026 289.833 2.66366C289.981 2.72473 290.115 2.81436 290.228 2.92739C290.341 3.04043 290.431 3.17465 290.492 3.32238C290.553 3.47012 290.585 3.62845 290.584 3.7883C290.585 3.94816 290.553 4.10651 290.492 4.25424C290.431 4.40197 290.341 4.5362 290.228 4.64923C290.115 4.76227 289.981 4.85187 289.833 4.91294C289.685 4.974 289.526 5.00533 289.366 5.00509C289.043 5.00509 288.734 4.87689 288.505 4.6487C288.277 4.42051 288.148 4.11101 288.148 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M284.725 3.7883C284.725 3.62836 284.756 3.47 284.817 3.32225C284.879 3.17451 284.969 3.04029 285.082 2.92728C285.195 2.81427 285.33 2.72468 285.478 2.66364C285.626 2.6026 285.784 2.5713 285.944 2.57153C286.104 2.57153 286.263 2.603 286.41 2.66415C286.558 2.7253 286.693 2.81493 286.806 2.92792C286.919 3.04091 287.008 3.17505 287.07 3.32267C287.131 3.4703 287.162 3.62851 287.162 3.7883C287.162 3.94809 287.131 4.10633 287.07 4.25395C287.008 4.40158 286.919 4.53571 286.806 4.6487C286.693 4.76169 286.558 4.8513 286.41 4.91245C286.263 4.9736 286.104 5.00509 285.944 5.00509C285.784 5.00533 285.626 4.97402 285.478 4.91298C285.33 4.85194 285.195 4.76236 285.082 4.64934C284.969 4.53633 284.879 4.40212 284.817 4.25437C284.756 4.10663 284.725 3.94824 284.725 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M281.304 3.7883C281.303 3.62845 281.335 3.47012 281.396 3.32238C281.457 3.17465 281.547 3.04043 281.66 2.92739C281.773 2.81436 281.907 2.72473 282.055 2.66366C282.203 2.6026 282.362 2.5713 282.522 2.57153C282.682 2.5713 282.84 2.6026 282.988 2.66366C283.136 2.72473 283.27 2.81436 283.383 2.92739C283.497 3.04043 283.586 3.17465 283.647 3.32238C283.709 3.47012 283.74 3.62845 283.74 3.7883C283.74 3.94816 283.709 4.10651 283.647 4.25424C283.586 4.40197 283.497 4.5362 283.383 4.64923C283.27 4.76227 283.136 4.85187 282.988 4.91294C282.84 4.974 282.682 5.00533 282.522 5.00509C282.362 5.00533 282.203 4.974 282.055 4.91294C281.907 4.85187 281.773 4.76227 281.66 4.64923C281.547 4.5362 281.457 4.40197 281.396 4.25424C281.335 4.10651 281.303 3.94816 281.304 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M277.88 3.7883C277.88 3.62845 277.911 3.47012 277.972 3.32238C278.033 3.17465 278.123 3.04043 278.236 2.92739C278.349 2.81436 278.484 2.72473 278.631 2.66366C278.779 2.6026 278.938 2.5713 279.098 2.57153C279.258 2.5713 279.416 2.6026 279.564 2.66366C279.712 2.72473 279.846 2.81436 279.96 2.92739C280.073 3.04043 280.162 3.17465 280.224 3.32238C280.285 3.47012 280.316 3.62845 280.316 3.7883C280.316 3.94816 280.285 4.10651 280.224 4.25424C280.162 4.40197 280.073 4.5362 279.96 4.64923C279.846 4.76227 279.712 4.85187 279.564 4.91294C279.416 4.974 279.258 5.00533 279.098 5.00509C278.938 5.00533 278.779 4.974 278.631 4.91294C278.484 4.85187 278.349 4.76227 278.236 4.64923C278.123 4.5362 278.033 4.40197 277.972 4.25424C277.911 4.10651 277.88 3.94816 277.88 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M275.677 5.00508C276.35 5.00508 276.895 4.46031 276.895 3.78831C276.895 3.1163 276.35 2.57153 275.677 2.57153C275.004 2.57153 274.459 3.1163 274.459 3.78831C274.459 4.46031 275.004 5.00508 275.677 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M271.035 3.7883C271.035 3.62845 271.066 3.47012 271.127 3.32238C271.189 3.17465 271.278 3.04043 271.391 2.92739C271.504 2.81436 271.639 2.72473 271.787 2.66366C271.935 2.6026 272.093 2.5713 272.253 2.57153C272.576 2.57153 272.886 2.69973 273.114 2.92792C273.343 3.15611 273.471 3.46559 273.471 3.7883C273.471 4.11101 273.343 4.42051 273.114 4.6487C272.886 4.87689 272.576 5.00509 272.253 5.00509C272.093 5.00533 271.935 4.974 271.787 4.91294C271.639 4.85187 271.504 4.76227 271.391 4.64923C271.278 4.5362 271.189 4.40197 271.127 4.25424C271.066 4.10651 271.035 3.94816 271.035 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M268.829 5.00508C269.502 5.00508 270.047 4.46031 270.047 3.78831C270.047 3.1163 269.502 2.57153 268.829 2.57153C268.157 2.57153 267.611 3.1163 267.611 3.78831C267.611 4.46031 268.157 5.00508 268.829 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M265.408 5.00508C266.081 5.00508 266.626 4.46031 266.626 3.78831C266.626 3.1163 266.081 2.57153 265.408 2.57153C264.736 2.57153 264.19 3.1163 264.19 3.78831C264.19 4.46031 264.736 5.00508 265.408 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M260.765 3.7883C260.765 3.62836 260.796 3.47 260.858 3.32225C260.919 3.17451 261.009 3.04029 261.122 2.92728C261.235 2.81427 261.37 2.72468 261.518 2.66364C261.666 2.6026 261.824 2.5713 261.984 2.57153C262.307 2.57153 262.617 2.69973 262.846 2.92792C263.074 3.15611 263.202 3.46559 263.202 3.7883C263.202 4.11101 263.074 4.42051 262.846 4.6487C262.617 4.87689 262.307 5.00509 261.984 5.00509C261.824 5.00533 261.666 4.97402 261.518 4.91298C261.37 4.85194 261.235 4.76236 261.122 4.64934C261.009 4.53633 260.919 4.40212 260.858 4.25437C260.796 4.10663 260.765 3.94824 260.765 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M258.562 5.00508C259.234 5.00508 259.78 4.46031 259.78 3.78831C259.78 3.1163 259.234 2.57153 258.562 2.57153C257.889 2.57153 257.344 3.1163 257.344 3.78831C257.344 4.46031 257.889 5.00508 258.562 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M255.14 5.00508C255.813 5.00508 256.358 4.46031 256.358 3.78831C256.358 3.1163 255.813 2.57153 255.14 2.57153C254.467 2.57153 253.922 3.1163 253.922 3.78831C253.922 4.46031 254.467 5.00508 255.14 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M250.497 3.7883C250.497 3.46559 250.625 3.15611 250.854 2.92792C251.082 2.69973 251.392 2.57153 251.715 2.57153C251.875 2.5713 252.034 2.6026 252.182 2.66364C252.33 2.72468 252.464 2.81427 252.577 2.92728C252.691 3.04029 252.781 3.17451 252.842 3.32225C252.903 3.47 252.935 3.62836 252.935 3.7883C252.935 3.94824 252.903 4.10663 252.842 4.25437C252.781 4.40212 252.691 4.53633 252.577 4.64934C252.464 4.76236 252.33 4.85194 252.182 4.91298C252.034 4.97402 251.875 5.00533 251.715 5.00509C251.392 5.00509 251.082 4.87689 250.854 4.6487C250.625 4.42051 250.497 4.11101 250.497 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M247.075 3.7883C247.075 3.62845 247.106 3.47012 247.167 3.32238C247.229 3.17465 247.318 3.04043 247.431 2.92739C247.545 2.81436 247.679 2.72473 247.827 2.66366C247.975 2.6026 248.133 2.5713 248.293 2.57153C248.616 2.57153 248.926 2.69973 249.154 2.92792C249.383 3.15611 249.511 3.46559 249.511 3.7883C249.511 4.11101 249.383 4.42051 249.154 4.6487C248.926 4.87689 248.616 5.00509 248.293 5.00509C248.133 5.00533 247.975 4.974 247.827 4.91294C247.679 4.85187 247.545 4.76227 247.431 4.64923C247.318 4.5362 247.229 4.40197 247.167 4.25424C247.106 4.10651 247.075 3.94816 247.075 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M243.651 3.7883C243.651 3.62845 243.682 3.47012 243.744 3.32238C243.805 3.17465 243.894 3.04043 244.008 2.92739C244.121 2.81436 244.255 2.72473 244.403 2.66366C244.551 2.6026 244.709 2.5713 244.869 2.57153C245.029 2.5713 245.188 2.6026 245.336 2.66366C245.484 2.72473 245.618 2.81436 245.731 2.92739C245.844 3.04043 245.934 3.17465 245.995 3.32238C246.056 3.47012 246.088 3.62845 246.087 3.7883C246.088 3.94816 246.056 4.10651 245.995 4.25424C245.934 4.40197 245.844 4.5362 245.731 4.64923C245.618 4.76227 245.484 4.85187 245.336 4.91294C245.188 4.974 245.029 5.00533 244.869 5.00509C244.709 5.00533 244.551 4.974 244.403 4.91294C244.255 4.85187 244.121 4.76227 244.008 4.64923C243.894 4.5362 243.805 4.40197 243.744 4.25424C243.682 4.10651 243.651 3.94816 243.651 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M240.229 3.7883C240.229 3.46559 240.357 3.15611 240.585 2.92792C240.814 2.69973 241.123 2.57153 241.447 2.57153C241.607 2.5713 241.765 2.6026 241.913 2.66366C242.061 2.72473 242.195 2.81436 242.308 2.92739C242.421 3.04043 242.511 3.17465 242.572 3.32238C242.633 3.47012 242.665 3.62845 242.664 3.7883C242.665 3.94816 242.633 4.10651 242.572 4.25424C242.511 4.40197 242.421 4.5362 242.308 4.64923C242.195 4.76227 242.061 4.85187 241.913 4.91294C241.765 4.974 241.607 5.00533 241.447 5.00509C241.123 5.00509 240.814 4.87689 240.585 4.6487C240.357 4.42051 240.229 4.11101 240.229 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M236.805 3.7883C236.805 3.62836 236.836 3.47 236.898 3.32225C236.959 3.17451 237.049 3.04029 237.162 2.92728C237.275 2.81427 237.41 2.72468 237.558 2.66364C237.706 2.6026 237.864 2.5713 238.024 2.57153C238.347 2.57153 238.657 2.69973 238.886 2.92792C239.114 3.15611 239.242 3.46559 239.242 3.7883C239.242 4.11101 239.114 4.42051 238.886 4.6487C238.657 4.87689 238.347 5.00509 238.024 5.00509C237.864 5.00533 237.706 4.97402 237.558 4.91298C237.41 4.85194 237.275 4.76236 237.162 4.64934C237.049 4.53633 236.959 4.40212 236.898 4.25437C236.836 4.10663 236.805 3.94824 236.805 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M234.602 5.00508C235.274 5.00508 235.82 4.46031 235.82 3.78831C235.82 3.1163 235.274 2.57153 234.602 2.57153C233.929 2.57153 233.384 3.1163 233.384 3.78831C233.384 4.46031 233.929 5.00508 234.602 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M229.96 3.7883C229.96 3.62845 229.991 3.47012 230.052 3.32238C230.113 3.17465 230.203 3.04043 230.316 2.92739C230.429 2.81436 230.564 2.72473 230.712 2.66366C230.859 2.6026 231.018 2.5713 231.178 2.57153C231.338 2.5713 231.496 2.6026 231.644 2.66366C231.792 2.72473 231.927 2.81436 232.04 2.92739C232.153 3.04043 232.243 3.17465 232.304 3.32238C232.365 3.47012 232.396 3.62845 232.396 3.7883C232.396 3.94816 232.365 4.10651 232.304 4.25424C232.243 4.40197 232.153 4.5362 232.04 4.64923C231.927 4.76227 231.792 4.85187 231.644 4.91294C231.496 4.974 231.338 5.00533 231.178 5.00509C231.018 5.00533 230.859 4.974 230.712 4.91294C230.564 4.85187 230.429 4.76227 230.316 4.64923C230.203 4.5362 230.113 4.40197 230.052 4.25424C229.991 4.10651 229.96 3.94816 229.96 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M226.536 3.7883C226.536 3.46559 226.664 3.15611 226.893 2.92792C227.121 2.69973 227.431 2.57153 227.754 2.57153C227.914 2.5713 228.073 2.6026 228.221 2.66364C228.369 2.72468 228.503 2.81427 228.617 2.92728C228.73 3.04029 228.82 3.17451 228.881 3.32225C228.942 3.47 228.974 3.62836 228.974 3.7883C228.974 3.94824 228.942 4.10663 228.881 4.25437C228.82 4.40212 228.73 4.53633 228.617 4.64934C228.503 4.76236 228.369 4.85194 228.221 4.91298C228.073 4.97402 227.914 5.00533 227.754 5.00509C227.431 5.00509 227.121 4.87689 226.893 4.6487C226.664 4.42051 226.536 4.11101 226.536 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M223.115 3.7883C223.115 3.62845 223.146 3.47012 223.207 3.32238C223.269 3.17465 223.358 3.04043 223.471 2.92739C223.585 2.81436 223.719 2.72473 223.867 2.66366C224.015 2.6026 224.173 2.5713 224.333 2.57153C224.656 2.57153 224.966 2.69973 225.194 2.92792C225.423 3.15611 225.551 3.46559 225.551 3.7883C225.551 4.11101 225.423 4.42051 225.194 4.6487C224.966 4.87689 224.656 5.00509 224.333 5.00509C224.173 5.00533 224.015 4.974 223.867 4.91294C223.719 4.85187 223.585 4.76227 223.471 4.64923C223.358 4.5362 223.269 4.40197 223.207 4.25424C223.146 4.10651 223.115 3.94816 223.115 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M219.691 3.7883C219.691 3.62845 219.723 3.47012 219.784 3.32238C219.845 3.17465 219.934 3.04043 220.048 2.92739C220.161 2.81436 220.295 2.72473 220.443 2.66366C220.591 2.6026 220.749 2.5713 220.909 2.57153C221.069 2.5713 221.228 2.6026 221.376 2.66366C221.524 2.72473 221.658 2.81436 221.771 2.92739C221.884 3.04043 221.974 3.17465 222.035 3.32238C222.096 3.47012 222.128 3.62845 222.127 3.7883C222.128 3.94816 222.096 4.10651 222.035 4.25424C221.974 4.40197 221.884 4.5362 221.771 4.64923C221.658 4.76227 221.524 4.85187 221.376 4.91294C221.228 4.974 221.069 5.00533 220.909 5.00509C220.749 5.00533 220.591 4.974 220.443 4.91294C220.295 4.85187 220.161 4.76227 220.048 4.64923C219.934 4.5362 219.845 4.40197 219.784 4.25424C219.723 4.10651 219.691 3.94816 219.691 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M216.269 3.7883C216.269 3.46559 216.397 3.15611 216.625 2.92792C216.854 2.69973 217.164 2.57153 217.487 2.57153C217.647 2.5713 217.805 2.6026 217.953 2.66366C218.101 2.72473 218.235 2.81436 218.348 2.92739C218.461 3.04043 218.551 3.17465 218.612 3.32238C218.673 3.47012 218.705 3.62845 218.705 3.7883C218.705 3.94816 218.673 4.10651 218.612 4.25424C218.551 4.40197 218.461 4.5362 218.348 4.64923C218.235 4.76227 218.101 4.85187 217.953 4.91294C217.805 4.974 217.647 5.00533 217.487 5.00509C217.164 5.00509 216.854 4.87689 216.625 4.6487C216.397 4.42051 216.269 4.11101 216.269 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M212.845 3.7883C212.845 3.62836 212.876 3.47 212.938 3.32225C212.999 3.17451 213.089 3.04029 213.202 2.92728C213.315 2.81427 213.45 2.72468 213.598 2.66364C213.746 2.6026 213.904 2.5713 214.065 2.57153C214.388 2.57153 214.697 2.69973 214.926 2.92792C215.154 3.15611 215.282 3.46559 215.282 3.7883C215.282 4.11101 215.154 4.42051 214.926 4.6487C214.697 4.87689 214.388 5.00509 214.065 5.00509C213.904 5.00533 213.746 4.97402 213.598 4.91298C213.45 4.85194 213.315 4.76236 213.202 4.64934C213.089 4.53633 212.999 4.40212 212.938 4.25437C212.876 4.10663 212.845 3.94824 212.845 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M210.583 5.05037C211.256 5.05037 211.801 4.5056 211.801 3.83359C211.801 3.16159 211.256 2.61682 210.583 2.61682C209.911 2.61682 209.365 3.16159 209.365 3.83359C209.365 4.5056 209.911 5.05037 210.583 5.05037Z"
                fill="#4E415C"/>
            <path
                d="M205.909 4.36494C205.909 4.205 205.94 4.04657 206.001 3.89874C206.062 3.7509 206.152 3.61655 206.265 3.50337C206.378 3.39019 206.513 3.30042 206.661 3.23916C206.809 3.17789 206.967 3.14636 207.127 3.14636C207.45 3.14684 207.76 3.27542 207.988 3.5039C208.217 3.73238 208.345 4.04207 208.345 4.36494C208.345 4.68751 208.216 4.99671 207.988 5.22479C207.76 5.45288 207.45 5.58123 207.127 5.58171C206.804 5.58171 206.494 5.45351 206.266 5.22532C206.038 4.99713 205.909 4.68765 205.909 4.36494V4.36494Z"
                fill="#4E415C"/>
            <path
                d="M203.887 6.66792C204.56 6.66792 205.105 6.12315 205.105 5.45115C205.105 4.77914 204.56 4.23438 203.887 4.23438C203.214 4.23438 202.669 4.77914 202.669 5.45115C202.669 6.12315 203.214 6.66792 203.887 6.66792Z"
                fill="#4E415C"/>
            <path
                d="M199.696 7.04333C199.697 6.72077 199.825 6.41154 200.054 6.18346C200.282 5.95537 200.591 5.82702 200.914 5.82654C201.237 5.82654 201.547 5.95468 201.776 6.18282C202.005 6.41095 202.134 6.72045 202.134 7.04333C202.134 7.36652 202.006 7.67646 201.777 7.90499C201.548 8.13352 201.238 8.26191 200.914 8.26191C200.591 8.26143 200.281 8.13282 200.053 7.90435C199.825 7.67587 199.696 7.3662 199.696 7.04333V7.04333Z"
                fill="#4E415C"/>
            <path
                d="M197.044 9.08884C197.044 8.76628 197.173 8.45708 197.401 8.22899C197.63 8.0009 197.939 7.87255 198.262 7.87207C198.585 7.87207 198.895 8.00021 199.124 8.22835C199.352 8.45649 199.481 8.76596 199.482 9.08884C199.482 9.24878 199.45 9.40716 199.389 9.55491C199.327 9.70265 199.238 9.83687 199.124 9.94988C199.011 10.0629 198.877 10.1525 198.729 10.2135C198.581 10.2746 198.422 10.3059 198.262 10.3056C197.939 10.3056 197.629 10.1774 197.401 9.94924C197.172 9.72105 197.044 9.41155 197.044 9.08884V9.08884Z"
                fill="#4E415C"/>
            <path
                d="M194.764 11.535C194.764 11.2125 194.893 10.9032 195.121 10.6752C195.349 10.4471 195.659 10.3187 195.982 10.3182C196.305 10.3182 196.614 10.4464 196.843 10.6746C197.071 10.9028 197.2 11.2123 197.2 11.535C197.2 11.6949 197.169 11.8532 197.107 12.0009C197.046 12.1487 196.957 12.2829 196.843 12.3959C196.73 12.509 196.596 12.5986 196.448 12.6597C196.3 12.7207 196.142 12.752 195.982 12.7518C195.659 12.7518 195.349 12.6236 195.12 12.3954C194.892 12.1672 194.764 11.8577 194.764 11.535V11.535Z"
                fill="#4E415C"/>
            <path
                d="M192.909 14.3313C192.909 14.1714 192.94 14.0131 193.001 13.8654C193.063 13.7176 193.152 13.5834 193.265 13.4704C193.379 13.3573 193.513 13.2677 193.661 13.2066C193.809 13.1456 193.967 13.1143 194.127 13.1145C194.287 13.1143 194.446 13.1456 194.594 13.2066C194.741 13.2677 194.876 13.3573 194.989 13.4704C195.102 13.5834 195.192 13.7176 195.253 13.8654C195.314 14.0131 195.345 14.1714 195.345 14.3313C195.345 14.4911 195.314 14.6493 195.252 14.7969C195.191 14.9445 195.102 15.0787 194.988 15.1917C194.875 15.3047 194.741 15.3943 194.593 15.4554C194.445 15.5166 194.287 15.5481 194.127 15.5481C193.967 15.5481 193.809 15.5166 193.661 15.4554C193.513 15.3943 193.379 15.3047 193.266 15.1917C193.153 15.0787 193.063 14.9445 193.002 14.7969C192.941 14.6493 192.909 14.4911 192.909 14.3313V14.3313Z"
                fill="#4E415C"/>
            <path
                d="M191.529 17.4231C191.529 17.2632 191.56 17.1049 191.622 16.9572C191.683 16.8094 191.772 16.6752 191.885 16.5622C191.999 16.4491 192.133 16.3595 192.281 16.2985C192.429 16.2374 192.587 16.2061 192.747 16.2063C193.07 16.2063 193.38 16.3345 193.608 16.5627C193.837 16.7909 193.965 17.1004 193.965 17.4231C193.965 17.5829 193.934 17.7411 193.873 17.8887C193.811 18.0363 193.722 18.1705 193.608 18.2835C193.495 18.3965 193.361 18.4861 193.213 18.5472C193.066 18.6084 192.907 18.6399 192.747 18.6399C192.587 18.6401 192.429 18.6088 192.281 18.5477C192.133 18.4867 191.999 18.397 191.885 18.284C191.772 18.171 191.683 18.0367 191.622 17.889C191.56 17.7413 191.529 17.5829 191.529 17.4231V17.4231Z"
                fill="#4E415C"/>
            <path
                d="M190.677 20.7596C190.677 20.4368 190.806 20.1273 191.035 19.8991C191.263 19.671 191.573 19.5428 191.897 19.5428C192.219 19.5433 192.529 19.6717 192.757 19.8998C192.986 20.1279 193.114 20.4371 193.115 20.7596C193.115 21.0823 192.986 21.3918 192.758 21.62C192.529 21.8482 192.22 21.9764 191.897 21.9764C191.736 21.9766 191.578 21.9453 191.43 21.8843C191.282 21.8233 191.147 21.7337 191.034 21.6207C190.921 21.5076 190.831 21.3734 190.77 21.2257C190.708 21.0779 190.677 20.9196 190.677 20.7596V20.7596Z"
                fill="#4E415C"/>
            <path
                d="M190.406 24.2903C190.406 24.1304 190.437 23.9721 190.498 23.8243C190.56 23.6766 190.649 23.5424 190.762 23.4293C190.876 23.3163 191.01 23.2267 191.158 23.1656C191.306 23.1046 191.464 23.0732 191.624 23.0735C191.947 23.0735 192.257 23.2017 192.485 23.4299C192.714 23.658 192.842 23.9675 192.842 24.2903C192.842 24.6128 192.713 24.922 192.485 25.1501C192.257 25.3782 191.947 25.5065 191.624 25.507C191.464 25.507 191.306 25.4756 191.158 25.4144C191.01 25.3533 190.876 25.2636 190.763 25.1506C190.65 25.0376 190.56 24.9035 190.499 24.7559C190.438 24.6083 190.406 24.45 190.406 24.2903V24.2903Z"
                fill="#4E415C"/>
            <path
                d="M190.745 27.801C190.745 27.6411 190.776 27.4828 190.837 27.3351C190.898 27.1873 190.988 27.0531 191.101 26.9401C191.214 26.827 191.349 26.7374 191.497 26.6764C191.645 26.6153 191.803 26.584 191.963 26.5842C192.123 26.584 192.282 26.6153 192.429 26.6764C192.577 26.7374 192.712 26.827 192.825 26.9401C192.938 27.0531 193.028 27.1873 193.089 27.3351C193.15 27.4828 193.181 27.6411 193.181 27.801C193.181 27.9609 193.15 28.1192 193.089 28.2669C193.028 28.4146 192.938 28.5489 192.825 28.6619C192.712 28.7749 192.577 28.8646 192.429 28.9256C192.282 28.9867 192.123 29.018 191.963 29.0178C191.803 29.018 191.645 28.9867 191.497 28.9256C191.349 28.8646 191.214 28.7749 191.101 28.6619C190.988 28.5489 190.898 28.4146 190.837 28.2669C190.776 28.1192 190.745 27.9609 190.745 27.801V27.801Z"
                fill="#4E415C"/>
            <path
                d="M191.658 31.1123C191.659 30.7894 191.787 30.4799 192.016 30.2518C192.245 30.0236 192.555 29.8955 192.878 29.8955C193.201 29.896 193.51 30.0243 193.739 30.2524C193.967 30.4805 194.095 30.7897 194.096 31.1123C194.096 31.4351 193.968 31.7448 193.739 31.9733C193.511 32.2018 193.201 32.3304 192.878 32.3309C192.554 32.3309 192.244 32.2025 192.015 31.9739C191.787 31.7454 191.658 31.4355 191.658 31.1123Z"
                fill="#4E415C"/>
            <path
                d="M193.096 34.175C193.095 34.0152 193.127 33.8569 193.188 33.7091C193.249 33.5614 193.339 33.4271 193.452 33.3141C193.565 33.2011 193.699 33.1115 193.847 33.0504C193.995 32.9893 194.154 32.958 194.314 32.9583C194.637 32.9583 194.946 33.0865 195.175 33.3146C195.403 33.5428 195.532 33.8523 195.532 34.175C195.531 34.4976 195.403 34.8068 195.174 35.0349C194.946 35.263 194.637 35.3913 194.314 35.3918C193.991 35.3918 193.681 35.2636 193.452 35.0354C193.224 34.8072 193.096 34.4978 193.096 34.175V34.175Z"
                fill="#4E415C"/>
            <path
                d="M195.006 36.9349C195.006 36.7751 195.037 36.6167 195.098 36.469C195.159 36.3213 195.249 36.187 195.362 36.074C195.475 35.9609 195.61 35.8713 195.757 35.8103C195.905 35.7492 196.064 35.7179 196.224 35.7181C196.384 35.7179 196.542 35.7492 196.69 35.8103C196.838 35.8713 196.972 35.9609 197.086 36.074C197.199 36.187 197.288 36.3213 197.35 36.469C197.411 36.6167 197.442 36.7751 197.442 36.9349C197.442 37.2576 197.313 37.5671 197.085 37.7953C196.857 38.0235 196.547 38.1517 196.224 38.1517C195.901 38.1517 195.591 38.0235 195.363 37.7953C195.134 37.5671 195.006 37.2576 195.006 36.9349V36.9349Z"
                fill="#4E415C"/>
            <path
                d="M197.325 39.3359C197.326 39.013 197.454 38.7036 197.683 38.4754C197.912 38.2473 198.222 38.1191 198.545 38.1191C198.868 38.1196 199.177 38.248 199.406 38.4761C199.634 38.7041 199.762 39.0133 199.763 39.3359C199.763 39.6586 199.635 39.9681 199.406 40.1963C199.178 40.4245 198.868 40.5527 198.545 40.5527C198.385 40.5529 198.226 40.5216 198.078 40.4606C197.93 40.3995 197.796 40.31 197.683 40.1969C197.569 40.0839 197.479 39.9497 197.418 39.802C197.357 39.6542 197.325 39.4958 197.325 39.3359V39.3359Z"
                fill="#4E415C"/>
            <path
                d="M200.018 41.3324C200.018 41.0097 200.146 40.7002 200.374 40.472C200.603 40.2438 200.913 40.1156 201.236 40.1156C201.559 40.1156 201.868 40.2438 202.097 40.472C202.325 40.7002 202.454 41.0097 202.454 41.3324C202.454 41.4922 202.422 41.6506 202.361 41.7983C202.3 41.946 202.21 42.0803 202.097 42.1933C201.984 42.3063 201.85 42.3959 201.702 42.457C201.554 42.5181 201.396 42.5494 201.236 42.5491C201.076 42.5494 200.917 42.5181 200.769 42.457C200.621 42.3959 200.487 42.3063 200.374 42.1933C200.261 42.0803 200.171 41.946 200.11 41.7983C200.049 41.6506 200.017 41.4922 200.018 41.3324V41.3324Z"
                fill="#4E415C"/>
            <path
                d="M203.021 42.8719C203.021 42.549 203.15 42.2394 203.378 42.0109C203.606 41.7824 203.916 41.6538 204.239 41.6533C204.4 41.6533 204.558 41.6849 204.706 41.7461C204.854 41.8074 204.988 41.8972 205.101 42.0104C205.214 42.1235 205.304 42.2579 205.365 42.4057C205.426 42.5535 205.458 42.712 205.457 42.8719C205.457 43.1946 205.329 43.5041 205.101 43.7323C204.872 43.9605 204.563 44.0887 204.239 44.0887C203.917 44.0882 203.607 43.9599 203.379 43.7318C203.15 43.5037 203.022 43.1945 203.021 42.8719V42.8719Z"
                fill="#4E415C"/>
            <path
                d="M206.288 43.8983C206.288 43.7385 206.319 43.5801 206.38 43.4324C206.441 43.2847 206.531 43.1504 206.644 43.0374C206.757 42.9243 206.892 42.8347 207.04 42.7737C207.188 42.7126 207.346 42.6813 207.506 42.6815C207.666 42.6813 207.825 42.7126 207.972 42.7737C208.12 42.8347 208.255 42.9243 208.368 43.0374C208.481 43.1504 208.571 43.2847 208.632 43.4324C208.693 43.5801 208.724 43.7385 208.724 43.8983C208.724 44.0582 208.693 44.2165 208.632 44.3642C208.571 44.512 208.481 44.6462 208.368 44.7592C208.255 44.8723 208.12 44.9619 207.972 45.0229C207.825 45.084 207.666 45.1153 207.506 45.1151C207.346 45.1153 207.188 45.084 207.04 45.0229C206.892 44.9619 206.757 44.8723 206.644 44.7592C206.531 44.6462 206.441 44.512 206.38 44.3642C206.319 44.2165 206.288 44.0582 206.288 43.8983V43.8983Z"
                fill="#4E415C"/>
            <path
                d="M209.765 44.3698C209.765 44.047 209.894 43.7375 210.123 43.5094C210.351 43.2812 210.661 43.1531 210.984 43.1531C211.307 43.1536 211.617 43.2819 211.845 43.51C212.073 43.7381 212.202 44.0473 212.202 44.3698C212.202 44.5296 212.171 44.6878 212.11 44.8355C212.048 44.9831 211.959 45.1172 211.846 45.2302C211.733 45.3432 211.598 45.4328 211.451 45.494C211.303 45.5551 211.144 45.5866 210.984 45.5866C210.824 45.5868 210.666 45.5556 210.518 45.4945C210.37 45.4335 210.235 45.3439 210.122 45.2309C210.009 45.1179 209.919 44.9836 209.858 44.8359C209.796 44.6881 209.765 44.5298 209.765 44.3698Z"
                fill="#4E415C"/>
            <path
                d="M214.223 44.3371C214.223 44.0146 214.352 43.7054 214.58 43.4773C214.808 43.2492 215.118 43.1208 215.441 43.1204C215.764 43.1204 216.074 43.2485 216.302 43.4766C216.531 43.7048 216.66 44.0143 216.66 44.3371C216.66 44.4971 216.629 44.6555 216.568 44.8032C216.506 44.9509 216.416 45.0852 216.303 45.1982C216.19 45.3112 216.055 45.4008 215.907 45.4618C215.759 45.5229 215.601 45.5542 215.441 45.5539C215.118 45.5539 214.808 45.4257 214.579 45.1975C214.351 44.9693 214.223 44.6598 214.223 44.3371V44.3371Z"
                fill="#4E415C"/>
            <path
                d="M218.771 44.125C218.771 43.8023 218.9 43.4928 219.128 43.2646C219.357 43.0364 219.666 42.9082 219.989 42.9082C220.312 42.9082 220.622 43.0364 220.851 43.2646C221.079 43.4928 221.207 43.8023 221.207 44.125C221.208 44.2849 221.176 44.4433 221.115 44.5912C221.054 44.739 220.964 44.8734 220.851 44.9865C220.738 45.0997 220.604 45.1895 220.456 45.2508C220.308 45.312 220.15 45.3436 219.989 45.3436C219.829 45.3436 219.671 45.312 219.523 45.2508C219.375 45.1895 219.241 45.0997 219.128 44.9865C219.014 44.8734 218.925 44.739 218.864 44.5912C218.803 44.4433 218.771 44.2849 218.771 44.125V44.125Z"
                fill="#4E415C"/>
            <path
                d="M223.069 43.7569C223.069 43.4342 223.198 43.1247 223.426 42.8965C223.654 42.6684 223.964 42.5402 224.287 42.5402C224.61 42.5402 224.92 42.6684 225.149 42.8965C225.377 43.1247 225.505 43.4342 225.505 43.7569C225.505 43.9168 225.474 44.0751 225.413 44.2228C225.352 44.3706 225.262 44.5048 225.149 44.6179C225.036 44.7309 224.902 44.8205 224.754 44.8816C224.606 44.9426 224.447 44.9739 224.287 44.9737C224.127 44.9739 223.969 44.9426 223.821 44.8816C223.673 44.8205 223.539 44.7309 223.425 44.6179C223.312 44.5048 223.223 44.3706 223.162 44.2228C223.1 44.0751 223.069 43.9168 223.069 43.7569V43.7569Z"
                fill="#4E415C"/>
            <path
                d="M227.134 43.2455C227.134 42.9229 227.263 42.6137 227.491 42.3856C227.719 42.1575 228.029 42.0292 228.352 42.0287C228.675 42.0287 228.985 42.1569 229.213 42.3851C229.441 42.6133 229.57 42.9228 229.57 43.2455C229.57 43.4053 229.539 43.5637 229.477 43.7114C229.416 43.8591 229.327 43.9933 229.214 44.1064C229.1 44.2194 228.966 44.309 228.818 44.3701C228.67 44.4312 228.512 44.4625 228.352 44.4622C228.192 44.4622 228.033 44.4308 227.886 44.3696C227.738 44.3085 227.604 44.2188 227.491 44.1059C227.377 43.9929 227.288 43.8587 227.226 43.7111C227.165 43.5635 227.134 43.4053 227.134 43.2455V43.2455Z"
                fill="#4E415C"/>
            <path
                d="M230.968 42.6054C230.968 42.2827 231.096 41.9732 231.324 41.745C231.553 41.5168 231.863 41.3887 232.186 41.3887C232.509 41.3887 232.819 41.5168 233.047 41.745C233.275 41.9732 233.404 42.2827 233.404 42.6054C233.404 42.7653 233.373 42.9236 233.311 43.0714C233.25 43.2191 233.161 43.3533 233.047 43.4663C232.934 43.5794 232.8 43.669 232.652 43.7301C232.504 43.7911 232.346 43.8224 232.186 43.8222C232.026 43.8224 231.867 43.7911 231.719 43.7301C231.571 43.669 231.437 43.5794 231.324 43.4663C231.211 43.3533 231.121 43.2191 231.06 43.0714C230.999 42.9236 230.968 42.7653 230.968 42.6054V42.6054Z"
                fill="#4E415C"/>
            <path
                d="M234.592 41.851C234.592 41.5282 234.72 41.2185 234.948 40.99C235.177 40.7615 235.487 40.6329 235.81 40.6324C235.97 40.6324 236.128 40.664 236.276 40.7252C236.424 40.7865 236.559 40.8763 236.672 40.9895C236.785 41.1027 236.874 41.237 236.936 41.3848C236.997 41.5327 237.028 41.6911 237.028 41.851C237.028 42.1737 236.899 42.4832 236.671 42.7114C236.443 42.9396 236.133 43.0678 235.81 43.0678C235.487 43.0673 235.177 42.939 234.949 42.7109C234.721 42.4828 234.592 42.1736 234.592 41.851Z"
                fill="#4E415C"/>
            <path
                d="M238.024 40.9933C238.024 40.6705 238.153 40.361 238.381 40.1329C238.61 39.9047 238.919 39.7765 239.242 39.7765C239.402 39.7763 239.561 39.8075 239.709 39.8686C239.857 39.9296 239.992 40.0192 240.105 40.1322C240.218 40.2452 240.308 40.3795 240.369 40.5272C240.431 40.675 240.462 40.8333 240.462 40.9933C240.462 41.1532 240.431 41.3116 240.369 41.4593C240.308 41.6071 240.218 41.7413 240.105 41.8543C239.992 41.9673 239.857 42.0569 239.709 42.1179C239.561 42.179 239.402 42.2103 239.242 42.21C238.919 42.21 238.61 42.0818 238.381 41.8536C238.153 41.6254 238.024 41.316 238.024 40.9933V40.9933Z"
                fill="#4E415C"/>
            <path
                d="M241.263 40.0486C241.263 39.7259 241.391 39.4164 241.619 39.1882C241.848 38.96 242.158 38.8318 242.481 38.8318C242.804 38.8323 243.113 38.9606 243.341 39.1887C243.57 39.4168 243.698 39.726 243.699 40.0486C243.699 40.3715 243.57 40.6811 243.342 40.9096C243.114 41.1381 242.804 41.2667 242.481 41.2672C242.321 41.2672 242.162 41.2356 242.014 41.1744C241.866 41.1131 241.732 41.0233 241.619 40.9101C241.506 40.7969 241.416 40.6626 241.355 40.5148C241.294 40.3669 241.262 40.2085 241.263 40.0486V40.0486Z"
                fill="#4E415C"/>
            <path
                d="M244.332 39.0312C244.333 38.7087 244.461 38.3995 244.689 38.1714C244.918 37.9433 245.227 37.8149 245.55 37.8145C245.873 37.8145 246.183 37.9427 246.411 38.1708C246.64 38.399 246.768 38.7085 246.768 39.0312C246.768 39.1911 246.737 39.3494 246.676 39.4972C246.615 39.6449 246.525 39.7791 246.412 39.8922C246.299 40.0052 246.164 40.0948 246.016 40.1559C245.868 40.2169 245.71 40.2482 245.55 40.248C245.227 40.248 244.917 40.1198 244.689 39.8916C244.46 39.6634 244.332 39.354 244.332 39.0312V39.0312Z"
                fill="#4E415C"/>
            <path
                d="M247.244 37.9541C247.244 37.6314 247.372 37.3219 247.601 37.0937C247.829 36.8655 248.139 36.7373 248.462 36.7373C248.622 36.7371 248.781 36.7683 248.929 36.8294C249.077 36.8904 249.211 36.98 249.325 37.0931C249.438 37.2061 249.528 37.3403 249.589 37.488C249.65 37.6358 249.682 37.7941 249.682 37.9541C249.682 38.114 249.65 38.2724 249.589 38.4201C249.528 38.5679 249.438 38.7021 249.325 38.8151C249.211 38.9281 249.077 39.0177 248.929 39.0788C248.781 39.1398 248.622 39.1711 248.462 39.1708C248.139 39.1708 247.829 39.0427 247.601 38.8145C247.372 38.5863 247.244 38.2768 247.244 37.9541V37.9541Z"
                fill="#4E415C"/>
            <path
                d="M250.005 36.8317C250.005 36.5089 250.133 36.1994 250.362 35.9713C250.59 35.7431 250.9 35.6149 251.223 35.6149C251.383 35.6146 251.541 35.6459 251.689 35.707C251.837 35.7681 251.971 35.8577 252.085 35.9707C252.198 36.0838 252.287 36.218 252.349 36.3657C252.41 36.5135 252.441 36.6718 252.441 36.8317C252.441 37.1544 252.312 37.4638 252.084 37.692C251.856 37.9202 251.546 38.0484 251.223 38.0484C250.9 38.0479 250.59 37.9196 250.362 37.6915C250.134 37.4634 250.005 37.1542 250.005 36.8317V36.8317Z"
                fill="#4E415C"/>
            <path
                d="M252.644 35.6765C252.643 35.5167 252.675 35.3583 252.736 35.2106C252.797 35.0628 252.887 34.9286 253 34.8156C253.113 34.7025 253.247 34.6129 253.395 34.5518C253.543 34.4908 253.702 34.4595 253.862 34.4597C254.022 34.4595 254.18 34.4908 254.328 34.5518C254.476 34.6129 254.61 34.7025 254.723 34.8156C254.836 34.9286 254.926 35.0628 254.987 35.2106C255.048 35.3583 255.08 35.5167 255.079 35.6765C255.079 35.8363 255.048 35.9945 254.987 36.1421C254.926 36.2898 254.836 36.4239 254.723 36.5369C254.61 36.6499 254.475 36.7395 254.328 36.8007C254.18 36.8618 254.021 36.8933 253.862 36.8933C253.702 36.8933 253.543 36.8618 253.395 36.8007C253.248 36.7395 253.113 36.6499 253 36.5369C252.887 36.4239 252.797 36.2898 252.736 36.1421C252.675 35.9945 252.644 35.8363 252.644 35.6765V35.6765Z"
                fill="#4E415C"/>
            <path
                d="M255.156 34.5033C255.156 34.3433 255.188 34.185 255.249 34.0372C255.31 33.8895 255.4 33.7553 255.514 33.6422C255.627 33.5292 255.761 33.4396 255.909 33.3786C256.057 33.3176 256.216 33.2863 256.376 33.2865C256.699 33.2865 257.009 33.4147 257.237 33.6429C257.466 33.8711 257.594 34.1806 257.594 34.5033C257.594 34.826 257.466 35.1355 257.237 35.3637C257.009 35.5919 256.699 35.7201 256.376 35.7201C256.216 35.7203 256.057 35.689 255.909 35.6279C255.761 35.5669 255.627 35.4773 255.514 35.3643C255.4 35.2513 255.31 35.1171 255.249 34.9693C255.188 34.8216 255.156 34.6632 255.156 34.5033V34.5033Z"
                fill="#4E415C"/>
            <path
                d="M257.562 33.3264C257.563 33.0032 257.691 32.6932 257.92 32.4647C258.149 32.2362 258.459 32.1078 258.782 32.1078C259.106 32.1083 259.415 32.2369 259.644 32.4653C259.872 32.6938 260 33.0035 260 33.3264C260 33.6489 259.871 33.9582 259.643 34.1862C259.415 34.4143 259.105 34.5427 258.782 34.5432C258.459 34.5432 258.149 34.415 257.92 34.1869C257.692 33.9587 257.563 33.6492 257.562 33.3264V33.3264Z"
                fill="#4E415C"/>
            <path
                d="M259.877 32.1586C259.877 31.9987 259.908 31.8404 259.969 31.6926C260.03 31.5449 260.12 31.4107 260.233 31.2976C260.346 31.1846 260.481 31.095 260.629 31.0339C260.776 30.9729 260.935 30.9415 261.095 30.9418C261.255 30.9415 261.413 30.9729 261.561 31.0339C261.709 31.095 261.844 31.1846 261.957 31.2976C262.07 31.4107 262.16 31.5449 262.221 31.6926C262.282 31.8404 262.313 31.9987 262.313 32.1586C262.313 32.4813 262.185 32.7908 261.956 33.0189C261.728 33.2471 261.418 33.3753 261.095 33.3753C260.772 33.3753 260.462 33.2471 260.234 33.0189C260.005 32.7908 259.877 32.4813 259.877 32.1586V32.1586Z"
                fill="#4E415C"/>
            <path
                d="M262.223 30.9508C262.223 30.6281 262.351 30.3186 262.579 30.0904C262.808 29.8622 263.118 29.734 263.441 29.734C263.601 29.7338 263.759 29.7651 263.907 29.8261C264.055 29.8872 264.189 29.9768 264.302 30.0898C264.416 30.2029 264.505 30.3371 264.566 30.4849C264.627 30.6326 264.659 30.7909 264.659 30.9508C264.659 31.2735 264.53 31.583 264.302 31.8112C264.073 32.0393 263.764 32.1675 263.441 32.1675C263.118 32.1671 262.808 32.0387 262.58 31.8106C262.352 31.5826 262.223 31.2733 262.223 30.9508V30.9508Z"
                fill="#4E415C"/>
            <path
                d="M265.286 29.395C265.286 29.2352 265.317 29.0768 265.378 28.9291C265.439 28.7813 265.529 28.6471 265.642 28.5341C265.755 28.421 265.89 28.3314 266.038 28.2704C266.186 28.2093 266.344 28.178 266.504 28.1782C266.664 28.178 266.823 28.2093 266.971 28.2704C267.118 28.3314 267.253 28.421 267.366 28.5341C267.479 28.6471 267.569 28.7813 267.63 28.9291C267.691 29.0768 267.722 29.2352 267.722 29.395C267.722 29.5549 267.691 29.7132 267.63 29.8609C267.569 30.0087 267.479 30.1429 267.366 30.2559C267.253 30.369 267.118 30.4586 266.971 30.5196C266.823 30.5807 266.664 30.612 266.504 30.6118C266.344 30.612 266.186 30.5807 266.038 30.5196C265.89 30.4586 265.755 30.369 265.642 30.2559C265.529 30.1429 265.439 30.0087 265.378 29.8609C265.317 29.7132 265.286 29.5549 265.286 29.395V29.395Z"
                fill="#4E415C"/>
            <path
                d="M268.396 27.8825C268.396 27.5598 268.524 27.2503 268.752 27.0222C268.981 26.794 269.29 26.6658 269.613 26.6658C269.936 26.6663 270.246 26.7946 270.474 27.0227C270.702 27.2508 270.831 27.56 270.831 27.8825C270.831 28.2052 270.703 28.5147 270.475 28.7429C270.246 28.9711 269.936 29.0993 269.613 29.0993C269.453 29.0996 269.295 29.0682 269.147 29.0072C268.999 28.9461 268.865 28.8565 268.752 28.7435C268.639 28.6304 268.549 28.4962 268.488 28.3485C268.427 28.2007 268.395 28.0424 268.396 27.8825V27.8825Z"
                fill="#4E415C"/>
            <path
                d="M271.553 26.49C271.553 26.1674 271.682 25.8582 271.91 25.6301C272.138 25.402 272.448 25.2737 272.771 25.2732C273.094 25.2732 273.404 25.4014 273.632 25.6296C273.86 25.8578 273.989 26.1673 273.989 26.49C273.989 26.6498 273.958 26.8082 273.896 26.9559C273.835 27.1036 273.746 27.2379 273.632 27.3509C273.519 27.4639 273.385 27.5536 273.237 27.6146C273.089 27.6757 272.931 27.707 272.771 27.7067C272.448 27.7067 272.138 27.5786 271.909 27.3504C271.681 27.1222 271.553 26.8127 271.553 26.49Z"
                fill="#4E415C"/>
            <path
                d="M274.762 25.2859C274.762 24.9633 274.891 24.6541 275.119 24.426C275.347 24.1979 275.657 24.0696 275.98 24.0691C276.303 24.0691 276.613 24.1972 276.842 24.4253C277.07 24.6535 277.199 24.963 277.2 25.2859C277.2 25.4458 277.168 25.6042 277.107 25.7519C277.045 25.8997 276.955 26.0339 276.842 26.1469C276.729 26.2599 276.594 26.3495 276.446 26.4105C276.298 26.4716 276.14 26.5029 275.98 26.5026C275.82 26.5026 275.661 26.4712 275.514 26.41C275.366 26.3489 275.232 26.2592 275.118 26.1462C275.005 26.0332 274.916 25.8991 274.854 25.7515C274.793 25.6039 274.762 25.4456 274.762 25.2859V25.2859Z"
                fill="#4E415C"/>
            <path
                d="M278.027 24.341C278.027 24.0182 278.156 23.7085 278.384 23.48C278.612 23.2515 278.922 23.1229 279.245 23.1224C279.569 23.1224 279.879 23.2508 280.108 23.4794C280.337 23.7079 280.465 24.0179 280.465 24.341C280.465 24.6639 280.336 24.9734 280.107 25.2015C279.878 25.4297 279.569 25.5578 279.245 25.5578C278.922 25.5573 278.613 25.429 278.385 25.2009C278.156 24.9728 278.028 24.6636 278.027 24.341Z"
                fill="#4E415C"/>
            <path
                d="M281.353 23.7209C281.353 23.3982 281.481 23.0887 281.709 22.8605C281.938 22.6323 282.247 22.5042 282.571 22.5042C282.894 22.5042 283.203 22.6323 283.432 22.8605C283.66 23.0887 283.789 23.3982 283.789 23.7209C283.789 23.8809 283.757 24.0393 283.696 24.1871C283.635 24.335 283.546 24.4693 283.432 24.5825C283.319 24.6956 283.185 24.7854 283.037 24.8467C282.889 24.908 282.731 24.9395 282.571 24.9395C282.41 24.9395 282.252 24.908 282.104 24.8467C281.956 24.7854 281.822 24.6956 281.709 24.5825C281.596 24.4693 281.506 24.335 281.445 24.1871C281.384 24.0393 281.352 23.8809 281.353 23.7209V23.7209Z"
                fill="#4E415C"/>
            <path
                d="M284.739 23.4996C284.739 23.1769 284.868 22.8674 285.096 22.6392C285.324 22.411 285.634 22.2828 285.957 22.2828C286.28 22.2828 286.59 22.411 286.818 22.6392C287.047 22.8674 287.175 23.1769 287.175 23.4996C287.175 23.6595 287.144 23.8178 287.083 23.9655C287.022 24.1133 286.932 24.2475 286.819 24.3605C286.706 24.4736 286.571 24.5632 286.424 24.6243C286.276 24.6853 286.117 24.7166 285.957 24.7164C285.797 24.7166 285.639 24.6853 285.491 24.6243C285.343 24.5632 285.209 24.4736 285.095 24.3605C284.982 24.2475 284.893 24.1133 284.831 23.9655C284.77 23.8178 284.739 23.6595 284.739 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M290.748 45.2322C296.179 41.849 297.837 34.7076 294.45 29.2816C291.063 23.8556 283.915 22.1996 278.484 25.5829C273.052 28.9662 271.395 36.1075 274.781 41.5335C278.168 46.9596 285.316 48.6155 290.748 45.2322Z"
                fill="#E8D4C1"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M273.65 33.0416C275.109 34.3998 277.029 35.1547 279.023 35.1542C281.123 35.1542 283.137 34.3208 284.622 32.8374C286.107 31.354 286.941 29.3421 286.941 27.2443C286.941 25.8089 286.547 24.4009 285.803 23.1732C285.565 23.1587 285.329 23.137 285.089 23.137C282.31 23.1386 279.623 24.1407 277.522 25.9597C275.422 27.7786 274.047 30.2928 273.65 33.0416V33.0416Z"
                  fill="#232830"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M291.509 25.0809C292.733 26.9534 293.385 29.142 293.384 31.3788C293.384 34.4443 292.165 37.3842 289.995 39.5518C287.825 41.7195 284.882 42.9372 281.814 42.9372C279.529 42.9406 277.295 42.2645 275.396 40.9951C276.444 42.6098 277.881 43.9369 279.574 44.8553C281.267 45.7738 283.164 46.2546 285.09 46.2539C288.159 46.2539 291.102 45.0361 293.271 42.8685C295.441 40.7009 296.66 37.7609 296.66 34.6954C296.66 32.793 296.19 30.9198 295.292 29.2425C294.393 27.5652 293.094 26.1357 291.509 25.0809V25.0809Z"
                  fill="#FAFAFA"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M274.016 35.2376C274.016 32.1719 275.235 29.2318 277.405 27.0639C279.574 24.896 282.517 23.6778 285.586 23.6773C288.641 23.6741 291.573 24.8819 293.738 27.0357C292.655 25.8103 291.325 24.829 289.834 24.1568C288.343 23.4846 286.726 23.137 285.09 23.137C282.021 23.137 279.078 24.3549 276.908 26.5229C274.738 28.6908 273.519 31.6312 273.519 34.6972C273.517 36.2221 273.818 37.7322 274.405 39.1398C274.992 40.5474 275.853 41.8246 276.938 42.8973C275.055 40.7895 274.015 38.0628 274.016 35.2376V35.2376Z"
                  fill="#786A56"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M292.317 25.6793C293.49 26.7579 294.425 28.0677 295.065 29.526C295.705 30.9842 296.035 32.5592 296.035 34.1514C296.035 37.2169 294.816 40.1568 292.646 42.3244C290.476 44.4921 287.534 45.7098 284.465 45.7098C281.836 45.7127 279.285 44.8169 277.235 43.1711C279.369 45.1542 282.175 46.2558 285.09 46.2539C288.158 46.2539 291.101 45.0361 293.271 42.8685C295.44 40.7008 296.659 37.7609 296.659 34.6954C296.66 32.9633 296.27 31.2532 295.518 29.6923C294.767 28.1314 293.673 26.7598 292.317 25.6793Z"
                  fill="#F2F0ED"/>
            </g>
            <path
                d="M279.006 34.6973C279.006 33.899 279.163 33.1085 279.469 32.3709C279.774 31.6334 280.222 30.9632 280.787 30.3987C281.352 29.8342 282.023 29.3865 282.762 29.0811C283.5 28.7757 284.291 28.6187 285.09 28.6189C286.704 28.6189 288.252 29.2593 289.393 30.3992C290.534 31.5391 291.175 33.0852 291.175 34.6973C291.175 36.3094 290.534 37.8555 289.393 38.9954C288.252 40.1353 286.704 40.7758 285.09 40.7758C283.477 40.7758 281.929 40.1353 280.788 38.9954C279.647 37.8555 279.006 36.3094 279.006 34.6973V34.6973Z"
                fill="#FAECDE"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M289.637 30.668C290.481 31.7359 290.94 33.0571 290.94 34.418C290.94 36.0301 290.299 37.5762 289.158 38.7161C288.017 39.8561 286.469 40.4965 284.856 40.4965C283.996 40.4968 283.145 40.3142 282.361 39.9609C281.577 39.6076 280.878 39.0916 280.309 38.4473C280.876 39.173 281.601 39.7599 282.43 40.1633C283.258 40.5668 284.168 40.7762 285.09 40.7757C286.703 40.7757 288.251 40.1353 289.392 38.9954C290.533 37.8555 291.174 36.3094 291.174 34.6973C291.174 33.2112 290.627 31.7769 289.637 30.668V30.668Z"
                  fill="#F2F0ED"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M279.298 35.1216C279.298 33.5095 279.939 31.9634 281.08 30.8235C282.221 29.6836 283.769 29.0432 285.382 29.0432C286.33 29.0434 287.265 29.2654 288.112 29.6915C288.959 30.1175 289.694 30.7358 290.258 31.4967C289.714 30.6161 288.954 29.8892 288.049 29.3848C287.145 28.8805 286.126 28.6156 285.09 28.6152C284.291 28.615 283.5 28.772 282.762 29.0774C282.023 29.3828 281.352 29.8305 280.787 30.395C280.222 30.9595 279.774 31.6297 279.469 32.3673C279.163 33.1048 279.006 33.8954 279.006 34.6937C279.005 36.0015 279.429 37.2742 280.215 38.3204C279.616 37.3608 279.298 36.2526 279.298 35.1216V35.1216Z"
                  fill="#786A56"/>
            </g>
            <path
                d="M282.777 34.6973C282.777 34.0846 283.021 33.497 283.455 33.0637C283.888 32.6305 284.477 32.3871 285.09 32.3871C285.393 32.3871 285.694 32.4469 285.975 32.563C286.255 32.6791 286.51 32.8493 286.724 33.0638C286.939 33.2784 287.109 33.5331 287.225 33.8134C287.341 34.0936 287.401 34.394 287.401 34.6973C287.401 35.3096 287.157 35.8967 286.724 36.3296C286.29 36.7625 285.703 37.0057 285.09 37.0057C284.786 37.006 284.486 36.9464 284.205 36.8305C283.924 36.7146 283.67 36.5446 283.455 36.3303C283.24 36.1159 283.07 35.8613 282.953 35.5811C282.837 35.3009 282.777 35.0006 282.777 34.6973V34.6973Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M286.644 32.9982C286.836 33.338 286.939 33.7212 286.942 34.1116C286.942 34.724 286.698 35.3113 286.265 35.7445C285.832 36.1777 285.244 36.4213 284.631 36.4218C284.055 36.4203 283.5 36.2022 283.077 35.8107C283.275 36.1716 283.566 36.4729 283.92 36.6831C284.274 36.8933 284.678 37.0047 285.09 37.0057C285.703 37.0057 286.291 36.7625 286.724 36.3296C287.157 35.8967 287.401 35.3095 287.401 34.6973C287.4 34.377 287.333 34.0604 287.202 33.7678C287.072 33.4752 286.882 33.213 286.644 32.9982V32.9982Z"
                  fill="#DBD3E6"/>
            </g>
            <path d="M129.916 33.709H127.583L126.454 25.8933H131.046L129.916 33.709Z" fill="#824E3E"/>
            <path d="M121.598 32.329L119.669 31.018L123.132 23.9204L126.931 26.499L121.598 32.329Z" fill="#824E3E"/>
            <path d="M116.386 27.1265L115.348 25.0393L121.853 20.553L123.895 24.6621L116.386 27.1265Z" fill="#824E3E"/>
            <path d="M114.38 19.1077L114.418 16.7775L122.259 15.7765L122.185 20.3625L114.38 19.1077Z" fill="#824E3E"/>
            <path d="M115.701 10.9729L116.923 8.9873L124.178 12.1172L121.773 16.0268L115.701 10.9729Z" fill="#824E3E"/>
            <path d="M120.62 5.48381L122.666 4.36133L127.425 10.6646L123.401 12.8769L120.62 5.48381Z" fill="#824E3E"/>
            <path d="M128.803 3.30774L131.135 3.38753L131.994 11.2376L127.405 11.0799L128.803 3.30774Z" fill="#824E3E"/>
            <path d="M136.824 4.76208L138.792 6.01694L135.532 13.2106L131.662 10.7408L136.824 4.76208Z" fill="#824E3E"/>
            <path d="M142.226 9.77966L143.315 11.8415L136.922 16.4855L134.78 12.4272L142.226 9.77966Z" fill="#824E3E"/>
            <path d="M144.297 18.0868L144.168 20.4134L136.294 21.1061L136.55 16.5237L144.297 18.0868Z" fill="#824E3E"/>
            <path d="M142.689 25.8807L141.42 27.8355L134.241 24.5297L136.741 20.6799L142.689 25.8807Z" fill="#824E3E"/>
            <path d="M137.758 31.3189L135.743 32.4976L130.816 26.3213L134.781 24.0038L137.758 31.3189Z" fill="#824E3E"/>
            <path d="M129.916 33.709H127.583L126.454 25.8933H131.046L129.916 33.709Z" fill="#824E3E"/>
            <path
                d="M129.316 29.2372C135.389 29.2372 140.312 24.3189 140.312 18.2518C140.312 12.1847 135.389 7.26636 129.316 7.26636C123.243 7.26636 118.319 12.1847 118.319 18.2518C118.319 24.3189 123.243 29.2372 129.316 29.2372Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M118.522 18.7033C118.522 15.7898 119.681 12.9956 121.743 10.9354C123.805 8.87526 126.602 7.71789 129.519 7.71789C131.616 7.71877 133.669 8.31903 135.436 9.44781C137.202 10.5766 138.609 12.1868 139.489 14.0883C138.662 12.0708 137.253 10.3447 135.441 9.12991C133.629 7.91508 131.496 7.26637 129.314 7.26636C126.397 7.26636 123.6 8.42373 121.538 10.4839C119.476 12.5441 118.317 15.3383 118.317 18.2518C118.319 19.8465 118.67 21.4216 119.345 22.8668C118.802 21.5457 118.523 20.1314 118.522 18.7033V18.7033Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M136.227 9.71265C137.391 10.7403 138.323 12.0036 138.962 13.4185C139.6 14.8335 139.93 16.3679 139.93 17.92C139.93 20.8335 138.771 23.6277 136.709 25.6878C134.647 27.748 131.85 28.9054 128.933 28.9054C126.416 28.908 123.974 28.0434 122.02 26.4573C124.026 28.2483 126.622 29.2379 129.313 29.2372C132.229 29.2372 135.027 28.0799 137.089 26.0198C139.151 23.9597 140.31 21.1655 140.311 18.2518C140.312 16.6141 139.946 14.9969 139.239 13.5192C138.532 12.0414 137.503 10.7407 136.227 9.71265V9.71265Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M123.439 18.6199C123.439 17.0083 124.08 15.4627 125.221 14.3231C126.361 13.1835 127.909 12.5433 129.522 12.5433C130.568 12.5433 131.596 12.8133 132.507 13.327C133.418 13.8407 134.18 14.5808 134.72 15.4755C134.21 14.4817 133.435 13.6478 132.481 13.0655C131.527 12.4833 130.431 12.1752 129.313 12.1752C128.514 12.1752 127.723 12.3324 126.985 12.6379C126.247 12.9434 125.576 13.3912 125.011 13.9556C124.447 14.5201 123.999 15.1902 123.693 15.9276C123.387 16.6651 123.23 17.4555 123.23 18.2536C123.231 19.3629 123.537 20.4508 124.114 21.398C123.671 20.5391 123.439 19.5865 123.439 18.6199V18.6199Z"
                  fill="#331911"/>
            </g>
            <path
                d="M129.314 9.91565C127.099 9.91565 124.975 10.7945 123.409 12.3588C121.844 13.9232 120.964 16.0449 120.964 18.2572C120.964 20.4695 121.844 22.5912 123.409 24.1555C124.975 25.7198 127.099 26.5987 129.314 26.5987C131.528 26.5987 133.652 25.7198 135.218 24.1555C136.784 22.5912 137.663 20.4695 137.663 18.2572C137.663 16.0449 136.784 13.9232 135.218 12.3588C133.652 10.7945 131.528 9.91565 129.314 9.91565V9.91565ZM129.314 24.3374C128.515 24.3374 127.724 24.1802 126.985 23.8747C126.247 23.5692 125.577 23.1214 125.012 22.557C124.447 21.9925 123.999 21.3224 123.693 20.585C123.388 19.8475 123.231 19.0571 123.231 18.259C123.231 17.4608 123.388 16.6705 123.693 15.933C123.999 15.1955 124.447 14.5254 125.012 13.961C125.577 13.3965 126.247 12.9488 126.985 12.6433C127.724 12.3378 128.515 12.1806 129.314 12.1806C130.927 12.1806 132.475 12.8209 133.616 13.9609C134.757 15.1008 135.398 16.6469 135.398 18.259C135.396 19.8698 134.754 21.414 133.613 22.5524C132.472 23.6908 130.926 24.3302 129.314 24.3302V24.3374Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M121.171 18.6199C121.171 16.4076 122.05 14.2859 123.616 12.7216C125.182 11.1573 127.306 10.2784 129.52 10.2784C130.975 10.2798 132.403 10.6607 133.665 11.3833C134.927 12.1059 135.978 13.1453 136.714 14.3984C136.008 13.0461 134.945 11.9129 133.64 11.1219C132.335 10.3309 130.838 9.91252 129.312 9.91211C127.097 9.91211 124.973 10.791 123.407 12.3553C121.842 13.9196 120.962 16.0413 120.962 18.2536C120.962 19.7397 121.362 21.1985 122.12 22.477C121.495 21.2871 121.17 19.9635 121.171 18.6199V18.6199Z"
                  fill="#E8E6E1"/>
              <path
                  d="M129.523 24.6966C131.136 24.6966 132.684 24.0562 133.825 22.9162C134.966 21.7763 135.607 20.2302 135.607 18.6181C135.607 17.5085 135.3 16.4205 134.721 15.4738C135.166 16.3322 135.399 17.285 135.398 18.2518C135.398 19.8639 134.757 21.41 133.616 22.5499C132.475 23.6899 130.928 24.3303 129.314 24.3303C128.268 24.3295 127.24 24.0591 126.329 23.5451C125.418 23.0311 124.656 22.2909 124.115 21.3962C124.626 22.3896 125.401 23.2232 126.355 23.8054C127.309 24.3876 128.405 24.696 129.523 24.6966Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M118.317 18.2573C118.317 18.4749 118.337 18.6889 118.35 18.9028C119.564 19.507 120.902 19.8217 122.258 19.8222C124.601 19.8222 126.848 18.8926 128.504 17.2379C130.161 15.5831 131.092 13.3387 131.092 10.9983C131.091 9.7277 130.815 8.47242 130.281 7.319C129.962 7.2918 129.64 7.27185 129.314 7.27185C127.87 7.27185 126.44 7.55599 125.106 8.10806C123.771 8.66013 122.559 9.4693 121.538 10.4894C120.517 11.5095 119.707 12.7205 119.154 14.0533C118.602 15.3861 118.317 16.8146 118.317 18.2573V18.2573Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M134.136 8.38879C135.8 10.2935 136.717 12.7367 136.715 15.2651C136.715 18.0493 135.608 20.7194 133.638 22.6881C131.667 24.6567 128.994 25.7627 126.207 25.7627C123.81 25.7646 121.484 24.9439 119.62 23.438C120.558 25.1913 121.956 26.6572 123.663 27.6792C125.37 28.7012 127.323 29.2409 129.313 29.2408C132.23 29.2408 135.027 28.0835 137.089 26.0233C139.152 23.9632 140.311 21.169 140.311 18.2554C140.311 16.1979 139.731 14.182 138.64 12.4375C137.548 10.693 135.987 9.29007 134.136 8.38879V8.38879Z"
                  fill="#FAF8F2"/>
            </g>
            <path
                d="M125.781 18.2572C125.781 17.3211 126.153 16.4234 126.816 15.7613C127.478 15.0992 128.377 14.727 129.314 14.7266C130.251 14.7266 131.15 15.0985 131.813 15.7607C132.475 16.4228 132.848 17.3208 132.848 18.2572C132.848 18.7208 132.756 19.1798 132.579 19.608C132.401 20.0363 132.141 20.4254 131.812 20.7531C131.484 21.0808 131.095 21.3407 130.666 21.5179C130.237 21.6952 129.778 21.7863 129.314 21.786C128.377 21.786 127.478 21.4142 126.816 20.7524C126.153 20.0907 125.781 19.1931 125.781 18.2572Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M132.116 16.1211C132.326 16.5762 132.435 17.0709 132.437 17.5718C132.437 18.5082 132.065 19.4062 131.402 20.0683C130.74 20.7305 129.841 21.1024 128.903 21.1024C128.36 21.1014 127.824 20.9742 127.338 20.731C126.852 20.4877 126.429 20.135 126.103 19.7007C126.381 20.3186 126.832 20.8432 127.402 21.2115C127.971 21.5798 128.635 21.7761 129.314 21.777C129.778 21.7773 130.237 21.6861 130.666 21.5089C131.095 21.3317 131.484 21.0718 131.812 20.7441C132.141 20.4164 132.401 20.0273 132.579 19.599C132.756 19.1708 132.848 18.7117 132.848 18.2482C132.844 17.4781 132.587 16.7307 132.116 16.1211V16.1211Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M126.328 19.0043C126.328 18.0679 126.7 17.1699 127.363 16.5078C128.026 15.8457 128.924 15.4737 129.862 15.4737C130.333 15.4735 130.8 15.568 131.234 15.7515C131.668 15.935 132.06 16.2037 132.388 16.5418C132.084 15.993 131.639 15.5355 131.098 15.2166C130.558 14.8977 129.941 14.7292 129.314 14.7284C128.377 14.7289 127.478 15.1011 126.816 15.7631C126.153 16.4252 125.781 17.323 125.781 18.259C125.781 19.1798 126.142 20.0639 126.787 20.7216C126.489 20.1981 126.331 19.6066 126.328 19.0043Z"
                  fill="#241133"/>
            </g>
            <path d="M168.969 45.3726L166.687 45.8586L163.95 38.451L168.443 37.4935L168.969 45.3726Z" fill="#824E3E"/>
            <path d="M175.355 42.3533L173.364 43.5701L168.319 37.4898L172.238 35.0962L175.355 42.3533Z" fill="#824E3E"/>
            <path d="M180.604 36.9404L179.215 38.8136L172.256 35.0672L174.991 31.3806L180.604 36.9404Z" fill="#824E3E"/>
            <path d="M183.544 30.4921L182.811 32.7044L175.029 31.3172L176.472 26.9633L183.544 30.4921Z" fill="#824E3E"/>
            <path d="M184.292 23.5323L184.283 25.8625L176.456 26.9632L176.474 22.3754L184.292 23.5323Z" fill="#824E3E"/>
            <path d="M182.642 15.6841L183.459 17.8674L176.527 21.661L174.919 17.3651L182.642 15.6841Z" fill="#824E3E"/>
            <path d="M178.237 9.33179L179.82 11.0454L174.837 17.1746L171.723 13.8036L178.237 9.33179Z" fill="#824E3E"/>
            <path d="M172.347 4.76758L174.393 5.89006L171.612 13.2832L167.588 11.0727L172.347 4.76758Z" fill="#824E3E"/>
            <path d="M165.019 2.77283L167.33 3.09561L167.364 10.991L162.817 10.3564L165.019 2.77283Z" fill="#824E3E"/>
            <path d="M158.196 3.15549L160.503 2.81458L162.767 10.38L158.224 11.0527L158.196 3.15549Z" fill="#824E3E"/>
            <path d="M151.391 5.861L153.436 4.73853L158.196 11.0418L154.17 13.2541L151.391 5.861Z" fill="#824E3E"/>
            <path d="M146.093 10.4905L147.676 8.77869L154.19 13.2487L151.075 16.6215L146.093 10.4905Z" fill="#824E3E"/>
            <path d="M142.504 16.6016L143.457 14.4745L151.059 16.636L149.184 20.8249L142.504 16.6016Z" fill="#824E3E"/>
            <path d="M140.976 23.6049L141.175 21.2837L149.066 20.825L148.675 25.3965L140.976 23.6049Z" fill="#824E3E"/>
            <path d="M141.582 30.1602L141.176 27.8663L148.683 25.3965L149.482 29.9136L141.582 30.1602Z" fill="#824E3E"/>
            <path d="M144.1 36.4799L143.023 34.4108L149.445 29.8066L151.562 33.8777L144.1 36.4799Z" fill="#824E3E"/>
            <path d="M149.047 41.9744L147.292 40.4385L151.594 33.8142L155.05 36.8353L149.047 41.9744Z" fill="#824E3E"/>
            <path d="M155.696 45.3943L153.496 44.6218L155.024 36.8733L159.357 38.3947L155.696 45.3943Z" fill="#824E3E"/>
            <path d="M162.819 46.2557H160.486L159.357 38.4401H163.95L162.819 46.2557Z" fill="#824E3E"/>
            <path
                d="M175.231 37.2104C182.088 30.3603 182.088 19.2542 175.231 12.4041C168.375 5.55397 157.258 5.55397 150.401 12.4041C143.544 19.2542 143.544 30.3603 150.401 37.2104C157.258 44.0605 168.375 44.0605 175.231 37.2104Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M168.868 8.3562C171.137 9.75208 173.011 11.7051 174.311 14.0293C175.611 16.3535 176.293 18.9714 176.293 21.6337C176.293 25.7765 174.646 29.7496 171.714 32.679C168.782 35.6084 164.805 37.2541 160.658 37.2541C156.991 37.2524 153.443 35.9632 150.631 33.6119C147.82 31.2607 145.926 27.9971 145.28 24.3918C145.28 24.5315 145.26 24.6675 145.26 24.8071C145.26 27.1106 145.714 29.3915 146.596 31.5197C147.479 33.6478 148.772 35.5815 150.402 37.2103C152.033 38.8391 153.968 40.1312 156.099 41.0127C158.229 41.8942 160.512 42.3479 162.818 42.3479C165.123 42.3479 167.407 41.8942 169.537 41.0127C171.667 40.1312 173.603 38.8391 175.233 37.2103C176.864 35.5815 178.157 33.6478 179.039 31.5197C179.922 29.3915 180.376 27.1106 180.376 24.8071C180.374 21.218 179.269 17.7159 177.211 14.774C175.153 11.832 172.241 9.59194 168.868 8.3562V8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M145.51 25.3293C145.51 23.0257 145.964 20.7446 146.847 18.6164C147.729 16.4881 149.023 14.5543 150.653 12.9255C152.284 11.2967 154.22 10.0047 156.35 9.12326C158.481 8.24186 160.764 7.78834 163.07 7.78858C166.421 7.78993 169.702 8.75009 172.524 10.5555C175.346 12.3609 177.591 14.9359 178.994 17.9761C177.65 14.7994 175.399 12.089 172.521 10.1836C169.644 8.27812 166.268 7.26217 162.816 7.2627C158.159 7.2627 153.693 9.11073 150.4 12.4003C147.108 15.6898 145.258 20.1514 145.258 24.8035C145.261 27.3441 145.818 29.8535 146.891 32.1567C145.978 29.9963 145.508 27.6746 145.51 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <path
                d="M171.792 28.5214C173.845 23.5694 171.491 17.8922 166.535 15.841C161.578 13.7898 155.895 16.1414 153.842 21.0934C151.788 26.0454 154.142 31.7226 159.099 33.7738C164.056 35.825 169.739 33.4734 171.792 28.5214Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M153.407 25.2151C153.407 22.6416 154.431 20.1735 156.252 18.3538C158.074 16.5341 160.544 15.5118 163.12 15.5118C164.625 15.5113 166.109 15.8612 167.455 16.5335C168.8 17.2058 169.971 18.1822 170.873 19.3851C169.984 18.0657 168.785 16.9848 167.38 16.2379C165.975 15.4909 164.407 15.1008 162.815 15.1019C160.239 15.1019 157.768 16.1244 155.946 17.9445C154.124 19.7646 153.101 22.2331 153.101 24.8071C153.101 26.9127 153.79 28.9605 155.063 30.6389C153.981 29.037 153.404 27.1477 153.407 25.2151V25.2151Z"
                  fill="#331911"/>
            </g>
            <path
                d="M162.816 11.4843C161.064 11.4843 159.33 11.8289 157.712 12.4984C156.095 13.168 154.624 14.1494 153.386 15.3865C152.148 16.6237 151.166 18.0924 150.496 19.7088C149.826 21.3252 149.481 23.0576 149.481 24.8071C149.481 28.3401 150.886 31.7283 153.387 34.2265C155.888 36.7247 159.279 38.1281 162.816 38.1281C164.567 38.1281 166.301 37.7836 167.918 37.1141C169.536 36.4447 171.006 35.4635 172.244 34.2265C173.482 32.9895 174.465 31.521 175.135 29.9048C175.805 28.2887 176.15 26.5564 176.15 24.8071C176.15 23.0576 175.805 21.3252 175.135 19.7088C174.465 18.0924 173.483 16.6237 172.245 15.3865C171.007 14.1494 169.537 13.168 167.919 12.4984C166.301 11.8289 164.567 11.4843 162.816 11.4843ZM162.816 34.5141C160.239 34.5141 157.768 33.4916 155.946 31.6715C154.124 29.8514 153.101 27.3829 153.101 24.8089C153.101 22.2349 154.124 19.7664 155.946 17.9463C157.768 16.1262 160.239 15.1037 162.816 15.1037C165.392 15.1037 167.863 16.1262 169.685 17.9463C171.507 19.7664 172.53 22.2349 172.53 24.8089C172.53 27.3826 171.506 29.8507 169.684 31.6703C167.862 33.49 165.392 34.5123 162.816 34.5123V34.5141Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M163.121 34.9203C165.697 34.9203 168.168 33.8978 169.99 32.0777C171.812 30.2577 172.836 27.7891 172.836 25.2151C172.836 23.1099 172.147 21.0623 170.873 19.3851C171.956 20.9861 172.534 22.8749 172.531 24.8071C172.531 27.3811 171.507 29.8496 169.685 31.6697C167.863 33.4898 165.392 34.5123 162.816 34.5123C161.311 34.5122 159.828 34.1621 158.482 33.4898C157.136 32.8175 155.966 31.8414 155.063 30.6389C155.952 31.9577 157.152 33.038 158.557 33.7846C159.962 34.5311 161.53 34.9212 163.121 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M149.786 25.2151C149.786 21.6822 151.191 18.2939 153.692 15.7957C156.193 13.2975 159.584 11.8941 163.121 11.8941C165.218 11.894 167.285 12.3893 169.154 13.3394C171.023 14.2896 172.641 15.6677 173.875 17.3614C172.656 15.5509 171.009 14.068 169.08 13.0431C167.151 12.0182 165 11.4829 162.816 11.4843C161.064 11.4843 159.33 11.8289 157.712 12.4984C156.095 13.168 154.624 14.1494 153.386 15.3865C152.148 16.6237 151.166 18.0924 150.496 19.7088C149.826 21.3252 149.481 23.0576 149.481 24.8071C149.482 27.633 150.386 30.3849 152.063 32.6608C150.575 30.4626 149.782 27.8687 149.786 25.2151V25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M175.956 20.1721C178.629 29.1864 171.844 38.1245 162.543 38.1245C159.647 38.1227 156.823 37.2228 154.461 35.549C152.098 33.8753 150.314 31.5102 149.354 28.7802C150.212 31.6685 151.981 34.2026 154.398 36.0051C156.814 37.8075 159.749 38.7818 162.765 38.7827C172.354 38.7864 179.177 29.3188 175.956 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M145.421 22.5404C147.763 24.2554 150.591 25.1797 153.495 25.1789C155.298 25.1791 157.084 24.8244 158.751 24.135C160.417 23.4456 161.931 22.435 163.206 21.1609C164.482 19.8868 165.493 18.3743 166.183 16.7096C166.873 15.0449 167.228 13.2607 167.228 11.4589C167.226 10.1909 167.047 8.92933 166.696 7.71063C165.423 7.41855 164.122 7.26954 162.815 7.26636C158.554 7.26795 154.439 8.81883 151.238 11.6295C148.037 14.4401 145.969 18.3184 145.421 22.5404Z"
                  fill="#26170D"/>
            </g>
            <path
                d="M157.174 24.8071C157.174 24.0668 157.319 23.3337 157.603 22.6497C157.886 21.9657 158.302 21.3442 158.826 20.8207C159.349 20.2971 159.971 19.8818 160.656 19.5985C161.341 19.3151 162.074 19.1693 162.815 19.1693C163.556 19.1693 164.29 19.3151 164.975 19.5985C165.659 19.8818 166.281 20.2971 166.805 20.8207C167.329 21.3442 167.744 21.9657 168.028 22.6497C168.311 23.3337 168.457 24.0668 168.457 24.8071C168.457 26.3018 167.862 27.7354 166.804 28.7923C165.746 29.8493 164.312 30.443 162.815 30.443C161.319 30.443 159.884 29.8493 158.826 28.7923C157.768 27.7354 157.174 26.3018 157.174 24.8071V24.8071Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M167.12 21.1677C167.546 21.9748 167.768 22.8737 167.768 23.7862C167.768 25.281 167.173 26.7145 166.115 27.7715C165.057 28.8284 163.622 29.4222 162.126 29.4222C161.307 29.4228 160.497 29.2447 159.753 28.9001C159.01 28.5556 158.351 28.053 157.822 27.4275C158.3 28.3377 159.018 29.1 159.898 29.6319C160.778 30.1638 161.787 30.4449 162.816 30.4449C164.312 30.4449 165.747 29.8512 166.805 28.7942C167.863 27.7373 168.457 26.3037 168.457 24.809C168.459 23.4748 167.985 22.1837 167.12 21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M157.874 25.8933C157.875 24.3984 158.47 22.9649 159.528 21.908C160.586 20.8511 162.021 20.2573 163.518 20.2573C164.348 20.2563 165.168 20.4389 165.919 20.7919C166.671 21.1449 167.335 21.6597 167.863 22.2992C167.397 21.3598 166.677 20.569 165.785 20.0161C164.894 19.4631 163.865 19.1699 162.815 19.1693C162.074 19.1693 161.341 19.3151 160.656 19.5985C159.971 19.8818 159.349 20.2971 158.826 20.8207C158.302 21.3442 157.886 21.9657 157.603 22.6497C157.319 23.3337 157.174 24.0668 157.174 24.8071C157.173 26.1195 157.631 27.391 158.47 28.4012C158.079 27.6229 157.875 26.7641 157.874 25.8933V25.8933Z"
                  fill="#241133"/>
            </g>
            <path d="M30.9828 45.3726L28.7011 45.8586L25.9639 38.451L30.4546 37.4935L30.9828 45.3726Z" fill="#824E3E"/>
            <path d="M37.3685 42.3533L35.3773 43.5701L30.333 37.4898L34.2501 35.0962L37.3685 42.3533Z" fill="#824E3E"/>
            <path d="M42.6174 36.9404L41.2288 38.8136L34.2695 35.0672L37.0032 31.3806L42.6174 36.9404Z" fill="#824E3E"/>
            <path d="M45.5569 30.4921L44.8236 32.7044L37.042 31.3172L38.4869 26.9633L45.5569 30.4921Z" fill="#824E3E"/>
            <path d="M46.3067 23.5323L46.2976 25.8625L38.4707 26.9632L38.487 22.3754L46.3067 23.5323Z" fill="#824E3E"/>
            <path d="M44.6561 15.6841L45.4729 17.8674L38.5408 21.661L36.9326 17.3651L44.6561 15.6841Z" fill="#824E3E"/>
            <path d="M40.2508 9.33179L41.8318 11.0454L36.8492 17.1746L33.7344 13.8036L40.2508 9.33179Z" fill="#824E3E"/>
            <path d="M34.3609 4.76758L36.4048 5.89006L33.6258 13.2832L29.6016 11.0727L34.3609 4.76758Z" fill="#824E3E"/>
            <path d="M27.0327 2.77283L29.3434 3.09561L29.3779 10.991L24.8291 10.3564L27.0327 2.77283Z" fill="#824E3E"/>
            <path d="M20.208 3.15549L22.5169 2.81458L24.7786 10.38L20.2352 11.0527L20.208 3.15549Z" fill="#824E3E"/>
            <path d="M13.4053 5.861L15.4491 4.73853L20.2085 11.0418L16.1843 13.2541L13.4053 5.861Z" fill="#824E3E"/>
            <path d="M8.10449 10.4905L9.68731 8.77869L16.2037 13.2487L13.0889 16.6215L8.10449 10.4905Z" fill="#824E3E"/>
            <path d="M4.51562 16.6016L5.46858 14.4745L13.0723 16.636L11.1954 20.8249L4.51562 16.6016Z" fill="#824E3E"/>
            <path d="M2.98926 23.6049L3.18711 21.2837L11.0794 20.825L10.6873 25.3965L2.98926 23.6049Z" fill="#824E3E"/>
            <path d="M3.59423 30.1602L3.18945 27.8663L10.6969 25.3965L11.4956 29.9136L3.59423 30.1602Z" fill="#824E3E"/>
            <path d="M6.11168 36.4799L5.03711 34.4108L11.4573 29.8066L13.5756 33.8777L6.11168 36.4799Z" fill="#824E3E"/>
            <path d="M11.0619 41.9744L9.30664 40.4385L13.6086 33.8142L17.0646 36.8353L11.0619 41.9744Z" fill="#824E3E"/>
            <path d="M17.7106 45.3943L15.5088 44.6218L17.0371 36.8733L21.3699 38.3947L17.7106 45.3943Z" fill="#824E3E"/>
            <path d="M24.8334 46.2557H22.501L21.3701 38.4401H25.9643L24.8334 46.2557Z" fill="#824E3E"/>
            <path
                d="M37.2461 37.2096C44.1029 30.3595 44.1029 19.2533 37.2461 12.4032C30.3893 5.55311 19.2722 5.55311 12.4154 12.4032C5.55855 19.2533 5.55855 30.3595 12.4154 37.2096C19.2722 44.0597 30.3893 44.0597 37.2461 37.2096Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M30.8774 8.3562C33.1471 9.75186 35.0212 11.7048 36.3214 14.029C37.6216 16.3532 38.3045 18.9712 38.305 21.6337C38.305 25.7765 36.6577 29.7496 33.7254 32.679C30.7932 35.6084 26.8162 37.2541 22.6693 37.2541C19.003 37.2527 15.4538 35.9636 12.6427 33.6124C9.83151 31.2611 7.93731 27.9973 7.29133 24.3918C7.29133 24.5315 7.26953 24.6675 7.26953 24.8071C7.27001 29.4594 9.12029 33.9209 12.4133 37.2104C15.7064 40.4999 20.1725 42.3479 24.8293 42.3479C27.1351 42.3479 29.4183 41.8942 31.5485 41.0127C33.6787 40.1312 35.6143 38.8391 37.2447 37.2103C38.8751 35.5815 40.1684 33.6478 41.0508 31.5197C41.9332 29.3915 42.3873 27.1106 42.3873 24.8071C42.3853 21.2177 41.2804 17.7154 39.222 14.7734C37.1637 11.8313 34.251 9.59142 30.8774 8.3562V8.3562Z"
                  fill="#FAF8F2"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M7.52364 25.3293C7.52364 23.0259 7.97781 20.7449 8.86018 18.6168C9.74256 16.4886 11.0359 14.555 12.6663 12.9261C14.2967 11.2973 16.2323 10.0053 18.3625 9.12379C20.4927 8.24229 22.7759 7.78858 25.0817 7.78858C28.433 7.79003 31.7137 8.75019 34.536 10.5555C37.3583 12.3609 39.6041 14.9359 41.0078 17.9761C39.6637 14.7994 37.4122 12.089 34.5347 10.1836C31.6573 8.27812 28.2815 7.26217 24.8293 7.2627C22.5234 7.26246 20.2401 7.71598 18.1096 8.59738C15.9792 9.47878 14.0434 10.7708 12.4128 12.3996C10.7822 14.0285 9.4887 15.9622 8.60621 18.0905C7.72373 20.2187 7.26953 22.4998 7.26953 24.8035C7.27141 27.3442 7.82896 29.8538 8.90317 32.1567C7.99028 29.9962 7.52114 27.6745 7.52364 25.3293V25.3293Z"
                  fill="#331911"/>
            </g>
            <path
                d="M34.2637 27.0902C35.5253 21.8805 32.3206 16.6355 27.1057 15.3751C21.8909 14.1147 16.6407 17.3163 15.3791 22.526C14.1175 27.7358 17.3222 32.9808 22.537 34.2412C27.7519 35.5016 33.0021 32.3 34.2637 27.0902Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M15.4192 25.2151C15.4197 22.6415 16.4434 20.1734 18.2652 18.3537C20.087 16.534 22.5577 15.5118 25.1339 15.5118C26.6385 15.5115 28.1224 15.8614 29.4681 16.5337C30.8138 17.206 31.9842 18.1823 32.8865 19.3851C31.9979 18.0659 30.7982 16.9852 29.3931 16.2383C27.988 15.4914 26.4206 15.1011 24.829 15.1019C22.2525 15.1019 19.7815 16.1244 17.9597 17.9445C16.1378 19.7646 15.1143 22.2331 15.1143 24.8071C15.1138 26.9129 15.8029 28.961 17.0765 30.6389C15.9937 29.0372 15.4164 27.1479 15.4192 25.2151V25.2151Z"
                  fill="#331911"/>
            </g>
            <path
                d="M24.8291 11.4843C21.2922 11.4843 17.9001 12.8879 15.3992 15.3864C12.8982 17.8849 11.4932 21.2737 11.4932 24.8071C11.4932 26.5566 11.8381 28.289 12.5083 29.9053C13.1786 31.5216 14.1609 32.9902 15.3993 34.2271C16.6377 35.4641 18.1078 36.4453 19.7258 37.1147C21.3438 37.784 23.0779 38.1284 24.8291 38.1281C28.3655 38.1281 31.7571 36.7247 34.2577 34.2265C36.7584 31.7283 38.1632 28.3401 38.1632 24.8071C38.1634 23.0576 37.8187 21.3252 37.1487 19.7088C36.4787 18.0924 35.4966 16.6237 34.2584 15.3865C33.0202 14.1494 31.5502 13.168 29.9323 12.4984C28.3144 11.8289 26.5803 11.4843 24.8291 11.4843V11.4843ZM24.8291 34.5141C22.2526 34.5141 19.7816 33.4916 17.9598 31.6715C16.1379 29.8514 15.1144 27.3829 15.1144 24.8089C15.1144 22.2349 16.1379 19.7664 17.9598 17.9463C19.7816 16.1262 22.2526 15.1037 24.8291 15.1037C27.4053 15.1042 29.8758 16.1269 31.6973 17.947C33.5187 19.767 34.542 22.2353 34.542 24.8089C34.5415 27.3823 33.518 29.8501 31.6966 31.6697C29.8752 33.4893 27.405 34.5118 24.8291 34.5123V34.5141Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M25.1336 34.9203C27.7101 34.9203 30.1811 33.8978 32.003 32.0777C33.8248 30.2577 34.8483 27.7891 34.8483 25.2151C34.8481 23.11 34.1591 21.0627 32.8862 19.3851C33.9686 20.9863 34.5453 22.875 34.5416 24.8071C34.5416 27.3808 33.5183 29.8491 31.6968 31.6691C29.8754 33.4891 27.4049 34.5118 24.8287 34.5123C23.3242 34.5122 21.8404 34.1621 20.4948 33.4898C19.1492 32.8175 17.9788 31.8414 17.0762 30.6389C17.965 31.9577 19.1648 33.038 20.5699 33.7846C21.975 34.5311 23.5422 34.9212 25.1336 34.9203V34.9203Z"
                  fill="#E8E6E1"/>
              <path
                  d="M11.7999 25.2151C11.7999 21.6822 13.2048 18.2939 15.7054 15.7957C18.2061 13.2975 21.5976 11.8941 25.134 11.8941C27.2309 11.8939 29.2982 12.3891 31.1669 13.3393C33.0357 14.2894 34.6531 15.6677 35.887 17.3614C34.6674 15.5512 33.0209 14.0684 31.0926 13.0435C29.1643 12.0186 27.0133 11.4832 24.8291 11.4843C21.2922 11.4843 17.9001 12.8879 15.3992 15.3864C12.8982 17.8849 11.4932 21.2737 11.4932 24.8071C11.4932 27.633 12.3976 30.3849 14.0743 32.6608C12.5878 30.462 11.7956 27.8684 11.7999 25.2151V25.2151Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M37.9673 20.1721C40.641 29.1864 33.856 38.1245 24.5569 38.1245C21.6605 38.1228 18.836 37.223 16.4732 35.5492C14.1105 33.8755 12.3261 31.5103 11.3662 28.7802C12.2247 31.6685 13.9941 34.2025 16.4108 36.0049C18.8276 37.8073 21.7625 38.7816 24.7784 38.7827C34.3642 38.7864 41.1892 29.3188 37.9673 20.1721Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M7.43262 22.5404C9.77533 24.2551 12.6041 25.1793 15.5083 25.1789C19.1506 25.1789 22.6437 23.7334 25.2193 21.1604C27.7948 18.5874 29.2417 15.0976 29.2417 11.4589C29.2385 10.1909 29.0595 8.92952 28.7099 7.71063C27.4369 7.41866 26.1352 7.26965 24.8291 7.26636C20.5673 7.2679 16.4518 8.81871 13.2506 11.6293C10.0494 14.4399 7.98143 18.3183 7.43262 22.5404Z"
                  fill="#26170D"/>
            </g>
            <path
                d="M19.1865 24.8071C19.1865 23.3119 19.7811 21.8779 20.8394 20.8206C21.8978 19.7633 23.3332 19.1693 24.8299 19.1693C26.3262 19.1698 27.7612 19.764 28.8191 20.8212C29.877 21.8784 30.4714 23.3122 30.4714 24.8071C30.4709 26.3017 29.8763 27.7349 28.8185 28.7918C27.7606 29.8486 26.3259 30.4426 24.8299 30.443C23.3335 30.443 21.8983 29.8493 20.8401 28.7924C19.7818 27.7355 19.187 26.302 19.1865 24.8071V24.8071Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M29.133 21.1677C29.5592 21.9748 29.7817 22.8737 29.781 23.7862C29.7806 25.2808 29.186 26.7141 28.1282 27.7709C27.0703 28.8278 25.6356 29.4217 24.1395 29.4222C23.3197 29.4231 22.5097 29.2451 21.766 28.9005C21.0222 28.556 20.3629 28.0532 19.834 27.4275C20.3117 28.338 21.0297 29.1005 21.9103 29.6324C22.7908 30.1643 23.8003 30.4453 24.8293 30.4449C26.3254 30.4444 27.76 29.8505 28.8179 28.7937C29.8758 27.7368 30.4703 26.3036 30.4708 24.809C30.4729 23.4748 29.9985 22.1837 29.133 21.1677V21.1677Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M19.889 25.8933C19.8895 24.3987 20.484 22.9655 21.5419 21.9086C22.5998 20.8518 24.0345 20.2578 25.5305 20.2573C26.3608 20.2563 27.1809 20.4389 27.9322 20.7919C28.6835 21.1449 29.3473 21.6597 29.876 22.2992C29.4108 21.3592 28.6917 20.568 27.8 20.0149C26.9082 19.4618 25.8795 19.1689 24.8299 19.1693C24.0888 19.1693 23.3549 19.3151 22.6702 19.5985C21.9856 19.8818 21.3635 20.2971 20.8394 20.8206C20.3154 21.3441 19.8997 21.9656 19.6161 22.6496C19.3325 23.3336 19.1865 24.0667 19.1865 24.8071C19.1853 26.1195 19.6438 27.391 20.4826 28.4012C20.093 27.6224 19.8898 26.7639 19.889 25.8933V25.8933Z"
                  fill="#241133"/>
            </g>
            <path d="M58.517 36.6049H56.1827L55.0537 28.7893H59.646L58.517 36.6049Z" fill="#824E3E"/>
            <path d="M50.1981 35.2249L48.2686 33.9138L51.7319 26.8163L55.531 29.3949L50.1981 35.2249Z" fill="#824E3E"/>
            <path d="M44.9846 30.0223L43.9463 27.9351L50.4518 23.4489L52.4938 27.558L44.9846 30.0223Z" fill="#824E3E"/>
            <path d="M42.9795 22.0037L43.0176 19.6735L50.8591 18.6725L50.7847 23.2603L42.9795 22.0037Z" fill="#824E3E"/>
            <path d="M44.3008 13.8688L45.5224 11.8832L52.7794 15.0149L50.3743 18.9227L44.3008 13.8688Z" fill="#824E3E"/>
            <path d="M49.2217 8.38162L51.2655 7.25732L56.0249 13.5624L52.0007 15.7729L49.2217 8.38162Z" fill="#824E3E"/>
            <path d="M57.4026 6.20374L59.735 6.28352L60.5936 14.1336L56.0049 13.9759L57.4026 6.20374Z" fill="#824E3E"/>
            <path d="M65.423 7.65808L67.3907 8.91294L64.1307 16.1066L60.2607 13.6368L65.423 7.65808Z" fill="#824E3E"/>
            <path d="M70.8256 12.6757L71.9147 14.7375L65.5218 19.3833L63.3799 15.325L70.8256 12.6757Z" fill="#824E3E"/>
            <path d="M72.8966 20.9827L72.7677 23.3093L64.8936 24.002L65.1495 19.4214L72.8966 20.9827Z" fill="#824E3E"/>
            <path d="M71.2885 28.7766L70.0198 30.7332L62.8408 27.4274L65.3403 23.5776L71.2885 28.7766Z" fill="#824E3E"/>
            <path d="M66.3562 34.2168L64.3414 35.3936L59.415 29.2191L63.3793 26.9016L66.3562 34.2168Z" fill="#824E3E"/>
            <path d="M58.517 36.6049H56.1827L55.0537 28.7893H59.646L58.517 36.6049Z" fill="#824E3E"/>
            <path
                d="M62.1854 31.3127C67.7888 28.9733 70.433 22.5389 68.0913 16.9409C65.7497 11.343 59.3089 8.70144 53.7055 11.0408C48.1021 13.3802 45.4579 19.8146 47.7995 25.4125C50.1412 31.0105 56.582 33.6521 62.1854 31.3127Z"
                fill="#D98268"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M47.1212 21.5993C47.1212 18.6858 48.2797 15.8916 50.3419 13.8314C52.404 11.7713 55.201 10.6139 58.1174 10.6139C60.2144 10.615 62.2674 11.2153 64.0341 12.3441C65.8008 13.4728 67.2074 15.0829 68.088 16.9843C67.2608 14.9672 65.8517 13.2415 64.0401 12.0267C62.2284 10.8119 60.096 10.163 57.9141 10.1624C54.9975 10.1624 52.2004 11.3197 50.1379 13.3798C48.0754 15.4399 46.9165 18.2341 46.916 21.1478C46.9179 22.7425 47.2685 24.3176 47.9434 25.7628C47.4005 24.4417 47.1211 23.0274 47.1212 21.5993Z"
                  fill="#331911"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M64.8264 12.6104C65.9905 13.6377 66.9227 14.9006 67.5611 16.3153C68.1995 17.7299 68.5295 19.2641 68.5293 20.8159C68.5293 23.7294 67.3707 26.5236 65.3085 28.5837C63.2464 30.6439 60.4494 31.8013 57.533 31.8013C55.0152 31.8039 52.5734 30.9393 50.6191 29.3532C52.626 31.1444 55.2231 32.1341 57.9142 32.1331C60.8306 32.1331 63.6275 30.9757 65.6897 28.9156C67.7519 26.8554 68.9105 24.0612 68.9105 21.1477C68.9116 19.5102 68.5452 17.8933 67.8385 16.4159C67.1317 14.9384 66.1024 13.6381 64.8264 12.6104V12.6104Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M52.0388 21.5213C52.0386 20.7231 52.1958 19.9327 52.5013 19.1953C52.8069 18.4578 53.2549 17.7877 53.8197 17.2233C54.3846 16.6588 55.0552 16.211 55.7933 15.9056C56.5314 15.6001 57.3225 15.4428 58.1214 15.4428C59.1673 15.4429 60.1956 15.7128 61.1064 16.2265C62.0172 16.7403 62.7797 17.4804 63.32 18.3751C62.8099 17.3816 62.0355 16.5478 61.0818 15.9655C60.1281 15.3833 59.0322 15.075 57.9145 15.0747C56.3008 15.0747 54.7532 15.7151 53.6122 16.8551C52.4711 17.995 51.8301 19.541 51.8301 21.1531C51.8301 22.2633 52.1366 23.352 52.7159 24.2993C52.2712 23.4407 52.039 22.488 52.0388 21.5213V21.5213Z"
                  fill="#331911"/>
            </g>
            <path
                d="M57.9142 12.8044C55.6997 12.8044 53.5759 13.6833 52.01 15.2476C50.4442 16.8119 49.5645 18.9337 49.5645 21.146C49.5645 23.3583 50.4442 25.48 52.01 27.0443C53.5759 28.6086 55.6997 29.4875 57.9142 29.4875C60.1286 29.4875 62.2524 28.6086 63.8183 27.0443C65.3842 25.48 66.2639 23.3583 66.2639 21.146C66.2639 18.9337 65.3842 16.8119 63.8183 15.2476C62.2524 13.6833 60.1286 12.8044 57.9142 12.8044V12.8044ZM57.9142 27.2262C56.3005 27.2262 54.7529 26.5858 53.6119 25.4459C52.4708 24.3059 51.8298 22.7599 51.8298 21.1478C51.8298 19.5357 52.4708 17.9896 53.6119 16.8497C54.7529 15.7097 56.3005 15.0693 57.9142 15.0693C58.7131 15.0693 59.5042 15.2266 60.2423 15.5321C60.9804 15.8376 61.651 16.2853 62.2158 16.8498C62.7807 17.4142 63.2287 18.0843 63.5343 18.8218C63.8398 19.5592 63.997 20.3496 63.9967 21.1478C63.997 21.9459 63.8398 22.7363 63.5343 23.4738C63.2287 24.2112 62.7807 24.8813 62.2158 25.4458C61.651 26.0102 60.9804 26.458 60.2423 26.7635C59.5042 27.0689 58.7131 27.2262 57.9142 27.2262Z"
                fill="#824E3E"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M49.7714 21.5213C49.7714 19.309 50.6511 17.1873 52.2169 15.623C53.7828 14.0586 55.9066 13.1798 58.1211 13.1798C59.5752 13.181 61.0039 13.5616 62.2656 14.2839C63.5273 15.0062 64.5783 16.0451 65.3145 17.2979C64.6092 15.9459 63.5464 14.8129 62.2417 14.0219C60.937 13.231 59.4403 12.8124 57.9142 12.8116C55.6997 12.8116 53.5759 13.6905 52.01 15.2548C50.4442 16.8192 49.5645 18.9409 49.5645 21.1532C49.5646 22.6398 49.9646 24.099 50.7225 25.3783C50.0975 24.1886 49.7711 22.8649 49.7714 21.5213V21.5213Z"
                  fill="#E8E6E1"/>
              <path
                  d="M58.1212 27.5943C59.7339 27.5943 61.2807 26.9547 62.4216 25.8159C63.5625 24.6772 64.2041 23.1325 64.2056 21.5213C64.2053 20.4114 63.8995 19.323 63.3216 18.3751C63.765 19.2342 63.9965 20.1867 63.9968 21.1532C63.9971 21.9513 63.8399 22.7417 63.5344 23.4792C63.2288 24.2166 62.7808 24.8867 62.216 25.4512C61.6511 26.0157 60.9805 26.4634 60.2424 26.7689C59.5043 27.0744 58.7132 27.2316 57.9143 27.2316C56.8679 27.2316 55.8393 26.9614 54.9281 26.4473C54.017 25.9333 53.2543 25.1928 52.7139 24.2976C53.2249 25.2904 54 26.1234 54.9539 26.705C55.9078 27.2865 57.0037 27.5942 58.1212 27.5943V27.5943Z"
                  fill="#E8E6E1"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M46.916 21.1478C46.916 21.3654 46.936 21.5794 46.9487 21.7951C48.1631 22.3984 49.5006 22.7131 50.8568 22.7145C53.1998 22.7145 55.4468 21.7847 57.1036 20.1295C58.7603 18.4744 59.6911 16.2296 59.6911 13.8888C59.6901 12.6188 59.4133 11.3641 58.8797 10.2113C58.5603 10.1823 58.239 10.1624 57.9141 10.1624C54.9975 10.1624 52.2004 11.3197 50.1379 13.3798C48.0754 15.4399 46.9165 18.2341 46.916 21.1478Z"
                  fill="#26170D"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M62.7355 11.2866C64.3999 13.1906 65.3164 15.6332 65.3148 18.1611C65.3148 20.9452 64.2078 23.6154 62.2371 25.584C60.2665 27.5527 57.5938 28.6587 54.8069 28.6587C52.4093 28.6614 50.0836 27.8407 48.2197 26.334C49.1586 28.0873 50.5562 29.5532 52.2636 30.5751C53.971 31.5971 55.9241 32.1368 57.9144 32.1368C60.8308 32.1368 63.6277 30.9794 65.6899 28.9192C67.7521 26.8591 68.9107 24.0649 68.9107 21.1514C68.9105 19.0941 68.3315 17.0783 67.2396 15.334C66.1477 13.5897 64.587 12.1873 62.7355 11.2866V11.2866Z"
                  fill="#FAF8F2"/>
            </g>
            <path
                d="M54.3799 21.1477C54.3799 20.2113 54.7522 19.3133 55.415 18.6512C56.0778 17.989 56.9767 17.6171 57.914 17.6171C58.851 17.6175 59.7494 17.9897 60.4118 18.6518C61.0742 19.3139 61.4463 20.2116 61.4463 21.1477C61.4463 22.0838 61.0742 22.9815 60.4118 23.6436C59.7494 24.3057 58.851 24.6779 57.914 24.6784C56.9767 24.6784 56.0778 24.3064 55.415 23.6443C54.7522 22.9821 54.3799 22.0841 54.3799 21.1477V21.1477Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M60.7152 19.0188C60.9247 19.4739 61.0343 19.9686 61.0365 20.4695C61.0365 21.4059 60.6641 22.3039 60.0014 22.9661C59.3386 23.6282 58.4397 24.0002 57.5024 24.0002C56.9585 24.0012 56.4218 23.8761 55.9345 23.6348C55.4473 23.3934 55.0227 23.0424 54.6943 22.6093C54.9731 23.2275 55.4245 23.7523 55.9944 24.1206C56.5643 24.4889 57.2284 24.685 57.9071 24.6856C58.8441 24.6851 59.7426 24.3129 60.405 23.6508C61.0674 22.9888 61.4394 22.091 61.4394 21.155C61.4404 20.3826 61.1858 19.6316 60.7152 19.0188V19.0188Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M54.9262 21.9003C54.9262 20.9639 55.2986 20.0659 55.9614 19.4038C56.6241 18.7417 57.523 18.3697 58.4603 18.3697C58.9316 18.37 59.398 18.4646 59.832 18.6481C60.266 18.8315 60.6587 19.1 60.987 19.4378C60.6833 18.8891 60.2382 18.4317 59.6978 18.1128C59.1575 17.794 58.5416 17.6253 57.914 17.6244C56.9767 17.6244 56.0778 17.9964 55.415 18.6585C54.7522 19.3206 54.3799 20.2186 54.3799 21.155C54.3793 22.0761 54.7411 22.9606 55.3873 23.6176C55.0878 23.0946 54.9289 22.5029 54.9262 21.9003V21.9003Z"
                  fill="#241133"/>
            </g>
            <path
                d="M161.353 48.0873C148.313 48.0873 135.578 41.7241 124.347 36.1081C114.814 31.3426 105.813 26.8599 98.5559 27.2226C91.5821 27.5852 85.387 30.7713 78.2135 34.4561C66.3878 40.5273 51.6687 48.0854 25.4289 48.0854C19.0595 48.0787 12.9529 45.548 8.44906 41.0486C3.94522 36.5492 1.412 30.4486 1.40527 24.0854C1.412 17.7223 3.94522 11.6217 8.44906 7.1223C12.9529 2.62288 19.0595 0.0921695 25.4289 0.0854492H161.353C174.604 0.0854492 185.377 10.8514 185.377 24.0854C185.377 37.3194 174.6 48.0873 161.353 48.0873ZM99.4309 23.5396C107.398 23.5396 116.439 28.0585 125.988 32.8332C136.859 38.2733 149.179 44.4242 161.353 44.4242C166.75 44.418 171.925 42.2734 175.741 38.4609C179.558 34.6484 181.705 29.4792 181.712 24.0873C181.705 18.6953 179.558 13.5261 175.741 9.71362C171.925 5.9011 166.75 3.75651 161.353 3.75027H25.4289C20.0311 3.75603 14.8562 5.90071 11.0394 9.71373C7.22266 13.5267 5.07587 18.6967 5.0701 24.0891C5.07635 29.4812 7.22333 34.6506 11.04 38.4632C14.8567 42.2759 20.0315 44.4203 25.4289 44.4261C50.7811 44.4261 65.0628 37.0928 76.5382 31.2029C84.0856 27.3259 90.6056 23.9785 98.3618 23.5705C98.7157 23.5487 99.0733 23.5396 99.4309 23.5396Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M183.679 15.2416C184.569 17.7844 185.024 20.4593 185.022 23.1533C185.022 36.391 174.245 47.1533 160.998 47.1533C147.957 47.1533 135.223 40.7902 123.993 35.1742C114.46 30.4086 105.458 25.926 98.2014 26.2887C91.2276 26.6513 85.0325 29.8392 77.8572 33.5222C66.0315 39.5952 51.3142 47.1515 25.0725 47.1515C20.2606 47.1455 15.5611 45.6981 11.5811 42.9963C7.60112 40.2945 4.52359 36.4625 2.74609 31.9953C4.3932 36.6933 7.45957 40.7645 11.5219 43.647C15.5841 46.5294 20.442 48.0809 25.4246 48.0872C51.6645 48.0872 66.3836 40.5291 78.2093 34.4579C85.3828 30.7731 91.5779 27.5925 98.5518 27.2243C105.812 26.8617 114.81 31.3425 124.343 36.1099C135.577 41.7313 148.303 48.089 161.349 48.089C174.599 48.089 185.372 37.323 185.372 24.089C185.375 21.0592 184.8 18.0566 183.679 15.2416Z"
                  fill="#DBD3E6"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M1.94983 25.021C1.94983 11.7834 12.7264 1.02284 25.9734 1.02284H161.899C166.007 1.02509 170.046 2.07927 173.63 4.08468C177.214 6.0901 180.223 8.97981 182.371 12.4779C180.293 8.72646 177.249 5.59892 173.553 3.42003C169.857 1.24114 165.645 0.0903638 161.353 0.0871582H25.4289C19.0595 0.0938785 12.9529 2.62459 8.44907 7.12401C3.94523 11.6234 1.41201 17.724 1.40529 24.0872C1.40047 28.5166 2.63051 32.8599 4.95756 36.6302C2.9814 33.0797 1.94608 29.0836 1.94983 25.021Z"
                  fill="#241133"/>
            </g>
            <path
                d="M98.2256 23.4996C98.2261 23.1771 98.3545 22.8678 98.5829 22.6398C98.8112 22.4117 99.1207 22.2833 99.4435 22.2828C99.7667 22.2828 100.077 22.411 100.305 22.6391C100.534 22.8673 100.663 23.1768 100.663 23.4996C100.663 23.6596 100.632 23.8179 100.57 23.9657C100.509 24.1134 100.419 24.2476 100.306 24.3606C100.193 24.4737 100.058 24.5632 99.9102 24.6243C99.7622 24.6853 99.6036 24.7166 99.4435 24.7164C99.1205 24.7164 98.8107 24.5882 98.5823 24.36C98.3539 24.1318 98.2256 23.8223 98.2256 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M101.151 23.6974C101.151 23.3747 101.28 23.0652 101.508 22.837C101.737 22.6088 102.046 22.4806 102.369 22.4806C102.529 22.4804 102.688 22.5116 102.836 22.5727C102.984 22.6337 103.118 22.7233 103.232 22.8363C103.345 22.9493 103.435 23.0836 103.496 23.2313C103.558 23.3791 103.589 23.5374 103.589 23.6974C103.589 24.0202 103.46 24.3297 103.231 24.5578C103.003 24.786 102.693 24.9141 102.369 24.9141C102.046 24.9136 101.737 24.7853 101.509 24.5572C101.28 24.3291 101.152 24.0199 101.151 23.6974V23.6974Z"
                fill="#4E415C"/>
            <path
                d="M104.166 24.2286C104.166 24.0688 104.197 23.9104 104.258 23.7627C104.319 23.615 104.409 23.4807 104.522 23.3677C104.635 23.2547 104.77 23.165 104.918 23.104C105.065 23.0429 105.224 23.0116 105.384 23.0118C105.544 23.0116 105.702 23.0429 105.85 23.104C105.998 23.165 106.133 23.2547 106.246 23.3677C106.359 23.4807 106.449 23.615 106.51 23.7627C106.571 23.9104 106.602 24.0688 106.602 24.2286C106.602 24.3885 106.571 24.5468 106.51 24.6945C106.449 24.8423 106.359 24.9765 106.246 25.0895C106.133 25.2026 105.998 25.2922 105.85 25.3533C105.702 25.4143 105.544 25.4456 105.384 25.4454C105.224 25.4456 105.065 25.4143 104.918 25.3533C104.77 25.2922 104.635 25.2026 104.522 25.0895C104.409 24.9765 104.319 24.8423 104.258 24.6945C104.197 24.5468 104.166 24.3885 104.166 24.2286V24.2286Z"
                fill="#4E415C"/>
            <path
                d="M107.261 25.0719C107.261 24.9119 107.292 24.7536 107.354 24.6058C107.415 24.4581 107.505 24.3239 107.618 24.2109C107.731 24.0978 107.866 24.0083 108.014 23.9472C108.162 23.8862 108.32 23.8549 108.481 23.8551C108.804 23.8551 109.113 23.9833 109.342 24.2115C109.57 24.4397 109.698 24.7492 109.698 25.0719C109.698 25.3946 109.57 25.7041 109.342 25.9323C109.113 26.1605 108.804 26.2887 108.481 26.2887C108.32 26.2889 108.162 26.2576 108.014 26.1966C107.866 26.1355 107.731 26.0459 107.618 25.9329C107.505 25.8199 107.415 25.6857 107.354 25.5379C107.292 25.3902 107.261 25.2318 107.261 25.0719Z"
                fill="#4E415C"/>
            <path
                d="M111.654 27.3912C112.326 27.3912 112.871 26.8464 112.871 26.1744C112.871 25.5024 112.326 24.9576 111.654 24.9576C110.981 24.9576 110.436 25.5024 110.436 26.1744C110.436 26.8464 110.981 27.3912 111.654 27.3912Z"
                fill="#4E415C"/>
            <path
                d="M113.687 27.4928C113.687 27.1702 113.815 26.861 114.044 26.6329C114.272 26.4048 114.582 26.2765 114.904 26.276C115.228 26.276 115.538 26.4041 115.766 26.6323C115.995 26.8604 116.124 27.1699 116.124 27.4928C116.124 27.6527 116.093 27.8111 116.031 27.9588C115.97 28.1066 115.88 28.2408 115.767 28.3538C115.654 28.4668 115.519 28.5564 115.371 28.6174C115.223 28.6785 115.065 28.7098 114.904 28.7096C114.581 28.7096 114.272 28.5814 114.043 28.3532C113.815 28.125 113.687 27.8155 113.687 27.4928V27.4928Z"
                fill="#4E415C"/>
            <path
                d="M116.986 28.9815C116.987 28.659 117.115 28.3498 117.344 28.1217C117.572 27.8936 117.881 27.7653 118.204 27.7648C118.527 27.7648 118.837 27.8929 119.066 28.1211C119.294 28.3493 119.422 28.6588 119.422 28.9815C119.423 29.1414 119.391 29.2997 119.33 29.4475C119.269 29.5952 119.179 29.7294 119.066 29.8424C118.953 29.9555 118.819 30.0451 118.671 30.1062C118.523 30.1672 118.364 30.1985 118.204 30.1983C117.881 30.1983 117.571 30.0701 117.343 29.8419C117.115 29.6137 116.986 29.3042 116.986 28.9815V28.9815Z"
                fill="#4E415C"/>
            <path
                d="M120.354 30.5973C120.354 30.2741 120.482 29.9641 120.711 29.7356C120.94 29.5071 121.25 29.3787 121.573 29.3787C121.896 29.3791 122.206 29.5077 122.435 29.7362C122.663 29.9647 122.791 30.2744 122.791 30.5973C122.791 30.9198 122.662 31.229 122.434 31.4571C122.206 31.6852 121.896 31.8136 121.573 31.814C121.25 31.814 120.94 31.6859 120.711 31.4578C120.483 31.2296 120.354 30.9201 120.354 30.5973V30.5973Z"
                fill="#4E415C"/>
            <path
                d="M123.777 32.2927C123.777 32.1327 123.808 31.9743 123.87 31.8265C123.931 31.6786 124.02 31.5443 124.133 31.4311C124.247 31.3179 124.381 31.2282 124.529 31.1669C124.677 31.1056 124.835 31.0741 124.995 31.0741C125.318 31.0746 125.628 31.2032 125.857 31.4316C126.085 31.6601 126.213 31.9698 126.213 32.2927C126.213 32.6152 126.084 32.9244 125.856 33.1525C125.628 33.3806 125.318 33.509 124.995 33.5094C124.672 33.5094 124.362 33.3812 124.134 33.1531C123.906 32.9249 123.777 32.6154 123.777 32.2927V32.2927Z"
                fill="#4E415C"/>
            <path
                d="M126.906 33.8522C126.906 33.5294 127.034 33.2197 127.263 32.9912C127.491 32.7628 127.801 32.6341 128.124 32.6337C128.284 32.6337 128.443 32.6652 128.591 32.7265C128.739 32.7877 128.873 32.8775 128.986 32.9907C129.099 33.1039 129.189 33.2382 129.25 33.386C129.311 33.5339 129.342 33.6923 129.342 33.8522C129.342 34.175 129.214 34.4844 128.985 34.7126C128.757 34.9408 128.447 35.069 128.124 35.069C127.801 35.0685 127.492 34.9402 127.264 34.7121C127.035 34.484 126.907 34.1748 126.906 33.8522Z"
                fill="#4E415C"/>
            <path
                d="M129.959 35.3609C129.959 35.038 130.087 34.7284 130.316 34.4999C130.544 34.2714 130.854 34.1428 131.177 34.1423C131.337 34.1423 131.496 34.1739 131.643 34.2351C131.791 34.2964 131.926 34.3862 132.039 34.4994C132.152 34.6125 132.242 34.7469 132.303 34.8947C132.364 35.0426 132.395 35.201 132.395 35.3609C132.395 35.6836 132.267 35.9931 132.038 36.2213C131.81 36.4495 131.5 36.5777 131.177 36.5777C130.854 36.5772 130.545 36.4489 130.316 36.2208C130.088 35.9927 129.959 35.6835 129.959 35.3609V35.3609Z"
                fill="#4E415C"/>
            <path
                d="M133.072 36.8479C133.072 36.5252 133.201 36.2157 133.429 35.9875C133.657 35.7593 133.967 35.6311 134.29 35.6311C134.45 35.6309 134.609 35.6621 134.757 35.7232C134.905 35.7842 135.039 35.8738 135.153 35.9868C135.266 36.0998 135.356 36.2341 135.417 36.3818C135.478 36.5295 135.51 36.6879 135.51 36.8479C135.51 37.0078 135.478 37.1662 135.417 37.3139C135.356 37.4617 135.266 37.5959 135.153 37.7089C135.039 37.8219 134.905 37.9115 134.757 37.9725C134.609 38.0336 134.45 38.0649 134.29 38.0646C133.967 38.0646 133.657 37.9364 133.429 37.7082C133.201 37.4801 133.072 37.1706 133.072 36.8479V36.8479Z"
                fill="#4E415C"/>
            <path
                d="M136.238 38.2877C136.238 38.1277 136.27 37.9694 136.331 37.8216C136.392 37.6739 136.482 37.5397 136.596 37.4267C136.709 37.3137 136.843 37.2241 136.991 37.163C137.139 37.102 137.298 37.0707 137.458 37.0709C137.781 37.0709 138.091 37.1991 138.319 37.4273C138.548 37.6555 138.676 37.965 138.676 38.2877C138.676 38.6103 138.547 38.9195 138.319 39.1476C138.09 39.3756 137.781 39.504 137.458 39.5045C137.135 39.5045 136.825 39.3763 136.596 39.1482C136.367 38.9201 136.239 38.6106 136.238 38.2877V38.2877Z"
                fill="#4E415C"/>
            <path
                d="M140.669 40.8627C141.342 40.8627 141.887 40.318 141.887 39.646C141.887 38.974 141.342 38.4292 140.669 38.4292C139.996 38.4292 139.451 38.974 139.451 39.646C139.451 40.318 139.996 40.8627 140.669 40.8627Z"
                fill="#4E415C"/>
            <path
                d="M142.7 40.8953C142.7 40.7354 142.731 40.577 142.792 40.4292C142.853 40.2813 142.943 40.147 143.056 40.0338C143.169 39.9206 143.304 39.8308 143.452 39.7696C143.6 39.7083 143.758 39.6768 143.918 39.6768C144.241 39.6772 144.551 39.8058 144.78 40.0343C145.008 40.2628 145.136 40.5725 145.136 40.8953C145.136 41.2179 145.007 41.5271 144.779 41.7552C144.551 41.9833 144.241 42.1116 143.918 42.1121C143.595 42.1121 143.285 41.9839 143.057 41.7557C142.829 41.5276 142.7 41.218 142.7 40.8953V40.8953Z"
                fill="#4E415C"/>
            <path
                d="M145.98 42.0015C145.98 41.8416 146.012 41.6832 146.073 41.5354C146.134 41.3875 146.223 41.2532 146.337 41.14C146.45 41.0268 146.584 40.937 146.732 40.8758C146.88 40.8145 147.038 40.783 147.198 40.783C147.522 40.7834 147.831 40.912 148.06 41.1405C148.288 41.369 148.416 41.6787 148.416 42.0015C148.416 42.3241 148.287 42.6333 148.059 42.8614C147.831 43.0895 147.521 43.2178 147.198 43.2183C146.875 43.2183 146.566 43.0901 146.337 42.8619C146.109 42.6338 145.98 42.3242 145.98 42.0015V42.0015Z"
                fill="#4E415C"/>
            <path
                d="M149.282 42.9335C149.282 42.6104 149.411 42.3004 149.639 42.0719C149.868 41.8434 150.178 41.715 150.502 41.715C150.825 41.7154 151.135 41.844 151.363 42.0725C151.592 42.301 151.72 42.6107 151.72 42.9335C151.719 43.2561 151.591 43.5653 151.363 43.7934C151.134 44.0215 150.825 44.1499 150.502 44.1503C150.179 44.1503 149.869 44.0222 149.64 43.7941C149.411 43.5659 149.283 43.2564 149.282 42.9335V42.9335Z"
                fill="#4E415C"/>
            <path
                d="M152.602 43.6607C152.602 43.5008 152.633 43.3424 152.694 43.1947C152.756 43.0469 152.846 42.9127 152.959 42.7997C153.072 42.6867 153.207 42.5971 153.355 42.5361C153.503 42.475 153.661 42.4437 153.821 42.444C154.144 42.444 154.454 42.5722 154.683 42.8004C154.911 43.0285 155.039 43.338 155.039 43.6607C155.039 43.9833 154.91 44.2925 154.682 44.5206C154.454 44.7487 154.144 44.877 153.821 44.8775C153.498 44.8775 153.188 44.7494 152.959 44.5212C152.731 44.2931 152.602 43.9836 152.602 43.6607V43.6607Z"
                fill="#4E415C"/>
            <path
                d="M155.931 44.1504C155.931 43.8278 156.06 43.5186 156.288 43.2905C156.516 43.0624 156.826 42.9341 157.149 42.9336C157.472 42.9336 157.782 43.0617 158.011 43.2899C158.239 43.518 158.368 43.8275 158.368 44.1504C158.368 44.4736 158.24 44.7835 158.011 45.012C157.782 45.2406 157.472 45.369 157.149 45.369C156.825 45.3685 156.516 45.2399 156.287 45.0114C156.059 44.7829 155.931 44.4733 155.931 44.1504V44.1504Z"
                fill="#4E415C"/>
            <path
                d="M159.262 44.3698C159.262 44.0471 159.39 43.7377 159.618 43.5095C159.847 43.2813 160.157 43.1531 160.48 43.1531C160.64 43.1528 160.798 43.1841 160.946 43.2452C161.094 43.3063 161.228 43.3959 161.341 43.5089C161.455 43.6219 161.544 43.7562 161.605 43.9039C161.667 44.0517 161.698 44.21 161.698 44.3698C161.698 44.5296 161.666 44.6878 161.605 44.8355C161.544 44.9831 161.454 45.1172 161.341 45.2302C161.228 45.3432 161.094 45.4328 160.946 45.494C160.798 45.5551 160.64 45.5866 160.48 45.5866C160.157 45.5861 159.847 45.4578 159.619 45.2297C159.391 45.0016 159.262 44.6924 159.262 44.3698V44.3698Z"
                fill="#4E415C"/>
            <path
                d="M162.752 44.2175C162.752 44.0577 162.783 43.8993 162.844 43.7516C162.905 43.6039 162.995 43.4696 163.108 43.3566C163.221 43.2436 163.356 43.1539 163.504 43.0929C163.651 43.0318 163.81 43.0005 163.97 43.0007C164.13 43.0007 164.288 43.0322 164.436 43.0934C164.584 43.1545 164.718 43.2441 164.831 43.3571C164.944 43.4701 165.034 43.6042 165.095 43.7519C165.156 43.8995 165.188 44.0577 165.188 44.2175C165.188 44.5402 165.06 44.8497 164.831 45.0779C164.603 45.3061 164.293 45.4343 163.97 45.4343C163.81 45.4345 163.651 45.4032 163.504 45.3422C163.356 45.2811 163.221 45.1915 163.108 45.0784C162.995 44.9654 162.905 44.8312 162.844 44.6834C162.783 44.5357 162.752 44.3774 162.752 44.2175V44.2175Z"
                fill="#4E415C"/>
            <path
                d="M166.145 43.4813C166.145 43.1587 166.273 42.8495 166.502 42.6214C166.73 42.3934 167.04 42.265 167.362 42.2645C167.686 42.2645 167.996 42.3927 168.224 42.6208C168.453 42.8489 168.582 43.1584 168.582 43.4813C168.582 43.6412 168.551 43.7996 168.489 43.9474C168.428 44.0951 168.338 44.2293 168.225 44.3423C168.112 44.4553 167.977 44.5449 167.829 44.606C167.681 44.667 167.523 44.6983 167.362 44.6981C167.203 44.6981 167.044 44.6666 166.896 44.6054C166.749 44.5443 166.614 44.4547 166.501 44.3417C166.388 44.2287 166.298 44.0946 166.237 43.9469C166.176 43.7993 166.145 43.6411 166.145 43.4813V43.4813Z"
                fill="#4E415C"/>
            <path
                d="M169.287 42.2082C169.287 42.0484 169.318 41.89 169.379 41.7423C169.44 41.5946 169.53 41.4603 169.643 41.3473C169.756 41.2343 169.891 41.1447 170.039 41.0836C170.187 41.0225 170.345 40.9912 170.505 40.9915C170.828 40.9915 171.138 41.1197 171.366 41.3478C171.595 41.576 171.723 41.8855 171.723 42.2082C171.723 42.5308 171.594 42.84 171.366 43.0681C171.137 43.2962 170.828 43.4245 170.505 43.425C170.345 43.425 170.187 43.3935 170.039 43.3324C169.891 43.2712 169.757 43.1816 169.644 43.0686C169.531 42.9556 169.441 42.8215 169.38 42.6739C169.319 42.5262 169.287 42.368 169.287 42.2082V42.2082Z"
                fill="#4E415C"/>
            <path
                d="M172.149 40.4475C172.149 40.2877 172.181 40.1295 172.242 39.9819C172.303 39.8342 172.393 39.7001 172.506 39.5871C172.619 39.4741 172.753 39.3845 172.901 39.3233C173.049 39.2622 173.207 39.2307 173.367 39.2307C173.527 39.2305 173.686 39.2618 173.834 39.3228C173.982 39.3839 174.116 39.4735 174.229 39.5866C174.342 39.6996 174.432 39.8338 174.493 39.9816C174.554 40.1293 174.586 40.2876 174.585 40.4475C174.586 40.6073 174.554 40.7657 174.493 40.9134C174.432 41.0611 174.342 41.1954 174.229 41.3084C174.116 41.4214 173.982 41.5111 173.834 41.5721C173.686 41.6332 173.527 41.6645 173.367 41.6642C173.207 41.6642 173.049 41.6328 172.901 41.5716C172.753 41.5105 172.619 41.4209 172.506 41.3079C172.393 41.1949 172.303 41.0608 172.242 40.9131C172.181 40.7655 172.149 40.6073 172.149 40.4475V40.4475Z"
                fill="#4E415C"/>
            <path
                d="M174.676 38.2532C174.676 37.9304 174.804 37.6207 175.032 37.3922C175.261 37.1637 175.571 37.0351 175.894 37.0347C176.054 37.0347 176.212 37.0662 176.36 37.1275C176.508 37.1887 176.643 37.2785 176.756 37.3917C176.869 37.5049 176.958 37.6392 177.02 37.787C177.081 37.9349 177.112 38.0933 177.112 38.2532C177.112 38.576 176.983 38.8854 176.755 39.1136C176.527 39.3418 176.217 39.47 175.894 39.47C175.571 39.4695 175.261 39.3412 175.033 39.1131C174.805 38.885 174.676 38.5758 174.676 38.2532V38.2532Z"
                fill="#4E415C"/>
            <path
                d="M176.809 35.6747C176.809 35.5149 176.84 35.3566 176.901 35.209C176.963 35.0614 177.052 34.9273 177.165 34.8143C177.278 34.7013 177.413 34.6117 177.56 34.5505C177.708 34.4894 177.867 34.4579 178.027 34.4579C178.187 34.4579 178.345 34.4894 178.493 34.5505C178.64 34.6117 178.775 34.7013 178.888 34.8143C179.001 34.9273 179.091 35.0614 179.152 35.209C179.213 35.3566 179.245 35.5149 179.245 35.6747C179.245 35.8346 179.213 35.993 179.152 36.1409C179.091 36.2887 179.002 36.423 178.888 36.5362C178.775 36.6494 178.641 36.7392 178.493 36.8005C178.345 36.8617 178.187 36.8933 178.027 36.8933C177.866 36.8933 177.708 36.8617 177.56 36.8005C177.412 36.7392 177.278 36.6494 177.165 36.5362C177.052 36.423 176.962 36.2887 176.901 36.1409C176.84 35.993 176.808 35.8346 176.809 35.6747V35.6747Z"
                fill="#4E415C"/>
            <path
                d="M178.5 32.7643C178.5 32.4415 178.628 32.1321 178.857 31.9039C179.085 31.6757 179.395 31.5475 179.718 31.5475C180.041 31.5475 180.351 31.6757 180.579 31.9039C180.808 32.1321 180.936 32.4415 180.936 32.7643C180.936 32.9241 180.905 33.0824 180.844 33.2302C180.783 33.3779 180.693 33.5121 180.58 33.6252C180.467 33.7382 180.332 33.8278 180.184 33.8889C180.036 33.95 179.878 33.9813 179.718 33.981C179.558 33.9813 179.399 33.95 179.252 33.8889C179.104 33.8278 178.969 33.7382 178.856 33.6252C178.743 33.5121 178.653 33.3779 178.592 33.2302C178.531 33.0824 178.5 32.9241 178.5 32.7643V32.7643Z"
                fill="#4E415C"/>
            <path
                d="M179.696 29.5818C179.697 29.2592 179.825 28.95 180.054 28.7219C180.282 28.4938 180.591 28.3655 180.914 28.365C181.237 28.365 181.547 28.4932 181.776 28.7214C182.004 28.9496 182.132 29.259 182.132 29.5818C182.132 29.7416 182.101 29.9 182.04 30.0477C181.979 30.1954 181.889 30.3297 181.776 30.4427C181.663 30.5557 181.529 30.6453 181.381 30.7064C181.233 30.7675 181.074 30.7988 180.914 30.7985C180.591 30.7985 180.281 30.6703 180.053 30.4422C179.825 30.214 179.696 29.9045 179.696 29.5818V29.5818Z"
                fill="#4E415C"/>
            <path
                d="M180.347 26.1707C180.347 25.8475 180.475 25.5376 180.704 25.309C180.933 25.0805 181.243 24.9521 181.566 24.9521C181.89 24.9526 182.199 25.0812 182.428 25.3097C182.656 25.5382 182.784 25.8479 182.784 26.1707C182.784 26.4933 182.655 26.8025 182.427 27.0306C182.199 27.2587 181.889 27.387 181.566 27.3875C181.243 27.3875 180.933 27.2594 180.705 27.0312C180.476 26.8031 180.347 26.4936 180.347 26.1707V26.1707Z"
                fill="#4E415C"/>
            <path
                d="M180.4 22.6094C180.4 22.2866 180.529 21.9772 180.757 21.749C180.986 21.5208 181.295 21.3926 181.618 21.3926C181.779 21.3919 181.938 21.4228 182.086 21.4836C182.235 21.5444 182.37 21.6339 182.484 21.7469C182.598 21.86 182.688 21.9943 182.75 22.1423C182.811 22.2903 182.843 22.449 182.844 22.6094C182.844 22.9321 182.715 23.2415 182.487 23.4697C182.258 23.6979 181.949 23.8261 181.626 23.8261C181.465 23.8273 181.306 23.7967 181.157 23.7361C181.008 23.6755 180.873 23.586 180.759 23.4729C180.645 23.3597 180.555 23.2252 180.493 23.077C180.432 22.9287 180.4 22.7698 180.4 22.6094V22.6094Z"
                fill="#4E415C"/>
            <path
                d="M179.849 19.1639C179.848 19.0041 179.88 18.8457 179.941 18.698C180.002 18.5503 180.092 18.416 180.205 18.303C180.318 18.1899 180.452 18.1003 180.6 18.0393C180.748 17.9782 180.907 17.9469 181.067 17.9471C181.227 17.9469 181.385 17.9782 181.533 18.0393C181.681 18.1003 181.815 18.1899 181.928 18.303C182.041 18.416 182.131 18.5503 182.192 18.698C182.253 18.8457 182.285 19.0041 182.285 19.1639C182.285 19.3238 182.253 19.4821 182.192 19.6298C182.131 19.7776 182.041 19.9118 181.928 20.0248C181.815 20.1379 181.681 20.2275 181.533 20.2885C181.385 20.3496 181.227 20.3809 181.067 20.3807C180.907 20.3809 180.748 20.3496 180.6 20.2885C180.452 20.2275 180.318 20.1379 180.205 20.0248C180.092 19.9118 180.002 19.7776 179.941 19.6298C179.88 19.4821 179.848 19.3238 179.849 19.1639V19.1639Z"
                fill="#4E415C"/>
            <path
                d="M178.742 15.9343C178.742 15.6116 178.871 15.3021 179.099 15.0739C179.327 14.8457 179.637 14.7175 179.96 14.7175C180.12 14.7173 180.279 14.7486 180.427 14.8097C180.574 14.8707 180.709 14.9604 180.822 15.0734C180.935 15.1864 181.025 15.3206 181.086 15.4684C181.147 15.6161 181.178 15.7744 181.178 15.9343C181.178 16.257 181.05 16.5665 180.821 16.7947C180.593 17.0229 180.283 17.1511 179.96 17.1511C179.637 17.1506 179.328 17.0223 179.099 16.7942C178.871 16.5661 178.743 16.2569 178.742 15.9343V15.9343Z"
                fill="#4E415C"/>
            <path
                d="M177.132 12.9748C177.132 12.815 177.163 12.6566 177.224 12.5089C177.285 12.3612 177.375 12.227 177.488 12.1139C177.601 12.0009 177.736 11.9113 177.883 11.8502C178.031 11.7891 178.19 11.7578 178.35 11.7581C178.51 11.7578 178.668 11.7891 178.816 11.8502C178.964 11.9113 179.098 12.0009 179.212 12.1139C179.325 12.227 179.414 12.3612 179.476 12.5089C179.537 12.6566 179.568 12.815 179.568 12.9748C179.568 13.1347 179.537 13.293 179.476 13.4407C179.414 13.5885 179.325 13.7227 179.212 13.8358C179.098 13.9488 178.964 14.0384 178.816 14.0995C178.668 14.1605 178.51 14.1918 178.35 14.1916C178.19 14.1918 178.031 14.1605 177.883 14.0995C177.736 14.0384 177.601 13.9488 177.488 13.8358C177.375 13.7227 177.285 13.5885 177.224 13.4407C177.163 13.293 177.132 13.1347 177.132 12.9748V12.9748Z"
                fill="#4E415C"/>
            <path
                d="M176.289 11.555C176.962 11.555 177.507 11.0102 177.507 10.3382C177.507 9.66623 176.962 9.12146 176.289 9.12146C175.617 9.12146 175.071 9.66623 175.071 10.3382C175.071 11.0102 175.617 11.555 176.289 11.555Z"
                fill="#4E415C"/>
            <path
                d="M172.608 8.07333C172.608 7.75063 172.737 7.44114 172.965 7.21296C173.194 6.98477 173.503 6.85657 173.826 6.85657C173.986 6.85657 174.145 6.88804 174.292 6.94918C174.44 7.01033 174.574 7.09997 174.688 7.21296C174.801 7.32594 174.89 7.46008 174.952 7.60771C175.013 7.75533 175.044 7.91355 175.044 8.07333C175.045 8.23319 175.013 8.39154 174.952 8.53927C174.891 8.68701 174.801 8.82123 174.688 8.93427C174.575 9.0473 174.441 9.13691 174.293 9.19797C174.145 9.25904 173.986 9.29036 173.826 9.29012C173.666 9.29036 173.508 9.25904 173.36 9.19797C173.212 9.13691 173.078 9.0473 172.965 8.93427C172.851 8.82123 172.762 8.68701 172.701 8.53927C172.639 8.39154 172.608 8.23319 172.608 8.07333V8.07333Z"
                fill="#4E415C"/>
            <path
                d="M169.799 6.23459C169.799 5.91203 169.928 5.60283 170.156 5.37474C170.384 5.14666 170.694 5.0183 171.017 5.01782C171.34 5.01782 171.65 5.14596 171.879 5.3741C172.107 5.60224 172.236 5.91171 172.237 6.23459C172.237 6.39453 172.205 6.55292 172.144 6.70066C172.082 6.8484 171.993 6.98262 171.879 7.09563C171.766 7.20864 171.631 7.29823 171.483 7.35927C171.335 7.42031 171.177 7.45162 171.017 7.45138C170.694 7.45138 170.384 7.32318 170.156 7.09499C169.927 6.8668 169.799 6.5573 169.799 6.23459V6.23459Z"
                fill="#4E415C"/>
            <path
                d="M166.689 4.87447C166.689 4.71452 166.721 4.55612 166.782 4.40828C166.843 4.26045 166.932 4.12609 167.046 4.01291C167.159 3.89973 167.293 3.80994 167.441 3.74868C167.589 3.68742 167.747 3.65588 167.907 3.65588C168.068 3.65588 168.226 3.68742 168.374 3.74868C168.522 3.80994 168.656 3.89973 168.769 4.01291C168.882 4.12609 168.972 4.26045 169.033 4.40828C169.094 4.55612 169.126 4.71452 169.125 4.87447C169.125 5.19717 168.997 5.50668 168.769 5.73487C168.54 5.96306 168.23 6.09125 167.907 6.09125C167.584 6.09125 167.275 5.96306 167.046 5.73487C166.818 5.50668 166.689 5.19717 166.689 4.87447V4.87447Z"
                fill="#4E415C"/>
            <path
                d="M163.346 4.04221C163.346 3.88227 163.377 3.7239 163.439 3.57616C163.5 3.42841 163.59 3.2942 163.703 3.18119C163.816 3.06818 163.951 2.97857 164.099 2.91753C164.247 2.85648 164.405 2.8252 164.565 2.82544C164.889 2.82544 165.198 2.95364 165.427 3.18183C165.655 3.41002 165.783 3.7195 165.783 4.04221C165.783 4.36477 165.654 4.67399 165.426 4.90208C165.198 5.13016 164.888 5.25849 164.565 5.25897C164.242 5.25897 163.932 5.13086 163.704 4.90272C163.475 4.67458 163.346 4.36508 163.346 4.04221V4.04221Z"
                fill="#4E415C"/>
            <path
                d="M159.824 3.7883C159.824 3.62845 159.855 3.47012 159.916 3.32238C159.978 3.17465 160.067 3.04043 160.18 2.92739C160.294 2.81436 160.428 2.72473 160.576 2.66366C160.724 2.6026 160.882 2.5713 161.042 2.57153C161.202 2.5713 161.361 2.6026 161.509 2.66366C161.656 2.72473 161.791 2.81436 161.904 2.92739C162.017 3.04043 162.107 3.17465 162.168 3.32238C162.229 3.47012 162.26 3.62845 162.26 3.7883C162.26 3.94816 162.229 4.10651 162.168 4.25424C162.107 4.40197 162.017 4.5362 161.904 4.64923C161.791 4.76227 161.656 4.85187 161.509 4.91294C161.361 4.974 161.202 5.00533 161.042 5.00509C160.882 5.00533 160.724 4.974 160.576 4.91294C160.428 4.85187 160.294 4.76227 160.18 4.64923C160.067 4.5362 159.978 4.40197 159.916 4.25424C159.855 4.10651 159.824 3.94816 159.824 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M156.4 3.7883C156.4 3.46559 156.529 3.15611 156.757 2.92792C156.986 2.69973 157.295 2.57153 157.618 2.57153C157.778 2.5713 157.937 2.6026 158.085 2.66366C158.233 2.72473 158.367 2.81436 158.48 2.92739C158.593 3.04043 158.683 3.17465 158.744 3.32238C158.805 3.47012 158.837 3.62845 158.836 3.7883C158.837 3.94816 158.805 4.10651 158.744 4.25424C158.683 4.40197 158.593 4.5362 158.48 4.64923C158.367 4.76227 158.233 4.85187 158.085 4.91294C157.937 4.974 157.778 5.00533 157.618 5.00509C157.295 5.00509 156.986 4.87689 156.757 4.6487C156.529 4.42051 156.4 4.11101 156.4 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M152.978 3.7883C152.978 3.62851 153.009 3.4703 153.07 3.32267C153.131 3.17505 153.221 3.04091 153.334 2.92792C153.447 2.81493 153.582 2.7253 153.729 2.66415C153.877 2.603 154.036 2.57153 154.196 2.57153C154.356 2.5713 154.514 2.6026 154.662 2.66364C154.81 2.72468 154.945 2.81427 155.058 2.92728C155.171 3.04029 155.261 3.17451 155.322 3.32225C155.384 3.47 155.415 3.62836 155.415 3.7883C155.415 3.94824 155.384 4.10663 155.322 4.25437C155.261 4.40212 155.171 4.53633 155.058 4.64934C154.945 4.76236 154.81 4.85194 154.662 4.91298C154.514 4.97402 154.356 5.00533 154.196 5.00509C154.036 5.00509 153.877 4.9736 153.729 4.91245C153.582 4.8513 153.447 4.76169 153.334 4.6487C153.221 4.53571 153.131 4.40158 153.07 4.25395C153.009 4.10633 152.978 3.94809 152.978 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M150.774 5.00508C151.446 5.00508 151.992 4.46031 151.992 3.78831C151.992 3.1163 151.446 2.57153 150.774 2.57153C150.101 2.57153 149.556 3.1163 149.556 3.78831C149.556 4.46031 150.101 5.00508 150.774 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M147.353 5.00508C148.025 5.00508 148.571 4.46031 148.571 3.78831C148.571 3.1163 148.025 2.57153 147.353 2.57153C146.68 2.57153 146.135 3.1163 146.135 3.78831C146.135 4.46031 146.68 5.00508 147.353 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M142.709 3.7883C142.709 3.46559 142.837 3.15611 143.066 2.92792C143.294 2.69973 143.604 2.57153 143.927 2.57153C144.087 2.5713 144.245 2.6026 144.393 2.66366C144.541 2.72473 144.676 2.81436 144.789 2.92739C144.902 3.04043 144.992 3.17465 145.053 3.32238C145.114 3.47012 145.145 3.62845 145.145 3.7883C145.145 3.94816 145.114 4.10651 145.053 4.25424C144.992 4.40197 144.902 4.5362 144.789 4.64923C144.676 4.76227 144.541 4.85187 144.393 4.91294C144.245 4.974 144.087 5.00533 143.927 5.00509C143.604 5.00509 143.294 4.87689 143.066 4.6487C142.837 4.42051 142.709 4.11101 142.709 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M139.28 3.7883C139.28 3.62845 139.311 3.47012 139.372 3.32238C139.434 3.17465 139.523 3.04043 139.636 2.92739C139.75 2.81436 139.884 2.72473 140.032 2.66366C140.18 2.6026 140.338 2.5713 140.498 2.57153C140.821 2.57153 141.131 2.69973 141.359 2.92792C141.588 3.15611 141.716 3.46559 141.716 3.7883C141.716 4.11101 141.588 4.42051 141.359 4.6487C141.131 4.87689 140.821 5.00509 140.498 5.00509C140.338 5.00533 140.18 4.974 140.032 4.91294C139.884 4.85187 139.75 4.76227 139.636 4.64923C139.523 4.5362 139.434 4.40197 139.372 4.25424C139.311 4.10651 139.28 3.94816 139.28 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M135.864 3.7883C135.864 3.62845 135.895 3.47012 135.956 3.32238C136.018 3.17465 136.107 3.04043 136.22 2.92739C136.334 2.81436 136.468 2.72473 136.616 2.66366C136.764 2.6026 136.922 2.5713 137.082 2.57153C137.242 2.5713 137.401 2.6026 137.549 2.66366C137.697 2.72473 137.831 2.81436 137.944 2.92739C138.057 3.04043 138.147 3.17465 138.208 3.32238C138.269 3.47012 138.3 3.62845 138.3 3.7883C138.3 3.94816 138.269 4.10651 138.208 4.25424C138.147 4.40197 138.057 4.5362 137.944 4.64923C137.831 4.76227 137.697 4.85187 137.549 4.91294C137.401 4.974 137.242 5.00533 137.082 5.00509C136.922 5.00533 136.764 4.974 136.616 4.91294C136.468 4.85187 136.334 4.76227 136.22 4.64923C136.107 4.5362 136.018 4.40197 135.956 4.25424C135.895 4.10651 135.864 3.94816 135.864 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M132.44 3.7883C132.44 3.46559 132.569 3.15611 132.797 2.92792C133.026 2.69973 133.335 2.57153 133.658 2.57153C133.818 2.5713 133.977 2.6026 134.125 2.66366C134.273 2.72473 134.407 2.81436 134.52 2.92739C134.633 3.04043 134.723 3.17465 134.784 3.32238C134.845 3.47012 134.877 3.62845 134.876 3.7883C134.877 3.94816 134.845 4.10651 134.784 4.25424C134.723 4.40197 134.633 4.5362 134.52 4.64923C134.407 4.76227 134.273 4.85187 134.125 4.91294C133.977 4.974 133.818 5.00533 133.658 5.00509C133.335 5.00509 133.026 4.87689 132.797 4.6487C132.569 4.42051 132.44 4.11101 132.44 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M130.238 5.00508C130.91 5.00508 131.455 4.46031 131.455 3.78831C131.455 3.1163 130.91 2.57153 130.238 2.57153C129.565 2.57153 129.02 3.1163 129.02 3.78831C129.02 4.46031 129.565 5.00508 130.238 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M125.596 3.7883C125.595 3.62845 125.627 3.47012 125.688 3.32238C125.749 3.17465 125.839 3.04043 125.952 2.92739C126.065 2.81436 126.199 2.72473 126.347 2.66366C126.495 2.6026 126.654 2.5713 126.814 2.57153C127.137 2.57153 127.446 2.69973 127.675 2.92792C127.903 3.15611 128.032 3.46559 128.032 3.7883C128.032 4.11101 127.903 4.42051 127.675 4.6487C127.446 4.87689 127.137 5.00509 126.814 5.00509C126.654 5.00533 126.495 4.974 126.347 4.91294C126.199 4.85187 126.065 4.76227 125.952 4.64923C125.839 4.5362 125.749 4.40197 125.688 4.25424C125.627 4.10651 125.595 3.94816 125.596 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M122.173 3.7883C122.173 3.62845 122.204 3.47012 122.265 3.32238C122.326 3.17465 122.416 3.04043 122.529 2.92739C122.642 2.81436 122.777 2.72473 122.924 2.66366C123.072 2.6026 123.231 2.5713 123.391 2.57153C123.551 2.5713 123.709 2.6026 123.857 2.66366C124.005 2.72473 124.139 2.81436 124.253 2.92739C124.366 3.04043 124.455 3.17465 124.517 3.32238C124.578 3.47012 124.609 3.62845 124.609 3.7883C124.609 3.94816 124.578 4.10651 124.517 4.25424C124.455 4.40197 124.366 4.5362 124.253 4.64923C124.139 4.76227 124.005 4.85187 123.857 4.91294C123.709 4.974 123.551 5.00533 123.391 5.00509C123.231 5.00533 123.072 4.974 122.924 4.91294C122.777 4.85187 122.642 4.76227 122.529 4.64923C122.416 4.5362 122.326 4.40197 122.265 4.25424C122.204 4.10651 122.173 3.94816 122.173 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M119.969 5.00508C120.642 5.00508 121.187 4.46031 121.187 3.78831C121.187 3.1163 120.642 2.57153 119.969 2.57153C119.296 2.57153 118.751 3.1163 118.751 3.78831C118.751 4.46031 119.296 5.00508 119.969 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M115.32 3.7883C115.32 3.62836 115.352 3.47 115.413 3.32225C115.475 3.17451 115.564 3.04029 115.678 2.92728C115.791 2.81427 115.925 2.72468 116.073 2.66364C116.221 2.6026 116.38 2.5713 116.54 2.57153C116.863 2.57153 117.173 2.69973 117.401 2.92792C117.63 3.15611 117.758 3.46559 117.758 3.7883C117.758 4.11101 117.63 4.42051 117.401 4.6487C117.173 4.87689 116.863 5.00509 116.54 5.00509C116.38 5.00533 116.221 4.97402 116.073 4.91298C115.925 4.85194 115.791 4.76236 115.678 4.64934C115.564 4.53633 115.475 4.40212 115.413 4.25437C115.352 4.10663 115.32 3.94824 115.32 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M111.904 3.7883C111.904 3.62845 111.935 3.47012 111.997 3.32238C112.058 3.17465 112.147 3.04043 112.261 2.92739C112.374 2.81436 112.508 2.72473 112.656 2.66366C112.804 2.6026 112.962 2.5713 113.122 2.57153C113.282 2.5713 113.441 2.6026 113.589 2.66366C113.737 2.72473 113.871 2.81436 113.984 2.92739C114.097 3.04043 114.187 3.17465 114.248 3.32238C114.309 3.47012 114.341 3.62845 114.34 3.7883C114.341 3.94816 114.309 4.10651 114.248 4.25424C114.187 4.40197 114.097 4.5362 113.984 4.64923C113.871 4.76227 113.737 4.85187 113.589 4.91294C113.441 4.974 113.282 5.00533 113.122 5.00509C112.962 5.00533 112.804 4.974 112.656 4.91294C112.508 4.85187 112.374 4.76227 112.261 4.64923C112.147 4.5362 112.058 4.40197 111.997 4.25424C111.935 4.10651 111.904 3.94816 111.904 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M109.7 5.00508C110.373 5.00508 110.918 4.46031 110.918 3.78831C110.918 3.1163 110.373 2.57153 109.7 2.57153C109.028 2.57153 108.482 3.1163 108.482 3.78831C108.482 4.46031 109.028 5.00508 109.7 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M105.058 3.7883C105.058 3.46559 105.186 3.15611 105.414 2.92792C105.643 2.69973 105.953 2.57153 106.276 2.57153C106.436 2.5713 106.594 2.6026 106.742 2.66364C106.89 2.72468 107.025 2.81427 107.138 2.92728C107.251 3.04029 107.341 3.17451 107.402 3.32225C107.464 3.47 107.495 3.62836 107.495 3.7883C107.495 3.94824 107.464 4.10663 107.402 4.25437C107.341 4.40212 107.251 4.53633 107.138 4.64934C107.025 4.76236 106.89 4.85194 106.742 4.91298C106.594 4.97402 106.436 5.00533 106.276 5.00509C105.953 5.00509 105.643 4.87689 105.414 4.6487C105.186 4.42051 105.058 4.11101 105.058 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M101.636 3.7883C101.636 3.62845 101.667 3.47012 101.728 3.32238C101.789 3.17465 101.879 3.04043 101.992 2.92739C102.105 2.81436 102.239 2.72473 102.387 2.66366C102.535 2.6026 102.694 2.5713 102.854 2.57153C103.177 2.57153 103.487 2.69973 103.715 2.92792C103.943 3.15611 104.072 3.46559 104.072 3.7883C104.072 4.11101 103.943 4.42051 103.715 4.6487C103.487 4.87689 103.177 5.00509 102.854 5.00509C102.694 5.00533 102.535 4.974 102.387 4.91294C102.239 4.85187 102.105 4.76227 101.992 4.64923C101.879 4.5362 101.789 4.40197 101.728 4.25424C101.667 4.10651 101.636 3.94816 101.636 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M98.2129 3.7883C98.2127 3.62845 98.244 3.47012 98.3051 3.32238C98.3662 3.17465 98.456 3.04043 98.5691 2.92739C98.6822 2.81436 98.8166 2.72473 98.9645 2.66366C99.1124 2.6026 99.2708 2.5713 99.4309 2.57153C99.5909 2.5713 99.7493 2.6026 99.8972 2.66366C100.045 2.72473 100.179 2.81436 100.293 2.92739C100.406 3.04043 100.495 3.17465 100.557 3.32238C100.618 3.47012 100.649 3.62845 100.649 3.7883C100.649 3.94816 100.618 4.10651 100.557 4.25424C100.495 4.40197 100.406 4.5362 100.293 4.64923C100.179 4.76227 100.045 4.85187 99.8972 4.91294C99.7493 4.974 99.5909 5.00533 99.4309 5.00509C99.2708 5.00533 99.1124 4.974 98.9645 4.91294C98.8166 4.85187 98.6822 4.76227 98.5691 4.64923C98.456 4.5362 98.3662 4.40197 98.3051 4.25424C98.244 4.10651 98.2127 3.94816 98.2129 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M94.7891 3.7883C94.7891 3.46559 94.9174 3.15611 95.1458 2.92792C95.3742 2.69973 95.684 2.57153 96.0071 2.57153C96.1671 2.5713 96.3256 2.6026 96.4734 2.66366C96.6213 2.72473 96.7557 2.81436 96.8688 2.92739C96.982 3.04043 97.0717 3.17465 97.1328 3.32238C97.1939 3.47012 97.2253 3.62845 97.225 3.7883C97.2253 3.94816 97.1939 4.10651 97.1328 4.25424C97.0717 4.40197 96.982 4.5362 96.8688 4.64923C96.7557 4.76227 96.6213 4.85187 96.4734 4.91294C96.3256 4.974 96.1671 5.00533 96.0071 5.00509C95.684 5.00509 95.3742 4.87689 95.1458 4.6487C94.9174 4.42051 94.7891 4.11101 94.7891 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M91.3604 3.7883C91.3604 3.62836 91.3919 3.47 91.4532 3.32225C91.5146 3.17451 91.6044 3.04029 91.7177 2.92728C91.831 2.81427 91.9655 2.72468 92.1135 2.66364C92.2615 2.6026 92.42 2.5713 92.5801 2.57153C92.9032 2.57153 93.2129 2.69973 93.4414 2.92792C93.6698 3.15611 93.7981 3.46559 93.7981 3.7883C93.7981 4.11101 93.6698 4.42051 93.4414 4.6487C93.2129 4.87689 92.9032 5.00509 92.5801 5.00509C92.42 5.00533 92.2615 4.97402 92.1135 4.91298C91.9655 4.85194 91.831 4.76236 91.7177 4.64934C91.6044 4.53633 91.5146 4.40212 91.4532 4.25437C91.3919 4.10663 91.3604 3.94824 91.3604 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M89.1623 5.00508C89.835 5.00508 90.3803 4.46031 90.3803 3.78831C90.3803 3.1163 89.835 2.57153 89.1623 2.57153C88.4896 2.57153 87.9443 3.1163 87.9443 3.78831C87.9443 4.46031 88.4896 5.00508 89.1623 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M84.5205 3.7883C84.5203 3.62845 84.5516 3.47012 84.6127 3.32238C84.6739 3.17465 84.7636 3.04043 84.8767 2.92739C84.9899 2.81436 85.1242 2.72473 85.2721 2.66366C85.42 2.6026 85.5785 2.5713 85.7385 2.57153C85.8985 2.5713 86.057 2.6026 86.2048 2.66366C86.3527 2.72473 86.4871 2.81436 86.6002 2.92739C86.7134 3.04043 86.8031 3.17465 86.8642 3.32238C86.9253 3.47012 86.9567 3.62845 86.9564 3.7883C86.9567 3.94816 86.9253 4.10651 86.8642 4.25424C86.8031 4.40197 86.7134 4.5362 86.6002 4.64923C86.4871 4.76227 86.3527 4.85187 86.2048 4.91294C86.057 4.974 85.8985 5.00533 85.7385 5.00509C85.5785 5.00533 85.42 4.974 85.2721 4.91294C85.1242 4.85187 84.9899 4.76227 84.8767 4.64923C84.7636 4.5362 84.6739 4.40197 84.6127 4.25424C84.5516 4.10651 84.5203 3.94816 84.5205 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M82.3176 5.00508C82.9902 5.00508 83.5355 4.46031 83.5355 3.78831C83.5355 3.1163 82.9902 2.57153 82.3176 2.57153C81.6449 2.57153 81.0996 3.1163 81.0996 3.78831C81.0996 4.46031 81.6449 5.00508 82.3176 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M78.8937 5.00508C79.5664 5.00508 80.1117 4.46031 80.1117 3.78831C80.1117 3.1163 79.5664 2.57153 78.8937 2.57153C78.2211 2.57153 77.6758 3.1163 77.6758 3.78831C77.6758 4.46031 78.2211 5.00508 78.8937 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M74.252 3.7883C74.2517 3.62845 74.2831 3.47012 74.3442 3.32238C74.4053 3.17465 74.495 3.04043 74.6082 2.92739C74.7213 2.81436 74.8557 2.72473 75.0035 2.66366C75.1514 2.6026 75.3099 2.5713 75.4699 2.57153C75.6299 2.5713 75.7884 2.6026 75.9363 2.66366C76.0842 2.72473 76.2185 2.81436 76.3317 2.92739C76.4448 3.04043 76.5345 3.17465 76.5957 3.32238C76.6568 3.47012 76.6881 3.62845 76.6879 3.7883C76.6881 3.94816 76.6568 4.10651 76.5957 4.25424C76.5345 4.40197 76.4448 4.5362 76.3317 4.64923C76.2185 4.76227 76.0842 4.85187 75.9363 4.91294C75.7884 4.974 75.6299 5.00533 75.4699 5.00509C75.3099 5.00533 75.1514 4.974 75.0035 4.91294C74.8557 4.85187 74.7213 4.76227 74.6082 4.64923C74.495 4.5362 74.4053 4.40197 74.3442 4.25424C74.2831 4.10651 74.2517 3.94816 74.252 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M72.049 5.00508C72.7217 5.00508 73.267 4.46031 73.267 3.78831C73.267 3.1163 72.7217 2.57153 72.049 2.57153C71.3764 2.57153 70.8311 3.1163 70.8311 3.78831C70.8311 4.46031 71.3764 5.00508 72.049 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M68.6252 5.00508C69.2979 5.00508 69.8432 4.46031 69.8432 3.78831C69.8432 3.1163 69.2979 2.57153 68.6252 2.57153C67.9525 2.57153 67.4072 3.1163 67.4072 3.78831C67.4072 4.46031 67.9525 5.00508 68.6252 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M63.9844 3.7883C63.9841 3.62845 64.0155 3.47012 64.0766 3.32238C64.1377 3.17465 64.2274 3.04043 64.3406 2.92739C64.4537 2.81436 64.5881 2.72473 64.736 2.66366C64.8838 2.6026 65.0423 2.5713 65.2023 2.57153C65.5254 2.57153 65.8352 2.69973 66.0636 2.92792C66.292 3.15611 66.4203 3.46559 66.4203 3.7883C66.4203 4.11101 66.292 4.42051 66.0636 4.6487C65.8352 4.87689 65.5254 5.00509 65.2023 5.00509C65.0423 5.00533 64.8838 4.974 64.736 4.91294C64.5881 4.85187 64.4537 4.76227 64.3406 4.64923C64.2274 4.5362 64.1377 4.40197 64.0766 4.25424C64.0155 4.10651 63.9841 3.94816 63.9844 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M60.5605 3.7883C60.5603 3.62845 60.5916 3.47012 60.6528 3.32238C60.7139 3.17465 60.8036 3.04043 60.9168 2.92739C61.0299 2.81436 61.1643 2.72473 61.3121 2.66366C61.46 2.6026 61.6185 2.5713 61.7785 2.57153C61.9385 2.5713 62.097 2.6026 62.2449 2.66366C62.3928 2.72473 62.5271 2.81436 62.6403 2.92739C62.7534 3.04043 62.8431 3.17465 62.9043 3.32238C62.9654 3.47012 62.9967 3.62845 62.9965 3.7883C62.9967 3.94816 62.9654 4.10651 62.9043 4.25424C62.8431 4.40197 62.7534 4.5362 62.6403 4.64923C62.5271 4.76227 62.3928 4.85187 62.2449 4.91294C62.097 4.974 61.9385 5.00533 61.7785 5.00509C61.6185 5.00533 61.46 4.974 61.3121 4.91294C61.1643 4.85187 61.0299 4.76227 60.9168 4.64923C60.8036 4.5362 60.7139 4.40197 60.6528 4.25424C60.5916 4.10651 60.5603 3.94816 60.5605 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M57.1377 3.7883C57.1377 3.46559 57.266 3.15611 57.4944 2.92792C57.7228 2.69973 58.0326 2.57153 58.3557 2.57153C58.5158 2.5713 58.6744 2.6026 58.8223 2.66364C58.9703 2.72468 59.1048 2.81427 59.2181 2.92728C59.3314 3.04029 59.4212 3.17451 59.4825 3.32225C59.5439 3.47 59.5754 3.62836 59.5754 3.7883C59.5754 3.94824 59.5439 4.10663 59.4825 4.25437C59.4212 4.40212 59.3314 4.53633 59.2181 4.64934C59.1048 4.76236 58.9703 4.85194 58.8223 4.91298C58.6744 4.97402 58.5158 5.00533 58.3557 5.00509C58.0326 5.00509 57.7228 4.87689 57.4944 4.6487C57.266 4.42051 57.1377 4.11101 57.1377 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M53.7158 3.7883C53.7156 3.62845 53.7469 3.47012 53.808 3.32238C53.8692 3.17465 53.9588 3.04043 54.072 2.92739C54.1851 2.81436 54.3195 2.72473 54.4674 2.66366C54.6153 2.6026 54.7738 2.5713 54.9338 2.57153C55.0937 2.57153 55.2521 2.603 55.3998 2.66415C55.5476 2.7253 55.6819 2.81493 55.795 2.92792C55.9081 3.04091 55.9978 3.17505 56.059 3.32267C56.1202 3.4703 56.1517 3.62851 56.1517 3.7883C56.1517 3.94809 56.1202 4.10633 56.059 4.25395C55.9978 4.40158 55.9081 4.53571 55.795 4.6487C55.6819 4.76169 55.5476 4.8513 55.3998 4.91245C55.2521 4.9736 55.0937 5.00509 54.9338 5.00509C54.7738 5.00533 54.6153 4.974 54.4674 4.91294C54.3195 4.85187 54.1851 4.76227 54.072 4.64923C53.9588 4.5362 53.8692 4.40197 53.808 4.25424C53.7469 4.10651 53.7156 3.94816 53.7158 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M50.292 3.7883C50.2918 3.62845 50.3231 3.47012 50.3842 3.32238C50.4453 3.17465 50.5351 3.04043 50.6482 2.92739C50.7613 2.81436 50.8957 2.72473 51.0436 2.66366C51.1915 2.6026 51.3499 2.5713 51.51 2.57153C51.67 2.5713 51.8285 2.6026 51.9763 2.66366C52.1242 2.72473 52.2586 2.81436 52.3717 2.92739C52.4849 3.04043 52.5746 3.17465 52.6357 3.32238C52.6968 3.47012 52.7282 3.62845 52.7279 3.7883C52.7282 3.94816 52.6968 4.10651 52.6357 4.25424C52.5746 4.40197 52.4849 4.5362 52.3717 4.64923C52.2586 4.76227 52.1242 4.85187 51.9763 4.91294C51.8285 4.974 51.67 5.00533 51.51 5.00509C51.3499 5.00533 51.1915 4.974 51.0436 4.91294C50.8957 4.85187 50.7613 4.76227 50.6482 4.64923C50.5351 4.5362 50.4453 4.40197 50.3842 4.25424C50.3231 4.10651 50.2918 3.94816 50.292 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M48.0891 5.00508C48.7617 5.00508 49.307 4.46031 49.307 3.78831C49.307 3.1163 48.7617 2.57153 48.0891 2.57153C47.4164 2.57153 46.8711 3.1163 46.8711 3.78831C46.8711 4.46031 47.4164 5.00508 48.0891 5.00508Z"
                fill="#4E415C"/>
            <path
                d="M43.4404 3.7883C43.4404 3.46559 43.5688 3.15611 43.7972 2.92792C44.0256 2.69973 44.3354 2.57153 44.6584 2.57153C44.8185 2.5713 44.9771 2.6026 45.125 2.66364C45.273 2.72468 45.4075 2.81427 45.5208 2.92728C45.6341 3.04029 45.724 3.17451 45.7853 3.32225C45.8466 3.47 45.8782 3.62836 45.8782 3.7883C45.8782 3.94824 45.8466 4.10663 45.7853 4.25437C45.724 4.40212 45.6341 4.53633 45.5208 4.64934C45.4075 4.76236 45.273 4.85194 45.125 4.91298C44.9771 4.97402 44.8185 5.00533 44.6584 5.00509C44.3354 5.00509 44.0256 4.87689 43.7972 4.6487C43.5688 4.42051 43.4404 4.11101 43.4404 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M40.0244 3.7883C40.0242 3.62845 40.0555 3.47012 40.1166 3.32238C40.1778 3.17465 40.2675 3.04043 40.3806 2.92739C40.4938 2.81436 40.6281 2.72473 40.776 2.66366C40.9239 2.6026 41.0824 2.5713 41.2424 2.57153C41.5654 2.57153 41.8752 2.69973 42.1036 2.92792C42.3321 3.15611 42.4604 3.46559 42.4604 3.7883C42.4604 4.11101 42.3321 4.42051 42.1036 4.6487C41.8752 4.87689 41.5654 5.00509 41.2424 5.00509C41.0824 5.00533 40.9239 4.974 40.776 4.91294C40.6281 4.85187 40.4938 4.76227 40.3806 4.64923C40.2675 4.5362 40.1778 4.40197 40.1166 4.25424C40.0555 4.10651 40.0242 3.94816 40.0244 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M36.6006 3.7883C36.6003 3.62845 36.6317 3.47012 36.6928 3.32238C36.7539 3.17465 36.8436 3.04043 36.9568 2.92739C37.0699 2.81436 37.2043 2.72473 37.3522 2.66366C37.5001 2.6026 37.6585 2.5713 37.8185 2.57153C37.9786 2.5713 38.137 2.6026 38.2849 2.66366C38.4328 2.72473 38.5672 2.81436 38.6803 2.92739C38.7934 3.04043 38.8832 3.17465 38.9443 3.32238C39.0054 3.47012 39.0367 3.62845 39.0365 3.7883C39.0367 3.94816 39.0054 4.10651 38.9443 4.25424C38.8832 4.40197 38.7934 4.5362 38.6803 4.64923C38.5672 4.76227 38.4328 4.85187 38.2849 4.91294C38.137 4.974 37.9786 5.00533 37.8185 5.00509C37.6585 5.00533 37.5001 4.974 37.3522 4.91294C37.2043 4.85187 37.0699 4.76227 36.9568 4.64923C36.8436 4.5362 36.7539 4.40197 36.6928 4.25424C36.6317 4.10651 36.6003 3.94816 36.6006 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M33.1777 3.7883C33.1777 3.46559 33.3061 3.15611 33.5345 2.92792C33.7629 2.69973 34.0727 2.57153 34.3957 2.57153C34.5557 2.5713 34.7142 2.6026 34.8621 2.66366C35.0099 2.72473 35.1443 2.81436 35.2575 2.92739C35.3706 3.04043 35.4603 3.17465 35.5214 3.32238C35.5826 3.47012 35.6139 3.62845 35.6137 3.7883C35.6139 3.94816 35.5826 4.10651 35.5214 4.25424C35.4603 4.40197 35.3706 4.5362 35.2575 4.64923C35.1443 4.76227 35.0099 4.85187 34.8621 4.91294C34.7142 4.974 34.5557 5.00533 34.3957 5.00509C34.0727 5.00509 33.7629 4.87689 33.5345 4.6487C33.3061 4.42051 33.1777 4.11101 33.1777 3.7883Z"
                fill="#4E415C"/>
            <path
                d="M29.7559 3.7883C29.7556 3.62845 29.787 3.47012 29.8481 3.32238C29.9092 3.17465 29.9989 3.04043 30.112 2.92739C30.2252 2.81436 30.3596 2.72473 30.5074 2.66366C30.6553 2.6026 30.8138 2.5713 30.9738 2.57153C31.2968 2.57153 31.6066 2.69973 31.835 2.92792C32.0635 3.15611 32.1918 3.46559 32.1918 3.7883C32.1918 4.11101 32.0635 4.42051 31.835 4.6487C31.6066 4.87689 31.2968 5.00509 30.9738 5.00509C30.8138 5.00533 30.6553 4.974 30.5074 4.91294C30.3596 4.85187 30.2252 4.76227 30.112 4.64923C29.9989 4.5362 29.9092 4.40197 29.8481 4.25424C29.787 4.10651 29.7556 3.94816 29.7559 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M26.332 3.7883C26.3318 3.62845 26.3631 3.47012 26.4243 3.32238C26.4854 3.17465 26.5751 3.04043 26.6882 2.92739C26.8014 2.81436 26.9357 2.72473 27.0836 2.66366C27.2315 2.6026 27.39 2.5713 27.55 2.57153C27.71 2.5713 27.8685 2.6026 28.0164 2.66366C28.1642 2.72473 28.2986 2.81436 28.4117 2.92739C28.5249 3.04043 28.6146 3.17465 28.6757 3.32238C28.7369 3.47012 28.7682 3.62845 28.768 3.7883C28.7682 3.94816 28.7369 4.10651 28.6757 4.25424C28.6146 4.40197 28.5249 4.5362 28.4117 4.64923C28.2986 4.76227 28.1642 4.85187 28.0164 4.91294C27.8685 4.974 27.71 5.00533 27.55 5.00509C27.39 5.00533 27.2315 4.974 27.0836 4.91294C26.9357 4.85187 26.8014 4.76227 26.6882 4.64923C26.5751 4.5362 26.4854 4.40197 26.4243 4.25424C26.3631 4.10651 26.3318 3.94816 26.332 3.7883V3.7883Z"
                fill="#4E415C"/>
            <path
                d="M24.0686 5.05037C24.7412 5.05037 25.2865 4.5056 25.2865 3.83359C25.2865 3.16159 24.7412 2.61682 24.0686 2.61682C23.3959 2.61682 22.8506 3.16159 22.8506 3.83359C22.8506 4.5056 23.3959 5.05037 24.0686 5.05037Z"
                fill="#4E415C"/>
            <path
                d="M19.3945 4.36494C19.3943 4.205 19.4256 4.04657 19.4867 3.89874C19.5478 3.7509 19.6375 3.61655 19.7506 3.50337C19.8637 3.39019 19.9981 3.30042 20.146 3.23916C20.2939 3.17789 20.4524 3.14636 20.6125 3.14636C20.7726 3.14636 20.9311 3.17789 21.079 3.23916C21.2269 3.30042 21.3613 3.39019 21.4744 3.50337C21.5875 3.61655 21.6772 3.7509 21.7383 3.89874C21.7994 4.04657 21.8307 4.205 21.8305 4.36494C21.8305 4.68765 21.7021 4.99713 21.4737 5.22532C21.2453 5.45351 20.9355 5.58171 20.6125 5.58171C20.2895 5.58171 19.9797 5.45351 19.7513 5.22532C19.5229 4.99713 19.3945 4.68765 19.3945 4.36494V4.36494Z"
                fill="#4E415C"/>
            <path
                d="M17.3742 6.66792C18.0469 6.66792 18.5922 6.12315 18.5922 5.45115C18.5922 4.77914 18.0469 4.23438 17.3742 4.23438C16.7016 4.23438 16.1562 4.77914 16.1562 5.45115C16.1562 6.12315 16.7016 6.66792 17.3742 6.66792Z"
                fill="#4E415C"/>
            <path
                d="M13.1836 7.04333C13.1836 6.72062 13.3119 6.41112 13.5403 6.18293C13.7687 5.95474 14.0785 5.82654 14.4016 5.82654C14.7244 5.82702 15.0339 5.95537 15.2622 6.18346C15.4906 6.41154 15.619 6.72077 15.6195 7.04333C15.6195 7.3662 15.4913 7.67587 15.2629 7.90435C15.0346 8.13282 14.7247 8.26143 14.4016 8.26191C14.2415 8.26191 14.0829 8.23038 13.935 8.16911C13.7872 8.10785 13.6528 8.01806 13.5397 7.90488C13.4265 7.7917 13.3369 7.65734 13.2758 7.50951C13.2147 7.36167 13.1834 7.20327 13.1836 7.04333V7.04333Z"
                fill="#4E415C"/>
            <path
                d="M10.5313 9.08884C10.5313 8.76613 10.6596 8.45665 10.888 8.22846C11.1164 8.00027 11.4262 7.87207 11.7492 7.87207C12.0721 7.87255 12.3816 8.0009 12.6099 8.22899C12.8383 8.45708 12.9667 8.76628 12.9672 9.08884C12.9672 9.41155 12.8389 9.72105 12.6105 9.94924C12.3821 10.1774 12.0722 10.3056 11.7492 10.3056C11.5892 10.3059 11.4307 10.2746 11.2828 10.2135C11.135 10.1524 11.0006 10.0628 10.8875 9.94977C10.7743 9.83673 10.6846 9.70251 10.6235 9.55478C10.5623 9.40704 10.531 9.24869 10.5313 9.08884V9.08884Z"
                fill="#4E415C"/>
            <path
                d="M8.25195 11.535C8.25195 11.2123 8.38028 10.9028 8.60869 10.6746C8.83711 10.4464 9.14693 10.3182 9.46996 10.3182C9.79284 10.3187 10.1023 10.4471 10.3307 10.6752C10.559 10.9032 10.6874 11.2125 10.6879 11.535C10.6879 11.8577 10.5596 12.1672 10.3312 12.3954C10.1028 12.6236 9.79299 12.7518 9.46996 12.7518C9.30995 12.752 9.15142 12.7207 9.00354 12.6597C8.85566 12.5986 8.72131 12.509 8.60816 12.3959C8.49501 12.2829 8.40534 12.1487 8.34422 12.0009C8.28309 11.8532 8.25172 11.6949 8.25195 11.535V11.535Z"
                fill="#4E415C"/>
            <path
                d="M6.39453 14.3313C6.39453 14.0086 6.52286 13.6991 6.75127 13.4709C6.97968 13.2427 7.28951 13.1145 7.61254 13.1145C7.77255 13.1143 7.93103 13.1456 8.07891 13.2066C8.22679 13.2677 8.36115 13.3573 8.47429 13.4704C8.58744 13.5834 8.67715 13.7176 8.73828 13.8654C8.7994 14.0131 8.83074 14.1714 8.8305 14.3313C8.8305 14.4911 8.799 14.6493 8.73779 14.7969C8.67658 14.9445 8.58686 15.0787 8.47376 15.1917C8.36066 15.3047 8.22637 15.3943 8.0786 15.4554C7.93083 15.5166 7.77248 15.5481 7.61254 15.5481C7.28966 15.5476 6.98011 15.4192 6.7518 15.1911C6.52349 14.9631 6.39501 14.6538 6.39453 14.3313V14.3313Z"
                fill="#4E415C"/>
            <path
                d="M5.01563 17.4231C5.01539 17.2632 5.04672 17.1049 5.10785 16.9572C5.16897 16.8094 5.25869 16.6752 5.37183 16.5622C5.48498 16.4491 5.61933 16.3595 5.76721 16.2985C5.91509 16.2374 6.07357 16.2061 6.23359 16.2063C6.3936 16.2061 6.55208 16.2374 6.69996 16.2985C6.84784 16.3595 6.9822 16.4491 7.09534 16.5622C7.20849 16.6752 7.2982 16.8094 7.35933 16.9572C7.42045 17.1049 7.45179 17.2632 7.45155 17.4231C7.45179 17.5829 7.42045 17.7413 7.35933 17.889C7.2982 18.0367 7.20849 18.171 7.09534 18.284C6.9822 18.397 6.84784 18.4867 6.69996 18.5477C6.55208 18.6088 6.3936 18.6401 6.23359 18.6399C6.07357 18.6401 5.91509 18.6088 5.76721 18.5477C5.61933 18.4867 5.48498 18.397 5.37183 18.284C5.25869 18.171 5.16897 18.0367 5.10785 17.889C5.04672 17.7413 5.01539 17.5829 5.01563 17.4231V17.4231Z"
                fill="#4E415C"/>
            <path
                d="M4.16406 20.7596C4.16406 20.4369 4.29234 20.1274 4.52076 19.8992C4.74917 19.671 5.059 19.5428 5.38203 19.5428C5.70505 19.5428 6.01483 19.671 6.24325 19.8992C6.47166 20.1274 6.59999 20.4369 6.59999 20.7596C6.60023 20.9195 6.56889 21.0778 6.50777 21.2256C6.44664 21.3733 6.35693 21.5075 6.24378 21.6205C6.13063 21.7336 5.99628 21.8232 5.8484 21.8843C5.70052 21.9453 5.54204 21.9766 5.38203 21.9764C5.22201 21.9766 5.06353 21.9453 4.91565 21.8843C4.76777 21.8232 4.63337 21.7336 4.52023 21.6205C4.40708 21.5075 4.31741 21.3733 4.25628 21.2256C4.19516 21.0778 4.16382 20.9195 4.16406 20.7596V20.7596Z"
                fill="#4E415C"/>
            <path
                d="M3.8916 24.2903C3.89136 24.1304 3.9227 23.9721 3.98382 23.8243C4.04495 23.6766 4.13462 23.5424 4.24776 23.4293C4.36091 23.3163 4.49531 23.2267 4.64319 23.1656C4.79107 23.1046 4.94955 23.0732 5.10956 23.0735C5.26958 23.0732 5.42806 23.1046 5.57594 23.1656C5.72382 23.2267 5.85817 23.3163 5.97132 23.4293C6.08447 23.5424 6.17418 23.6766 6.23531 23.8243C6.29643 23.9721 6.32776 24.1304 6.32753 24.2903C6.32753 24.613 6.1992 24.9224 5.97079 25.1506C5.74237 25.3788 5.43259 25.507 5.10956 25.507C4.78654 25.507 4.47671 25.3788 4.2483 25.1506C4.01988 24.9224 3.8916 24.613 3.8916 24.2903V24.2903Z"
                fill="#4E415C"/>
            <path
                d="M4.23242 27.801C4.23242 27.4783 4.36075 27.1688 4.58916 26.9406C4.81757 26.7124 5.12736 26.5842 5.45038 26.5842C5.61048 26.584 5.76904 26.6153 5.91702 26.6763C6.065 26.7374 6.19951 26.8269 6.3128 26.94C6.42609 27.053 6.51595 27.1872 6.57728 27.3349C6.6386 27.4827 6.67016 27.6411 6.67016 27.801C6.67016 27.9609 6.6386 28.1193 6.57728 28.267C6.51595 28.4148 6.42609 28.549 6.3128 28.662C6.19951 28.775 6.065 28.8646 5.91702 28.9257C5.76904 28.9867 5.61048 29.018 5.45038 29.0178C5.12736 29.0178 4.81757 28.8896 4.58916 28.6614C4.36075 28.4332 4.23242 28.1237 4.23242 27.801V27.801Z"
                fill="#4E415C"/>
            <path
                d="M5.14063 31.1123C5.14063 30.7896 5.26895 30.4801 5.49736 30.2519C5.72578 30.0237 6.03556 29.8955 6.35859 29.8955C6.68161 29.8955 6.9914 30.0237 7.21981 30.2519C7.44822 30.4801 7.57655 30.7896 7.57655 31.1123C7.57679 31.2722 7.54547 31.4306 7.48437 31.5785C7.42327 31.7263 7.3336 31.8606 7.22048 31.9738C7.10735 32.087 6.97298 32.1768 6.82509 32.2381C6.67721 32.2993 6.51869 32.3309 6.35859 32.3309C6.19849 32.3309 6.03997 32.2993 5.89208 32.2381C5.74419 32.1768 5.60982 32.087 5.4967 31.9738C5.38358 31.8606 5.2939 31.7263 5.2328 31.5785C5.1717 31.4306 5.14039 31.2722 5.14063 31.1123Z"
                fill="#4E415C"/>
            <path
                d="M6.58203 34.175C6.58203 33.8523 6.71036 33.5428 6.93877 33.3146C7.16718 33.0865 7.47697 32.9583 7.79999 32.9583C7.96001 32.958 8.11853 32.9893 8.26641 33.0504C8.41429 33.1115 8.54865 33.2011 8.66179 33.3141C8.77494 33.4271 8.86461 33.5614 8.92573 33.7091C8.98686 33.8569 9.01824 34.0152 9.018 34.175C9.018 34.4978 8.88967 34.8072 8.66126 35.0354C8.43285 35.2636 8.12302 35.3918 7.79999 35.3918C7.47711 35.3913 7.16761 35.263 6.9393 35.0349C6.71099 34.8068 6.58251 34.4976 6.58203 34.175V34.175Z"
                fill="#4E415C"/>
            <path
                d="M8.4873 36.9349C8.4873 36.6122 8.61563 36.3027 8.84404 36.0745C9.07246 35.8463 9.38224 35.7181 9.70527 35.7181C9.86536 35.7179 10.024 35.7492 10.172 35.8102C10.3199 35.8713 10.4544 35.9609 10.5677 36.0739C10.681 36.1869 10.7709 36.3211 10.8322 36.4689C10.8935 36.6166 10.9251 36.775 10.9251 36.9349C10.9246 37.2578 10.7959 37.5673 10.5672 37.7954C10.3385 38.0235 10.0285 38.1517 9.70527 38.1517C9.38239 38.1512 9.07288 38.0229 8.84457 37.7948C8.61627 37.5667 8.48778 37.2575 8.4873 36.9349V36.9349Z"
                fill="#4E415C"/>
            <path
                d="M10.8125 39.3359C10.8125 39.0132 10.9409 38.7037 11.1693 38.4755C11.3977 38.2473 11.7075 38.1191 12.0305 38.1191C12.3535 38.1191 12.6633 38.2473 12.8917 38.4755C13.1201 38.7037 13.2485 39.0132 13.2485 39.3359C13.2499 39.4959 13.2197 39.6546 13.1596 39.8029C13.0994 39.9512 13.0106 40.0862 12.8981 40.2001C12.7856 40.314 12.6517 40.4046 12.504 40.4667C12.3564 40.5287 12.1979 40.561 12.0378 40.5617C11.8764 40.563 11.7164 40.5321 11.5671 40.4709C11.4177 40.4097 11.2821 40.3194 11.168 40.2053C11.054 40.0912 10.9639 39.9556 10.9028 39.8063C10.8418 39.657 10.8111 39.4971 10.8125 39.3359V39.3359Z"
                fill="#4E415C"/>
            <path
                d="M13.5029 41.3324C13.5034 41.0098 13.6319 40.7006 13.8602 40.4725C14.0885 40.2444 14.398 40.1161 14.7209 40.1156C15.0439 40.1156 15.3537 40.2438 15.5821 40.472C15.8105 40.7002 15.9389 41.0097 15.9389 41.3324C15.9391 41.4922 15.9078 41.6506 15.8466 41.7983C15.7855 41.946 15.6958 42.0803 15.5826 42.1933C15.4695 42.3063 15.3351 42.3959 15.1873 42.457C15.0394 42.5181 14.8809 42.5494 14.7209 42.5491C14.3979 42.5491 14.0881 42.4209 13.8597 42.1927C13.6313 41.9646 13.5029 41.6551 13.5029 41.3324V41.3324Z"
                fill="#4E415C"/>
            <path
                d="M16.5088 42.8719C16.5086 42.712 16.5399 42.5535 16.601 42.4057C16.6621 42.2579 16.7518 42.1235 16.8649 42.0104C16.978 41.8972 17.1124 41.8074 17.2602 41.7461C17.4081 41.6849 17.5667 41.6533 17.7268 41.6533C18.05 41.6538 18.3597 41.7824 18.5881 42.0109C18.8165 42.2394 18.9448 42.549 18.9448 42.8719C18.9443 43.1945 18.8158 43.5037 18.5875 43.7318C18.3592 43.9599 18.0497 44.0882 17.7268 44.0887C17.4038 44.0887 17.0939 43.9605 16.8655 43.7323C16.6371 43.5041 16.5088 43.1946 16.5088 42.8719V42.8719Z"
                fill="#4E415C"/>
            <path
                d="M19.7744 43.8983C19.7744 43.5756 19.9027 43.2661 20.1311 43.0379C20.3595 42.8097 20.6693 42.6815 20.9924 42.6815C21.1525 42.6813 21.311 42.7126 21.459 42.7736C21.607 42.8347 21.7415 42.9243 21.8548 43.0373C21.968 43.1503 22.0579 43.2845 22.1193 43.4322C22.1806 43.58 22.2122 43.7384 22.2122 43.8983C22.2122 44.0583 22.1806 44.2166 22.1193 44.3644C22.0579 44.5121 21.968 44.6463 21.8548 44.7593C21.7415 44.8723 21.607 44.9619 21.459 45.023C21.311 45.084 21.1525 45.1153 20.9924 45.1151C20.8324 45.1151 20.674 45.0836 20.5263 45.0225C20.3785 44.9613 20.2442 44.8717 20.1311 44.7587C20.018 44.6457 19.9283 44.5116 19.8671 44.3639C19.8059 44.2163 19.7744 44.0581 19.7744 43.8983V43.8983Z"
                fill="#4E415C"/>
            <path
                d="M23.252 44.3698C23.252 44.0471 23.3803 43.7377 23.6087 43.5095C23.8371 43.2813 24.1469 43.1531 24.4699 43.1531C24.7929 43.1531 25.1027 43.2813 25.3311 43.5095C25.5596 43.7377 25.6879 44.0471 25.6879 44.3698C25.6881 44.5297 25.6568 44.688 25.5957 44.8358C25.5345 44.9835 25.4448 45.1177 25.3317 45.2308C25.2185 45.3438 25.0842 45.4334 24.9363 45.4945C24.7884 45.5555 24.6299 45.5868 24.4699 45.5866C24.3099 45.5868 24.1514 45.5555 24.0035 45.4945C23.8557 45.4334 23.7213 45.3438 23.6082 45.2308C23.495 45.1177 23.4053 44.9835 23.3442 44.8358C23.2831 44.688 23.2517 44.5297 23.252 44.3698Z"
                fill="#4E415C"/>
            <path
                d="M27.71 44.3371C27.71 44.1773 27.7415 44.0191 27.8027 43.8715C27.8639 43.7239 27.9536 43.5897 28.0667 43.4767C28.1798 43.3638 28.314 43.2741 28.4618 43.213C28.6096 43.1518 28.768 43.1204 28.9279 43.1204C29.2508 43.1208 29.5603 43.2492 29.7886 43.4773C30.0169 43.7054 30.1454 44.0146 30.1459 44.3371C30.1459 44.6598 30.0176 44.9693 29.7891 45.1975C29.5607 45.4257 29.2509 45.5539 28.9279 45.5539C28.7679 45.5542 28.6094 45.5229 28.4615 45.4618C28.3137 45.4007 28.1793 45.3111 28.0662 45.1981C27.953 45.085 27.8633 44.9508 27.8022 44.8031C27.7411 44.6553 27.7097 44.497 27.71 44.3371V44.3371Z"
                fill="#4E415C"/>
            <path
                d="M32.2568 44.125C32.2573 43.8021 32.3861 43.4926 32.6148 43.2645C32.8435 43.0363 33.1535 42.9082 33.4767 42.9082C33.7995 42.9087 34.109 43.037 34.3373 43.2651C34.5657 43.4932 34.6941 43.8024 34.6946 44.125C34.6946 44.4479 34.5663 44.7575 34.338 44.986C34.1096 45.2145 33.7999 45.3431 33.4767 45.3436C33.1532 45.3436 32.8429 45.2152 32.6141 44.9867C32.3854 44.7581 32.2568 44.4482 32.2568 44.125V44.125Z"
                fill="#4E415C"/>
            <path
                d="M36.5557 43.7569C36.5561 43.4344 36.6846 43.1252 36.9129 42.8971C37.1412 42.669 37.4507 42.5406 37.7736 42.5402C38.0968 42.5402 38.4068 42.6683 38.6355 42.8964C38.8642 43.1246 38.9929 43.4341 38.9934 43.7569C38.9934 43.9169 38.9618 44.0752 38.9005 44.223C38.8392 44.3707 38.7493 44.505 38.636 44.618C38.5228 44.731 38.3882 44.8206 38.2403 44.8816C38.0923 44.9427 37.9337 44.9739 37.7736 44.9737C37.4506 44.9737 37.1408 44.8455 36.9124 44.6173C36.684 44.3891 36.5557 44.0796 36.5557 43.7569V43.7569Z"
                fill="#4E415C"/>
            <path
                d="M40.6162 43.2455C40.6167 42.9226 40.7454 42.6131 40.9741 42.385C41.2028 42.1568 41.5128 42.0287 41.836 42.0287C42.1589 42.0292 42.4684 42.1575 42.6967 42.3856C42.925 42.6137 43.0535 42.9229 43.054 43.2455C43.054 43.5682 42.9257 43.8777 42.6973 44.1059C42.4688 44.334 42.159 44.4622 41.836 44.4622C41.6759 44.4625 41.5173 44.4312 41.3693 44.3701C41.2214 44.3091 41.0869 44.2195 40.9736 44.1065C40.8603 43.9935 40.7704 43.8593 40.7091 43.7115C40.6478 43.5638 40.6162 43.4054 40.6162 43.2455V43.2455Z"
                fill="#4E415C"/>
            <path
                d="M44.4531 42.6054C44.4536 42.2829 44.5821 41.9737 44.8104 41.7456C45.0387 41.5175 45.3482 41.3892 45.6711 41.3887C45.9941 41.3887 46.3039 41.5168 46.5323 41.745C46.7607 41.9732 46.889 42.2827 46.889 42.6054C46.8893 42.7653 46.858 42.9236 46.7968 43.0714C46.7357 43.2191 46.646 43.3533 46.5328 43.4663C46.4197 43.5794 46.2853 43.669 46.1375 43.7301C45.9896 43.7911 45.8311 43.8224 45.6711 43.8222C45.3481 43.8222 45.0383 43.694 44.8099 43.4658C44.5814 43.2376 44.4531 42.9281 44.4531 42.6054V42.6054Z"
                fill="#4E415C"/>
            <path
                d="M48.0781 41.851C48.0781 41.5278 48.2066 41.2179 48.4354 40.9894C48.6641 40.7608 48.9744 40.6324 49.2979 40.6324C49.6211 40.6329 49.9309 40.7615 50.1593 40.99C50.3876 41.2185 50.5159 41.5282 50.5159 41.851C50.5154 42.1736 50.3869 42.4828 50.1586 42.7109C49.9303 42.939 49.6208 43.0673 49.2979 43.0678C48.9747 43.0678 48.6647 42.9397 48.436 42.7115C48.2073 42.4834 48.0786 42.1739 48.0781 41.851Z"
                fill="#4E415C"/>
            <path
                d="M51.5068 40.9933C51.5066 40.8334 51.5379 40.6751 51.5991 40.5273C51.6602 40.3796 51.7499 40.2454 51.863 40.1323C51.9762 40.0193 52.1105 39.9297 52.2584 39.8686C52.4063 39.8076 52.5648 39.7763 52.7248 39.7765C53.0478 39.7765 53.3577 39.9047 53.5861 40.1329C53.8145 40.361 53.9428 40.6705 53.9428 40.9933C53.9428 41.316 53.8145 41.6254 53.5861 41.8536C53.3577 42.0818 53.0478 42.21 52.7248 42.21C52.5648 42.2103 52.4063 42.179 52.2584 42.1179C52.1105 42.0568 51.9762 41.9672 51.863 41.8542C51.7499 41.7411 51.6602 41.6069 51.5991 41.4592C51.5379 41.3114 51.5066 41.1531 51.5068 40.9933Z"
                fill="#4E415C"/>
            <path
                d="M54.749 40.0486C54.749 39.7259 54.8773 39.4164 55.1058 39.1882C55.3342 38.96 55.644 38.8318 55.967 38.8318C56.29 38.8318 56.5998 38.96 56.8282 39.1882C57.0566 39.4164 57.1849 39.7259 57.1849 40.0486C57.1852 40.2085 57.1539 40.3669 57.0928 40.5148C57.0317 40.6626 56.942 40.7969 56.8289 40.9101C56.7158 41.0233 56.5814 41.1131 56.4335 41.1744C56.2856 41.2356 56.1271 41.2672 55.967 41.2672C55.8069 41.2672 55.6484 41.2356 55.5005 41.1744C55.3526 41.1131 55.2182 41.0233 55.1051 40.9101C54.992 40.7969 54.9023 40.6626 54.8412 40.5148C54.7801 40.3669 54.7488 40.2085 54.749 40.0486V40.0486Z"
                fill="#4E415C"/>
            <path
                d="M57.8193 39.0312C57.8193 38.7085 57.9477 38.399 58.1761 38.1708C58.4045 37.9427 58.7143 37.8145 59.0373 37.8145C59.3602 37.8149 59.6697 37.9433 59.898 38.1714C60.1263 38.3995 60.2548 38.7087 60.2553 39.0312C60.2553 39.354 60.127 39.6634 59.8986 39.8916C59.6702 40.1198 59.3603 40.248 59.0373 40.248C58.8773 40.2482 58.7188 40.2169 58.5709 40.1559C58.423 40.0948 58.2887 40.0052 58.1755 39.8922C58.0624 39.7791 57.9727 39.6449 57.9116 39.4972C57.8504 39.3494 57.8191 39.1911 57.8193 39.0312Z"
                fill="#4E415C"/>
            <path
                d="M60.7314 37.9541C60.7312 37.7942 60.7625 37.6359 60.8237 37.4882C60.8848 37.3404 60.9745 37.2062 61.0877 37.0932C61.2008 36.9801 61.3352 36.8905 61.483 36.8294C61.6309 36.7684 61.7894 36.7371 61.9494 36.7373C62.2724 36.7373 62.5822 36.8655 62.8106 37.0937C63.039 37.3219 63.1674 37.6314 63.1674 37.9541C63.1674 38.2768 63.039 38.5863 62.8106 38.8145C62.5822 39.0427 62.2724 39.1708 61.9494 39.1708C61.7894 39.1711 61.6309 39.1398 61.483 39.0787C61.3352 39.0176 61.2008 38.928 61.0877 38.815C60.9745 38.702 60.8848 38.5677 60.8237 38.42C60.7625 38.2723 60.7312 38.1139 60.7314 37.9541V37.9541Z"
                fill="#4E415C"/>
            <path
                d="M63.4961 36.8317C63.4961 36.6717 63.5277 36.5133 63.589 36.3656C63.6503 36.2178 63.7402 36.0836 63.8535 35.9706C63.9667 35.8576 64.1013 35.768 64.2492 35.707C64.3972 35.6459 64.5558 35.6146 64.7159 35.6149C65.0389 35.6149 65.3487 35.7431 65.5771 35.9713C65.8055 36.1994 65.9338 36.5089 65.9338 36.8317C65.9334 37.1542 65.8049 37.4634 65.5766 37.6915C65.3483 37.9196 65.0387 38.0479 64.7159 38.0484C64.3927 38.0484 64.0827 37.9203 63.854 37.6921C63.6253 37.464 63.4966 37.1545 63.4961 36.8317V36.8317Z"
                fill="#4E415C"/>
            <path
                d="M66.1299 35.6765C66.1299 35.3538 66.2582 35.0443 66.4866 34.8161C66.715 34.5879 67.0248 34.4597 67.3478 34.4597C67.5079 34.4595 67.6663 34.4908 67.8142 34.5518C67.9621 34.6129 68.0965 34.7025 68.2096 34.8156C68.3227 34.9286 68.4125 35.0628 68.4736 35.2106C68.5347 35.3583 68.566 35.5167 68.5658 35.6765C68.5658 35.8363 68.5343 35.9945 68.4731 36.1421C68.4119 36.2898 68.3222 36.4239 68.2091 36.5369C68.096 36.6499 67.9617 36.7395 67.814 36.8007C67.6662 36.8618 67.5078 36.8933 67.3478 36.8933C67.025 36.8928 66.7155 36.7644 66.4872 36.5364C66.2588 36.3083 66.1304 35.9991 66.1299 35.6765Z"
                fill="#4E415C"/>
            <path
                d="M68.6436 34.5033C68.6433 34.3434 68.6747 34.1851 68.7358 34.0374C68.7969 33.8896 68.8866 33.7554 68.9997 33.6424C69.1129 33.5293 69.2473 33.4397 69.3951 33.3786C69.543 33.3176 69.7015 33.2863 69.8615 33.2865C70.0215 33.2863 70.18 33.3176 70.3279 33.3786C70.4758 33.4397 70.6101 33.5293 70.7233 33.6424C70.8364 33.7554 70.9261 33.8896 70.9873 34.0374C71.0484 34.1851 71.0797 34.3434 71.0795 34.5033C71.0797 34.6631 71.0484 34.8215 70.9873 34.9692C70.9261 35.1169 70.8364 35.2512 70.7233 35.3642C70.6101 35.4772 70.4758 35.5668 70.3279 35.6279C70.18 35.689 70.0215 35.7203 69.8615 35.7201C69.7015 35.7203 69.543 35.689 69.3951 35.6279C69.2473 35.5668 69.1129 35.4772 68.9997 35.3642C68.8866 35.2512 68.7969 35.1169 68.7358 34.9692C68.6747 34.8215 68.6433 34.6631 68.6436 34.5033V34.5033Z"
                fill="#4E415C"/>
            <path
                d="M71.0508 33.3264C71.0505 33.1664 71.0819 33.008 71.143 32.8602C71.2041 32.7124 71.2937 32.578 71.4069 32.4648C71.52 32.3516 71.6543 32.2618 71.8022 32.2006C71.9501 32.1393 72.1086 32.1078 72.2687 32.1078C72.4288 32.1078 72.5874 32.1393 72.7353 32.2006C72.8831 32.2618 73.0175 32.3516 73.1306 32.4648C73.2437 32.578 73.3334 32.7124 73.3945 32.8602C73.4556 33.008 73.4869 33.1664 73.4867 33.3264C73.4867 33.4862 73.4552 33.6444 73.394 33.792C73.3328 33.9396 73.2431 34.0738 73.13 34.1868C73.0169 34.2998 72.8826 34.3894 72.7348 34.4505C72.587 34.5117 72.4287 34.5432 72.2687 34.5432C71.9457 34.5432 71.6359 34.415 71.4075 34.1868C71.1791 33.9586 71.0508 33.6491 71.0508 33.3264V33.3264Z"
                fill="#4E415C"/>
            <path
                d="M73.3633 32.1586C73.3633 31.9988 73.3948 31.8405 73.456 31.6929C73.5172 31.5453 73.6069 31.4111 73.72 31.2982C73.8331 31.1852 73.9674 31.0956 74.1151 31.0344C74.2629 30.9733 74.4213 30.9418 74.5812 30.9418C74.7413 30.9415 74.8999 30.9728 75.0479 31.0339C75.1959 31.0949 75.3303 31.1845 75.4436 31.2975C75.5569 31.4105 75.6468 31.5447 75.7081 31.6925C75.7695 31.8402 75.801 31.9986 75.801 32.1586C75.8005 32.4814 75.6718 32.7909 75.4431 33.0191C75.2144 33.2472 74.9044 33.3753 74.5812 33.3753C74.2584 33.3748 73.9488 33.2465 73.7205 33.0184C73.4922 32.7903 73.3638 32.4811 73.3633 32.1586Z"
                fill="#4E415C"/>
            <path
                d="M75.708 30.9508C75.708 30.7908 75.7396 30.6325 75.8009 30.4847C75.8622 30.337 75.9521 30.2028 76.0654 30.0898C76.1787 29.9767 76.3132 29.8871 76.4611 29.8261C76.6091 29.7651 76.7677 29.7338 76.9278 29.734C77.2508 29.734 77.5606 29.8622 77.789 30.0904C78.0174 30.3186 78.1457 30.6281 78.1457 30.9508C78.1453 31.2733 78.0168 31.5826 77.7885 31.8106C77.5602 32.0387 77.2507 32.1671 76.9278 32.1675C76.6046 32.1675 76.2946 32.0394 76.0659 31.8113C75.8372 31.5831 75.7085 31.2737 75.708 30.9508V30.9508Z"
                fill="#4E415C"/>
            <path
                d="M78.7725 29.395C78.7725 29.2352 78.804 29.077 78.8652 28.9294C78.9264 28.7817 79.0161 28.6476 79.1292 28.5346C79.2423 28.4216 79.3766 28.332 79.5244 28.2709C79.6721 28.2097 79.8305 28.1782 79.9905 28.1782C80.1506 28.178 80.3091 28.2093 80.4571 28.2703C80.6051 28.3314 80.7396 28.421 80.8528 28.534C80.9661 28.647 81.056 28.7812 81.1174 28.9289C81.1787 29.0767 81.2102 29.2351 81.2102 29.395C81.2102 29.555 81.1787 29.7133 81.1174 29.8611C81.056 30.0088 80.9661 30.143 80.8528 30.256C80.7396 30.369 80.6051 30.4586 80.4571 30.5197C80.3091 30.5807 80.1506 30.612 79.9905 30.6118C79.6674 30.6118 79.3576 30.4836 79.1292 30.2554C78.9008 30.0272 78.7725 29.7177 78.7725 29.395V29.395Z"
                fill="#4E415C"/>
            <path
                d="M81.8818 27.8825C81.8818 27.5598 82.0102 27.2503 82.2386 27.0222C82.467 26.794 82.7768 26.6658 83.0998 26.6658C83.4228 26.6658 83.7326 26.794 83.961 27.0222C84.1894 27.2503 84.3178 27.5598 84.3178 27.8825C84.318 28.0424 84.2867 28.2007 84.2255 28.3485C84.1644 28.4962 84.0747 28.6304 83.9616 28.7435C83.8484 28.8565 83.7141 28.9461 83.5662 29.0072C83.4183 29.0682 83.2598 29.0996 83.0998 29.0993C82.9398 29.0996 82.7813 29.0682 82.6334 29.0072C82.4855 28.9461 82.3512 28.8565 82.238 28.7435C82.1249 28.6304 82.0352 28.4962 81.9741 28.3485C81.9129 28.2007 81.8816 28.0424 81.8818 27.8825V27.8825Z"
                fill="#4E415C"/>
            <path
                d="M85.04 26.49C85.04 26.1673 85.1684 25.8578 85.3968 25.6296C85.6252 25.4014 85.935 25.2732 86.258 25.2732C86.5809 25.2737 86.8904 25.402 87.1187 25.6301C87.347 25.8582 87.4755 26.1674 87.476 26.49C87.476 26.8127 87.3477 27.1222 87.1193 27.3504C86.8909 27.5786 86.581 27.7067 86.258 27.7067C86.098 27.707 85.9395 27.6757 85.7916 27.6146C85.6437 27.5536 85.5094 27.4639 85.3962 27.3509C85.2831 27.2379 85.1934 27.1036 85.1323 26.9559C85.0711 26.8082 85.0398 26.6498 85.04 26.49V26.49Z"
                fill="#4E415C"/>
            <path
                d="M88.249 25.2859C88.249 24.9631 88.3773 24.6536 88.6057 24.4255C88.8341 24.1973 89.144 24.0691 89.467 24.0691C89.79 24.0691 90.0998 24.1973 90.3282 24.4255C90.5566 24.6536 90.6849 24.9631 90.6849 25.2859C90.6852 25.4457 90.6539 25.604 90.5927 25.7518C90.5316 25.8995 90.4419 26.0337 90.3287 26.1468C90.2156 26.2598 90.0812 26.3494 89.9334 26.4105C89.7855 26.4716 89.627 26.5029 89.467 26.5026C89.307 26.5029 89.1485 26.4716 89.0006 26.4105C88.8527 26.3494 88.7183 26.2598 88.6052 26.1468C88.492 26.0337 88.4024 25.8995 88.3412 25.7518C88.2801 25.604 88.2488 25.4457 88.249 25.2859V25.2859Z"
                fill="#4E415C"/>
            <path
                d="M91.5146 24.341C91.5144 24.1811 91.5457 24.0227 91.6068 23.8748C91.6679 23.727 91.7576 23.5926 91.8707 23.4795C91.9838 23.3663 92.1182 23.2765 92.2661 23.2152C92.414 23.154 92.5725 23.1224 92.7326 23.1224C93.0558 23.1229 93.3656 23.2515 93.5939 23.48C93.8223 23.7085 93.9506 24.0182 93.9506 24.341C93.9501 24.6636 93.8216 24.9728 93.5933 25.2009C93.365 25.429 93.0555 25.5573 92.7326 25.5578C92.5727 25.5578 92.4143 25.5263 92.2665 25.4652C92.1187 25.404 91.9845 25.3144 91.8714 25.2014C91.7583 25.0884 91.6686 24.9543 91.6074 24.8067C91.5461 24.659 91.5146 24.5008 91.5146 24.341V24.341Z"
                fill="#4E415C"/>
            <path
                d="M94.8379 23.7209C94.8384 23.3984 94.9669 23.0891 95.1952 22.861C95.4235 22.633 95.733 22.5046 96.0559 22.5042C96.2158 22.5042 96.3742 22.5356 96.522 22.5968C96.6697 22.6579 96.804 22.7475 96.9171 22.8605C97.0302 22.9735 97.1199 23.1076 97.1811 23.2553C97.2424 23.4029 97.2739 23.5611 97.2739 23.7209C97.2741 23.8809 97.2427 24.0393 97.1816 24.1871C97.1205 24.335 97.0309 24.4693 96.9177 24.5825C96.8046 24.6956 96.6703 24.7854 96.5224 24.8467C96.3745 24.908 96.216 24.9395 96.0559 24.9395C95.7327 24.939 95.4229 24.8104 95.1945 24.5819C94.9662 24.3535 94.8379 24.0438 94.8379 23.7209V23.7209Z"
                fill="#4E415C"/>
            <path
                d="M98.2256 23.4996C98.2261 23.1771 98.3545 22.8678 98.5829 22.6398C98.8112 22.4117 99.1207 22.2833 99.4435 22.2828C99.7667 22.2828 100.077 22.411 100.305 22.6391C100.534 22.8673 100.663 23.1768 100.663 23.4996C100.663 23.6596 100.632 23.8179 100.57 23.9657C100.509 24.1134 100.419 24.2476 100.306 24.3606C100.193 24.4737 100.058 24.5632 99.9102 24.6243C99.7622 24.6853 99.6036 24.7166 99.4435 24.7164C99.1205 24.7164 98.8107 24.5882 98.5823 24.36C98.3539 24.1318 98.2256 23.8223 98.2256 23.4996V23.4996Z"
                fill="#4E415C"/>
            <path
                d="M103.932 45.3459C109.364 41.9626 111.021 34.8213 107.635 29.3952C104.248 23.9692 97.0997 22.3133 91.6683 25.6965C86.237 29.0798 84.5794 36.2212 87.966 41.6472C91.3526 47.0732 98.5009 48.7292 103.932 45.3459Z"
                fill="#E8D4C1"/>
            <g style="mix-blend-mode:screen">
              <path
                  d="M87.1367 33.0416C88.5961 34.3999 90.5168 35.1548 92.5114 35.1542C94.6113 35.1542 96.6252 34.3208 98.1101 32.8374C99.5949 31.354 100.429 29.3421 100.429 27.2443C100.429 25.8087 100.034 24.4007 99.2892 23.1732C99.0532 23.1587 98.8172 23.137 98.5758 23.137C95.7958 23.1386 93.1095 24.1407 91.0087 25.9597C88.908 27.7786 87.5334 30.2928 87.1367 33.0416Z"
                  fill="#232830"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M104.994 25.0809C106.219 26.9534 106.87 29.142 106.869 31.3788C106.869 34.4443 105.65 37.3842 103.48 39.5518C101.311 41.7195 98.3677 42.9372 95.2992 42.9372C93.0143 42.9402 90.7801 42.2642 88.8809 40.9951C89.9297 42.6097 91.3661 43.9366 93.0593 44.8551C94.7526 45.7736 96.6489 46.2544 98.5756 46.2539C101.644 46.2539 104.587 45.0361 106.757 42.8685C108.926 40.7009 110.145 37.7609 110.145 34.6954C110.146 32.7929 109.676 30.9196 108.778 29.2422C107.879 27.5648 106.579 26.1353 104.994 25.0809Z"
                  fill="#FAFAFA"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M87.5014 35.2376C87.5014 32.1716 88.7205 29.2312 90.8906 27.0633C93.0607 24.8953 96.004 23.6773 99.073 23.6773C102.128 23.674 105.059 24.8819 107.223 27.0357C106.141 25.81 104.811 24.8283 103.32 24.1561C101.829 23.4839 100.211 23.1365 98.5757 23.137C95.507 23.1374 92.5642 24.3556 90.3945 26.5235C88.2248 28.6914 87.0059 31.6316 87.0059 34.6972C87.0039 36.222 87.3051 37.732 87.8918 39.1396C88.4785 40.5473 89.3392 41.8245 90.4238 42.8973C88.5414 40.7891 87.5012 38.0627 87.5014 35.2376Z"
                  fill="#786A56"/>
            </g>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M105.803 25.6793C106.975 26.7579 107.911 28.0677 108.551 29.526C109.191 30.9842 109.521 32.5592 109.52 34.1514C109.52 37.2169 108.301 40.1568 106.132 42.3244C103.962 44.4921 101.019 45.7098 97.9506 45.7098C95.3218 45.7129 92.7711 44.817 90.7227 43.1711C92.8556 45.1539 95.6615 46.2555 98.575 46.2538C101.644 46.2538 104.586 45.0361 106.756 42.8684C108.926 40.7008 110.145 37.7609 110.145 34.6954C110.146 32.9633 109.756 31.2532 109.004 29.6923C108.252 28.1314 107.158 26.7598 105.803 25.6793V25.6793Z"
                  fill="#F2F0ED"/>
            </g>
            <path
                d="M92.4912 34.6973C92.4912 33.0852 93.1323 31.5391 94.2733 30.3992C95.4144 29.2593 96.9619 28.6189 98.5756 28.6189C100.189 28.6189 101.737 29.2593 102.878 30.3992C104.019 31.5391 104.66 33.0852 104.66 34.6973C104.66 36.3094 104.019 37.8555 102.878 38.9954C101.737 40.1353 100.189 40.7758 98.5756 40.7758C96.9619 40.7758 95.4144 40.1353 94.2733 38.9954C93.1323 37.8555 92.4912 36.3094 92.4912 34.6973V34.6973Z"
                fill="#FAECDE"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M103.122 30.668C103.966 31.7359 104.426 33.0571 104.425 34.418C104.425 36.0301 103.784 37.5762 102.643 38.7161C101.502 39.8561 99.9546 40.4965 98.3409 40.4965C97.4809 40.4968 96.6306 40.3142 95.8466 39.9609C95.0627 39.6076 94.3629 39.0916 93.7939 38.4473C94.3615 39.1728 95.087 39.7595 95.9155 40.1629C96.7439 40.5664 97.6535 40.776 98.5751 40.7757C100.189 40.7757 101.736 40.1353 102.877 38.9954C104.018 37.8555 104.659 36.3094 104.659 34.6973C104.66 33.2112 104.112 31.7769 103.122 30.668V30.668Z"
                  fill="#F2F0ED"/>
            </g>
            <g style="mix-blend-mode:screen">
              <path
                  d="M92.7835 35.1216C92.7835 33.5095 93.4245 31.9634 94.5655 30.8235C95.7066 29.6836 97.2542 29.0432 98.8679 29.0432C99.8159 29.0431 100.751 29.265 101.597 29.6911C102.444 30.1172 103.179 30.7356 103.743 31.4967C103.2 30.6159 102.439 29.8887 101.535 29.3844C100.63 28.88 99.6115 28.6152 98.5756 28.6152C96.9619 28.6152 95.4144 29.2556 94.2733 30.3956C93.1323 31.5355 92.4912 33.0816 92.4912 34.6937C92.4912 36.0013 92.9153 37.2738 93.7001 38.3204C93.101 37.3608 92.7834 36.2526 92.7835 35.1216V35.1216Z"
                  fill="#786A56"/>
            </g>
            <path
                d="M96.2607 34.6973C96.2605 34.394 96.3201 34.0936 96.4361 33.8134C96.5521 33.5331 96.7223 33.2784 96.9369 33.0638C97.1515 32.8493 97.4063 32.6791 97.6868 32.563C97.9672 32.4469 98.2678 32.3871 98.5714 32.3871C98.875 32.3871 99.1756 32.4469 99.4561 32.563C99.7366 32.6791 99.9914 32.8493 100.206 33.0638C100.421 33.2784 100.591 33.5331 100.707 33.8134C100.823 34.0936 100.882 34.394 100.882 34.6973C100.882 35.0005 100.822 35.3007 100.706 35.5807C100.59 35.8608 100.42 36.1153 100.205 36.3296C99.9908 36.544 99.736 36.714 99.4557 36.83C99.1754 36.946 98.8749 37.0057 98.5714 37.0057C97.9586 37.0057 97.3709 36.7625 96.9375 36.3296C96.5042 35.8967 96.2607 35.3096 96.2607 34.6973Z"
                fill="#4E415C"/>
            <g style="mix-blend-mode:multiply">
              <path
                  d="M100.129 32.9982C100.323 33.3375 100.426 33.7209 100.429 34.1116C100.429 34.7243 100.185 35.3119 99.7515 35.7452C99.3178 36.1784 98.7296 36.4218 98.1163 36.4218C97.54 36.42 96.9854 36.2019 96.5625 35.8107C96.7605 36.1715 97.0516 36.4726 97.4057 36.6828C97.7597 36.893 98.1637 37.0045 98.5755 37.0057C99.1884 37.0057 99.7761 36.7625 100.209 36.3296C100.643 35.8967 100.886 35.3095 100.886 34.6973C100.886 34.3769 100.819 34.0602 100.688 33.7675C100.558 33.4748 100.367 33.2128 100.129 32.9982Z"
                  fill="#DBD3E6"/>
            </g>
          </svg>

          <div class="blood">
            <div
                v-bind:class="[{ active:  key.time >= time },  {newImg: key.result}]"
                v-for="(key, value) in bloodArray" :style="cssVars" class="item">

              <img ref="blood" class="limpha" src="../assets/img/game-clopidogrel/blood.png"
                   alt="">
              <img v-show="key.result" class="protect" src="../assets/img/game-clopidogrel/protect.png" alt="">
            </div>

          </div>

          <div class="error-message" v-show="error">
            промах!
          </div>

          <div ref="zone" class="hit-zone">
            <svg v-show="!error" viewBox="0 0 102 102" fill="none" class="green"
                 xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_267_18456)">
                <path d="M25.1644 10H10V25.726M76.8356 10H92V25.726M76.8356 92H92V77.3973M25.1644 92H10V77.3973"
                      stroke="#00A03B" stroke-width="4"/>
              </g>
              <defs>
                <filter id="filter0_d_267_18456" x="0" y="0" width="102" height="102" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="4"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_267_18456"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_267_18456" result="shape"/>
                </filter>
              </defs>
            </svg>

            <svg v-show="error" class="red" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_307_8740)">
                <path
                    d="M49.5753 36.6847H37V49.7258M92.4247 36.6847H105V49.7258M92.4247 104.685H105V92.5751M49.5753 104.685H37V92.5751"
                    stroke="#C40000" stroke-width="4"/>
              </g>
              <g opacity="0.4" filter="url(#filter1_d_307_8740)">
                <path d="M40.7534 23H23V41.411M101.247 23H119V41.411M101.247 119H119V101.904M40.7534 119H23V101.904"
                      stroke="#C40000" stroke-width="4"/>
              </g>
              <g opacity="0.1" filter="url(#filter2_d_307_8740)">
                <path d="M32.5616 10H10V33.3973M109.438 10H132V33.3973M109.438 132H132V110.274M32.5616 132H10V110.274"
                      stroke="#C40000" stroke-width="4"/>
              </g>
              <defs>
                <filter id="filter0_d_307_8740" x="27" y="26.6847" width="88" height="88" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="4"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.768559 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_307_8740"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_307_8740" result="shape"/>
                </filter>
                <filter id="filter1_d_307_8740" x="13" y="13" width="116" height="116" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="4"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.768559 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_307_8740"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_307_8740" result="shape"/>
                </filter>
                <filter id="filter2_d_307_8740" x="0" y="0" width="142" height="142" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset/>
                  <feGaussianBlur stdDeviation="4"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.768559 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_307_8740"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_307_8740" result="shape"/>
                </filter>
              </defs>
            </svg>

          </div>
        </div>

        <div class="game__btn-wrapper" ref="btnWrapper">

          <div class="game__grey-text">активуйте Клопідогрел коли тромбоцит буде у прицілі</div>

          <div class="game__btn hover_type6" @click="scan()">
            <p>Активувати <span>Клопідогрел</span></p>
          </div>

        </div>

      </div>

    </div>

    <div class="win" v-show="result.win">

      <div class="win__green-block stage1">

        <p class="win__text desk white">
          <span>Клопідогрел-Тева!</span><br>Ось наше призначення.
          Дякую за допомогу!<br>
        </p>

        <p class="win__text mob white">
          <span>Клопідогрел-Тева!</span><br>Ось наше призначення.<br>
          Дякую за допомогу!<br>
        </p>


        <div class="win__arrow-down"></div>

      </div>

      <img class="win__img-circle" src="../assets/img/game-clopidogrel/win.png" alt="">

      <div class="win__text-center">Сьогодні тромбоцитам не склеїлись!<br>
        Чудова робота та прекрасна рекомендація!
      </div>

      <div class="win__info-text-btn">
        <div class="win__info-text">
          <p><span>Клопідогрел-Тева </span> — для вторинної профілактики проявів атеротромбозу у
            дорослих та профілактики атеротромботичних та тромбоемболічних подій при фібриляції передсердь*<sup>1</sup>
          </p>
        </div>
        <div class="win__info-btn" @click="winPopup = !winPopup">
          <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="23" r="23" fill="#35786E"/>
            <path
                d="M21 14.0109C21 12.6703 21.6667 12 23 12C24.3333 12 25 12.6703 25 14.0109C25 14.6497 24.8313 15.1491 24.494 15.509C24.1647 15.8599 23.6667 16.0353 23 16.0353C21.6667 16.0353 21 15.3605 21 14.0109ZM24.8313 33H21.1566V17.9113H24.8313V33Z"
                fill="white"/>
          </svg>

        </div>
      </div>

      <div class="win__popup" v-show="winPopup">
        <div class="win__popup--close-btn" @click="winPopup = !winPopup">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="12.5" stroke="#00A03B"/>
            <path d="M19.3643 6.63599L6.63634 19.3639" stroke="#00A03B"/>
            <path d="M19.3643 19.3639L6.63634 6.63597" stroke="#00A03B"/>
          </svg>
        </div>
        <div class="win__popup--text ">*З повним переліком показань детальніше можна ознайомитись в інструкції для
          медичного застосування лікарського засобу.
        </div>
        <div class="win__popup--text">1. Інструкція для медичного застосування лікарського засобу КЛОПІДОГРЕЛ-ТЕВА,
          таблетки, вкриті плівковою оболонкою, по 75мг. РП №UA/11636/01/01. Наказ МОЗ України №347 від 24.02.2021.
        </div>
        <div class="win__popup--text">
          Інформація про лікарський засіб. РП №UA/11636/01/01. Для професійної діяльності медичних та фармацевтичних
          працівників.
        </div>
        <div class="win__popup--text">Клопідогрел-Тева (1 таблетка містить клопідогрелю бісульфату 97,86мг, що
          еквівалентно клопідогрелю 75мг). Характеристики: рожеві круглі двоопуклі таблетки, вкриті плівковою
          оболонкою, з гравіюванням «І» з одного боку. Лікувальні властивості: інгібітор агрегації тромбоцитів, крім
          гепарину. Найбільш частими побічними реакціями є: гематома, носова кровотеча, шлунково-кишкова кровотеча,
          діарея, абдомінальний біль, диспепсія, підшкірний крововилив/утворення синців.
        </div>
        <div class="win__popup--text">Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9;
          0-800-502-284
        </div>
        <div class="win__popup--text">CBG-UA-00825. Термін дії матеріалу – 23.08.2024.</div>

      </div>

    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">

        <p class="lose__text desk white">
          <span>Тромбоцити почали склеюватись!</span><br>Змініть їх, щоб запобігти агрегації, а в цьому Вам
          допоможе клопідогрел<br>
        </p>

        <div class="lose__arrow-down"></div>

      </div>

      <img class="lose__img-circle" src="../assets/img/game-clopidogrel/lose.png" alt="">

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>

    </div>

    <div v-show="result.lose " class="notes-wrapper">


      <span>Інформація про лікарський засіб. РП №UA/11636/01/01. Для професійної діяльності медичних та фармацевтичних працівників.</span>

      <span>Клопідогрел-Тева (1 таблетка містить клопідогрелю бісульфату 97,86 мг, що еквівалентно клопідогрелю 75 мг). Характеристики: рожеві круглі двоопуклі таблетки, вкриті плівковою оболонкою, з гравіюванням «І» з одного боку. Лікувальні властивості: інгібітор агрегації тромбоцитів, крім гепарину. Найбільш частими побічними реакціями є: гематома, носова кровотеча, шлунково-кишкова кровотеча, діарея, абдомінальний біль, диспепсія, підшкірний крововилив/утворення синців.</span>

      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284</span>

      <span>CBG-UA-00825. Термін дії матеріалу – 23.08.2024.</span>
    </div>

  </div>
</template>

<script>
import Timer from "./Timer.vue"

export default {
  name: "Clopidogrel-game",

  components: {
    'timer': Timer
  },

  data: () => ({
    minutes: 0,
    secondes: 0,
    time: 30,
    timer: null,
    hp: 3,
    winPopup: false,

    hpTrigger: false,

    light: false,

    gameHeight: '0px',

    bloodArray: [
      {
        time: 29,
        result: null
      }, {
        time: 27,
        result: null
      }, {
        time: 26,
        result: null
      }, {
        time: 24,
        result: null
      }, {
        time: 23,
        result: null
      }, {
        time: 22,
        result: null
      }, {
        time: 20,
        result: null
      }, {
        time: 18,
        result: null
      }, {
        time: 17,
        result: null
      }, {
        time: 15,
        result: null
      }, {
        time: 14,
        result: null
      }, {
        time: 13,
        result: null
      }, {
        time: 11,
        result: null
      }, {
        time: 9,
        result: null
      }, {
        time: 8,
        result: null
      }, {
        time: 7,
        result: null
      }, {
        time: 6,
        result: null
      }
    ],

    widthBox: null,

    error: false,

    result: {
      win: false,
      lose: false
    }
  }),
  watch: {
    hpTrigger: function (val) {
      if (val) {
        this.hp = this.hp - 1
      }
    },

    time: function (val) {
      this.bloodArray.forEach((el) => {
        if (el.result === null && val < el.time - 4) {
          el.result = false;
          this.hpTrigger = true;
        }
      })

      if (val === 0) {
        if (this.hp > 0) {
          this.result.win = true
          window.scrollTo(0, 0)
        }
      }
    },

    hp: function (val) {
      this.hpTrigger = false;

      if (val === 0) {

        setTimeout(() => {
          this.result.lose = true
          window.scrollTo(0, 0)
        }, 1000)
      }
    },
  },

  computed: {
    cssVars() {
      return {
        '--width': this.widthBox
      }
    },
    heightContent() {
      return {
        '--height': this.gameHeight
      }
    },
    prettyTime() {
      let time = this.time / 60
      let minutes = parseInt(time)
      let secondes = Math.round((time - minutes) * 60)
      return minutes + ":" + secondes
    }
  },

  mounted() {
    this.widthBox = "-" + this.$refs.wrapper.getBoundingClientRect().width + 'px';
    this.gameHeight = (this.$refs.gameBare.getBoundingClientRect().height + this.$refs.btnWrapper.getBoundingClientRect().height) + "px"
    this.start();
  },

  methods: {
    start() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--
          } else {
            clearInterval(this.timer)
            // this.reset()
          }
        }, 1000)
      }
    },

    reset() {
      this.time = 30
      this.result.lose = false
      this.result.win = false
      this.hp = 3
      this.bloodArray.forEach((el) => {
        el.result = null
      })
      window.scrollTo(0, 0)

      this.start();
    },

    scan() {
      this.light = true

      setTimeout(() => {
        this.light = false
      }, 200)

      let checkMiss = this.$refs.blood.some(el => el.getBoundingClientRect().left + el.getBoundingClientRect().width / 3 >= this.$refs.zone.getBoundingClientRect().left
          && el.getBoundingClientRect().left < this.$refs.zone.getBoundingClientRect().left + this.$refs.zone.getBoundingClientRect().width / 3)

      if (checkMiss) {
        this.$refs.blood.forEach((el, index) => {
          if (el.getBoundingClientRect().left + el.getBoundingClientRect().width / 3 >= this.$refs.zone.getBoundingClientRect().left
              && el.getBoundingClientRect().left < this.$refs.zone.getBoundingClientRect().left + this.$refs.zone.getBoundingClientRect().width) {
            this.bloodArray[index].result = true
          }
        })
        // if (this.bloodArray[this.bloodArray.length - 1].result !== null) {
        //   setTimeout(() => {
        //     this.result.win = true
        //   }, 3000)
        //   window.scrollTo(0, 0)
        //
        // }
      } else {

        this.error = true

        setTimeout(() => {
          this.error = false
        }, 300)

        this.hpTrigger = true
      }
    },
  },
}
</script>

<style scoped lang="scss">

@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.clopidogrel-game {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 40px;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));
  height: 100%;
  margin: 0 auto;

  .game-bar {
    display: flex;
    justify-content: space-between;

    max-width: 650px;
    padding: 0 20px;
    margin: 0 auto;

    &__timer-section {

      display: flex;
      align-items: center;

      svg {
        max-width: 28px;
        width: 100%;
        display: block;
        margin-right: 10px;

        &.red {
          circle {
            stroke: #C40000
          }

          path {
            stroke: #C40000
          }
        }
      }
    }

    &__health-wrapper {
      display: flex;
      align-items: center;

      svg {
        display: block;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }

        path {
          fill: $grey;
        }

        &.active {
          path {
            fill: #C40000;
          }
        }
      }
    }
  }

  .game {
    max-width: 650px;
    padding: 0 20px;

    margin: 0 auto;
    margin-bottom: 20px;

    width: 100%;

    &__action {
      background: #FFFFFF;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      margin-top: 10px;

      width: 100%;
      height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - (var(--height)) - 140px);
      overflow: hidden;
      position: relative;

      display: flex;
      justify-content: center;

      .hit-zone {
        position: absolute;
        margin: 0 auto;

        left: 0;
        right: 0;
        bottom: 68px;
        width: 122px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .green {
          max-width: 82px;
          width: 100%;
          height: auto;
          display: block;

        }

        .red {
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translate(-50%, 0);
          max-width: 122px;
          width: 100%;
          height: auto;
          display: block;
        }
      }

      .slider {
        position: absolute;
        bottom: 31px;
        right: 0;
        max-height: 51px;
        height: 100%;
        width: auto;
        object-fit: contain;
      }

      .top-img {
        position: absolute;

        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);

        height: 100%;

        max-height: 25vh;
        width: auto;
        display: block;

      }

      .light {
        position: absolute;

        max-height: 35vh;
        height: 100%;
        width: auto;

        top: calc(19vh);
        left: 0;
        right: 0;

        margin: 0 auto;


        &.light1 {
          transform: translate(7px, 1px);
        }

        &.light2 {
          transform: translate(26px, -49px);
        }

        &.light3 {
          transform: translate(17px, -9px);

        }
      }

      .blood {
        position: absolute;
        bottom: 83px;
        width: 100%;
        display: flex;

        .item {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 130px;

          display: flex;
          justify-content: center;


          transform: translateX(var(--width));

          .limpha {
            width: 52px;
            height: auto;
            display: block;
            object-fit: contain;
          }

          .protect {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: auto;
            display: block;
          }

          &.active {
            transform: translateX(130px);
            transition: transform 6s linear;
          }

          &.hide {
            display: none;
          }
        }
      }

      .error-message {
        color: #C40000;
        font-family: $EB;

        font-size: 24px;
        line-height: 120%;

        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 10px;
      }


    }

    &__btn-wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }

    &__grey-text {
      text-align: center;
      color: $grey;
      font-family: $M;
      opacity: .4;
    }

    &__btn {
      background: #00A03B;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 40px;
      margin-top: 10px;
      cursor: pointer;

      user-select: none;

      p {
        text-align: center;
        font-family: $EB;
        font-size: 24px;
        line-height: 26px;
        color: white;
        text-transform: uppercase;
        padding: 24px 15px;

        span {
          font-family: $M;
        }
      }
    }
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    padding: 0 20px 30px 20px;

    margin: 0 auto;

    &__red-block {
      background: #C40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #C40000;
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 50px auto 0 auto;
      border-radius: 50%;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    }

    &__repeat {
      margin: 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      background: white;
      margin-top: 48px;
      padding: 14px 15px;

      max-width: 230px;
      width: 100%;

      cursor: pointer;


      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);

    position: relative;
    padding: 0 20px 0 20px;

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;

    &__green-block {
      background: $green;
      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      &.mob {
        display: none;
      }

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $green;
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto 0 auto;

      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;

    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 30px;
      color: $green;
      font-size: 20px;
      line-height: 120%;
      font-family: $B;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {

      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }


      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100% - 20px);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;

      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &.active {
      color: white;
    }
  }

}

@media screen and (max-height: 594px) {
  .clopidogrel-game {

    .game {

      &__action {

        .blood {


          .item {
            .limpha {
              width: 32px;
            }

            .protect {
              width: 60px;
            }

            &.active {
              transform: translateX(110px);
              transition: transform 6s linear;
            }

            &.hide {
              display: none;
            }
          }
        }

        .hit-zone {

          .green {
            max-width: 52px !important;
          }

          .red {
            max-width: 100px !important;

          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .clopidogrel-game {
    padding-top: 0;

    .lose {
      padding-top: 20px;
      padding-bottom: 0;

      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;


        top: 22px;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {

      }

      &__img-circle {
        max-width: 100%;
      }

      &__repeat {

        p {
        }
      }
    }

    .win {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;


      }

      &__text {
        font-size: 16px;
        line-height: 120%;

        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 30px;
      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      &__info-text-btn {
        margin-bottom: 0;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {

          }
        }

        span {
        }
      }

      &__info-btn {

      }

      &__popup {
        padding: 18px 20px 43px 20px;
        bottom: 10px;
      }

      &__popup--close-btn {
        margin-bottom: 14px;

        svg {

        }
      }

      &__popup--text {
        font-size: 8px;
        line-height: 8px;
      }
    }

    .notes-wrapper {


    }

    .game-bar {
      margin-top: 13px;

      &__timer-section {

        svg {
          max-width: 48px;

        }
      }

      &__health-wrapper {

        svg {

          &:last-child {
          }

          path {
          }

        }
      }
    }

    .game {

      &__action {
        height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - (var(--height)) - 80px);

        .hit-zone {

          svg {
          }

        }

        .slider {
        }

        .top-img {
        }

        .blood {
          position: absolute;
          bottom: 83px;
          width: 100%;
          display: flex;

          .item {

            &.active {
            }

            &.hide {
              display: none;
            }
          }
        }
      }

      &__btn-wrapper {
      }

      &__grey-text {
        font-size: 14px;
        line-height: 120%;
      }

      &__btn {
        max-width: 230px;
        width: 100%;
        margin: 20px auto 0 auto;

        p {
          font-size: 24px;
          line-height: 26px;

          padding: 10px;


          span {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 434px) {
  .clopidogrel-game {

    .lose {
      &__img-circle {
        margin-top: 60px;
      }
    }
  }
}

</style>