<template>
  <div class="valsartan-game">
    <div class="game-stage" v-show="!result.win && !result.lose">
      <div class="game-bar">
        <div class="game-bar__timer-section">
          <svg
              :class="[
              { red: time <= 10 },
              {
           green: time > 60,
              yellow: time > 30 && time <= 60,
              red: time <= 30,
              },
            ]"
              viewBox="0 0 28 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle
                cx="14"
                cy="18.3333"
                r="13"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path d="M17.7913 1H10.208" stroke="#00A03B" stroke-width="2"/>
            <path
                d="M14 31.3333L14 28.0833"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M14 8.58331L14 5.33331"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M1 18.3333L4.25 18.3333"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M23.75 18.3333L27 18.3333"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M4.80737 9.14093L7.10547 11.439"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M20.8943 25.2277L23.1924 27.5258"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M23.1926 9.14093L20.8945 11.439"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M7.10571 25.2277L4.80762 27.5258"
                stroke="#00A03B"
                stroke-width="2"
            />
            <path
                d="M14.0003 18.7246L18.6045 10.75"
                stroke="#00A03B"
                stroke-width="2"
            />
          </svg>

          <timer
              :class="[
              { red: time <= 5 },
              {
          green: time > 60,
              yellow: time > 30 && time <= 60,
              red: time <= 30,
              },
            ]"
              :time="prettyTime"
          ></timer>
        </div>

        <div ref="timerLine" class="game-bar__timer-line">
          <div class="game-bar__timer-line--section .green">Норма</div>
          <div class="game-bar__timer-line--section"></div>
          <div class="game-bar__timer-line--section .red no-border">Дуже високий</div>

          <div
              class="game-bar__timer-line--progress"
              :style="{
              width: 100 - time / 0.9 + '%',
            }"
              :class="{
              green: time > 60,
              yellow: time > 30 && time <= 60,
              red: time <= 30,
            }"
          >
            <!-- <div class="game-bar__upper" :style="cssVars">
              <div class="game-bar__timer-line--section no-border">Норма</div>
              <div class="game-bar__timer-line--section no-border"></div>
              <div class="game-bar__timer-line--section no-border">
                Дуже високий
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="game">
        <div class="game__map" :style="heightContent">
          <div v-if="showOverlay" class="game__map--overlay">
            <e-win v-if="showWin"></e-win>

            <div class="msg" v-else>
              <span>
                Завдання – відновити шлях для протоку та знизити тиск!
              </span>
              <div class="msg--text">{{ msg }}</div>
            </div>
          </div>

          <div
              v-for="item in items"
              :key="item.id"
              :style="getStyle(item)"
              :data-id="item.id"
              class="game__map--item"
              @click="itemClick(item)"
          >
            <v-component :is="item.element"></v-component>
          </div>
        </div>
      </div>

      <div class="tip">
        торкайтесь частин труби щоб повернути їх у правильну сторону та з’єднати
        трубопровід
      </div>
    </div>

    <div class="win" v-show="result.win">
      <div class="win__green-block stage1">
        <p class="win__text blue white">
          <span>Валсартан-Тева!</span><br/>
          Діюча речовина – валсартан 80 мг, як зазначено у рецепті. <br/>
          Це відомий бренд<br/>
        </p>

        <div class="win__arrow-down blue"></div>
      </div>

      <img
          class="win__img-circle"
          src="../../assets/img/game-valsartan/win.png"
          alt=""
      />

      <div class="win__text-center">
        І як Вам вдається завжди все упорядковувати? Тримайте рекомендацію, що
        задовольнить Ваших відвідувачів!
      </div>

      <div class="win__info-text-btn">
        <div class="win__info-text">
          <p>
            <span>Валсартан-Тева</span> — для лікування артеріальної гіпертензії
            у дорослих та дітей віком від 6 років*<sup>1</sup>
          </p>
        </div>
        <div class="win__info-btn" @click="winPopup = !winPopup">
          <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23" cy="23" r="23" fill="#35786E"/>
            <path
                d="M21 14.0109C21 12.6703 21.6667 12 23 12C24.3333 12 25 12.6703 25 14.0109C25 14.6497 24.8313 15.1491 24.494 15.509C24.1647 15.8599 23.6667 16.0353 23 16.0353C21.6667 16.0353 21 15.3605 21 14.0109ZM24.8313 33H21.1566V17.9113H24.8313V33Z"
                fill="white"
            />
          </svg>
        </div>
      </div>

      <div class="win__popup" v-show="winPopup">
        <div class="win__popup--close-btn" @click="winPopup = !winPopup">
          <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13" cy="13" r="12.5" stroke="#00A03B"/>
            <path d="M19.3643 6.63599L6.63634 19.3639" stroke="#00A03B"/>
            <path d="M19.3643 19.3639L6.63634 6.63597" stroke="#00A03B"/>
          </svg>
        </div>

        <div class="win__popup--text">
          *З повним переліком показань, протипоказань та побічних реакцій
          детальніше можна ознайомитись в інструкції для медичного застосування
          лікарського засобу.
        </div>
        <div class="win__popup--text">
          1. Інструкція для медичного застосування лікарського засобу
          ВАЛСАРТАН-ТЕВА, таблетки, вкриті плівковою оболонкою, по 40 мг.
          РП №UA/5463/01/01. Наказ МОЗ України №2852 від 23.12.2021.
        </div>
        <div class="win__popup--text">
          Інформація про лікарський засіб. РП №UA/5463/01/02, №UA/5463/01/03.
          Для професійної діяльності медичних та фармацевтичних працівників.
        </div>
        <div class="win__popup--text">
          Валсартан-Тева (1 таблетка містить валсартану 80 мг або 160 мг).
          Характеристики: таблетки 80 мг: рожеві, круглі, двоопуклі таблетки,
          вкриті плівковою оболонкою, з лінією розлому з двох сторін, логотипом
          «V» з однієї сторони і лініями розлому по боках; таблетки 160 мг:
          жовті, овальні, двоопуклі таблетки, вкриті плівковою оболонкою, з
          логотипом «V» з однієї сторони і лінією розлому з іншої сторони та по
          боках. Лікувальні властивості: антагоністом рецепторів ангіотензину
          ІІ. Використовується для лікування артеріальної гіпертензії та
          постінфарктному періоді. Найбільш частими побічними реакціями є:
          вірусні інфекції, запаморочення, постуральне запаморочення,
          ортостатична гіпотензія, підвищення рівня креатиніну сироватки крові,
          підвищення рівня сечовини крові.
        </div>
        <div class="win__popup--text">
          Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
          поверх 9; 0-800-502-284
        </div>
        <div class="win__popup--text">
          CBG-UA-00828. Термін дії матеріалу – 23.08.2024.
        </div>
      </div>
    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">
        <p class="lose__text desk white">
          <span>Майже вдалось!</span>
          <br/>
          Завдання не найпростіше, проте хтось має все упорядкувати.<br/>Спробуєте
          ще раз?
        </p>

        <div class="lose__arrow-down"></div>
      </div>

      <img
          class="lose__img-circle"
          src="../../assets/img/game-atorvastatin/lose.png"
          alt=""
      />

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>
    </div>

    <div v-show="result.lose" class="notes-wrapper">
      <span>
        Інформація про лікарський засіб. РП №UA/5463/01/02, №UA/5463/01/03. Для
        професійної діяльності медичних та фармацевтичних працівників.
      </span>

      <span>
        Валсартан-Тева (1 таблетка містить валсартану 80 мг або 160 мг).
        Характеристики: таблетки 80 мг: рожеві, круглі, двоопуклі таблетки,
        вкриті плівковою оболонкою, з лінією розлому з двох сторін, логотипом
        «V» з однієї сторони і лініями розлому по боках; таблетки 160 мг: жовті,
        овальні, двоопуклі таблетки, вкриті плівковою оболонкою, з логотипом «V»
        з однієї сторони і лінією розлому з іншої сторони та по боках.
        Лікувальні властивості: антагоністом рецепторів ангіотензину ІІ.
        Використовується для лікування артеріальної гіпертензії та
        постінфарктному періоді. Найбільш частими побічними реакціями є: вірусні
        інфекції, запаморочення, постуральне запаморочення, ортостатична
        гіпотензія, підвищення рівня креатиніну сироватки крові, підвищення
        рівня сечовини крові.
      </span>

      <span>
        Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284
      </span>

      <span>CBG-UA-00828. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <div v-show="!result.win && !result.lose" class="notes-wrapper desk">
      <span>
        Інформація про лікарський засіб. РП №UA/5463/01/02, №UA/5463/01/03. Для
        професійної діяльності медичних та фармацевтичних працівників.
      </span>

      <span>
        Валсартан-Тева (1 таблетка містить валсартану 80 мг або 160 мг).
        Характеристики: таблетки 80 мг: рожеві, круглі, двоопуклі таблетки,
        вкриті плівковою оболонкою, з лінією розлому з двох сторін, логотипом
        «V» з однієї сторони і лініями розлому по боках; таблетки 160 мг: жовті,
        овальні, двоопуклі таблетки, вкриті плівковою оболонкою, з логотипом «V»
        з однієї сторони і лінією розлому з іншої сторони та по боках.
        Лікувальні властивості: антагоністом рецепторів ангіотензину ІІ.
        Використовується для лікування артеріальної гіпертензії та
        постінфарктному періоді. Найбільш частими побічними реакціями є: вірусні
        інфекції, запаморочення, постуральне запаморочення, ортостатична
        гіпотензія, підвищення рівня креатиніну сироватки крові, підвищення
        рівня сечовини крові.
      </span>

      <span>
        Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284
      </span>

      <span>CBG-UA-00828. Термін дії матеріалу – 23.08.2024.</span>
    </div>
  </div>
</template>

<script>
import Timer from "../Timer.vue";
import Element1 from "./el1.vue";
import Element2 from "./el2.vue";
import Element3 from "./el3.vue";
import Element4 from "./el4.vue";
import Element5 from "./el5.vue";
import Element6 from "./el6.vue";
import Element7 from "./el7.vue";
import Element8 from "./el8.vue";
import Element9 from "./el9.vue";
import Element10 from "./el10.vue";
import EWin from "./win.vue";

const items = () => [
  {
    id: 0,
    element: "element1",
    w: 44,
    h: 44, // 14
    position: {top: 0, left: 30},
    angle: 0,
  },
  {
    id: 1,
    element: "element2",
    w: 44,
    h: 53,
    position: {top: 61, left: 0},
    angle: 0,
    disabled: true,
    win: 0,
  },
  {
    id: 2,
    element: "element3",
    w: 92,
    h: 89,
    position: {top: 35, left: 52.5},
    angle: 0,
    win: 180,
  },
  {
    id: 3,
    element: "element4",
    w: 70,
    h: 44,
    position: {top: 20, left: 170},
    angle: 0,
  },
  {
    id: 4,
    element: "element5",
    w: 44,
    h: 38,
    position: {top: 67.5, left: 234.5},
    angle: 0,
    win: 90,
  },
  {
    id: 5,
    element: "element6",
    w: 45,
    h: 30,
    position: {top: 56.5, left: 276},
    angle: 0,
    disabled: true,
    win: 0,
  },
  {
    id: 6,
    element: "element4",
    w: 70,
    h: 44,
    position: {top: 125, left: 9},
    angle: 180,
    win: 90,
  },
  {
    id: 7,
    element: "element2",
    w: 44,
    h: 53,
    position: {top: 112, left: 123},
    angle: 180,
    win: 180,
  },
  {
    id: 8,
    element: "element2",
    w: 44,
    h: 53,
    position: {top: 108, left: 229.7},
    angle: 180,
    win: 180,
  },
  {
    id: 9,
    element: "element7",
    w: 40,
    h: 43,
    position: {top: 139.5, left: 274},
    angle: 0,
    win: 0,
  },
  {
    id: 10,
    element: "element5",
    w: 44,
    h: 38,
    position: {top: 196, left: 21},
    angle: 90,
  },
  {
    id: 11,
    element: "element5",
    w: 44,
    h: 38,
    position: {top: 176, left: 79},
    angle: 0,
    win: 90,
  },
  {
    id: 12,
    element: "element8",
    w: 49,
    h: 36, // 25
    position: {top: 163, left: 118},
    angle: 0,
    win: [0, 180],
  },
  {
    id: 13,
    element: "element4",
    w: 70,
    h: 44,
    position: {top: 144, left: 166},
    angle: 270,
    win: 0,
  },
  {
    id: 14,
    element: "element5",
    w: 44,
    h: 38,
    position: {top: 170.5, left: 233},
    angle: 0,
    win: 90,
  },
  {
    id: 15,
    element: "element7",
    w: 40,
    h: 43,
    position: {top: 234, left: 25},
    angle: 180,
  },
  {
    id: 16,
    element: "element9",
    w: 51,
    h: 44,
    position: {top: 216, left: 78},
    angle: 0,
    win: 0,
  },
  {
    id: 17,
    element: "element1",
    w: 44,
    h: 44, // 14
    position: {top: 227, left: 127},
    angle: 90,
    win: [0, 180],
  },
  {
    id: 18,
    element: "element1",
    w: 44,
    h: 44, // 14
    position: {top: 227, left: 170},
    angle: 0,
    win: [0, 180],
  },
  {
    id: 19,
    element: "element10",
    w: 44,
    h: 53,
    position: {top: 211, left: 213},
    angle: 0,
    win: 0,
  },
  {
    id: 20,
    element: "element4",
    w: 70,
    h: 44,
    position: {top: 280, left: 124},
    angle: 180,
  },
  {
    id: 21,
    element: "element1",
    w: 44,
    h: 44,
    position: {top: 286, left: 244},
    angle: 0,
  },
];

export default {
  name: "Valsartan-game",

  components: {
    Timer,
    Element1,
    Element2,
    Element3,
    Element4,
    Element5,
    Element6,
    Element7,
    Element8,
    Element9,
    Element10,
    EWin,
  },

  data: () => ({
    widthBar: "0px",
    minutes: 0,
    secondes: 0,
    time: 90,
    timer: null,

    widthBox: null,
    winPopup: false,

    result: {
      win: false,
      lose: false,
    },

    winComb: [],

    items: items(),
    showOverlay: false,
    showWin: false,
    msg: "старт!",
  }),

  watch: {
    time: function (val) {
      if (val === 0) {
        window.scrollTo(0, 0);
        this.onSubmit();
      }
    },
  },

  computed: {
    cssVars() {
      return {
        "--width": this.widthBar,
      };
    },
    heightContent() {
      return {
        "--height": this.gameHeight,
      };
    },
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },

  mounted() {
    this.reset();
    window.addEventListener("resize", () => {
      this.widthBar = this.$refs.timerLine.getBoundingClientRect().width + "px";
    });
  },

  methods: {
    start() {
      if (this.timer) clearInterval(this.timer);

      this.showOverlay = false;

      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },

    reset() {
      window.scrollTo(0, 0);
      this.time = 90;
      this.result.lose = false;
      this.result.win = false;

      this.items = items();
      this.showOverlay = true;
      this.showWin = false;
      this.msg = "приготуйтеся!";

      setTimeout(() => {
        this.msg = "старт!";

        this.widthBar =
            this.$refs.timerLine.getBoundingClientRect().width + "px";

        setTimeout(() => this.start(), 2000);
      }, 3000);
    },

    itemClick({id, disabled, angle}) {
      this.items[id].angle = disabled ? angle : (angle + 90) % 360;

      if (this.isWin()) {
        this.onSubmit();
      }
    },

    isWin() {
      return !this.items.reduce((r, {angle, win}) => {
        if (win === void 0) return r;
        return (r -= Array.isArray(win) ? win.includes(angle) : win === angle);
      }, 16);
    },

    getStyle(item) {
      const sizes = {
        element1: {w: 44, h: 44},
        element2: {w: 44, h: 53},
        element3: {w: 92, h: 90},
        element4: {w: 70, h: 44},
        element5: {w: 44, h: 38},
        element6: {w: 44, h: 30},
        element7: {w: 40, h: 44},
        element8: {w: 50, h: 36},
        element9: {w: 50, h: 44},
        element10: {w: 44, h: 53},
      };
      const {w, h} = sizes[item.element];
      const {left, top} = item.position;

      return {
        left: `calc(min(320px, 100vw - 40px) / 320 * ${left})`,
        top: `calc(min(320px, 100vw - 40px) / 320 * ${top})`,
        width: `calc(min(320px, 100vw - 40px) / 320 * ${w})`,
        height: `calc(min(320px, 100vw - 40px) / 320 * ${h})`,
        transform: `rotate(${item.angle}deg)`,
      };
    },

    onSubmit() {
      this.items.forEach((el) => (this.items[el.id].disabled = true));
      this.showOverlay = this.showWin = this.isWin();

      setTimeout(() => {
        this.result[this.showWin ? "win" : "lose"] = true;

        window.scrollTo(0, 0);
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.valsartan-game {
  display: flex;
  flex-direction: column;

  height: 100%;
  margin: 0 auto;
  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);

  .game-bar {
    max-width: 320px;
    margin: 0 auto;

    &__upper {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: var(--width);
      height: 100%;
      display: flex;
    }

    &__timer-section {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-width: 26px;
        width: 100%;
        display: block;
        margin-right: 10px;

        &.green {
          circle {
            stroke: #00a03b;
          }

          path {
            stroke: #00a03b;
          }
        }

        &.yellow {
          circle {
            stroke: #afcb37;
          }

          path {
            stroke: #afcb37;
          }
        }

        &.red {
          circle {
            stroke: #c40000;
          }

          path {
            stroke: #c40000;
          }
        }
      }
    }

    &__timer-line {
      position: relative;
      margin: 10px auto 0;
      display: flex;

      width: 100%;
      background: #424242;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      border: 2px solid #fff;
      border-radius: 20px;
      overflow: hidden;

      &--progress {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        transition: all 0.25s linear;
        overflow: hidden;

        &.green {
          background-color: #00a03b;
        }

        &.yellow {
          background-color: #afcb37;
        }

        &.red {
          background-color: #c40000;
        }
      }

      &--section {
        z-index: 2;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        width: 33.3%;
        height: 30px;
        border-right: 2px solid #fff;

        font-family: $EB;
        font-weight: 800;
        font-size: 12px;
        line-height: 100%;
        color: #fff;

        &.no-border {
          border: none;
        }

        &.red {
          color: #c40000;
          border: none;
        }

        &.green {
          color: #00a03b;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  .game {
    margin: 10px auto 20px;
    padding: 0;

    width: 100%;
    height: 100%;

    &__map {
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      padding: 0px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      background-color: #fff;
      width: calc(min(320px, 100%));
      height: calc(min(360px, 100vw));
      max-width: 320px;

      &--overlay {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        svg {
          width: 100%;
          height: 100%;
        }

        .msg {
          span {
            font-family: $M;
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            color: #005a85;
          }

          &--text {
            font-family: $EB;
            font-size: 28px;
            line-height: 120%;
            color: #00a03b;
          }
        }
      }

      &--item {
        position: absolute;
        // display: flex;
        // align-items: center;

        svg {
          width: 100%;
          height: 100%;
          //   vertical-align: bottom;
        }

        // box-shadow: 0 0 0 1px #ddd inset;
        // &::before {
        //   content: attr(data-id);
        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        //   text-shadow: 0 0 8px #fff;
        //   border-bottom: 2px solid #000;
        // }
      }
    }
  }

  .tip {
    margin: 24px auto 0;
    max-width: 540px;
    text-align: center;

    font-family: $R;
    font-size: 16px;
    line-height: 120%;
    color: #424242;
    opacity: 0.4;
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 610px;
    width: 100%;

    padding: 0 0 30px 0;

    margin: 0 auto;

    &__red-block {
      background: #c40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100%);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #c40000;
    }

    &__img-circle {
      max-width: 440px;
      width: 100%;
      height: auto;
      display: block;
      margin: 8% auto 0 auto;
      border-radius: 50%;
    }

    &__repeat {
      margin: -3% auto 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      padding: 14px 15px;
      background: white;

      max-width: 230px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    position: relative;

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;

    min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);

    &__green-block {
      background: $blue;

      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100%);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $R;
      color: white;
      text-align: center;

      &.mob {
        display: none;
      }

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $blue;
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 75px auto 0 auto;

      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 30px;
      color: $green;
      font-size: 20px;
      line-height: 120%;
      font-family: $B;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {
      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100%);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;
      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 0;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-height: 594px) {
  .valsartan-game {
    .game {
      &__action {
        .blood {
          .item {
            .limpha {
              width: 32px;
            }

            .protect {
              width: 60px;
            }

            &.active {
              transform: translateX(110px);
              transition: transform 6s linear;
            }

            &.hide {
              display: none;
            }
          }
        }

        .hit-zone {
          .green {
            max-width: 52px !important;
          }

          .red {
            max-width: 100px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .valsartan-game {
    padding-top: 0;

    .lose {
      padding-bottom: 0;

      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;

        position: relative;
        top: 0;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 100%;
        margin-top: -7%;
      }

      &__repeat {
        margin: -5% auto 0 auto;

        p {
        }
      }
    }

    .win {
      min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 20px);
      padding: 0 10px;

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        height: 90px;
      }

      &__text {
        font-family: $M;
        font-size: 16px;
        line-height: 120%;

        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 50px;
      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      &__info-text-btn {
        margin-bottom: 40px;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {
          }
        }

        span {
        }
      }

      &__info-btn {
      }

      &__popup {
        padding: 18px 20px 32px 20px;
        bottom: 10px;
        width: calc(100% + 10px);
      }

      &__popup--close-btn {
        margin-bottom: 14px;

        svg {
        }
      }

      &__popup--text {
        font-size: 8px;
        line-height: 8px;
      }
    }

    .notes-wrapper {
      &.desk {
        display: none;
      }
    }

    .game-bar {
      margin-top: 0;

      &__timer-section {
        svg {
          max-width: 26px;
        }
      }
    }

    .game {
      &__action {
        height: calc(
            (var(--vh, 1vh) * 100) - (var(--height_header)) - (var(--height)) -
            80px
        );

        .hit-zone {
          svg {
          }
        }

        .slider {
        }

        .top-img {
        }

        .blood {
          position: absolute;
          bottom: 83px;
          width: 100%;
          display: flex;

          .item {
            &.active {
            }

            &.hide {
              display: none;
            }
          }
        }
      }

      &__btn-wrapper {
      }

      &__grey-text {
        font-size: 14px;
        line-height: 120%;
      }

      &__btn {
        max-width: 230px;
        width: 100%;
        margin: 20px auto 0 auto;

        p {
          font-size: 24px;
          line-height: 26px;

          padding: 10px;

          span {
          }
        }
      }
    }

    .tip {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 434px) {
}
</style>
