<template>
  <div class="Stoptusin">
    <div class="game-stage1" v-show="!result.win && !result.lose">
      <div class="game-bar">
        <div class="game-bar__timer-section">
          <svg
            :class="[{ red: time <= 10 }]"
            viewBox="0 0 28 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="14"
              cy="18.3333"
              r="13"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path d="M17.7913 1H10.208" stroke="#00A03B" stroke-width="2" />
            <path
              d="M14 31.3333L14 28.0833"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M14 8.58331L14 5.33331"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M1 18.3333L4.25 18.3333"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M23.75 18.3333L27 18.3333"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M4.80737 9.14093L7.10547 11.439"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M20.8943 25.2277L23.1924 27.5258"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M23.1926 9.14093L20.8945 11.439"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M7.10571 25.2277L4.80762 27.5258"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M14.0003 18.7246L18.6045 10.75"
              stroke="#00A03B"
              stroke-width="2"
            />
          </svg>

          <timer :class="[{ red: time <= 10 }]" :time="prettyTime"></timer>
        </div>
      </div>

      <div class="game">
        <div class="game__map">
          <div class="game__map--content">
            <img src="../assets/img/game-stoptusin/houses-min.svg" alt="" />

            <div class="game__homes">
              <div
                v-for="(item, i) in activeIcons"
                :key="i"
                class="game__homes--item"
                @click="iconClick(item.key)"
                :style="item"
              >
                <img src="../assets/img/game-stoptusin/icon-min.svg" alt="" />
              </div>
            </div>
          </div>

          <div v-if="overlayText" class="game__map--overlay">
            {{ overlayText }}
          </div>
        </div>

        <div class="game__progress">
          <div class="game__progress--bar bar1" ref="green">
            <div class="bg" :style="{ width: `${progress.s}%` }">
              <!-- <span :style="cssVars">Тиша</span> -->
            </div>
            <span>Тиша</span>
          </div>

          <div class="game__progress--bar bar2">
            <div class="bg" :style="{ width: `${progress.l}%` }">
              <!-- <span :style="cssVars">Шумно</span> -->
            </div>
            <span>Шумно</span>
          </div>

          <div class="game__progress--bar bar3">
            <div class="bg" :style="{ width: `${progress.vl}%` }">
              <!-- <span :style="cssVars">Дуже шумно</span> -->
            </div>
            <span>Дуже шумно</span>
          </div>
        </div>

        <div class="game__tip">
          <span class="grey">
            Подолайте кашель в усіх будинках, натискаючи на них, коли він звідти
            лунає, та не дайте рівню шуму піднятися занадто високо
          </span>
        </div>
      </div>
    </div>

    <div class="win" v-show="result.win">
      <div class="win__green-block green stage1">
        <p class="win__text green">
          <span>Стоптусин-Тева!</span>
          <br />
          Тримай, це для твого сухого нападоподібного кашлю
        </p>

        <div class="win__arrow-down green"></div>
      </div>

      <img
        class="win__img-circle"
        src="../assets/img/game-stoptusin/win.png"
        alt=""
      />

      <div class="win__text-center">
        У Кашлеленді сьогодні панує небувала тиша, адже у Вашу зміну не шумлять!
        Гарна робота!
      </div>
    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">
        <p class="lose__text desk white">
          <span>
            Занадто високий рівень шуму! Спробуйте ще раз та приборкайте кашель
          </span>
          <br />
        </p>

        <div class="lose__arrow-down"></div>
      </div>

      <img
        class="lose__img-circle"
        src="../assets/img/game-atorvastatin/lose.png"
        alt=""
      />

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>
    </div>

    <div v-show="result.lose || result.win" class="notes-wrapper">
      <span>
        Інформація про лікарський засіб. РП №UA/2447/03/01. Для професійної
        діяльності медичних та фармацевтичних працівників.
      </span>
      <span>
        Стоптусин-Тева (1 таблетка містить: бутамірату цитрату 4 мг,
        гвайфенезину 100 мг). Характеристики: таблетки круглої форми, білого
        кольору з лінією розлому з одного боку. Лікувальні властивості: для
        лікування сухого подразливого нападоподібного кашлю різного походження.
        Найбільш частими побічними реакціями є: головний біль, сонливість,
        утруднене дихання тощо.
      </span>
      <span>
        Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284
      </span>
      <span>CBG-UA-00897. Термін дії матеріалу – 10.2024.</span>
    </div>

    <div v-show="!result.win && !result.lose" class="notes-wrapper desk">
      <span>
        Інформація про лікарський засіб. РП №UA/2447/03/01. Для професійної
        діяльності медичних та фармацевтичних працівників.
      </span>
      <span>
        Стоптусин-Тева (1 таблетка містить: бутамірату цитрату 4 мг,
        гвайфенезину 100 мг). Характеристики: таблетки круглої форми, білого
        кольору з лінією розлому з одного боку. Лікувальні властивості: для
        лікування сухого подразливого нападоподібного кашлю різного походження.
        Найбільш частими побічними реакціями є: головний біль, сонливість,
        утруднене дихання тощо.
      </span>
      <span>
        Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284
      </span>
      <span>CBG-UA-00897. Термін дії матеріалу – 10.2024.</span>
    </div>
  </div>
</template>

<script>
import Timer from "./Timer.vue";

const getRandomInt = (max) => Math.floor(Math.random() * max);

export default {
  components: {
    timer: Timer,
  },

  data: () => ({
    time: 30,
    timer: null,
    winPopup: false,

    overlayText: "Приготуйтеся!",

    value: 0,
    valueMax: 30,
    interval: null,
    timeout1: null,
    timeout2: null,

    widthBar: "0px",

    iconCount: 5,
    iconSpawn: 750,
    iconValue: 1,
    stepValue: 1,

    icons: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
      13: false,
      14: false,
      15: false,
    },

    result: {
      win: false,
      lose: false,
    },
  }),

  computed: {
    cssVars() {
      return {
        "--width": this.widthBar,
      };
    },
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },

    progress() {
      return {
        s: this.getVal(1),
        l: this.getVal(2),
        vl: this.getVal(3),
      };
    },

    available() {
      const list = Object.keys(this.icons);

      return list.reduce((r, key) => {
        if (!this.icons[key]) r.push(key);
        return r;
      }, []);
    },

    activeIcons() {
      const list = Object.keys(this.icons);

      return list.reduce((r, key) => {
        if (this.icons[key]) {
          const i = Math.floor(key / 4);
          const j = key - i * 4;

          r.push({
            key,
            top: 25 * i + "%",
            left: 25 * j + "%",
          });
        }
        return r;
      }, []);
    },
  },

  watch: {
    time(value) {
      if (value === 0 || this.getVal(3) === 100) {
        this.onSubmit();
      }
    },
  },

  methods: {
    start() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            // this.reset()
          }
        }, 1000);
      }
    },

    reset() {
      this.time = 30;
      this.result.lose = false;
      this.result.win = false;
      clearInterval(this.timer);
      clearInterval(this.timeout1);
      clearInterval(this.timeout2);
      this.timer = null;
      this.timeout1 = null;
      this.timeout2 = null;

      this.interval = null;

      this.value = 0;

      this.icons = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
      };

      this.value = 0;
      this.valueMax = 30;
      this.iconCount = 5;
      this.iconSpawn = 750;
      this.iconValue = 1;
      this.stepValue = 1;

      this.overlayText = "Приготуйтеся!";
      this.timeout1 = setTimeout(() => (this.overlayText = "Старт!"), 3000);
      this.timeout2 = setTimeout(() => {
        this.overlayText = null;

        this.run();
        this.start();
      }, 4000);

      window.scrollTo(0, 0);
    },

    run() {
      this.interval = setInterval(() => {
        this.tick();

        if (!this.time) {
          clearInterval(this.interval);
        }
      }, this.iconSpawn);
    },

    tick() {
      if (this.value < this.valueMax) {
        this.value += this.stepValue;
      }

      this.addIcon();
    },

    addIcon() {
      if (this.activeIcons.length >= this.iconCount) return;

      const i = getRandomInt(this.available.length);
      this.icons[i] = true;
    },

    iconClick(key) {
      this.value = this.value > 0 ? this.value - this.iconValue : this.value;
      this.icons[key] = false;
    },

    getVal(i = 1) {
      const sections = this.value / (this.valueMax / 3);

      if (sections >= i - 1 && sections < i) {
        return (sections - (i - 1)) * 100;
      }

      if (sections >= i) {
        return 100;
      }

      return 0;
    },

    onSubmit() {
      this.result[this.value > (this.valueMax / 3) * 2 ? "lose" : "win"] = true;
      window.scrollTo(0, 0);

      clearInterval(this.interval);
      clearInterval(this.time);
      clearInterval(this.timer);
      clearInterval(this.timeout1);
      clearInterval(this.timeout2);

      this.time = 30;
      this.timer = null;
      this.timeout1 = null;
      this.timeout2 = null;

      this.interval = null;
    },
  },

  mounted() {
    this.overlayText = "Приготуйтеся!";
    this.timeout1 = setTimeout(() => (this.overlayText = "Старт!"), 3000);
    this.timeout2 = setTimeout(() => {
      this.overlayText = null;

      this.run();
      this.start();
    }, 4000);

    this.$nextTick(() => {
      this.widthBar = this.$refs.green.getBoundingClientRect().width + "px";
    });

    window.addEventListener("resize", () => {
      this.widthBar = this.$refs.green.getBoundingClientRect().width + "px";
    });
  },

  beforeDestroy() {
    clearTimeout(this.timeout1);
    clearTimeout(this.timeout2);
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.Stoptusin {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 40px;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);
  height: 100%;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;

  .timer {
    font-family: $B;
  }

  .game-bar {
    display: flex;
    justify-content: space-between;

    max-width: 650px;
    padding: 0 20px;
    margin: 0 auto;

    &__timer-section {
      margin: auto;
      display: flex;
      align-items: center;

      svg {
        max-width: 28px;
        width: 100%;
        display: block;
        margin-right: 10px;

        &.red {
          circle {
            stroke: #c40000;
          }

          path {
            stroke: #c40000;
          }
        }
      }
    }
  }

  .game {
    &__map {
      position: relative;
      margin: 20px auto 30px;

      padding: 30px 20px;
      width: 100%;
      max-width: 320px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      overflow: hidden;

      img {
        width: 100%;
      }

      &--content {
        position: relative;
      }

      &--overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ffffff88;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: $EB;
        font-weight: 800;
        font-size: 28px;
        line-height: 120%;

        color: #00a03b;
      }
    }

    &__homes {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &--item {
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;

        width: 30%;
        height: 25%;

        margin: 0 -2.5%;

        img {
          position: relative;
          top: -20px;
          //   left: 5px;
          width: 100%;
          @media screen and (min-width: 1023px) {
            &:hover {
              transition: opacity 0.2s ease;
              opacity: 0.4;
            }
          }

          &:active {
            opacity: 0.4;
          }
        }
      }
    }

    &__progress {
      margin-top: 25px;
      margin-bottom: 20px;
      max-width: 610px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      display: flex;
      border: 2px solid #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      overflow: hidden;
      background-color: #424242;
      color: #fff;

      &--bar {
        position: relative;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 33.333%;
        height: 30px;
        border-right: 2px solid #fff;
        text-align: center;

        span {
          z-index: 10;
          position: relative;

          font-family: $EB;
          font-weight: 800;
          font-size: 12px;
          line-height: 120%;
        }

        &:last-child {
          border: none;
        }

        &.bar1 {
          //   color: #00a03b;
          // > span {
          //   color: #00a03b;
          //   position: relative;
          //   z-index: 1;
          // }

          .bg {
            background-color: #00a03b;
          }
        }

        &.bar2 {
          //   color: #afcb37;
          // > span {
          //   color: #afcb37;
          //   position: relative;
          //   z-index: 1;
          // }

          .bg {
            background-color: #afcb37;
          }
        }

        &.bar3 {
          //   color: #c40000;
          // > span {
          //   color: #c40000;
          //   position: relative;
          //   z-index: 1;
          // }

          .bg {
            background-color: #c40000;
          }
        }

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: rgba(120, 120, 120, 0.5);
          overflow: hidden;
          z-index: 2;

          span {
            //top: 8px;
            //left: 40px;

            position: absolute;
            height: 30px;
            width: var(--width);

            left: -1px;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
        }
      }
    }

    &__tip {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      font-weight: 700;
      font-size: 14px;
      line-height: 120%;

      font-family: $M;
      font-size: 14px;
      line-height: 120%;

      max-width: 610px;
      width: 100%;
      margin: 0 auto;

      .grey {
        color: $grey;
        opacity: 0.2;
      }

      .red {
        color: #c40000;
      }
    }
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    margin: 0 auto;

    &__red-block {
      background: #c40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100%);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #c40000;
    }

    &__img-circle {
      max-width: 440px;
      width: 100%;
      height: auto;
      display: block;
      margin: 6% auto 0 auto;
      border-radius: 50%;
    }

    &__repeat {
      margin: -3% auto 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      padding: 14px 15px;
      background: white;

      max-width: 230px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    position: relative;

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;

    &__green-block {
      background: $green;
      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100%);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;
      height: 110px;
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      &.mob {
        display: none;
      }

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $green;

      &.blue {
        border-top: 10px solid $blue;
      }
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 40px auto 0 auto;

      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      color: $green;
      font-size: 24px;
      line-height: 120%;
      font-family: $B;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {
      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100% - 20px);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;
      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1270px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 0;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }

  @media screen and (max-width: 600px) {
    padding-top: 20px !important;
    min-height: calc(
      (var(--vh, 1vh) * 100) - (var(--height_header))
    ) !important;

    .game {
      &__map {
        margin-top: 10px;
        margin-bottom: 14px;
      }

      &__progress {
        margin-top: 14px;
        margin-bottom: 14px;
      }

      &__tip {
        margin-bottom: 20px;
      }
    }

    .lose {
      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;

        position: relative;
        top: 0;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 100%;
        margin-top: -4%;
      }

      &__repeat {
        margin: -5% auto 0 auto;

        p {
        }
      }
    }

    .win {
      padding-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;

        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 30px;
      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      &__info-text-btn {
        margin-bottom: 0;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {
          }
        }

        span {
        }
      }

      &__info-btn {
      }

      &__popup {
        padding: 18px 20px 26px 20px;
        bottom: 10px;
      }

      &__popup--close-btn {
        margin-bottom: 7px;

        svg {
        }
      }

      &__popup--text {
        font-size: 8px;
        line-height: 8px;
      }
    }

    .notes-wrapper {
      &.desk {
        display: none;
      }
    }
  }
}
</style>
