<template>
  <div class="answers">

    <div class="main-content">

      <h1 class="h1-title">Переваги Лiнiйки бренду Тева</h1>

      <div class="col-wrapper">

        <div class="col-left">

          <div v-if="value !== 3" ref="blocks" :id="`${value+1}`" :class="`section${value+1} section section-desk`"
               v-for="(key, value ) in  getAnswerData.img">
            <img
                :src="`${$root.globalUrl}/uploads/mnn/${key}`">
          </div>

          <div v-if="value === 3" ref="blocks" id="3" :class="`section3 section section-desk`"
               v-for="(key, value ) in  getAnswerData.img">
            <img
                :src="`${$root.globalUrl}/uploads/mnn/${key}`">
          </div>

          <div ref="blocks" :id="`${value+1}`" :class="`section${value+1} section section-mob`"
               v-for="(key, value ) in  getAnswerData.imgMob">
            <img
                :src="`${$root.globalUrl}/uploads/mnn/${key}`">
          </div>

        </div>

        <div class="col-right">

          <div class="page-navigation-wrapper">
            <p :onclick="`gtag('event', 'click', {  'event_category': 'нажатие на якорь: ${key}',  'event_label': '${$route.name}'})`"
               v-bind:class="[activeBlock === `${value+1}` ? activeClass : '']"
               @click="scrollToBlock(`section${value+1}`)" class="page-navigation"
               v-for="(key, value) in getAnswerData.linkText">{{ key }}</p>
          </div>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios"
import TweenMax from 'gsap/TweenMax'
import TweenLite from 'gsap/TweenLite'
import 'gsap/ScrollToPlugin'
import {mapGetters} from "vuex";


export default {
  name: "answers",


  data: () => ({

    swiperOption: {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: true
      },
    },

    activeBlock: "1",

    activeClass: "active",


  }),


  computed: {
    ...mapGetters([
      'getAnswerData'
    ])
  },
  created() {

    document.title = "Лінійка бренду Тева";
  },

  mounted() {

    document.addEventListener('scroll', () => {

      var h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

      var percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);

      if (percent >= 25 && percent < 50 && this.trigger25) {
        this.trigger25 = false
        gtag('event', 'scroll', {'event_category': 'moreThan25%', 'event_label': this.$route.name})
      } else if (percent >= 50 && percent < 75 && this.trigger50) {
        this.trigger50 = false
        gtag('event', 'scroll', {'event_category': 'moreThan50%', 'event_label': this.$route.name})
      } else if (percent >= 75 && percent < 90 && this.trigger75) {
        this.trigger75 = false
        gtag('event', 'scroll', {'event_category': 'moreThan75%', 'event_label': this.$route.name})
      } else if (percent >= 90 && this.trigger90) {
        this.trigger90 = false
        gtag('event', 'scroll', {'event_category': 'moreThan90%', 'event_label': this.$route.name})
      }

      return false
    });

    this.$nextTick(() => {

      document.title = "Лінійка бренду Тева";

      window.onscroll = () => {

        this.$refs.blocks.forEach(ha => {

          const rect = ha.getBoundingClientRect();

          if (rect.top > 0 && rect.top < 200) {
            this.activeBlock = ha.id.toString();
          }
        });
      }
    })
  },

  methods: {

    scrollToBlock(className) {
      let rect = document.querySelector("." + className).getBoundingClientRect();

      window.scroll({
        top: rect.top + window.scrollY - 90,
        left: 0,
        behavior: 'smooth'
      });

    }
  }
}
</script>

<style lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";


.answers {
  position: relative;

  min-height: calc(100vh);
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_content));

  max-width: 1270px;
  width: 100%;
  margin: 0 auto;

  padding: 75px 20px;

  @media screen and (max-width: 768px) {
    padding: 29px 15px;

  }


  .main-content {

    max-width: 1240px;
    width: 100%;
    margin: 0 auto 0 auto;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }

    .banner-wrapper, .single-img-wrapper {
      width: 100%;

      img {
        display: block;
        width: 100%;
        height: auto;

        &.desktop {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        &.mob {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
          }
        }
      }
    }

    .h1-title {
      color: #424242;
      font-family: $B;
      font-size: 48px;
      margin-bottom: 29px;
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    .col-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .col-left {
        max-width: 900px;
        width: 100%;
        @media screen and (max-width: 1000px) {
          max-width: 1000px;
        }

        .section {
          width: 100%;

          &.section-desk {
            @media screen and (max-width: 768px) {
              display: none;
            }

            &.section1 {
              margin-bottom: 70px;
            }

            &.section2 {
              margin-bottom: 38px;
            }
            &.section3 {
              margin-bottom: 12px;
            }
          }

          &.section-mob {
            display: none;
            padding: 0;
            margin: 0;

            &.section1 {
              margin-top: 0;
              margin-bottom: 70px;
            }

            &.section3 {
              margin-top: 60px;
              margin-bottom: -24px;
            }

            &.section4 {
              margin-bottom: 0;
            }

            img {
              max-width: 425px;
              width: 100%;
            }

            @media screen and (max-width: 768px) {
              display: flex;
              justify-content: center;
            }
          }

          &:first-child {
            margin-top: 0;
          }

          img {
            width: 100%;
            height: auto;
          }

          @media screen and (max-width: 768px) {
            padding: 10px;
          }
        }
      }

      .col-right {
        margin-left: 10px;
        max-width: 300px;
        width: 100%;
        @media screen and (max-width: 1000px) {
          display: none;
        }

        .page-navigation-wrapper {
          position: sticky;
          top: 112px;
          z-index: 2;

          .page-navigation {
            padding-left: 15px;
            color: #35786E;
            font-family: $R;
            font-size: 18px;
            margin-bottom: 30px;
            transition: all 0.2s ease;
            position: relative;
            cursor: pointer;

            &:before {
              transition: all 0.2s ease;
              content: '';
              position: absolute;
              width: 7px;
              height: 0;
              left: 0;
              bottom: 0;
              background: #35786E;

            }

            &.active, &:hover {
              &:before {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>